import { Button, Grid, Typography } from "@material-ui/core";
import React, { PureComponent } from "react";
import { Some } from "../../utils/Some";

interface Props {
  name: string;
  text: string;
  buttonText: string;
  handleClick: () => void;
  secondaryButton?: {
    buttonText: string;
    handleClick: () => void;
  };
  testId?: string;
}
class CommonHeader extends PureComponent<Props> {
  handleClick = () => {
    const { handleClick } = this.props;
    handleClick();
  };
  render() {
    const { name, text, buttonText, secondaryButton, testId } = this.props;

    return (
      <Grid container spacing={0} style={{ alignContent: "center", alignItems: "center" }}>
        <Grid item xs={Some(secondaryButton) ? 2 : 3}>
          <Typography gutterBottom variant="h3" className="headerTitle" data-testid={testId && `${testId}HeaderTitle`}>
            {name}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <p className="text font-normal">{text}</p>
        </Grid>
        <Grid item xs={Some(secondaryButton) ? 4 : 3} className="buttonRight">
          {Some(secondaryButton) ? (
            <Grid container spacing={8} alignItems="center">
              <Grid item xs={6}>
                <Button
                  aria-label="Add"
                  variant="contained"
                  color="primary"
                  onClick={this.handleClick}
                  data-testid={testId && `${testId}PrimaryButton-CommonHeader`}
                >
                  {buttonText}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  aria-label="Add"
                  variant="contained"
                  color="primary"
                  onClick={secondaryButton.handleClick}
                  data-testid={testId && `${testId}SecondaryButton-CommonHeader`}
                >
                  {secondaryButton.buttonText}
                </Button>
              </Grid>
            </Grid>
          ) : (
            buttonText && (
              <Button
                aria-label="Add"
                variant="contained"
                color="primary"
                onClick={this.handleClick}
                data-testid={testId && `${testId}Button`}
              >
                {buttonText}
              </Button>
            )
          )}
        </Grid>
      </Grid>
    );
  }
}

export default CommonHeader;
