import { Grid, Modal, createStyles, withStyles } from "@material-ui/core";
import React, { PureComponent } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { PoliciesTable } from "../CreateEvent/PoliciesTable";

const styles = (theme: any) =>
  createStyles({
    backdrop: {
      background: "linear-gradient(67.73deg, #0E69F1 1.08%, #20A2F9 100%);",
      backgroundColor: "black",
      opacity: 0.9,
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "6px",
      boxShadow: theme.shadows[5],
      left: "50%",
      maxHeight: "calc(100vh - 240px)",
      overflowY: "scroll",
      padding: theme.spacing.unit * 2,
      position: "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: theme.spacing.unit * 120,
    },
    root: {
      height: "200px",
      marginTop: theme.spacing.unit * 3,
      overflowX: "hidden",
      overflowY: "auto",
      width: "100%",
    },
  });

interface dataObject {
  name: string;
  val: any;
}

interface Props {
  readonly show: boolean;
  readonly handleClose: () => void;
  readonly handleChange: (data: dataObject) => void;
  readonly classes: {
    readonly root: string;
    readonly paper: string;
    readonly backdrop: string;
  };
  readonly policyList: Policy[];
  readonly selectPolicyList: Policy[];
}

class AddPolicy extends PureComponent<Props, {}> {
  render() {
    const { classes, show, handleClose, policyList, handleChange, selectPolicyList } = this.props;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick
        open={show}
        onClose={handleClose}
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
      >
        <div className={classes.paper}>
          <Grid container>
            <Grid item>
              <CloseIcon onClick={handleClose} className="close_icon" data-testid="closeIcon-AddPolicy" />
            </Grid>
          </Grid>
          <h4 data-testid="header-AddPolicy">Select Policies</h4>
          <PoliciesTable
            policyList={policyList}
            handleChange={handleChange}
            selectPolicyList={selectPolicyList}
            isRowSelectionAvailable
          />
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(AddPolicy);
