/* eslint-disable react/no-multi-comp */
/* eslint-disable no-negated-condition */
import { Button, Col, Icon, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { getVirtualKioskEvents, updateVirtualKiosk } from "../../../constant/api";
import { Colors } from "../../../theme/Colors";
import { Fonts } from "../../../theme/Fonts";
import { KioskConfirmation } from "../../../components/UI-Components/KioskConfirmation";
import { KioskNameModal } from "../../UI-Components/KioskNameModal";
import { ShareVirtualKiosk } from "../../Web/EventCheckins/Modals/ShareVirtualKiosk";
import { VirtualKioskTable } from "./VirtualKioskTable";
import { useDefaultErrorHandler } from "../../../utils/useDefaultErrorHandler";

const styles = StyleSheet.create({
  activeButton: {
    color: Colors.lightBlue,
    fontFamily: Fonts.lato,
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "22px",
  },
  inactiveButton: {
    color: Colors.cyanBlue,
  },
  searchContainer: {
    background: Colors.searchbarColor,
    borderRadius: "50px",
    borderWidth: "1px",
    marginBottom: "22px",
    marginLeft: "63%",
    width: 184,
  },
});

interface Props {
  readonly activeVirtualKiosk: Array<VirtualKiosk>;
  readonly pageCount: number;
  readonly totalPages: number;
  readonly notify: (arg1: string, arg2: string) => void;
  readonly isVirtualKioskTab: boolean;
}

export const ActiveInactiveTabs: React.SFC<Props> = ({
  activeVirtualKiosk,
  pageCount,
  totalPages,
  notify,
  isVirtualKioskTab,
}) => {
  let totalPageCount = totalPages;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [kioskId, setKioskId] = useState<number>();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [UUID, setUUID] = useState<string>("");
  const [events, setEvents] = useState<Array<VirtualKiosk>>(activeVirtualKiosk);
  const [showKioskNameModal, setKioskNameModal] = useState<boolean>(false);
  const [virtualKiosk, setVirtualKiosk] = useState<{ name: string; kioskId: number }>({
    kioskId: 0,
    name: "",
  });
  const [activeTab, setActiveTab] = useState<number>(1);
  const [searchEvent, setSearchEvent] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [isVirtualKioskTabActive, setIsVirtualKioskTabActive] = useState<boolean>(true);
  const { errorHandler } = useDefaultErrorHandler();

  useEffect(() => {
    if (pageNumber === pageCount) {
      setIsVirtualKioskTabActive(!!isVirtualKioskTab);
      getKioskEvents(isVirtualKioskTab && isVirtualKioskTabActive ? 1 : 0, pageNumber);
    } else {
      setIsVirtualKioskTabActive(!!isVirtualKioskTabActive);
      getKioskEvents(isVirtualKioskTabActive ? 1 : 0, pageNumber);
    }
  }, [pageNumber]);

  const registerScroll = () => {
    const tableWrapper = document.getElementById("table");
    if (tableWrapper) {
      tableWrapper.onscroll = () => {
        if (tableWrapper.scrollTop > tableWrapper.scrollHeight - tableWrapper.offsetTop - 1000) {
          if (!loading && pageNumber < totalPageCount) {
            setPageNumber(pageNumber + 1);
          }
        }
      };
    }
  };

  const showShareURLModal = (UUID: string) => {
    setUUID(UUID);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const kioskNameModal = (event: VirtualKiosk) => {
    setVirtualKiosk(Object.assign({}, virtualKiosk, event));
    setKioskNameModal(true);
  };

  const kioskhandleClose = () => {
    setKioskNameModal(false);
  };

  const handleSaveName = async (params: { name: string; kioskId: number }) => {
    try {
      const response = await updateVirtualKiosk(params);
      if (response.data.success) {
        notify("Kiosk name updated successfully.", "success");
        getKioskEvents(activeTab, pageNumber);
      }
      setKioskNameModal(false);
    } catch (error) {
      notify(error.message, "error");
    }
  };

  const showActivateModal = (kioskId: number | undefined, isActive: boolean | undefined) => {
    setIsVisible(true);
    if (kioskId !== undefined && isActive !== undefined) {
      setIsActive(isActive);
      setKioskId(kioskId);
    }
  };

  const onCancel = () => {
    setIsVisible(false);
  };

  const onActivate = async () => {
    setIsVisible(false);
    if (kioskId !== undefined && isActive !== undefined) {
      const params = { active: !isActive, kioskId };
      try {
        const response = await updateVirtualKiosk(params);
        if (response) {
          if (isActive) {
            await handleTabChange("1");
          } else {
            await handleTabChange("2");
          }
        }
      } catch (error) {
        notify(error.message, "error");
      }
    }
  };

  const handleTabChange = async (key: string) => {
    if (key === "1") {
      getKioskEvents(1, 1);
    }
    if (key === "2") {
      getKioskEvents(0, 1);
    }
  };

  const handleActiveEvents = async () => {
    setActiveTab(1);
    setSearchEvent("");
    getKioskEvents(1, 1);
    setIsVirtualKioskTabActive(true);
  };

  const handleInactiveEvents = async () => {
    setActiveTab(2);
    setSearchEvent("");
    getKioskEvents(0, 1);
    setIsVirtualKioskTabActive(false);
  };

  const getKioskEvents = async (active: number, pageNumber: number) => {
    try {
      setLoading(true);
      const response = await getVirtualKioskEvents(active, pageNumber);
      if (pageNumber === 1) {
        setEvents(response.data.item);
      } else if (response.data.item.length > 0) {
        setEvents(events.concat(response.data.item));
      }
      totalPageCount = response.data.metadata.totalPages;

      setPageNumber(pageNumber);
      setLoading(false);
      registerScroll();
    } catch (error: unknown) {
      errorHandler(error);
    }
  };

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setSearchEvent(e.target.value);
      if (e.target.value.length > 0) {
        const response = await getVirtualKioskEvents(activeTab, 1, e.target.value);
        setEvents(response.data.item);
      } else {
        getKioskEvents(activeTab, 1);
        registerScroll();
      }
    } catch (error: unknown) {
      errorHandler(error);
    }
  };

  const handleVirtualKioskTable = () => {
    return (
      <VirtualKioskTable
        activities={events}
        handleKioskNameModal={kioskNameModal}
        handleShowShareURLModal={showShareURLModal}
        handleShowActivateModal={showActivateModal}
        activeTab={activeTab}
        isVirtualKioskTabActive={isVirtualKioskTabActive}
      />
    );
  };

  return (
    <div className="tab_container">
      <Row type="flex" justify="start" align="top">
        <Col span={8}>
          <Button
            data-testid="activeTab-ActiveInactiveTabs"
            type="link"
            onClick={handleActiveEvents}
            className={
              isVirtualKioskTabActive ? css(styles.activeButton) : css(styles.activeButton, styles.inactiveButton)
            }
          >
            Active
          </Button>
          <Button
            data-testid="inactiveTab-ActiveInactiveTabs"
            type="link"
            onClick={handleInactiveEvents}
            className={
              !isVirtualKioskTabActive ? css(styles.activeButton) : css(styles.activeButton, styles.inactiveButton)
            }
          >
            Inactive
          </Button>
        </Col>
        <Col span={4}>
          <Input
            data-testid="searchInputField-ActiveInactiveTabs"
            placeholder="Search"
            prefix={<Icon type="search" style={{ color: Colors.darkGrey, fontSize: "10px" }} />}
            className={css(styles.searchContainer)}
            onChange={handleSearch}
            value={searchEvent}
          />
        </Col>
      </Row>
      {handleVirtualKioskTable()}
      {isVisible && (
        <KioskConfirmation visible={isVisible} onCancel={onCancel} onActivate={onActivate} isActive={isActive} />
      )}
      {showModal && <ShareVirtualKiosk handleClose={handleClose} show={showModal} UUID={UUID} />}
      {showKioskNameModal && (
        <KioskNameModal
          handleCloseModal={kioskhandleClose}
          showModal={showKioskNameModal}
          virtualKiosk={virtualKiosk}
          handleSaveName={handleSaveName}
        />
      )}
    </div>
  );
};
