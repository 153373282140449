import { Button, Card, Col, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../theme/Colors";
import { Fonts } from "../../theme/Fonts";
import { Header } from "./Layout/Header";
import LocationIcon from "../../assets/Images/Icons/location-icon.svg";
import { getReverseGeocodingInfo } from "./constant/api";
import history from "../../history";

const styles = StyleSheet.create({
  activityText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "18px",
    letterSpacing: "0.15px",
    marginTop: "5px",
    wordBreak: "break-word",
  },
  buttonWrapper: {
    alignItems: "center",
    background: Colors.defaultButtonBg,
    color: Colors.white,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    fontWeight: 500,
    height: "48px",
    justifyContent: "center",
    letterSpacing: "1.25px",
    lineHeight: "16px",
    marginTop: "20px",
    textTransform: "uppercase",
    width: "327px",
  },
  cardWrapper: {
    borderRadius: "4px",
    display: "flex",
    width: "327px",
  },
  checkinText: {
    color: Colors.sherpa,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.15px",
    marginTop: "10px",
    textAlign: "left",
  },
  contentWrapper: {
    alignItems: "center",
    height: "auto",
    justifyContent: "center",
    minWidth: "375px",
  },
  differentLocationText: {
    color: Colors.darkBlue,
    cursor: "pointer",
    fontFamily: Fonts.roboto,
    fontSize: "15px",
    marginTop: "10px",
    padding: "15px 0px",
  },
  flexWrapper: {
    textAlign: "left",
    width: "330px",
  },
  footerWrapper: {
    alignItems: "center",
    backgroundColor: Colors.white,
    bottom: "0px",
    height: "100px",
    justifyContent: "center",
    minWidth: "375px",
    position: "sticky",
    textAlign: "center",
  },
  headerText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "24px",
    letterSpacing: "0.25px",
    textAlign: "left",
  },
  image: {
    height: "14px",
    width: "10px",
  },
  locationText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "15px",
    marginTop: "10px",
    wordBreak: "break-word",
  },
  locationWrapper: {
    height: "60px",
    marginTop: "15px",
  },
});

interface Props
  extends RouteComponentProps<
    {},
    {},
    {
      actionType: string;
      eventData: {
        readonly active: boolean;
        readonly createdAt: string;
        readonly createdBy?: null;
        readonly event: eventName;
        readonly eventId: number;
        readonly kioskEventId: number;
        readonly kioskId: number;
        readonly sequence?: null;
        readonly updatedAt: string;
        readonly updatedBy?: null;
        readonly visible: boolean;
      };
      hasBackButton: boolean;
      latitude: number;
      locationType: string;
      longitude: number;
      positionError?: string;
    }
  > {
  readonly notify: (message: string, status: string) => void;
}

const DeviceLocation = ({ notify, location }: Props) => {
  const { Content } = Layout;
  const { push, goBack } = history;
  const [address, setAddress] = useState("");

  const getFormattedAddress = async (latitude: number, longitude: number) => {
    try {
      const response = await getReverseGeocodingInfo(latitude, longitude);
      const {
        geometry: { location },
        formatted_address,
        place_id,
      } = response.data.results[0];
      const locationDetails = { ...location, formatted_address, place_id };

      setAddress(formatted_address);
      localStorage.setItem("deviceLocation", JSON.stringify(locationDetails));
    } catch (error) {
      notify("Unable to fetch the location. Please try again.", "error");
    }
  };

  const handleCheckin = () => {
    const deviceLocation = localStorage.getItem("deviceLocation");
    const deviceLocationDetails = deviceLocation ? JSON.parse(deviceLocation) : null;

    const manualLocation = localStorage.getItem("manualLocation");
    const manualLocationDetails = manualLocation ? JSON.parse(manualLocation) : null;

    const locationData = {
      deviceLatitude: deviceLocationDetails ? deviceLocationDetails.lat : null,
      deviceLocation: deviceLocationDetails ? deviceLocationDetails.formatted_address : null,
      deviceLongitude: deviceLocationDetails ? deviceLocationDetails.lng : null,
      devicePlaceId: deviceLocationDetails ? deviceLocationDetails.place_id : null,
      locationType: location.state.locationType,
      manualLatitude: manualLocationDetails ? manualLocationDetails.lat : null,
      manualLocation: manualLocationDetails ? manualLocationDetails.formatted_address : null,
      manualLongitude: manualLocationDetails ? manualLocationDetails.lng : null,
      manualPlaceId: manualLocationDetails ? manualLocationDetails.place_id : null,
      relatedTo: location.state.actionType,
      remarks: location.state.positionError ? location.state.positionError : null,
    };
    localStorage.setItem("locationData", JSON.stringify(locationData));

    if (location.state.actionType === "checkin") {
      history.push({ pathname: "/web-checkin/checkInWith", state: { eventData: location.state.eventData } });
    } else {
      history.push({ pathname: "/web-checkin/checkoutwith", state: { eventData: location.state.eventData } });
    }
  };

  useEffect(() => {
    if (location.state !== undefined) {
      const { eventData, locationType } = location.state;
      localStorage.setItem("eventData", JSON.stringify(eventData));

      switch (locationType) {
        case "device": {
          const { latitude, longitude } = location.state;
          getFormattedAddress(latitude, longitude);
          break;
        }
        case "manual": {
          const manualLocation = localStorage.getItem("manualLocation");
          if (manualLocation) {
            const manualLocationDetails = JSON.parse(manualLocation);
            setAddress(manualLocationDetails.formatted_address);
          } else {
            throw new Error(`Somehow did not found address for locationType: ${locationType}`);
          }
          break;
        }
        default:
          throw new Error(`Somehow got invalid LocationType: ${locationType}`);
      }
    }
  }, []);

  const navigateToInputLocation = () => {
    push({
      pathname: "/web-checkin/input-location",
      state: { actionType: location.state.actionType, eventData: location.state.eventData, hasBackButton: true },
    });
  };

  return (
    <>
      {(location.state.hasBackButton || location.state.hasBackButton === undefined) && (
        <Header backArrow exitButton handleExit={() => push("/web-checkin/home")} handleGoBack={() => goBack()} />
      )}
      <Content>
        <div className={css(styles.contentWrapper)}>
          <Row type="flex" justify="space-around" align="middle">
            <Row className={css(styles.flexWrapper)} justify="space-around">
              <h3 className={css(styles.checkinText)}>
                {location.state.actionType === "checkin" ? "Check-in" : "Check-out"}
              </h3>
              <h3 className={css(styles.headerText)}>
                Use this location to check <span>{location.state.actionType === "checkin" ? "into" : "out"}</span> this
                activity?
              </h3>
              <Card className={css(styles.cardWrapper)}>
                <div className="guest_checkin" style={{ display: "grid" }}>
                  <span className={css(styles.activityText)} data-testid="activityName-DeviceLocation">
                    {location.state.eventData.event.name}
                  </span>
                </div>
                <Row className={css(styles.locationWrapper)}>
                  <Col span={2}>
                    <img className={css(styles.image)} src={LocationIcon} />
                  </Col>
                  <Col span={22}>
                    <span className={css(styles.locationText)} data-testid="location-DeviceLocation">
                      {address}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={2}></Col>
                  <Col span={22}>
                    <h4
                      onClick={navigateToInputLocation}
                      className={css(styles.differentLocationText)}
                      data-testid="differentLocationLink-DeviceLocation"
                    >
                      Enter a different location
                    </h4>
                  </Col>
                </Row>
              </Card>
            </Row>
          </Row>
        </div>
      </Content>
      <div className={css(styles.footerWrapper)}>
        <Button
          className={css(styles.buttonWrapper)}
          onClick={handleCheckin}
          data-testid={`${location.state.actionType}Button-DeviceLocation`}
        >
          {location.state.actionType === "checkin" ? "Check in" : "Check out"}
        </Button>
      </div>
    </>
  );
};

export default withRouter(DeviceLocation);
