import { TableCell, TableRow } from "@material-ui/core";
import React from "react";

interface Props {
  readonly eventAttendeeId: number;
  readonly firstName: string;
  readonly email: string;
  readonly lastName: string;
}

export const ShowEventAttendeeDetails: React.FC<Props> = ({ eventAttendeeId, firstName, lastName, email }) => {
  return (
    <TableRow key={eventAttendeeId}>
      <TableCell component="th" scope="row" className="record_data">
        {firstName}&nbsp;&nbsp;{lastName}
      </TableCell>
      <TableCell align="right" className="record_data">
        {email}
      </TableCell>
    </TableRow>
  );
};
