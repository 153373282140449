import { Button, Icon, Layout, Row } from "antd";
import React, { useState } from "react";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../theme/Colors";
import { Fonts } from "../../theme/Fonts";
import LoadingScreen from "../UI-Components/LoadingScreen";
import { getCheckInAccessMerit } from "./constant/api";
import history from "../../history";
import loader from "../../assets/Images/gifs/circles-menu.gif";

const styles = StyleSheet.create({
  bottomContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    background: Colors.checkinButtonBg,
    color: Colors.white,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    fontWeight: 500,
    height: "48px",
    letterSpacing: "1.25px",
    lineHeight: "16px",
    textTransform: "uppercase",
    width: "327px",
  },
  buttonContainer: {
    alignItems: "center",
    background: Colors.white,
    bottom: "0",
    boxShadow: `0px -1px 0px ${Colors.inActiveGray}`,
    display: "flex",
    height: "100px",
    justifyContent: "center",
    position: "sticky",
    width: "375px",
  },
  circle: {
    alignItems: "center",
    border: `3px solid ${Colors.green}`,
    borderRadius: "80px",
    display: "flex",
    height: "80px",
    justifyContent: "center",
    width: "80px",
  },
  contentContainer: {
    paddingTop: "28px",
    textAlign: "center",
    width: "327px",
  },
  contentText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "24px",
    letterSpacing: " 0.25px",
    lineHeight: "32px",
  },
  description: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.15px",
    lineHeight: "24px",
    paddingTop: "13px",
  },
  headerText: {
    color: Colors.sherpa,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "0.15px",
    lineHeight: "30px",
    textAlign: "center",
  },
  iconContainer: {
    float: "right",
    paddingTop: "15px",
  },
  imageContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    paddingTop: "31px",
  },
  mainContainer: {
    height: "31px",
    paddingTop: "14px",
    textAlign: "center",
    width: "327px",
  },
  step: {
    color: Colors.headerText,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
    paddingTop: "55px",
    textTransform: "uppercase",
  },
});

const { Content } = Layout;
const { push } = history;

interface LocationState {
  eventId: number;
  memberId: number;
}

interface Props extends RouteComponentProps<{}, {}, LocationState> {
  readonly orgDetails: {
    readonly name: string;
  };

  readonly notify: (message: string, status: string) => void;
}
const CheckInAccessMerit: React.FC<Props> = ({ orgDetails: { name }, notify, location }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const getAccessMerit = async () => {
    try {
      setLoading(true);
      if (location.state !== undefined) {
        const { eventId, memberId } = location.state;
        const params = { allowDuplicates: false, eventId: eventId, memberId: memberId };
        const response: any = await getCheckInAccessMerit(params);
        if (response.data.success) {
          const { metQualification, attendeeDetails, eventAttendeeId, hasWaiver } = response.data.item;
          if (metQualification) {
            if (hasWaiver) {
              push({
                pathname: "/web-checkin/waiver-form",
                state: { attendeeDetails: attendeeDetails, eventAttendeeId: eventAttendeeId },
              });
            } else {
              push({ pathname: "/web-checkin/checkin-success", state: { attendeeDetails: attendeeDetails } });
            }
          } else {
            push({ pathname: "/web-checkin/checkin-failure", state: { attendeeDetails: attendeeDetails } });
          }
        } else {
          notify("You have not granted permission yet.", "error");
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <LoadingScreen key={1} minHeight="calc(100vh - 125px)" />
      ) : (
        <React.Fragment>
          <Content>
            <Row type="flex" justify="space-around" align="middle" style={{ flexDirection: "column" }}>
              <div className={css(styles.mainContainer)}>
                <span className={css(styles.headerText)}>Awaiting for you to grant access</span>
                <div className={css(styles.iconContainer)}>
                  <Icon
                    type="close"
                    onClick={() => {
                      push("/web-checkin/home");
                    }}
                  />
                </div>
              </div>
              <div className={css(styles.imageContainer)}>
                <div className={css(styles.circle)}>
                  <img src={loader} />
                </div>
              </div>
              <div className={css(styles.contentContainer)}>
                <h1 className={css(styles.contentText)}>Check your device and grant access permission to {name}</h1>
                <p className={css(styles.step)}>step-1</p>
                <p className={css(styles.description)}>
                  On <strong>your device</strong>, accept access permission request from {name}
                </p>
                <p className={css([styles.step])}>step-2</p>
                <p className={css(styles.description)}>
                  Return to this screen and press the button below to continue with check-in
                </p>
              </div>
            </Row>
          </Content>
          <div className={css(styles.bottomContainer)}>
            <div className={css(styles.buttonContainer)}>
              <Button block type="default" className={css(styles.button)} onClick={getAccessMerit}>
                continue
              </Button>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default withRouter(CheckInAccessMerit);
