import * as React from "react";
import { Button, Col, Layout, Row, Typography } from "antd";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../../theme/Colors";
import { Fonts } from "../../../theme/Fonts";

interface Props {
  isActivityFound: boolean;
  readonly downloadCheckIns: () => void;
}

const styles = StyleSheet.create({
  contentHeader: {
    fontFamily: Fonts.itcAvantGardeGothicStd,
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "32px",
    marginTop: "25px",
  },
  exportButton: {
    background: Colors.blue,
    float: "right",
    fontFamily: Fonts.sfCompactDisplay,
    fontSize: "16px",
    height: "39px",
    marginTop: "20px",
    width: "184px",
  },
  headerDescription: {
    color: Colors.gulfBlue,
    fontFamily: `${Fonts.sfCompactDisplayRegular} !important`,
    fontSize: "16px !important",
    fontStyle: "normal",
    fontWeight: "normal",
    height: "38px",
    lineHeight: "24px",
    marginTop: "25px",
    textAlign: "center",
    width: "554px",
    wordWrap: "break-word",
  },
  headerStyle: {
    background: Colors.white,
    boxShadow: Colors.boxShadow,
    height: "96px",
    width: "100%",
  },
  wrapper: {
    height: "100%",
    paddingLeft: "71px",
    width: "100%",
  },
});
export const DashboardHeader: React.SFC<Props> = ({ isActivityFound, downloadCheckIns }) => {
  const { Header } = Layout;
  const { Text, Paragraph } = Typography;

  return (
    <div className={css(styles.wrapper)}>
      <Layout>
        <Header className={css(styles.headerStyle)}>
          <Row type="flex">
            <Col xs={2} sm={4} md={12} lg={8} xl={6}>
              <Text>
                <h1 className={css(styles.contentHeader)} data-testid="homeHeaderTitle">
                  Overview
                </h1>
              </Text>
            </Col>
            <Col xs={20} sm={4} md={6} lg={8} xl={12}>
              <Paragraph>
                <p className={css(styles.headerDescription)}>
                  {isActivityFound
                    ? "Understand the overview of activity participation"
                    : "Understand the overview of the relationship between activities and the people who participate in it"}
                </p>
              </Paragraph>
            </Col>
            <Col xs={2} sm={4} md={12} lg={8} xl={6}>
              <Button
                className={css(styles.exportButton)}
                data-testid="exportButton-DashboardHeader"
                type="primary"
                onClick={() => downloadCheckIns()}
              >
                Export
              </Button>
            </Col>
          </Row>
        </Header>
      </Layout>
    </div>
  );
};
