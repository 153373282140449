import "./Offline.css";
import { Button, Dropdown, Icon, Menu, Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { deleteOrphanedCheckout, exportOrphanedCheckouts, fetchOrphanedCheckOuts } from "../../../constant/api";
import { ActionMenu } from "./ActionsMenu";
import { Colors } from "../../../theme/Colors";
import { DeleteOrphanedCheckOutModal } from "./Modals/DeleteOrphanedCheckOutModal";
import { Fonts } from "../../../theme/Fonts";
import { None } from "../../../utils/None";
// eslint-disable-next-line no-unused-vars
import { PaginationConfig } from "antd/lib/table/interface";
import action from "../../../assets/Images/Icons/action.svg";

const styles = StyleSheet.create({
  actionButton: {
    background: Colors.inputBGColor,
    border: "none",
    borderLeft: `1px solid ${Colors.blue}`,
    borderRadius: "0",
    boxShadow: "none",
    color: Colors.closeIcon,
    fontFamily: Fonts.lato,
    fontSize: "12px",
    fontWeight: "bold",
    height: "40px",
    lineHeight: "14px",
    width: "115px",
  },
  actionTitle: {
    color: Colors.closeIcon,
    fontFamily: Fonts.lato,
    fontSize: "10px",
    fontWeight: "bold",
    letterSpacing: "0.03em",
    lineHeight: "12px",
    paddingLeft: "16px",
    textTransform: "uppercase",
  },
  linkButton: {
    color: Colors.defaultButtonBg,
    fontFamily: Fonts.roboto,
    fontSize: "12px",
    letterSpacing: "0.4px",
    lineHeight: "15px",
  },
  menuItem: {
    color: Colors.closeIcon,
    fontFamily: Fonts.lato,
    fontSize: "12px",
    fontStyle: "italic",
    fontWeight: 900,
    letterSpacing: "0.01em",
    paddingLeft: "16px",
  },
  tableRows: {
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    lineHeight: "24px",
  },
  tableWrapper: {
    height: "calc(100vh - 250px)",
    overflow: "auto",
    width: "100%",
  },
  viewURL: {
    fontFamily: Fonts.sfCompactDisplayRegular,
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "14px",
  },
  viewURLButton: {
    background: Colors.blue,
    borderRadius: "4px",
    color: Colors.white,
    fontFamily: Fonts.sfCompactDisplayLight,
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "14px",
  },
});

type State = {
  readonly isLoading: boolean;
  readonly pagination?: PaginationConfig;
  readonly orphanedCheckOuts: OrphanedCheckoutType[];
};

type Props = {
  readonly notify: (message: string, status: string) => void;
};

type TableColumns = {
  readonly dataIndex: string;
  readonly title: string | React.ReactNode;
  readonly render?: (text: string, event: OrphanedCheckoutType) => React.ReactNode;
  readonly className: string;
  readonly width: string;
};

export const OrphanedCheckOutTable = ({ notify }: Props) => {
  const [selectedOrphanedCheckOutIds, setSelectedOrphanedCheckOutIds] = useState<number[]>([]);
  const [tableState, setTableState] = useState<State>({
    isLoading: false,
    orphanedCheckOuts: [],
  });
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const selectedOrphanedCheckOutIdsLength = selectedOrphanedCheckOutIds.length;

  const getOrphanedCheckOuts = useCallback(async (pagination: PaginationConfig) => {
    try {
      setTableState((prev) => ({
        ...prev,
        isLoading: true,
      }));

      if (None(pagination.current)) {
        throw new Error(`Incomplete pagination object passed: ${JSON.stringify(pagination)}`);
      }

      const orphanedCheckOutsData = await fetchOrphanedCheckOuts(pagination.current);

      setTableState({
        isLoading: false,
        orphanedCheckOuts: orphanedCheckOutsData.data.item,
        pagination: {
          ...pagination,
          pageSize: orphanedCheckOutsData.data.metadata.pageSize,
          total: orphanedCheckOutsData.data.metadata.total,
        },
      });
    } catch (error) {
      setTableState((prev) => ({
        ...prev,
        isLoading: false,
      }));
      notify("Error in fetching the orphaned checkout", "error");
    }
  }, []);

  useEffect(() => {
    getOrphanedCheckOuts({ current: 1 });
  }, [getOrphanedCheckOuts]);

  const clearOrphanedCheckOutIds = () => {
    setShowDeleteModal(false);
    setSelectedOrphanedCheckOutIds([]);
  };

  const deleteLog = async (orphanedCheckoutIds: number[]) => {
    try {
      setTableState((prev) => ({
        ...prev,
        isLoading: true,
      }));
      const params = {
        orphanedCheckoutIds,
      };

      await deleteOrphanedCheckout(params);
      notify("Orphaned checkout deleted successfully.", "success");

      clearOrphanedCheckOutIds();

      await getOrphanedCheckOuts({ current: 1 });

      setTableState((prev) => ({
        ...prev,
        isLoading: false,
      }));
    } catch (error) {
      setTableState((prev) => ({
        ...prev,
        isLoading: false,
      }));
      notify("Unable to delete this orphaned checkout.", "error");
    }
  };

  const downloadOrphanedCheckoutLog = async () => {
    try {
      const response = await exportOrphanedCheckouts(selectedOrphanedCheckOutIds);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.headers.filename);
      const body: HTMLElement = document.body;
      body.appendChild(link);
      link.click();
    } catch (error) {
      notify("Download failed", "error");
    }
  };

  const onMenuClick = (params: { readonly key: string }) => {
    switch (params.key) {
      case "Delete checkout(s)":
        setShowDeleteModal(true);
        break;
      case "Download checkout(s)":
        downloadOrphanedCheckoutLog();
        clearOrphanedCheckOutIds();
        break;
      default:
        break;
    }
  };

  const menu = (
    <Menu onClick={onMenuClick} style={{ width: "176px" }}>
      <p className={css(styles.actionTitle)}>Actions</p>
      {["Delete checkout(s)", "Download checkout(s)"].map((menu: string) => {
        return (
          <Menu.Item key={menu} className={css(styles.menuItem)}>
            <span>{menu}</span>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  // eslint-disable-next-line react/no-multi-comp,no-unused-vars
  const getTitle = () => {
    return (
      <>
        {tableState.pagination ? `${selectedOrphanedCheckOutIdsLength} selected` : 0}
        <Dropdown overlay={menu} disabled={selectedOrphanedCheckOutIdsLength === 0}>
          <Button className={css(styles.actionButton)} data-testid="actionsButton-OrphanedCheckOutTable">
            Actions <Icon type="caret-down" />
          </Button>
        </Dropdown>
      </>
    );
  };

  const onMenuItemClick = (orphanedCheckoutId: number) => {
    const orphanedCheckoutIds = [];
    orphanedCheckoutIds.push(orphanedCheckoutId);
    setSelectedOrphanedCheckOutIds(orphanedCheckoutIds);
    setShowDeleteModal(true);
  };

  const columns: Array<TableColumns> = [
    {
      className: "checkin-table-header member_title",
      dataIndex: "fullName",
      // eslint-disable-next-line react/no-multi-comp
      render: (_, { firstName, lastName, email }) => {
        return (
          <>
            <div className="table-content-title-pointer">{`${firstName} ${lastName}`}</div>
            <div className="table-content-subTitle">{email}</div>
          </>
        );
      },
      title: getTitle(),
      width: "280px",
    },
    {
      className: "checkin-table-header",
      dataIndex: "eventName",
      title: "Activity",
      width: "25%",
    },
    {
      className: "checkin-table-header",
      dataIndex: "dateTime",
      title: "Checked Out",
      width: "40%",
    },
    {
      className: "checkin-table-header",
      dataIndex: "menu-action",
      // eslint-disable-next-line react/no-multi-comp
      render: (_, { orphanedCheckoutId }) => {
        return (
          <div className="table-content-title">
            <Dropdown
              overlayClassName="orphaned_check_out_action_menu"
              trigger={["click"]}
              overlay={<ActionMenu actionOptions={["Delete"]} onSelect={() => onMenuItemClick(orphanedCheckoutId)} />}
              placement="bottomCenter"
            >
              <img alt="" src={action} className="checkinlog_cursor_pointer" />
            </Dropdown>
          </div>
        );
      },
      title: "",
      width: "7%",
    },
  ];

  const rowSelection = {
    onChange: (rowKeys: number[]) => {
      setSelectedOrphanedCheckOutIds(rowKeys);
    },
    selectedRowKeys: selectedOrphanedCheckOutIds,
  };

  return (
    <React.Fragment>
      <Table
        className="orphaned_check_out_log_table"
        rowSelection={rowSelection}
        loading={tableState.isLoading}
        dataSource={tableState.orphanedCheckOuts}
        columns={columns}
        rowKey="orphanedCheckoutId"
        onChange={(pagination) => {
          getOrphanedCheckOuts(pagination);
        }}
        pagination={{ ...tableState.pagination }}
      />

      {showDeleteModal && (
        <DeleteOrphanedCheckOutModal
          count={selectedOrphanedCheckOutIdsLength}
          onClose={clearOrphanedCheckOutIds}
          isVisible={showDeleteModal}
          onDelete={() => deleteLog(selectedOrphanedCheckOutIds)}
        />
      )}
    </React.Fragment>
  );
};
