import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../theme/Colors";
import { Fonts } from "../../theme/Fonts";
import { Footer } from "./Layout/Footer";
import { Layout } from "antd";
import React from "react";
import linkExpiredIcon from "../../assets/Images/Icons/linkexpiredIcon.svg";

const styles = StyleSheet.create({
  contentWrapper: {
    alignItems: "center",
    backgroundColor: Colors.yellow,
    display: "flex",
    height: "144px",
    justifyContent: "center",
    marginTop: "80px",
  },
  headerText: {
    color: Colors.blackPearl,
    fontSize: "24px",
    letterSpacing: "0.25px",
    lineHeight: "32px",
  },
  subText: {
    color: Colors.blackPearl,
    fontSize: "16px",
    letterSpacing: "0.15px",
    lineHeight: "24px",
  },
  textWrapper: {
    fontFamily: Fonts.roboto,
    padding: "30px",
    textAlign: "center",
  },
});

export const LinkExpired = () => {
  const { Content } = Layout;

  return (
    <React.Fragment>
      <Content>
        <div className={css(styles.contentWrapper)}>
          <img src={linkExpiredIcon} />
        </div>
        <div className={css(styles.textWrapper)}>
          <p className={css(styles.headerText)} data-testid="noLongerAvailableHeader-LinkExpired">
            This check-in link is no longer available
          </p>
          <p className={css(styles.subText)}>
            If you have any questions or concerns, please contact the individual that sent you the check-in link
          </p>
        </div>
      </Content>
      <Footer />
    </React.Fragment>
  );
};
