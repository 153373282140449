import "../Event/Events.css";
import {
  AppBar,
  ClickAwayListener,
  Grid,
  Grow,
  InputBase,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React, { PureComponent } from "react";
import { getWaivers, searchWaiver } from "../../../constant/api";
import CommonHeader from "../../UI-Components/CommonHeader";
import EnhancedTableHead from "./EnhancedTableHead";
import LoadingScreen from "../../UI-Components/LoadingScreen";
import SearchIcon from "@material-ui/icons/Search";
import Welcome from "../Welcome/Welcome";
import action from "../../../assets/Images/Icons/action.svg";
import history from "../../../history";
import moment from "moment-timezone";
import noForms from "../../../assets/Images/Icons/noForms.svg";

interface obj {
  name: string;
  waiverName: string;
}

interface Props {
  readonly classes: {
    readonly newBtn: string;
    readonly tableWrapper: string;
    readonly table: string;
  };
  readonly handleOnCreate?: () => Promise<void>;
  readonly setSideBar?: () => Promise<void>;
}

interface State {
  anchorEl: any;
  searchText: string;
  isWaiverFound: boolean;
  waivers: WaiverList[];
  pageCount: number;
  totalPages: number;
  selectedForm: WaiverList;
  isSearch: boolean;
}
interface body {
  scrollTop: number;
  offsetHeight: number;
}

class WaiverWelcome extends PureComponent<Props, State> {
  isLoading: boolean;

  constructor(props: Props) {
    super(props);
    this.isLoading = true;

    this.state = {
      anchorEl: null,
      isSearch: false,
      isWaiverFound: false,
      pageCount: 1,
      searchText: "",
      selectedForm: {} as WaiverList,
      totalPages: 0,
      waivers: [],
    };
    this.getWaivers(1);
    localStorage.removeItem("waiverName");
  }

  registerScroll = () => {
    const { pageCount, totalPages, searchText } = this.state;
    const tableBody = document.getElementById("table");
    if (tableBody !== null) {
      tableBody.onscroll = () => {
        if (tableBody.scrollTop > tableBody.scrollHeight - tableBody.offsetTop - 600) {
          if (!this.isLoading && pageCount < totalPages && searchText.length === 0) {
            this.getWaivers(pageCount + 1);
          }
          if (!this.isLoading && searchText.length >= 1 && pageCount < totalPages) {
            this.searchAllWaivers(searchText, pageCount + 1);
          }
        }
      };
    }
  };
  handleClick = () => {
    localStorage.removeItem("html");
    localStorage.removeItem("draft");
    localStorage.removeItem("waiverId");
    history.push("/create-waiver");
  };
  getWaivers = async (page: number) => {
    this.isLoading = true;
    const response = await getWaivers(page);
    this.isLoading = false;
    let waiversData;
    if (page === 1) {
      waiversData = response.data.item;
    } else {
      const { waivers } = this.state;
      waiversData = waivers.concat(response.data.item);
    }
    if (response.data.item.length === 0) {
      this.setState({
        isWaiverFound: true,
        waivers: waiversData,
      });
    } else {
      this.setState({
        isSearch: false,
        pageCount: page,
        totalPages: response.data.metadata.totalPages,
        waivers: waiversData,
      });
    }
    this.registerScroll();
  };
  searchWaiver = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    const { searchText: oldSearchTerm } = this.state;
    this.setState({
      searchText: e.target.value,
    });

    if (e.target.value.length >= 1) {
      this.searchAllWaivers(e.target.value, 1);
    } else if (searchTerm.length < oldSearchTerm.length && searchTerm.length <= 2) {
      this.getWaivers(1);
    } else {
      this.getWaivers(1);
    }
  };
  searchAllWaivers = async (searchTerm: string, page: number) => {
    this.isLoading = true;
    const response = await searchWaiver(searchTerm, page);
    this.isLoading = false;
    if (response.data.item.length === 0) {
      this.setState({
        isSearch: true,
        waivers: response.data.item,
      });
    } else {
      const { waivers } = this.state;
      let waiversData;
      if (page === 1) {
        waiversData = response.data.item;
      } else {
        waiversData = waivers.concat(response.data.item);
      }
      this.setState({
        isSearch: false,
        pageCount: page,
        totalPages: response.data.metadata.totalPages,
        waivers: waiversData,
      });
    }
    this.registerScroll();
  };
  handleClickAction = (event: React.MouseEvent<HTMLElement>, n: any) => {
    const { anchorEl } = this.state;
    if (anchorEl) {
      this.setState({ anchorEl: null });
    } else {
      this.setState({ anchorEl: event.currentTarget, selectedForm: n });
    }
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    const { selectedForm } = this.state;
    if (index === 0) {
      history.push({
        pathname: "/preview",
        state: { data: selectedForm },
      });
    }
    if (index === 1) {
      history.push({
        pathname: "/create-waiver",
        state: { data: selectedForm },
      });
    } else if (index === 2) {
      history.push({
        pathname: "/create-waiver",
        state: { data: selectedForm, key: "Duplicate" },
      });
    }
    this.handleClose();
  };
  loading_data = () => {
    const { isWaiverFound } = this.state;
    if (isWaiverFound) {
      return (
        <Grid container>
          <Welcome
            imagePath={noForms}
            header="No Forms"
            testId="forms"
            text="Create waivers, release forms, and NDAs that can be added to your activities to be completed by participants during check-in."
            path="/create-waiver"
            buttonText="Create"
          />
        </Grid>
      );
    }

    return <LoadingScreen key={1} minHeight="calc(100vh - 125px)" />;
  };
  changeDateFormat = (
    date:
      | string
      | number
      | void
      | moment.Moment
      | Date
      | (string | number)[]
      | moment.MomentInputObject
      | null
      | undefined,
  ) => {
    if (date) {
      const dateFormat = moment(date).format("MMM D, YYYY");

      return dateFormat;
    }

    return "";
  };

  render() {
    const { classes } = this.props;
    const { anchorEl, waivers: data, waivers, isSearch, searchText } = this.state;
    const open = Boolean(anchorEl);
    const options = ["Preview", "Edit", "Duplicate"];

    return (
      <div>
        <AppBar position="static" className="appbar app_bar">
          <CommonHeader
            name="Forms"
            buttonText="Create Form"
            handleClick={this.handleClick}
            testId="forms"
            text="Forms are digital versions of any offline forms and waivers that your participants must fill out. Create one here to add to your activity."
          />
        </AppBar>

        {waivers.length === 0 && !isSearch ? (
          this.loading_data()
        ) : (
          <div className="card_container_form" id="card-container">
            <Grid container spacing={24} className="grid_container">
              <Grid item style={{ marginLeft: "470px" }}>
                <div
                  style={{
                    background: "#CFE4FD",
                    borderRadius: "24px",
                    display: "inline-block",
                    position: "relative",
                  }}
                >
                  <SearchIcon
                    style={{ height: 20, left: 0, paddingLeft: "10px", position: "absolute", top: "11px", width: 20 }}
                  />
                  <InputBase
                    placeholder="Search"
                    value={searchText}
                    id="search3"
                    onChange={this.searchWaiver}
                    style={{
                      height: "40px",
                      paddingLeft: "30px",
                      width: "184px",
                    }}
                    inputProps={{
                      "data-testid": "searchInputField",
                    }}
                  />
                </div>
              </Grid>
              <div className={classes.tableWrapper} id="table">
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <EnhancedTableHead />
                  {isSearch ? (
                    <div data-testid="noDataAvailable" className="no_data">
                      No data available
                    </div>
                  ) : (
                    <TableBody data-testid="formsTableBody">
                      {data.map((n) => {
                        return (
                          <TableRow data-testid={`${n.waiverName}Row`} key={n.waiverId} hover tabIndex={-1}>
                            <TableCell align="left">
                              <div className="table-content-title table-data-font content_width">{n.waiverName}</div>
                            </TableCell>
                            <TableCell align="left">
                              <div className="table-content-title table-data-font">
                                {this.changeDateFormat(n.createdAt)}
                              </div>
                            </TableCell>
                            <TableCell align="left">
                              <div className="table-content-title table-data-font">
                                {this.changeDateFormat(n.updatedAt)}
                                <span className="action">
                                  <img
                                    alt=""
                                    data-testid="formsRowContextMenu"
                                    src={action}
                                    onClick={(event) => this.handleClickAction(event, n)}
                                    className="cursor_pointer"
                                  />
                                </span>
                                <Popper open={open} anchorEl={anchorEl} transition disablePortal>
                                  {({ TransitionProps, placement }) => (
                                    <Grow
                                      {...TransitionProps}
                                      style={{
                                        transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                                      }}
                                    >
                                      <Paper id="menu-list-grow">
                                        <ClickAwayListener onClickAway={this.handleClose}>
                                          <MenuList>
                                            {options.map((option, index) => (
                                              <MenuItem
                                                className="menu_item"
                                                data-testid={`${option}ContextMenu`}
                                                key={option}
                                                onClick={(event) => this.handleMenuItemClick(event, index)}
                                              >
                                                {option}
                                              </MenuItem>
                                            ))}
                                          </MenuList>
                                        </ClickAwayListener>
                                      </Paper>
                                    </Grow>
                                  )}
                                </Popper>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  )}
                </Table>
              </div>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}
const styles = (theme: { spacing: { unit: number } }) =>
  createStyles({
    input: {
      paddingBottom: "10.5px",
      paddingTop: "10.5px",
    },
    newBtn: {
      backgroundColor: "#fff",
      border: "1px solid #147DF4",
      height: 39,
      position: "absolute",
      right: "175px",
    },
    root: {
      marginTop: theme.spacing.unit * 3,
      width: "100%",
    },
    table: {},
    tableWrapper: {
      height: "calc(100vh - 204px)",
      overflow: "auto",
      width: "100%",
    },
  });

export default withStyles(styles)(WaiverWelcome);
