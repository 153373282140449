import "./qualification.css";
import { Modal, createStyles, withStyles } from "@material-ui/core";
import React, { PureComponent } from "react";
import { getOrgMeritTemplates, getOrgSearch } from "../../../constant/api";

import Async from "react-select/async";
import CloseIcon from "@material-ui/icons/Close";
import Select from "react-select";

const styles = (theme: { spacing: { unit: number } }) =>
  createStyles({
    backdrop: {
      background: "linear-gradient(67.73deg, #0E69F1 1.08%, #20A2F9 100%);",
      backgroundColor: "black",
      opacity: 0.9,
    },
    button: {
      margin: theme.spacing.unit,
    },
    paper: {
      left: "35%",
      outline: "none",
      padding: theme.spacing.unit * 4,
      position: "absolute",
      top: "25%",
      width: theme.spacing.unit * 50,
    },
    root: {
      height: "200px",
      marginTop: theme.spacing.unit * 3,
      overflowX: "hidden",
      overflowY: "auto",
      width: "100%",
    },
    table: {
      minWidth: 350,
    },
  });
const customStyles = {
  menuList: (provided: any) => ({
    ...provided,
    fontFamily: "Lato",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "black",
    fontFamily: "Lato",
  }),
};

interface Props {
  orgDetails: any;
  showQualification: boolean;
  handleClose: any;
  error: string;
  classes: any;
  setMerit: any;
}

interface State {
  hasNext: boolean;
  isLoading: boolean;
  meritsData: any;
  nextPage: string;
  placeholder: string;
  value: any;
}

class MeritModal extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { orgDetails } = this.props;
    this.state = {
      hasNext: false,
      isLoading: false,
      meritsData: [],
      nextPage: "",
      placeholder: "Choose a merit",
      value: { id: "", label: orgDetails.name },
    };
    this.getDefaultMerits();
  }

  getDefaultMerits = async () => {
    const { value, nextPage, meritsData } = this.state;
    this.setState({ isLoading: true });
    const response = await getOrgMeritTemplates(value.id, nextPage);
    if (response.data.templateData.length === 0) {
      this.setState({
        isLoading: false,
        placeholder: "No data present",
      });
    } else {
      const responseData = response.data.templateData.map((item) => {
        return { label: item.title, orgName: value.label, value: item.id };
      });
      const meritResponse = meritsData.concat(responseData);
      meritResponse.sort((meritResponseA: MeritTemplates$Response, meritResponseB: MeritTemplates$Response) =>
        meritResponseA.label.toLowerCase() > meritResponseB.label.toLowerCase() ? 1 : -1,
      );
      this.setState({
        hasNext: response.data.pagingInfo.hasNextPage,
        isLoading: false,
        meritsData: meritResponse,
        nextPage: response.data.pagingInfo.after,
        placeholder: "Choose a merit",
      });
    }
  };

  handleChangeMerit = (data: { value: any; label: any; orgName: any }) => {
    const dataFormat: any = data;
    dataFormat.id = data.value;
    const { setMerit } = this.props;
    const selected = {
      meritName: data.label,
      orgName: data.orgName,
      val: dataFormat,
    };
    setMerit(selected);
  };
  onChange = async (value: any) => {
    await this.setState({ hasNext: false, meritsData: [], nextPage: "", value: value });
    await this.getDefaultMerits();
  };
  getUsers = async (input: any) => {
    if (!input) {
      return Promise.resolve({ options: [] });
    } else if (input.length >= 3) {
      const response = await getOrgSearch(input);

      return response.data.map((item: { name: any; id: any }) => {
        const itemVal: any = item;
        itemVal.label = item.name;
        itemVal.value = item.id;

        return itemVal;
      });
    }

    return null;
  };
  clearData = () => {
    this.setState({
      value: { id: "", label: "Search Organization" },
    });
  };
  handleScroll = async () => {
    const { hasNext } = this.state;
    if (hasNext) {
      await this.getDefaultMerits();
    }
  };

  render() {
    const { classes, showQualification, handleClose, error } = this.props;
    const { value, meritsData, placeholder, isLoading } = this.state;
    const formatOptionLabel = (data: { label: string }) => (
      <div data-testid={`${data.label}-OptionLabel`}>{data.label}</div>
    );
    const formatOrgOptionLabel = (data: { label: string }) => (
      <div data-testid={`${data.label}-OrgOptionLabel`}>{data.label}</div>
    );

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick
        open={showQualification}
        onClose={handleClose}
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
      >
        <div className={classes.paper}>
          <CloseIcon
            onClick={handleClose}
            className="close_icon"
            style={{ color: "white", right: "-350px", top: "-141px" }}
          />
          <h6 style={{ color: "white", marginTop: 0 }} data-testid="title-meritModal">
            Choose an organization to add Merits from
          </h6>
          <div data-testid="chooseOrgDropdown-meritModal">
            <Async
              value={value}
              cacheOptions
              formatOptionLabel={formatOrgOptionLabel}
              loadOptions={this.getUsers}
              onChange={this.onChange}
              onFocus={this.clearData}
              styles={customStyles}
            />
          </div>
          <div style={{ marginTop: "30px" }} data-testid="chooseMeritDropdown">
            <Select
              options={meritsData}
              placeholder={placeholder}
              onChange={this.handleChangeMerit}
              styles={customStyles}
              onMenuScrollToBottom={this.handleScroll}
              isSearchable={false}
              isLoading={isLoading}
              formatOptionLabel={formatOptionLabel}
            />
          </div>

          <span className="error-msg highlight" style={{ color: "white" }}>
            {error}
          </span>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(MeritModal);
