import "./Dashboard.less";
// eslint-disable-next-line no-unused-vars
import { AppState, useStore } from "../../../store/store";
import { Col, Icon, Row } from "antd";
import React, { useEffect, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { getAllActivities, getSnapShotDetails, importCheckIns } from "../../../constant/api";
import { AllActivities } from "./AllActivitiesModal";
import { CalendarModal } from "./CalendarModal";
import CardSection from "./CardSection";
import { Colors } from "../../../theme/Colors";
import { DashboardHeader } from "./DashboardHeader";
import { DashboardTable } from "./DashboardTable";
import { Fonts } from "../../../theme/Fonts";
import LoadingScreen from "../../UI-Components/LoadingScreen";
import { NoOverView } from "./NoOverView";
import moment from "moment";
import { useDefaultErrorHandler } from "../../../utils/useDefaultErrorHandler";

interface OrgDetails {
  readonly name: string;
}

interface Props {
  orgDetails: OrgDetails;
}

interface ActivityData {
  readonly eventId: number;
  readonly name: string;
}

const styles = StyleSheet.create({
  orgName: {
    color: Colors.lightGray,
    fontFamily: Fonts.itcAvantGardeGothicStd,
    fontSize: "20px",
    lineHeight: "28px",
  },
  wrapper: {
    fontFamily: Fonts.lato,
    paddingLeft: "105px",
    paddingRight: "10px",
    paddingTop: "30px",
  },
});

const selectedOrgSelector = (state: AppState) => state.selectedOrg;

export const Dashboard: React.SFC<Props> = ({ orgDetails: { name } }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const selectedOrg = useStore(selectedOrgSelector);
  const [snapShotData, setSnapShotData] = useState<SnapShotData>({
    checkinsCount: 0,
    loggedHours: 0,
    notCheckedOut: 0,
    unsuccessfulCheckins: 0,
  });
  const [eventIds, setEventIds] = useState<Array<number>>([]);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const [allEventIds, setAllEventIds] = useState<Array<number>>([]);
  const [activities, setActivities] = useState<Array<ActivityData>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { errorHandler } = useDefaultErrorHandler();

  useEffect(() => {
    getAllActivitiesList();
  }, []);

  const getAllActivitiesList = async () => {
    try {
      const responseEvents = await getAllActivities();
      if (responseEvents.data.item.length > 0) {
        const eventIdsList: Array<number> = [];
        setActivities(responseEvents.data.item);
        responseEvents.data.item.map((event: ActivityData) => eventIdsList.push(event.eventId));
        setAllEventIds(eventIdsList);
      }
      setLoading(false);
    } catch (error: unknown) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getSnapShotData();
  }, [eventIds, startDate]);

  const getSnapShotData = async () => {
    if (!selectedOrg) {
      return;
    }
    try {
      const params = { endDate, eventIds, startDate };
      const snapShotResponse = await getSnapShotDetails(selectedOrg.id, params);
      setSnapShotData(Object.assign({}, snapShotData, snapShotResponse.data));
    } catch (error: unknown) {
      errorHandler(error);
    }
  };

  const handleModal = () => {
    setIsCalendarOpen(false);
    setIsOpen(!isOpen);
  };

  const getEventIds = (eventData: Array<number>) => {
    setEventIds(eventData);
  };

  const eventIdsLength = () => {
    const eventIdsLength = eventIds.length;
    if (eventIdsLength > 0) {
      return `${eventIdsLength} Activities`;
    }

    return "All Activities";
  };

  const handleCalendarModal = (startDate: moment.Moment, endDate: moment.Moment) => {
    setStartDate(moment(startDate).startOf("day").format());
    setEndDate(moment(endDate).endOf("day").format());
    setIsCalendarOpen(!isCalendarOpen);
    setIsOpen(false);
  };

  const closeCalendar = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const displayDateRange = () => {
    if (startDate) {
      return `${moment(startDate).format("Do MMM")}-${moment(endDate).format("Do MMM YYYY")}`;
    }

    return `${moment().startOf("month").format("Do MMM")} - ${moment().format("Do MMM YYYY")}`;
  };

  const downloadCheckIns = async () => {
    try {
      const params = { eventIds: eventIds, fromDate: startDate, toDate: endDate };
      const response: any = await importCheckIns(params);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Check-ins.xlsx"); // or any other extension
      const body = document.body;
      body.appendChild(link);
      link.click();
    } catch (error: unknown) {
      errorHandler(error);
    }
  };

  return (
    <div>
      {loading ? (
        <LoadingScreen key={1} minHeight="calc(100vh - 125px)" />
      ) : (
        <React.Fragment>
          <DashboardHeader isActivityFound={activities.length > 0} downloadCheckIns={downloadCheckIns} />
          <div className={css(styles.wrapper)}>
            {activities.length > 0 ? (
              <React.Fragment>
                <Row>
                  <Col span={6}>
                    <h4 className={css(styles.orgName)} data-testid="orgName-Dashboard">
                      {name}
                    </h4>
                  </Col>
                  <Col span={6}>
                    <p onClick={handleModal} style={{ cursor: "pointer" }}>
                      <Icon type="appstore" />
                      <span data-testid="allActivities-Dashboard">&nbsp;{eventIdsLength()}</span>
                    </p>
                  </Col>
                  <Col span={6}>
                    <p style={{ cursor: "pointer" }} onClick={() => setIsCalendarOpen(true)}>
                      <Icon type="calendar" />
                      <span data-testid="calendar-Dashboard">
                        &nbsp;
                        {displayDateRange()}
                      </span>
                    </p>
                    <CalendarModal
                      isCalendarOpen={isCalendarOpen}
                      handleCalendarModal={handleCalendarModal}
                      closeCalendar={closeCalendar}
                    />
                  </Col>
                </Row>
                <CardSection snapShotData={snapShotData} />
                <DashboardTable
                  allEventIds={eventIds.length > 0 ? eventIds : allEventIds}
                  startDate={startDate}
                  endDate={endDate}
                  initialValue={0}
                  finalValue={15}
                />
                <AllActivities
                  isOpen={isOpen}
                  handleModalClose={handleModal}
                  sendEventData={getEventIds}
                  activities={activities}
                />
              </React.Fragment>
            ) : (
              <NoOverView />
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
