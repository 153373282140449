import {
  Button,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React, { PureComponent } from "react";
// eslint-disable-next-line no-unused-vars
import { WithErrorHandler, WithErrorHandlerProps } from "../../../WithErrorHandler";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmationBox from "../../../UI-Components/Confirmation";
import FormValidator from "../../../../constant/validation";
import { ShowEventAttendeeDetails } from "../ShowEventAttendeeDetails";
import { addNotes } from "../../../../constant/api";

const styles = (theme: any) =>
  createStyles({
    backdrop: {
      background: "linear-gradient(67.73deg, #0E69F1 1.08%, #20A2F9 100%);",
      backgroundColor: "black",
      opacity: 0.9,
    },
    button: {
      margin: theme.spacing.unit,
    },
    cssFocused: {
      color: "#687FA3",
    },
    cssLabel: {
      color: "#687FA3",
    },
    notchedOutline: {
      borderColor: "#687FA3 !important",
      borderWidth: "1px",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "6px",
      boxShadow: theme.shadows[5],
      left: "50%",
      padding: theme.spacing.unit * 2,
      position: "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: theme.spacing.unit * 50,
    },
    root: {
      height: "200px",
      marginTop: theme.spacing.unit * 3,
      overflowX: "hidden",
      overflowY: "auto",
      width: "100%",
    },
    table: {
      minWidth: 350,
    },
  });
interface Props extends WithErrorHandlerProps {
  readonly show: boolean;
  readonly notify: (arg1: string, arg2: string) => void;
  readonly handleClose: () => void;
  readonly classes: {
    readonly root: string;
    readonly table: string;
    readonly paper: string;
    readonly button: string;
    readonly notchedOutline: string;
    readonly cssLabel: string;
    readonly cssFocused: string;
    readonly backdrop: string;
  };
  readonly eventAttendee: number[];
  readonly clearEventAttendeeIds: () => void;

  selectedAttendees: any;
  note?: string | null;
}
interface State {
  multiline: string;
  validation: {
    isValid: boolean;
    multiline: {
      isInvalid: boolean;
      message: string;
    };
  };
  open: boolean;
  [key: string]: any;
}
interface validations {
  isValid: boolean;
  multiline: {
    isInvalid: boolean;
    message: string;
  };
}

interface eventAttendee {
  eventAttendeeId: number;
  firstName: string;
  lastName: string;
  email: string;
}
class AddNotes extends PureComponent<Props, State> {
  readonly validator: {
    validate: (state: State) => validations;
    valid: () => validations;
  };
  submitted: boolean;
  constructor(props: Props) {
    super(props);
    const { note } = this.props;
    this.submitted = false;
    this.validator = new FormValidator([
      {
        field: "multiline",
        message: "Please enter note.",
        method: "isEmpty",
        validWhen: false,
      },
    ]);
    this.state = {
      multiline: note === null ? "" : note!,
      open: false,
      validation: this.validator.valid(),
    };
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  goToPreviousPage = () => {
    this.saveNotes();
  };
  closeConfirmation = () => {
    const { handleClose } = this.props;
    this.setState({
      open: false,
    });
    handleClose();
  };
  saveNotes = async () => {
    const { eventAttendee, notify, handleClose, clearEventAttendeeIds } = this.props;
    const { multiline } = this.state;
    const params = {
      eventAttendeeId: eventAttendee,
      note: multiline,
    };
    try {
      await addNotes(params);
      notify("Note added successfully.", "success");

      handleClose();
      clearEventAttendeeIds();
      this.setState({
        multiline: "",
        open: false,
      });
    } catch (error: unknown) {
      const { errorHandler } = this.props;
      errorHandler(error);
    }
  };
  AddNotes = async () => {
    const { state } = this;
    const validation: validations = this.validator.validate(state);
    const { eventAttendee } = this.props;
    this.setState({ validation });
    this.submitted = true;
    if (eventAttendee.length > 1) {
      this.setState({ open: true });
    } else {
      this.saveNotes();
    }
  };
  render() {
    const { validation: stateValidation, multiline, open } = this.state;
    const validation: validations = this.submitted ? this.validator.validate(this.state) : stateValidation;
    const { classes, selectedAttendees: rows, show, handleClose } = this.props;
    const focusUsernameInputField = (input: HTMLInputElement) => {
      if (input !== null) {
        input.focus();
      }
    };

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick
        open={show}
        onClose={handleClose}
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
      >
        <div className={classes.paper} data-testid="modal-AddNotes">
          <Grid container>
            <Grid item>
              <CloseIcon onClick={handleClose} className="close_icon" />
            </Grid>
            <Grid item xs={6} className="add_note_grid">
              <TextField
                id="outlined-multiline-flexible"
                label="Add Notes"
                multiline
                rowsMax="2"
                name="multiline"
                value={multiline}
                onChange={this.handleChange}
                inputRef={focusUsernameInputField}
                margin="normal"
                style={{ width: "280px" }}
                variant="outlined"
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                  },
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                inputProps={{
                  "data-testid": "addNotesInputField-AddNotes",
                }}
              />
              <span className="error-msg highlight" id="event-name-error">
                {validation.multiline.message}
              </span>
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={!this.validator.validate(this.state).isValid || this.submitted}
                variant="contained"
                color="primary"
                style={{ float: "right" }}
                onClick={this.AddNotes}
                data-testid="addNotesButton-AddNotes"
              >
                Add Notes
              </Button>
            </Grid>
          </Grid>
          {rows.length !== 0 && (
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className="record_data">Full Name</TableCell>
                    <TableCell className="record_data">Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(({ eventAttendeeId, firstName, lastName, email }: EventAttendee) => (
                    <ShowEventAttendeeDetails
                      eventAttendeeId={eventAttendeeId}
                      firstName={firstName}
                      lastName={lastName}
                      email={email}
                    />
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
          <ConfirmationBox
            open={open}
            closeConfirmation={this.closeConfirmation}
            goToPreviousPage={this.goToPreviousPage}
            message="Saving notes will overwrite any previous notes for the selected individuals. Do you want to save it?"
            testId="saveNotes"
          />
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(WithErrorHandler(AddNotes));
