// eslint-disable-next-line no-unused-vars
import { DataSource, TableColumn } from "../../../UI-Components/EnhancedTable/type";
import React, { useMemo, useState } from "react";
import AddCheckOutAutomation from "../Modals/AddCheckOutAutomation";
import { Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import EnhancedTable from "../../../UI-Components/EnhancedTable";
import { Some } from "../../../../utils/Some";

type Props = {
  readonly checkOutAutomationList: CheckOutAutomation[];
  readonly handleSelectCheckOutAutomation: (selectedAutomations: CheckOutAutomation[]) => void;
  readonly selectedAutomationIds: number[];
  readonly selectedAutomations: CheckOutAutomation[];
};
export const EventCheckOutAutomation = ({
  checkOutAutomationList,
  handleSelectCheckOutAutomation,
  selectedAutomationIds,
  selectedAutomations,
}: Props) => {
  const [showModal, setShowModal] = useState(false);

  const handleSelectAutomation = (automationIds: number[]) => {
    const selectedAutomations: CheckOutAutomation[] = [];
    automationIds.forEach((automationId) => {
      const checkOutAutomation = checkOutAutomationList.find(
        (automation) => automation.checkOutAutomationId === automationId,
      );
      if (Some(checkOutAutomation)) {
        selectedAutomations.push(checkOutAutomation);
      }
    });

    handleSelectCheckOutAutomation(selectedAutomations);
  };

  const columns: TableColumn[] = [
    { key: "name", label: "Name" },
    { key: "description", label: "Description" },
    { key: "action", label: "" },
  ];

  const selectedCheckoutAutomation: DataSource[] = useMemo(() => {
    return selectedAutomations.map((e) => ({
      action: (
        <Close
          onClick={() => {
            const selectAutomations = selectedAutomations.filter(
              ({ checkOutAutomationId }) => checkOutAutomationId !== e.checkOutAutomationId,
            );
            handleSelectCheckOutAutomation(selectAutomations);
          }}
        />
      ),
      description: e.description ?? "",
      key: e.checkOutAutomationId,
      name: e.name,
    }));
  }, [selectedAutomations]);

  return (
    <>
      <h6 className="margin_10" data-testid="header-EventCheckOutAutomation">
        Add a check-out automation
      </h6>
      <div className="step2_button">
        <Button
          variant="contained"
          color="secondary"
          style={{ float: "right", marginLeft: "2px" }}
          onClick={() => setShowModal(!showModal)}
          data-testid="addButton-EventCheckOutAutomation"
        >
          Add
        </Button>
      </div>
      {showModal && (
        <AddCheckOutAutomation
          isShowModal={showModal}
          showHideChooseAutomationModal={() => setShowModal(!showModal)}
          selectedAutomationIds={selectedAutomationIds}
          checkOutAutomationList={checkOutAutomationList}
          handleSelectAutomation={handleSelectAutomation}
        />
      )}
      {selectedAutomationIds.length > 0 && (
        <EnhancedTable
          tableContentHeight="auto"
          columns={columns}
          title=""
          idColumn="key"
          dataSource={selectedCheckoutAutomation}
          dataTestIdKey="name"
        />
      )}
      <br />
    </>
  );
};
