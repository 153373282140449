import { Card, CardContent, createStyles, withStyles } from "@material-ui/core";
import React, { PureComponent } from "react";
import { Colors } from "./../../theme/Colors";
import { Fonts } from "./../../theme/Fonts";

interface Props {
  readonly id?: string;
  readonly name: string;
  readonly iconImg: string;
  readonly rectangle: string;
  readonly description: string;
  readonly arrow: string;
  readonly appStore: string;
  readonly classes: {
    readonly card: string;
    readonly title: string;
    readonly cardDetails: string;
    readonly cardTitle: string;
    readonly cardDescription: string;
  };
}

class WebKioskCard extends PureComponent<Props> {
  render() {
    const { classes, name, rectangle, description, iconImg, arrow, appStore } = this.props;

    return (
      <Card
        className={classes.card}
        style={{ backgroundImage: `url(${rectangle})`, backgroundSize: "cover", overflow: "hidden" }}
      >
        <CardContent className={classes.cardDetails}>
          <img src={iconImg} />
          <img className="arrow-right" src={arrow} />
          <h4 className={classes.cardTitle}>{name}</h4>
          <label className={classes.cardDescription}>{description}</label>
          <br />
          {appStore !== undefined && (
            <a target="_new" href="https://apps.apple.com/us/app/check-in-with-merits/id1459643211">
              <img className="margin_top_20" src={appStore} />
            </a>
          )}
        </CardContent>
      </Card>
    );
  }
}
const styles = () =>
  createStyles({
    card: {
      background: "#FFFFFF",
      border: "1px solid #E5E5E5",
      borderRadius: "4px",
      boxSize: "border-box",
      height: 321,
      width: 270,
    },
    cardDescription: {
      color: Colors.black,
      fontFamily: Fonts.sfCompactDisplayRegular,
    },
    cardDetails: {
      WebkitBoxOrient: "vertical",
      display: "-webkit-box",
      fontFamily: "Lato !important",
      fontSize: "16px",
      lineHeight: "24px",
      margin: "19px 19px",
      overflowWrap: "break-word",
      padding: 0,
    },
    cardTitle: {
      fontFamily: "avant",
      fontWeight: "normal",
      marginBottom: "0px !important",
      marginTop: "30px !important",
      width: "230px",
      wordWrap: "break-word",
    },
    title: {
      fontSize: 14,
    },
  });
export default withStyles(styles)(WebKioskCard);
