import { Button, Card, Col, Divider, Layout, Row } from "antd";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import CheckCircle from "../../assets/Images/Icons/check-circle.svg";
import { Colors } from "../../theme/Colors";
import Cookies from "js-cookie";
import { Fonts } from "../../theme/Fonts";
import LocationIcon from "../../assets/Images/Icons/location-icon.svg";
import React from "react";
import { getSelectedAddress } from "../../utils/GeoLocationHelper";
import { getUserFullName } from "../../utils/getUserFullName";
import history from "../../history";
import moment from "moment-timezone";
import { useAuth0 } from "@auth0/auth0-react";

const styles = StyleSheet.create({
  accountText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "18px",
    marginTop: "5px",
    textAlign: "left",
  },
  activityText: {
    color: Colors.sherpa,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.15px",
    marginTop: "10px",
    textAlign: "left",
  },
  alreadyCheckinText: {
    color: Colors.checkinButtonBg,
    cursor: "pointer",
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.25px",
    marginBottom: "20px",
    marginTop: "10px",
    textAlign: "center",
    textTransform: "uppercase",
  },
  buttonWrapper: {
    background: Colors.defaultButtonBg,
    color: Colors.white,
    height: "50px",
    marginBottom: "20px",
    marginTop: "20px",
    textAlign: "center",
    textTransform: "uppercase",
    width: "300px",
  },
  cardInnerWrapper: {
    height: "auto",
    marginLeft: "2%",
    marginRight: "2%",
    width: "96%",
  },
  cardWrapper: {
    borderRadius: "4px",
    height: "auto",
    marginTop: "20px",
  },
  checkinText: {
    color: Colors.blackPearl,
    cursor: "pointer",
    fontFamily: Fonts.roboto,
    fontSize: "34px",
    letterSpacing: "0.25px",
    lineHeight: "43px",
    textAlign: "center",
  },
  checkinTime: {
    fontFamily: Fonts.roboto,
    fontSize: "64px",
    fontWeight: 500,
    lineHeight: "81px",
    textAlign: "center",
  },
  checkoutbuttonWrapper: {
    background: Colors.checkoutButtonBg,
    border: `1px solid ${Colors.imageContainerBorder}`,
    color: Colors.blackPearl,
    height: "50px",
    marginBottom: "10px",
    marginTop: "20px",
    textAlign: "center",
    textTransform: "uppercase",
    width: "300px",
  },
  flexWrapper: {
    marginTop: "15px",
    textAlign: "left",
  },
  footerWrapper: {
    alignItems: "center",
    backgroundColor: Colors.white,
    bottom: "0px",
    height: "auto",
    justifyContent: "center",
    minWidth: "375px",
    position: "sticky",
    textAlign: "center",
  },
  guestCheckinText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.25px",
    lineHeight: "24px",
    marginTop: "10px",
    textAlign: "center",
  },
  image: {
    height: "14px",
    marginTop: "8px",
    width: "10px",
  },
  imageWidth: {
    width: "44px",
  },
  imageWrapper: {
    alignItems: "center",
    marginTop: "20px",
    textAlign: "center",
  },
  locationText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "15px",
    lineHeight: "20px",
    marginTop: "8px",
    wordBreak: "break-word",
  },
  meritText: {
    color: Colors.darkBlue,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.5px",
    marginTop: "15px",
    textAlign: "left",
  },
  nextText: {
    fontFamily: Fonts.roboto,
    fontSize: "24px",
  },
  rowWrapper: {
    marginTop: "7px",
    width: "100%",
  },
  successText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.25px",
    lineHeight: "24px",
    marginTop: "10px",
    textAlign: "left",
    wordBreak: "break-word",
  },
  textInput: {
    backgroundColor: Colors.textfieldColor,
    border: "none",
    marginTop: "10px",
  },
});

interface LocationState {
  attendeeDetails: {
    readonly firstName: string;
    readonly lastName?: string;
  };
}
interface Props extends RouteComponentProps<{}, {}, LocationState> {}

const CheckInSuccess = ({ location }: Props | any) => {
  const { Content } = Layout;
  const { logout, isAuthenticated } = useAuth0();

  const eventData = localStorage.getItem("eventData");
  const eventDetails = JSON.parse(eventData!);
  const { push } = history;

  const attendeeDetails =
    location.state?.attendeeDetails ?? JSON.parse(localStorage.getItem("attendeeDetails") ?? "{}");
  localStorage.setItem("attendeeDetails", JSON.stringify(attendeeDetails));

  const { firstName, lastName, location: attendeeLocation } = attendeeDetails;
  const { checkinSuccessMessage, name, locationEnabled } = eventDetails.event;
  const activitiesCount = localStorage.getItem("activitiesCount");
  const checkoutCount = localStorage.getItem("checkoutCount");
  const virtualKioskType = Cookies.get("virtualKioskType");

  // eslint-disable-next-line react/no-multi-comp
  const handleFooterButton = (): React.ReactNode => {
    if (virtualKioskType === "1") {
      return (
        <div className={css(styles.footerWrapper)}>
          <Button
            className={css(styles.buttonWrapper)}
            onClick={() => {
              push("/web-checkin/home");
            }}
          >
            Done
          </Button>
        </div>
      );
    }

    if (virtualKioskType === "2") {
      if (activitiesCount && activitiesCount !== "1") {
        return (
          <div className={css(styles.footerWrapper)}>
            {checkoutCount && checkoutCount !== "0" && (
              <Button
                className={css(styles.checkoutbuttonWrapper)}
                data-testid="checkOutButton-CheckInSuccess"
                onClick={() => {
                  push("/web-checkin/home");
                }}
              >
                Check-Out
              </Button>
            )}
            <p
              className={css(styles.alreadyCheckinText)}
              data-testid="checkIntoAnotherActivityButton-CheckInSuccess"
              onClick={() => {
                push("/web-checkin/selectactivity");
              }}
            >
              Check-in to another activity
            </p>
          </div>
        );
      }
    }

    return <div> </div>;
  };

  if (isAuthenticated) {
    logout({
      logoutParams: {
        returnTo: window.location.href,
      },
    });

    return null;
  }

  return (
    <React.Fragment>
      <Content className="checkin-success">
        <Row type="flex" justify="space-around" align="middle">
          <Row className={css(styles.flexWrapper)} justify="space-around">
            <p className={css(styles.imageWrapper)}>
              <img className={css(styles.imageWidth)} src={CheckCircle} />
            </p>
            <h2 className={css(styles.checkinText)} data-testid="checkedInSuccessMessage-CheckInSuccess">
              Checked in
            </h2>
            <h2 className={css(styles.checkinTime)} data-testid="checkedInTime-CheckInSuccess">
              {moment(attendeeDetails.checkIn).tz(attendeeDetails.checkInTz).format("h:mm A z")}
            </h2>
            <Card className={css(styles.cardWrapper)}>
              <div className={css(styles.cardInnerWrapper)}>
                <Row className={css(styles.rowWrapper)}>
                  <div className={css(styles.successText)}>
                    <b data-testid="checkedInUserName-CheckInSuccess">{getUserFullName(firstName, lastName)}</b>, you
                    are checked into <b data-testid="checkedInActivity-CheckInSuccess">{name}</b>
                  </div>
                </Row>

                {locationEnabled && (
                  <Row>
                    <Col span={1}>
                      <img className={css(styles.image)} src={LocationIcon} />
                    </Col>
                    <Col span={23}>
                      <h2 className={css(styles.locationText)} data-testid="checkedInLocation-CheckInSuccess">
                        {getSelectedAddress(attendeeLocation)}
                      </h2>
                    </Col>
                  </Row>
                )}
                <Divider orientation="center" />
                <Row className={css(styles.rowWrapper)}>
                  <h3 className={css(styles.nextText)}>What&apos;s next?</h3>
                  <div className={css(styles.successText)}>{checkinSuccessMessage}</div>
                </Row>
              </div>
            </Card>
          </Row>
        </Row>
      </Content>
      {handleFooterButton()}
    </React.Fragment>
  );
};

export default withRouter(CheckInSuccess);
