export const Fonts = {
  default:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
  itcAvantGardeGothicStd: "ITC Avant Garde Gothic Std",
  lato: "Lato",
  roboto: "Roboto",
  sfCompactDisplay: "SF Compact Display",
  sfCompactDisplayLight: "SF Compact Display Light",
  sfCompactDisplayRegular: "SF Compact Display Regular",
  sfCompactDisplayThin: "SF Compact Display Thin",
};
