import { Button, Card, Col, Icon, Input, Modal, Row } from "antd";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../../../theme/Colors";
import { Fonts } from "../../../../theme/Fonts";
import React from "react";

const styles = StyleSheet.create({
  copyButton: {
    background: Colors.blue,
    borderRadius: "4px",
    color: Colors.white,
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: "bold",
    height: "44px",
    letterSpacing: "0.05em",
    lineHeight: "19px",
    position: "absolute",
    textAlign: "center",
    width: "112px",
  },
  inputText: {
    background: Colors.inputBGColor,
    border: `1px solid ${Colors.borderColor}`,
    borderRadius: "4px",
    boxSizing: "border-box",
    color: Colors.darkBlack,
    fontFamily: "Lato",
    fontSize: "16px",
    height: "44px",
    lineHeight: "19px",
    marginLeft: "23px",
    marginRight: "-37px",
    paddingRight: "43px",
    width: "290px",
  },
  modalBody: {
    height: "579px",
    width: "520px",
  },
  paper: {
    backgroundColor: Colors.lightWhite,
  },
  root: {
    backgroundColor: Colors.white,
    border: "1px solid #D7D5D5",
    marginBottom: "15px",
    marginTop: "15px",
    overflowX: "hidden",
    overflowY: "auto",
    width: "472px",
  },
  urlText: {
    fontFamily: Fonts.roboto,
    fontSize: "14px",
    letterSpacing: "0.25px",
    lineHeight: "21px",
    paddingLeft: "27px",
    paddingTop: "50px",
    width: "407px",
  },
  urlTitle: {
    color: Colors.gulfBlue,
    fontFamily: Fonts.lato,
    fontSize: "18px",
    fontWeight: "bold",
    height: "22px",
    lineHeight: "22px",
    paddingLeft: "25px",
    paddingTop: "15px",
    position: "absolute",
    width: "242px",
  },
});
interface Props {
  readonly show: boolean;
  readonly handleClose: () => void;
  readonly UUID: string;
}

export const ShareVirtualKiosk: React.SFC<Props> = ({ show, handleClose, UUID }) => {
  const kioskData = {
    UUID: UUID,
    type: 1,
  };

  const indiviualData = {
    UUID: UUID,
    type: 2,
  };

  // eslint-disable-next-line
  const encodedKioskData = Buffer.from(JSON.stringify(kioskData)).toString("base64");
  // eslint-disable-next-line
  const encodedIndiviualData = Buffer.from(JSON.stringify(indiviualData)).toString("base64");

  // const kioskURL = `https://${document.domain}/api/virtual-kiosk/validate/${encodedKioskData}`;
  const individualsURL = `https://${document.domain}/api/virtual-kiosk/validate/${encodedIndiviualData}`;
  // const kioskMessage =
  //   "Use this URL to set up a check-in kiosk to check-in multiple people on a device. After a check-in is completed, the page will reset for the next person.";
  const individualsMessage =
    "Send this URL to individuals you want to check-in remotely. After a check-in is completed, the page will not reset. ";
  // const kioskTitle = "Kiosk URL";
  const individualsTitle = "Individual checkin URL";
  const copyURL = (text: string) => {
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
  };

  return (
    <Modal
      title={<span data-testid="modalTitle-ShareVirtualKiosk">Share virtual Kiosk</span>}
      visible={show}
      onCancel={handleClose}
      closeIcon={<Icon type="close" data-testid="closeIcon-ShareVirtualKiosk" />}
      footer={false}
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{ style: { display: "none" } }}
      className={css(styles.modalBody)}
      bodyStyle={{ backgroundColor: Colors.lightWhite }}
      wrapClassName="share_virtual_link"
    >
      <div className={css(styles.paper)}>
        {/* <div className={css(styles.root)}>
          <Row>
            <Col>
              <Card>
                <p className={css(styles.urlTitle)}>{kioskTitle}</p>
                <p className={css(styles.urlText)}>{kioskMessage}</p>
                <div>
                  <Input className={css(styles.inputText)} size="large" value={kioskURL} />
                  <Button
                    className={css(styles.copyButton)}
                    type="primary"
                    size="large"
                    onClick={() => copyURL(kioskURL)}
                  >
                    Copy
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>
        </div> */}
        <div className={css(styles.root)}>
          <Row>
            <Col>
              <Card>
                <p className={css(styles.urlTitle)}>{individualsTitle}</p>
                <p className={css(styles.urlText)}>{individualsMessage}</p>
                <div>
                  <Input
                    id="individualsURL"
                    className={css(styles.inputText)}
                    data-testid="kioskInputField-ShareVirtualKiosk"
                    size="large"
                    value={individualsURL}
                  />
                  <Button
                    className={css(styles.copyButton)}
                    data-testid="copyButton-ShareVirtualKiosk"
                    type="primary"
                    size="large"
                    onClick={() => copyURL(individualsURL)}
                  >
                    Copy
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  );
};
