// eslint-disable-next-line no-unused-vars
import { Button, Grid } from "@material-ui/core";
import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import history from "../../../history";

interface Props {
  readonly activityFilter: (event: { currentTarget: any }) => void;
  readonly allowOfflineMode: boolean;
  readonly exportCheckIns: () => void;
  readonly open: boolean;
  readonly resetClick: () => void;
  readonly selectedEventName: string;
  readonly queryForCheckins: () => void;
  readonly updateFirstName: (firstName: string) => void;
  readonly updateLastName: (LastName: string) => void;
  readonly updateEmail: (email: string) => void;
}

export function CheckinHeader({
  activityFilter,
  exportCheckIns,
  open,
  resetClick,
  selectedEventName,
  queryForCheckins,
  updateFirstName,
  updateLastName,
  updateEmail,
}: Props) {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  return (
    <>
      <Grid container>
        <Grid container item alignItems="center" justify="flex-start" xs={12} md={10} spacing={16}>
          <Grid item>
            <TextField
              data-testid="firstNameInputField-CheckinHeader"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
                updateFirstName(e.target.value);
              }}
              variant="outlined"
              label="First Name"
            />
          </Grid>
          <Grid item>
            <TextField
              data-testid="lastNameInputField-CheckinHeader"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                updateLastName(e.target.value);
              }}
              variant="outlined"
              label="Last Name"
            />
          </Grid>
          <Grid item>
            <TextField
              data-testid="emailInputField-CheckinHeader"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                updateEmail(e.target.value);
              }}
              variant="outlined"
              label="Email"
            />
          </Grid>
          <Grid item>
            <Button
              color="primary"
              disabled={false}
              variant="contained"
              onClick={() => queryForCheckins()}
              data-testid="searchButton-AllCheckIns"
            >
              Search
            </Button>
          </Grid>
          <Grid item>
            <h6
              className="filter-text"
              aria-label="More"
              aria-owns={open ? "long-menu" : undefined}
              aria-haspopup="true"
              onClick={activityFilter}
              data-testid="filterText"
            >
              {selectedEventName === "" ? "Filter by Activity" : selectedEventName}
              <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 1L7.5 0H0.5L0 1L3.5 6H4.5L8 1Z" fill="#687FA3" />
              </svg>
            </h6>
          </Grid>
        </Grid>
        <Grid container item alignItems="center" justify="flex-end" xs={12} md={2} spacing={16}>
          <Grid item>
            <h6
              className="filter-text"
              aria-label="More"
              aria-owns={open ? "long-menu" : undefined}
              aria-haspopup="true"
              onClick={() => {
                resetClick();
                setFirstName("");
                setLastName("");
                setEmail("");
              }}
              data-testid="resetFilter-AllCheckIns"
            >
              Reset
            </h6>
          </Grid>
          <Grid item>
            <Button
              className="common_button"
              color="primary"
              variant="contained"
              onClick={exportCheckIns}
              data-testid="exportButton-AllCheckIns"
            >
              Export
            </Button>
          </Grid>
          <Grid item>
            <Button
              className="common_button"
              color="primary"
              variant="outlined"
              onClick={() => history.push("/admin-log")}
              style={{ textTransform: "none", whiteSpace: "nowrap" }}
              data-testid="adminLogButton-CheckinHeader"
            >
              Admin log
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
