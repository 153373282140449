import { Icon, Layout, Row } from "antd";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../theme/Colors";
import { Fonts } from "../../theme/Fonts";
import MeritIcon from "../../assets/Images/Icons/merit_icon.svg";
import React from "react";
import history from "../../history";

const styles = StyleSheet.create({
  closeIcon: {
    cursor: "pointer",
    paddingLeft: "40%",
  },
  closeIconWrapper: {
    width: "3%",
  },
  contentWrapper: {
    display: "flex",
    height: "50px",
    justifyContent: "center",
    marginTop: "10px",
    textAlign: "center",
    width: "100%",
  },
  headerText: {
    bottom: "10%",
    color: Colors.blackPearl,
    fontFamily: "Roboto",
    fontSize: "24px",
    left: "6.4%",
    letterSpacing: "0.25px",
    lineHeight: "32px",
    position: "absolute",
    right: "6.4%",
    textAlign: "center",
  },
  iconWrapper: {
    alignItems: "center",
    width: "97%",
  },
  mainContent: {
    fontFamily: Fonts.roboto,
  },
  mainDiv: {
    width: "350px",
  },
  meritLogo: {
    height: "40px",
    width: "76px",
  },
  subText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.sfCompactDisplayRegular,
    fontSize: "20px",
    letterSpacing: "0.15px",
    lineHeight: "28px",
    textAlign: "left",
  },
  textWrapper: {
    fontFamily: Fonts.roboto,
    padding: "30px",
    textAlign: "center",
  },
});

export const AboutMerit = () => {
  const { Content } = Layout;
  const { goBack } = history;

  return (
    <React.Fragment>
      <Content className={css(styles.mainContent)}>
        <Row type="flex" justify="space-around" align="middle" style={{ flexDirection: "column" }}>
          <div className={css(styles.mainDiv)}>
            <Row>
              <div className={css(styles.contentWrapper)}>
                <div className={css(styles.iconWrapper)}>
                  <img className={css(styles.meritLogo)} src={MeritIcon}></img>
                </div>
                <div className={css(styles.closeIconWrapper)}>
                  <Icon className={css(styles.closeIcon)} type="close" onClick={() => goBack()} />
                </div>
              </div>
            </Row>
            <Row>
              <div className={css(styles.textWrapper)}>
                <p className={css(styles.headerText)} data-testid="whatIsMeritHeader-AboutMerit">
                  What is Merit?
                </p>
              </div>
            </Row>
            <Row>
              <div>
                <p className={css(styles.subText)} data-testid="descriptionMessage-AboutMerit">
                  Merit is an online credentialing platform offers you a way to verify your personal and professional
                  qualifications instead of relying on paper certificates, ledgers, and identification cards.
                </p>
                <p className={css(styles.subText)}>
                  Visit{" "}
                  <a href="https://www.merits.com/" target="_blank">
                    merits.com
                  </a>{" "}
                  to learn more.
                </p>
              </div>
            </Row>
          </div>
        </Row>
      </Content>
    </React.Fragment>
  );
};
