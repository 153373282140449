import { Button, Card, Icon, Layout, Row } from "antd";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../theme/Colors";
import ErrorOutline from "../../assets/Images/Icons/error_outline.svg";
import { Fonts } from "../../theme/Fonts";
import React from "react";
import history from "../../history";
import { useAuth0 } from "@auth0/auth0-react";

const styles = StyleSheet.create({
  accountText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "18px",
    marginTop: "5px",
    textAlign: "left",
  },
  bottomText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.25px",
    lineHeight: "24px",
    marginTop: "10px",
    textAlign: "left",
  },
  buttonWrapper: {
    background: Colors.defaultButtonBg,
    color: Colors.white,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    fontWeight: 500,
    height: "48px",
    letterSpacing: "1.25px",
    lineHeight: "16px",
    textAlign: "center",
    textTransform: "uppercase",
    width: "327px",
  },
  cardInnerWrapper: {
    height: "auto",
    marginLeft: "2%",
    marginRight: "2%",
    width: "96%",
  },
  cardWrapper: {
    borderRadius: "4px",
    height: "auto",
    width: "327px",
  },
  checkinText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "24px",
    letterSpacing: "0.25px",
    marginTop: "10px",
    textAlign: "center",
  },
  contentWrapper: {
    alignItems: "center",
    height: "auto",
    justifyContent: "center",
    marginTop: "20px",
    minWidth: "375px",
  },
  flexWrapper: {
    textAlign: "left",
  },
  footerWrapper: {
    alignItems: "center",
    backgroundColor: Colors.white,
    display: "flex",
    height: "100px",
    justifyContent: "center",
    minWidth: "375px",
  },
  imageWidth: {
    width: "44px",
  },
  imageWrapper: {
    alignItems: "center",
    marginTop: "20px",
    textAlign: "center",
  },
  line: {
    border: `.5px solid ${Colors.cardBorder}`,
    color: Colors.cardBorder,
  },
  rowWrapper: {
    marginTop: "7px",
    width: "100%",
  },
  successText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.25px",
    lineHeight: "24px",
    marginTop: "10px",
    textAlign: "left",
    wordBreak: "break-all",
  },
  titleText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "24px",
    letterSpacing: "0.15px",
    marginTop: "5px",
    textAlign: "justify",
  },
  viewMeritsButton: {
    alignItems: "center",
    background: Colors.checkoutButtonBg,
    border: `1px solid ${Colors.imageContainerBorder}`,
    color: Colors.sherpa,
    display: "flex",
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    fontWeight: 500,
    height: "48px",
    justifyContent: "space-between",
    letterSpacing: "0.15px",
    lineHeight: "30px",
    textTransform: "uppercase",
    width: "263px",
  },
});

interface LocationState {
  attendeeDetails: {
    readonly firstName: string;
    readonly lastName?: string;
  };
  policyRunDetails?: PolicyRunDetails[];
  validateMeritsResponse: readonly ValidateMerit[];
}

interface Props extends RouteComponentProps<{}, {}, LocationState> {}

const CheckInFailure = ({ location }: Props | any) => {
  const { Content } = Layout;
  const { logout, isAuthenticated } = useAuth0();

  const eventData = localStorage.getItem("eventData");
  const eventDetails = JSON.parse(eventData!);
  const { push } = history;

  const attendeeDetails =
    location.state?.attendeeDetails ?? JSON.parse(localStorage.getItem("attendeeDetails") ?? "{}");
  localStorage.setItem("attendeeDetails", JSON.stringify(attendeeDetails));

  const policyRunDetails: PolicyRunDetails[] =
    location.state?.policyRunDetails ?? JSON.parse(localStorage.getItem("policyRunDetails") ?? "[]");
  localStorage.setItem("policyRunDetails", JSON.stringify(policyRunDetails));

  const validateMeritsResponse: ValidateMerit[] =
    location.state?.validateMeritsResponse ?? JSON.parse(localStorage.getItem("validateMeritsResponse") ?? "[]");
  localStorage.setItem("validateMeritsResponse", JSON.stringify(validateMeritsResponse));

  const { firstName } = attendeeDetails;
  const { checkinFailedMessage, name } = eventDetails.event;

  const handleViewMerits = async () => {
    if (policyRunDetails.length) {
      push({
        pathname: "/web-checkin/view-merits",
        state: {
          eventData: eventDetails,
          highlightFailedPolicy: true,
          policyRunDetails: policyRunDetails,
          validateMeritsResponse: validateMeritsResponse,
        },
      });
    }
  };

  if (isAuthenticated) {
    logout({
      logoutParams: {
        returnTo: window.location.href,
      },
    });

    return null;
  }

  return (
    <React.Fragment>
      <Content>
        <div className={css(styles.contentWrapper)}>
          <Row type="flex" justify="space-around" align="middle">
            <Row className={css(styles.flexWrapper)} justify="space-around">
              <p className={css(styles.imageWrapper)}>
                <img className={css(styles.imageWidth)} src={ErrorOutline} />
              </p>
              <h2 className={css(styles.checkinText)} data-testid="checkinFailureMessage-CheckInFailure">
                We couldn&apos;t check you in
              </h2>
              <Card className={css(styles.cardWrapper)}>
                <div className={css(styles.cardInnerWrapper)}>
                  <Row className={css(styles.rowWrapper)}>
                    <div className={css(styles.successText)}>
                      <b>{firstName}</b>, you don&apos;t have the required policies to check-in to <br />
                      <b>{name}</b>
                    </div>
                  </Row>
                  <Row className={css(styles.rowWrapper)}>
                    <h3 className={css(styles.accountText)}>Missing policies</h3>
                    <Button
                      type="primary"
                      block
                      className={css(styles.viewMeritsButton)}
                      onClick={() => handleViewMerits()}
                      data-testid="viewMeritsButton-CheckInFailure"
                    >
                      <span style={{ float: "left" }}>View Policies</span>
                      <Icon type="right" style={{ float: "right" }} />
                    </Button>
                  </Row>
                  <br />
                  <Row className={css(styles.rowWrapper)}>
                    <hr className={css(styles.line)}></hr>
                  </Row>
                  <Row className={css(styles.rowWrapper)}>
                    <h2 className={css(styles.titleText)}>What&apos;s next?</h2>
                    <div className={css(styles.bottomText)}>{checkinFailedMessage}</div>
                  </Row>
                </div>
              </Card>
            </Row>
          </Row>
        </div>
      </Content>
      <div className={css(styles.footerWrapper)}>
        <Button
          className={css(styles.buttonWrapper)}
          onClick={() => {
            push("/web-checkin/home");
          }}
          data-testid="doneButton-CheckInFailure"
        >
          Done
        </Button>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CheckInFailure);
