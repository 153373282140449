import { Button, Card, Col, Divider, Layout, Row } from "antd";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import CheckCircle from "../../assets/Images/Icons/check-circle.svg";
import { Colors } from "../../theme/Colors";
import Cookies from "js-cookie";
import { Fonts } from "../../theme/Fonts";
import { Footer } from "./Layout/Footer";
import LocationIcon from "../../assets/Images/Icons/location-icon.svg";
import React from "react";
import { getSelectedAddress } from "../../utils/GeoLocationHelper";
import history from "../../history";
import moment from "moment-timezone";

const styles = StyleSheet.create({
  buttonWrapper: {
    background: Colors.defaultButtonBg,
    color: Colors.white,
    height: "50px",
    marginTop: "20px",
    textAlign: "center",
    textTransform: "uppercase",
    width: "300px",
  },
  cardInnerWrapper: {
    height: "auto",
    marginLeft: "2%",
    marginRight: "2%",
    width: "96%",
  },
  cardWrapper: {
    borderRadius: "4px",
    height: "auto",
    margin: "0 10px",
  },
  checkinText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "34px",
    letterSpacing: "0.25px",
    lineHeight: "43px",
    textAlign: "center",
  },
  checkinTime: {
    fontFamily: Fonts.roboto,
    fontSize: "64px",
    fontWeight: 500,
    lineHeight: "81px",
    textAlign: "center",
  },
  contentWrapper: {
    alignItems: "center",
    height: "auto",
    justifyContent: "center",
    marginTop: "20px",
    minWidth: "375px",
  },
  flexWrapper: {
    textAlign: "left",
  },
  footerWrapper: {
    alignItems: "center",
    backgroundColor: Colors.white,
    height: "100px",
    justifyContent: "center",
    marginTop: "20px",
    minWidth: "375px",
    textAlign: "center",
  },
  guestCheckinText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.25px",
    lineHeight: "24px",
    marginTop: "10px",
    textAlign: "center",
  },
  headerFlex: {
    display: "flex",
  },
  image: {
    height: "14px",
    marginTop: "8px",
    width: "10px",
  },
  imageWidth: {
    width: "44px",
  },
  imageWrapper: {
    alignItems: "center",
    marginTop: "20px",
    textAlign: "center",
    width: "97%",
  },
  locationText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "15px",
    lineHeight: "20px",
    marginTop: "8px",
    paddingBottom: "20px",
    wordBreak: "break-word",
  },
  nextText: {
    fontFamily: Fonts.roboto,
    fontSize: "24px",
  },
  rowWrapper: {
    marginTop: "7px",
    width: "100%",
  },
  successText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.25px",
    lineHeight: "24px",
    marginTop: "10px",
    textAlign: "left",
    wordBreak: "break-word",
  },
});

interface Props
  extends RouteComponentProps<
    {},
    {},
    {
      location: {
        readonly relatedTo: "checkin" | "checkout";
        readonly deviceLatitude: string;
        readonly deviceLongitude: string;
        readonly deviceLocation: string;
        readonly locationType: "device" | "manual";
        readonly manualLatitude: string;
        readonly manualLongitude: string;
        readonly manualLocation: string;
        readonly remarks: string;
      };
    }
  > {}

const GuestCheckInSuccess = ({ location }: Props) => {
  const { Content } = Layout;
  const { push } = history;
  const eventData = localStorage.getItem("eventData");
  const virtualKioskType = Cookies.get("virtualKioskType");
  const eventDetails = JSON.parse(eventData!);
  const { checkinSuccessMessage, locationEnabled, timezone } = eventDetails.event;

  // eslint-disable-next-line react/no-multi-comp
  const handleFooterButton = (): React.ReactNode => {
    if (virtualKioskType === "1") {
      return (
        <div className={css(styles.footerWrapper)}>
          <Button
            className={css(styles.buttonWrapper)}
            onClick={() => {
              push("/web-checkin/home");
            }}
          >
            Done
          </Button>
        </div>
      );
    }

    if (virtualKioskType === "2") {
      return <Footer />;
    }

    return <div> </div>;
  };

  return (
    <React.Fragment>
      <Content>
        <div className={css(styles.contentWrapper)}>
          <Row type="flex" justify="space-around" align="middle">
            <Row className={css(styles.flexWrapper)} justify="space-around">
              <div className={css(styles.headerFlex)}>
                <p className={css(styles.imageWrapper)}>
                  <img className={css(styles.imageWidth)} src={CheckCircle} />
                </p>
              </div>
              <h2 className={css(styles.checkinText)} data-testid="guestCheckedInSuccessMessage-GuestCheckInSuccess">
                Checked in
              </h2>
              <h2 className={css(styles.checkinTime)} data-testid="checkedInTime-GuestCheckInSuccess">
                {moment().tz(timezone).format("h:mm A z")}
              </h2>
              <Card className={css(styles.cardWrapper)}>
                {locationEnabled && (
                  <Row>
                    <Col span={1}>
                      <img className={css(styles.image)} src={LocationIcon} />
                    </Col>
                    <Col span={23}>
                      <h2 className={css(styles.locationText)} data-testid="checkedInLocation-GuestCheckInSuccess">
                        {getSelectedAddress(location.state.location)}
                      </h2>
                    </Col>
                    <Divider orientation="center" />
                  </Row>
                )}

                <div className={css(styles.cardInnerWrapper)}>
                  <Row className={css(styles.rowWrapper)}>
                    <h3 className={css(styles.nextText)} data-testid="whatsNextMessage-GuestCheckInSuccess">
                      What&apos;s next?
                    </h3>
                    <div className={css(styles.successText)}>{checkinSuccessMessage}</div>
                  </Row>
                </div>
              </Card>{" "}
              <br />
              {/* <Card className={css(styles.cardWrapper)}>
                <div className={css(styles.cardInnerWrapper)}>
                  <Row className={css(styles.rowWrapper)} type="flex" justify="space-around" align="middle">
                    <Icon type="mail" style={{ color: "#286DB3", fontSize: "32px" }} />
                  </Row>
                  <Row className={css(styles.rowWrapper)}>
                    <div className={css(styles.guestCheckinText)}>
                      <strong data-testid="openYourInboxMessage-GuestCheckInSuccess">Open your inbox</strong>
                    </div>
                    <div className={css(styles.guestCheckinText)}>
                      We sent you a check-in merit to <span style={{ color: Colors.darkBlue }}>{email}</span>
                    </div>
                    <div className={css(styles.guestCheckinText)}>
                      Click the &ldquo;Accept&ldquo; button in the email to set up your Merit account
                    </div>
                  </Row>
                </div>
              </Card> */}
            </Row>
          </Row>
        </div>
      </Content>
      {handleFooterButton()}
    </React.Fragment>
  );
};

export default withRouter(GuestCheckInSuccess);
