import { Layout, Row } from "antd";
import React, { useState } from "react";
// eslint-disable-next-line
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../theme/Colors";
import { Fonts } from "../../theme/Fonts";
import { Footer } from "./Layout/Footer";
import { Header } from "./Layout/Header";
import LoadingScreen from "../UI-Components/LoadingScreen";
import QrReader from "react-qr-reader";
import history from "../../history";
import { scanCheckInQr } from "./constant/api";
import scanIcon from "../../assets/Images/Icons/scanIcon.svg";
import { toast } from "react-toastify";

const styles = StyleSheet.create({
  activityName: {
    color: Colors.sherpa,
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "0.15px",
    lineHeight: "30px",
  },
  contentWrapper: {
    fontFamily: Fonts.roboto,
  },
  iconContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    paddingTop: "24px",
  },
  qrScanText: {
    color: Colors.black,
    fontFamily: Fonts.sfCompactDisplayThin,
    fontSize: "14px",
    lineHeight: "140%",
    marginTop: "18px",
    opacity: 0.8,
    width: "327px",
  },
  scanContainer: {
    alignItems: "center",
    background: "black",
    display: "flex",
    height: "280px",
    justifyContent: "center",
    width: "375px",
  },
  scanText: {
    color: Colors.blackPearl,
    fontSize: "24px",
    letterSpacing: "0.25px",
    lineHeight: "32px",
  },
});

interface LocationState {
  checkIn?: boolean;
  checkOut?: boolean;
}

interface Props extends RouteComponentProps<{}, {}, LocationState> {
  readonly notify: (message: string | React.ReactNode, status: string) => void;
}

const hasGetUserMedia = (): boolean => {
  return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
};

const CheckInCheckOutQrScan: React.FC<Props> = ({ notify, location }) => {
  const { Content } = Layout;
  const { push, goBack } = history;
  const { event } = JSON.parse(localStorage.getItem("eventData")!);

  const [loading, setLoading] = useState<boolean>(false);

  const handleError = (error: string) => {
    if (error && hasGetUserMedia()) {
      notify("Camera access required", "error");
    }
  };

  const handleCheckin = async (qrData: string) => {
    try {
      setLoading(true);
      const locationData = localStorage.getItem("locationData");
      const geolocation = locationData ? JSON.parse(locationData) : null;
      const params = { allowDuplicates: false, data: qrData, eventId: event.eventId, location: geolocation };
      const response: any = await scanCheckInQr(params);
      const {
        metQualification,
        attendeeDetails,
        eventAttendeeId,
        accessMeritSent,
        isMemberAlreadyCheckedIn,
        hasWaiver,
      } = response.data.item;

      if (isMemberAlreadyCheckedIn) {
        push({ pathname: "/web-checkin/already-checkedin", state: { attendeeDetails: attendeeDetails } });
      } else if (metQualification) {
        if (hasWaiver) {
          push({
            pathname: "/web-checkin/waiver-form",
            state: { attendeeDetails: attendeeDetails, eventAttendeeId: eventAttendeeId },
          });
        } else {
          push({ pathname: "/web-checkin/checkin-success", state: { attendeeDetails: attendeeDetails } });
        }
      } else if (accessMeritSent) {
        const { eventId, memberId } = response.data.item;
        push({ pathname: "/web-checkin/checkin-access", state: { eventId, memberId } });
      } else {
        push({ pathname: "/web-checkin/checkin-failure", state: { attendeeDetails: attendeeDetails } });
      }
    } catch (err) {
      setLoading(false);
      notify("Your Merit Key is expired. On the Merit Key page, press the refresh button on the top-right", "error");
    }
  };

  const handleScan = async (qrData: string) => {
    if (qrData !== null) {
      if (location.state) {
        const { checkIn, checkOut } = location.state;
        if (checkIn) {
          handleCheckin(qrData);
        }
        if (checkOut) {
          push({
            pathname: "/web-checkin/checkout-events",
            state: { memberToken: qrData },
          });
        }
      }
    }
  };

  const alertMessage = (
    <span>
      Scanning on iPhone is currently available through <strong>Safari</strong> only
    </span>
  );

  if (!hasGetUserMedia()) {
    toast.error(alertMessage, {
      autoClose: false,
      hideProgressBar: true,
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  return (
    <React.Fragment>
      {loading ? (
        <LoadingScreen key={1} minHeight="calc(100vh - 125px)" />
      ) : (
        <React.Fragment>
          <Header exitButton backArrow handleExit={() => push("/web-checkin/home")} handleGoBack={() => goBack()} />
          <Content className={css(styles.contentWrapper)}>
            <Row type="flex" justify="space-around" align="middle" style={{ flexDirection: "column" }}>
              <div style={{ width: "327px" }}>
                <p className={css(styles.activityName)}>{location.state.checkIn && event.name}</p>
                <p className={css(styles.scanText)}>Scan your Merit Key</p>
              </div>
              <div className={css(styles.scanContainer)}>
                <QrReader
                  delay={300}
                  onError={handleError}
                  onScan={handleScan}
                  style={{ height: "280px", width: "100%" }}
                  className="qr_scanner"
                />
              </div>
              <div className={css(styles.iconContainer)}>
                <img src={scanIcon} />
              </div>
              <div className={css(styles.qrScanText)}>
                Find your Merit Key on your Merit app and hold your phone so that the QR code appears on the box below
              </div>
            </Row>
          </Content>
          <Footer />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default withRouter(CheckInCheckOutQrScan);
