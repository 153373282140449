import { Icon, Layout, Popover } from "antd";
import { createStyles, withStyles } from "@material-ui/core";
import { useLogout, useMeritAuth0 } from "../../hooks/auth";
import React from "react";
import defaultOrg from "../../assets/Images/Icons/defaultOrg.svg";
import history from "../../history";

interface Props {
  readonly classes: {
    readonly sidebar: string;
    readonly orgLogo: string;
    readonly homePage: string;
    readonly logout: string;
  };
  readonly orgDetails: {
    readonly logoUrl: string;
  };
  activeNav: string;
}

const Sidebar = (props: Props) => {
  const logout = useLogout();
  const { accessToken } = useMeritAuth0();
  const onMenuClick = async (event: any, menu: any) => {
    if (!accessToken || menu === "/logout") {
      await logout();
    } else {
      history.push(menu);
    }
  };

  const { classes, orgDetails, activeNav } = props;
  const text = <span>Get started with Merit Check-in</span>;
  const content = (
    <div>
      <p>Learn what Merit Check-in can</p>
      <p>help you achieve.</p>
    </div>
  );

  return (
    <div className={classes.sidebar}>
      <div className={classes.orgLogo}>
        {orgDetails && (
          <img
            alt="logo"
            src={orgDetails.logoUrl === null ? defaultOrg : orgDetails.logoUrl}
            className={classes.orgLogo}
          />
        )}
      </div>
      <div
        onClick={(event) => onMenuClick(event, "/home")}
        className={activeNav === "/home" ? "active_div" : "inactive_div"}
        data-testid="homeMenu"
      >
        <svg
          width="14"
          height="16"
          viewBox="0 0 14 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="margin_10"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.67268 0.287191C7.29126 -0.0595659 6.70874 -0.0595659 6.32732 0.287191L0.327319 5.74186C0.11885 5.93138 0 6.20005 0 6.48179V14.6667C0 15.219 0.447715 15.6667 1 15.6667H13C13.5523 15.6667 14 15.219 14 14.6667V6.48179C14 6.20005 13.8812 5.93138 13.6727 5.74186L7.67268 0.287191ZM10 13.6667H12V6.92415L7 2.3786L2 6.92415V13.6667H4V9.00006C4 8.44778 4.44772 8.00006 5 8.00006H9C9.55228 8.00006 10 8.44778 10 9.00006V13.6667ZM6 13.6667H8V10.0001H6V13.6667Z"
            fill={activeNav === "/home" ? "#F7FAFF" : "#687FA3"}
          />
        </svg>

        <div>
          <span className={activeNav === "/home" ? "activenav_name" : "inactivenav_name"}>Home</span>
        </div>
      </div>
      <div
        onClick={(event) => onMenuClick(event, "/events")}
        className={activeNav === "/events" ? "active_div" : "inactive_div"}
        data-testid="activitiesMenu"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="margin_10"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.5 4L4 4.5V10.5L4.5 11H10.5L11 10.5V4.5L10.5 4H4.5ZM13.5 4L13 4.5V10.5L13.5 11H19.5L20 10.5V4.5L19.5 4H13.5ZM13 13.5L13.5 13H19.5L20 13.5V19.5L19.5 20H13.5L13 19.5V13.5ZM4.5 13L4 13.5V19.5L4.5 20H10.5L11 19.5V13.5L10.5 13H4.5Z"
            fill={activeNav === "/events" ? "#F7FAFF" : "#687FA3"}
          />
          <path
            d="M6.5 6L6 6.5V8.5L6.5 9H8.5L9 8.5V6.5L8.5 6H6.5Z"
            fill={activeNav === "/events" ? "#687FA3" : "#F7FAFF"}
          />
          <path
            d="M15.5 6L15 6.5V8.5L15.5 9H17.5L18 8.5V6.5L17.5 6H15.5Z"
            fill={activeNav === "/events" ? "#687FA3" : "#F7FAFF"}
          />
          <path
            d="M6.5 15L6 15.5V17.5L6.5 18H8.5L9 17.5V15.5L8.5 15H6.5Z"
            fill={activeNav === "/events" ? "#687FA3" : "#F7FAFF"}
          />
          <path
            d="M15.5 15L15 15.5V17.5L15.5 18H17.5L18 17.5V15.5L17.5 15H15.5Z"
            fill={activeNav === "/events" ? "#687FA3" : "#F7FAFF"}
          />
        </svg>

        <div>
          <span className={activeNav === "/events" ? "activenav_name" : "inactivenav_name"}>Activities</span>
        </div>
      </div>
      <div
        className={activeNav === "/members" || activeNav === "/admin-log" ? "active_div" : "inactive_div"}
        onClick={(event) => onMenuClick(event, "/members")}
        data-testid="checkinLogMenu"
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="margin_10"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.5 7C6.43311 7 8 5.43298 8 3.5C8 1.56702 6.43311 0 4.5 0C2.56689 0 1 1.56702 1 3.5C1 5.43298 2.56689 7 4.5 7ZM4.5 5C5.32861 5 6 4.32837 6 3.5C6 2.67163 5.32861 2 4.5 2C3.67139 2 3 2.67163 3 3.5C3 4.32837 3.67139 5 4.5 5Z"
            fill={activeNav === "/members" || activeNav === "/admin-log" ? "#F7FAFF" : "#687FA3"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 8C1.34326 8 0 9.34314 0 11V15.5L0.5 16H15.5L16 15.5V12C16 10.3431 14.6567 9 13 9H11C10.1362 9 9.35791 9.36499 8.81055 9.94897C8.38477 8.81055 7.28711 8 6 8H3ZM10 14L10.5 11H13.5L14 14H10ZM2.5 10H6.5L7 14H2L2.5 10Z"
            fill={activeNav === "/members" || activeNav === "/admin-log" ? "#F7FAFF" : "#687FA3"}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 8C13.6567 8 15 6.65686 15 5C15 3.34314 13.6567 2 12 2C10.3433 2 9 3.34314 9 5C9 6.65686 10.3433 8 12 8ZM12 6C12.5522 6 13 5.55225 13 5C13 4.44775 12.5522 4 12 4C11.4478 4 11 4.44775 11 5C11 5.55225 11.4478 6 12 6Z"
            fill={activeNav === "/members" || activeNav === "/admin-log" ? "#F7FAFF" : "#687FA3"}
          />
        </svg>

        <div>
          <span
            className={activeNav === "/members" || activeNav === "/admin-log" ? "activenav_name" : "inactivenav_name"}
          >
            Check-in log
          </span>
        </div>
      </div>
      <div
        className={
          ["/workflows", "/status-requirement", "/automate-checkout"].includes(activeNav)
            ? "active_div"
            : "inactive_div"
        }
        onClick={(event) => onMenuClick(event, "/workflows")}
        data-testid="workflowsMenu-SideBar"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="none"
          className="margin_10"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22,11V3h-7v3H9V3H2v8h7V8h2v10h4v3h7v-8h-7v3h-2V8h2v3H22z M7,9H4V5h3V9z M17,15h3v4h-3V15z M17,5h3v4h-3V5z"
            fill={
              ["/workflows", "/status-requirement", "/automate-checkout"].includes(activeNav) ? "#F7FAFF" : "#687FA3"
            }
          />
        </svg>

        <div>
          <span
            className={
              ["/workflows", "/status-requirement", "/automate-checkout"].includes(activeNav)
                ? "activenav_name"
                : "inactivenav_name"
            }
          >
            Workflows
          </span>
        </div>
      </div>
      <div
        className={activeNav === "/kiosk" ? "active_div" : "inactive_div"}
        onClick={(event) => onMenuClick(event, "/kiosk")}
        data-testid="kioskMenu"
      >
        <svg
          width="13"
          height="16"
          viewBox="0 0 13 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="margin_10"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 16L0 15V1L1 0H12L13 1V15L12 16H1ZM2 2H11V12H2V2ZM7.5 14C7.5 14.5523 7.05228 15 6.5 15C5.94772 15 5.5 14.5523 5.5 14C5.5 13.4477 5.94772 13 6.5 13C7.05228 13 7.5 13.4477 7.5 14Z"
            fill={activeNav === "/kiosk" ? "#F7FAFF" : "#687FA3"}
          />
        </svg>

        <div>
          <span className={activeNav === "/kiosk" ? "activenav_name" : "inactivenav_name"}>Kiosk</span>
        </div>
      </div>

      <div className={classes.homePage} onClick={(event) => onMenuClick(event, "/welcome")}>
        <Layout
          style={{
            backgroundColor: activeNav === "/welcome" ? "#147DF4" : "rgba(0,0,0,0.04)",
            borderRadius: "100px",
            height: "40px",
          }}
        >
          <Popover placement="right" content={content} title={text}>
            <Icon
              data-testid="getStartedIcon-SideBar"
              type="question-circle"
              style={{
                color: activeNav === "/welcome" ? "#fff" : "#687FA3",
                fontSize: "20px",
                marginTop: "10px",
              }}
              onClick={(event) => onMenuClick(event, "/welcome")}
              theme="outlined"
            />
          </Popover>
        </Layout>
      </div>

      <div className={classes.logout}>
        <Icon
          type="logout"
          style={{ color: "#F04A46", fontSize: "20px", marginTop: "10px", paddingLeft: "10px" }}
          onClick={(event) => onMenuClick(event, "/logout")}
          theme="outlined"
          data-testid="logoutIcon-SideBar"
        />
      </div>
    </div>
  );
};
const styles = () =>
  createStyles({
    homePage: {
      borderRadius: "100px",
      bottom: "80px",
      height: "40px",
      justifyContent: "center",
      marginStart: "25px",
      position: "absolute",
      width: "40px",
    },
    logout: {
      background: "rgba(0,0,0,0.04)",
      borderRadius: "100px",
      bottom: "20px",
      height: "40px",
      justifyContent: "center",
      marginStart: "25px",
      position: "absolute",
      width: "40px",
    },
    orgLogo: {
      height: "96px",
      marginBottom: "20px",
      width: "96px",
    },
    sidebar: {
      background: "#ffffff",
      boxShadow: "0px 40px 80px rgba(0, 0, 0, 0.16), 0px 0px 1px rgba(0, 0, 0, 0.16)",
      height: "100vh",
      overflowX: "hidden",
      overflowY: "auto",
      position: "fixed",
      width: "96px",
      zIndex: 1,
    },
  });
export default withStyles(styles)(Sidebar);
