import { UnreachableCaseError } from "./UnreachableCaseError";
import history from "../history";

interface HandleLocationParams {
  readonly eventData: EventObject;
  readonly hasBackButton: boolean;
  readonly actionType: "checkin" | "checkout";
}

interface LocationDetails {
  readonly relatedTo: "checkin" | "checkout";
  readonly deviceLatitude: string;
  readonly deviceLongitude: string;
  readonly deviceLocation: string;
  readonly locationType: "device" | "manual";
  readonly manualLatitude: string;
  readonly manualLongitude: string;
  readonly manualLocation: string;
  readonly remarks: string;
}

const { push } = history;

const handleLocationSuccessCallback = (
  position: GeolocationPosition,
  eventData: EventObject,
  hasBackButton: boolean,
  actionType: string,
) => {
  const { latitude, longitude } = position.coords;
  const params = { eventData, latitude, locationType: "device", longitude };
  const state = { ...params, actionType, hasBackButton };
  localStorage.removeItem("eventData");
  push({
    pathname: "/web-checkin/devicelocation",
    state,
  });
};

const handleLocationErrorCallback = (
  positionError: GeolocationPositionError,
  eventData: EventObject,
  hasBackButton: boolean,
  actionType: string,
) => {
  const state = {
    actionType,
    eventData,
    hasBackButton,
    positionError: positionError.message,
  };
  push({
    pathname: "/web-checkin/input-location",
    state,
  });
};

export const handleLocation = ({ eventData, hasBackButton, actionType }: HandleLocationParams) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => handleLocationSuccessCallback(position, eventData, hasBackButton, actionType),
      (positionError) => handleLocationErrorCallback(positionError, eventData, hasBackButton, actionType),
    );
  } else {
    push({
      pathname: "/web-checkin/input-location",
      state: { actionType, eventData, positionError: "Geolocation is not supported by this browser" },
    });
  }
};

export const getSelectedAddress = (locationDetails: LocationDetails | undefined) => {
  if (locationDetails) {
    const { deviceLocation, manualLocation, locationType } = locationDetails;

    switch (locationType) {
      case "device":
        return deviceLocation;
      case "manual":
        return manualLocation;
      default:
        throw new UnreachableCaseError(locationType);
    }
  }

  return "Unable to determine location";
};
