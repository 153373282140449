import { Button, Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { deleteUnMatchedCheckout, fetchUnMatchedCheckouts } from "../../../constant/api";
import { DeleteUnMatchedCheckoutModal } from "./Modals/DeleteUnMatchedCheckoutModal";
import { Some } from "../../../utils/Some";
import deleteIcon from "../../../assets/Images/Icons/delete.svg";

type State = {
  readonly isLoading: boolean;
  readonly unMatchedCheckouts: NoMatchOfflineCheckoutType[];
};

type TableColumns = {
  readonly dataIndex: string;
  readonly title: string;
  readonly render?: (text: string, event: NoMatchOfflineCheckoutType) => React.ReactNode;
  readonly className: string;
};

interface Props {
  readonly notify: (message: string, status: string) => void;
  readonly sessionId: string;
}

export const NoMatchesTable = ({ notify, sessionId }: Props) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [sessionLogId, setSessionLogId] = useState<string>("");
  const [tableState, setTableState] = useState<State>({
    isLoading: false,
    unMatchedCheckouts: [],
  });

  const getUnMatchedCheckouts = useCallback(async () => {
    try {
      setTableState((prev) => ({
        ...prev,
        isLoading: true,
      }));

      const sessionsData = await fetchUnMatchedCheckouts(sessionId);

      setTableState({
        isLoading: false,
        unMatchedCheckouts: sessionsData.data.item,
      });
    } catch (error) {
      setTableState((prev) => ({
        ...prev,
        isLoading: false,
      }));
      notify("Error in fetching the unmatched offline checkouts", "error");
    }
  }, []);

  useEffect(() => {
    getUnMatchedCheckouts();
  }, [getUnMatchedCheckouts]);

  const deleteSessionLog = async (notes: string | undefined) => {
    try {
      setTableState((prev) => ({
        ...prev,
        isLoading: true,
      }));

      if (Some(notes)) {
        await deleteUnMatchedCheckout(sessionId, sessionLogId, { notes });
      }

      setShowDeleteModal(false);
      setSessionLogId("");

      notify("Offline checkout deleted successfully.", "success");

      await getUnMatchedCheckouts();

      setTableState((prev) => ({
        ...prev,
        isLoading: false,
      }));
    } catch (error) {
      setTableState((prev) => ({
        ...prev,
        isLoading: false,
      }));
      notify("Unable to delete this session log.", "error");
    }
  };

  const columns: Array<TableColumns> = [
    {
      className: "checkin-table-header",
      dataIndex: "tabletName",
      title: "Tablet Name",
    },
    {
      className: "checkin-table-header",
      dataIndex: "firstName",
      title: "First Name",
    },
    {
      className: "checkin-table-header",
      dataIndex: "lastName",
      title: "Last Name",
    },
    {
      className: "checkin-table-header",
      dataIndex: "email",
      title: "Email",
    },
    {
      className: "checkin-table-header",
      dataIndex: "activityName",
      title: "Activity Name",
    },
    {
      className: "checkin-table-header",
      dataIndex: "checkedOut",
      title: "Checked Out",
    },
    {
      className: "checkin-table-header",
      dataIndex: "delete",
      // eslint-disable-next-line react/no-multi-comp
      render: (_, noMatchSessionData) => {
        return (
          <Button
            onClick={() => {
              setSessionLogId(noMatchSessionData.checkoutSessionLogId);
              setShowDeleteModal(true);
            }}
            style={{ border: "none" }}
          >
            <img alt="" src={deleteIcon}></img>
          </Button>
        );
      },
      title: "Delete",
    },
  ];

  return (
    <>
      <Table
        loading={tableState.isLoading}
        dataSource={tableState.unMatchedCheckouts}
        columns={columns}
        rowKey={(_, index) => `${index}+${_.email}`}
      />

      {showDeleteModal && (
        <DeleteUnMatchedCheckoutModal
          onClose={() => {
            setShowDeleteModal(false);
            setSessionLogId("");
          }}
          isVisible={showDeleteModal}
          onDelete={(notes: string | undefined) => deleteSessionLog(notes)}
        />
      )}
    </>
  );
};
