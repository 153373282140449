import "../CreateEvent/CreateEvent.css";
import { AppBar, Button, Grid } from "@material-ui/core";
import React, { Component } from "react";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import { WithErrorHandler, WithErrorHandlerProps } from "../../../WithErrorHandler";
import {
  createEvent,
  getAllActivities,
  getAllPolicies,
  getCheckOutAutomations,
  getOrgTemplates,
  updateEvent,
} from "../../../../constant/api";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmationBox from "../../../UI-Components/Confirmation";
import { EventDependentActivity } from "./EventDependentActivity";
import EventInfo from "./Event_step_1";
import { EventLocation } from "./EventLocation";
import { EventOfflineMode } from "./EventOfflineMode";
import EventPolicies from "./Event_Step_2";
import EventPostCheckOut from "./Event_step_5";
import FormValidator from "../../../../constant/validation";
import LoadingScreen from "../../../UI-Components/LoadingScreen";
import { None } from "../../../../utils/None";
import { Some } from "../../../../utils/Some";
import history from "../../../../history";
import momentTZ from "moment-timezone";

interface LocationState {
  data: {
    allowCheckinMerits: boolean;
    checkInMTId: string | undefined;
    checkinFailedMessage: string;
    checkinSuccessMessage: string;
    copyQualification: boolean;
    description: string;
    dependentEventId: number;
    hasDependentEvent: boolean;
    eventId?: number;
    expirationEnabled: boolean;
    expirationPeriodType: string;
    expirationPeriodValue: number | null;
    isCheckOutReq: boolean;
    isDraft: boolean;
    locationEnabled: boolean;
    message: string;
    name: string;
    offlineModeEnabled: boolean;
    publishStatus: boolean;
    policyIds: string | null;
    timezone: string;
    title: string;
    eventCheckOutAutomationMapping: EventCheckOutAutomationMapping[] | null;
    originSource?: string;
    activeTab?: number;
  };
}

interface Props extends WithErrorHandlerProps, RouteComponentProps<{}, {}, LocationState> {
  readonly notify: (arg1: string, arg2: string) => void;
  readonly checkInMTId: string;
  readonly allowOfflineMode: boolean;
}
interface validations {
  isValid: boolean;
  name: {
    isInvalid: boolean;
    message: string;
  };
  failedMessage: {
    isInvalid: boolean;
    message: string;
  };
  successMessage: {
    isInvalid: boolean;
    message: string;
  };
}

interface params {
  name: string;
  isCheckOutReq: boolean;
  hasQualification: boolean;
  checkinFailedMessage: string;
  checkinSuccessMessage: string;
  dependentEventId: number;
  hasDependentEvent: boolean;
  hasWaiver: boolean;
  isDraft: boolean;
  copyQualification?: boolean;
  description: string;
  publishStatus: boolean;
  timezone: string;
  policyIds?: string;
  eventId?: string;
  allowCheckinMerits: boolean;
  meritTemplates: Template[];
  locationEnabled: boolean;
  offlineModeEnabled: boolean;
  expirationEnabled: boolean;
  expirationPeriodType: string;
  expirationPeriodValue: number | undefined;
  checkOutAutomationIds?: number[] | undefined;
}
interface eventForm {
  id?: string;
  name: string;
  policyCheck: boolean;
  policyList: Policy[];
  eventList: any[];
  copyQualification?: boolean;
  checkOut: boolean;
  dependentEventId: number;
  hasDependentEvent: boolean;
  allowSendMerits: boolean;
  successMessage: string;
  failedMessage: string;
  timezone: string;
  description: string;
  isDraft: boolean;
  meritTemplates: Template[];
  locationEnabled: boolean;
  offlineModeEnabled: boolean;
  expirationEnabled: boolean;
  expirationPeriodValue: number | undefined;
  expirationPeriodType: string;
  checkOutAutomations: CheckOutAutomation[];
}
interface State {
  eventForm: eventForm;
  eventList: EventList[];
  activeStep: number;
  policies: Policy[];
  isPolicyFound: boolean;
  placeholderForPolicy: string;
  error: string;
  message: string;
  isLoader: boolean;
  open: boolean;
  policyShowMessage: boolean;
  validation: {
    isValid: boolean;
    name: {
      isInvalid: boolean;
      message: string;
    };
    failedMessage: {
      isInvalid: boolean;
      message: string;
    };
    successMessage: {
      isInvalid: boolean;
      message: string;
    };
  };
  requiredMeritTemplates: Template[];
}

// eslint-disable-next-line react/require-optimization
class CreateEvent extends Component<Props, State> {
  title: string;
  message: string;
  checkInMTId: string | undefined;
  pageTitle: string;
  policyList: PolicyResponse[];
  orgMeritTemplates: Template[];
  readonly validator: {
    validate: (eventForm: eventForm) => validations;
    valid: () => validations;
  };
  submitted: boolean;
  checkOutAutomations: CheckOutAutomation[];
  constructor(props: Props) {
    super(props);
    let eventForm = {};
    this.title = "";
    this.message = "";
    this.policyList = [];
    this.orgMeritTemplates = [];
    this.checkOutAutomations = [];
    this.validator = new FormValidator([
      {
        field: "name",
        message: "Please enter the name.",
        method: "isEmpty",
        validWhen: false,
      },
      {
        field: "failedMessage",
        message: "Please enter the Failed msg.",
        method: "isEmpty",
        validWhen: false,
      },
      {
        field: "successMessage",
        message: "Please enter the success msg.",
        method: "isEmpty",
        validWhen: false,
      },
    ]);
    const { location } = this.props;
    if (location.state === undefined) {
      eventForm = {
        allowSendMerits: true,
        checkOut: false,
        checkOutAutomations: [],
        copyQualification: false,
        dependentEventId: null,
        description: "",
        expirationEnabled: false,
        expirationPeriodType: "days",
        expirationPeriodValue: undefined,
        failedMessage:
          "Sorry, you do not have the necessary policies to check-in to this activity. Please see a staff member for help.",
        hasDependentEvent: false,
        isDraft: true,
        locationEnabled: false,
        meritTemplates: [],
        name: "",
        offlineModeEnabled: false,
        policyCheck: false,
        policyList: [],
        successMessage: "You’re all set! Thank you.",
        timezone: momentTZ.tz.guess(),
      };
      this.title = "Create activity";
      this.message = "";
      const { checkInMTId } = this.props;
      this.checkInMTId = checkInMTId;
    } else {
      eventForm = {
        allowSendMerits: location.state.data.allowCheckinMerits,
        checkOut: location.state.data.isCheckOutReq,
        checkOutAutomations: [],
        copyQualification: location.state.data.copyQualification,
        dependentEventId: location.state.data.dependentEventId,
        description: location.state.data.description,
        expirationEnabled: location.state.data.expirationEnabled,
        expirationPeriodType: location.state.data.expirationPeriodType
          ? location.state.data.expirationPeriodType
          : "days",
        expirationPeriodValue: location.state.data.expirationPeriodValue,
        failedMessage: location.state.data.checkinFailedMessage,
        hasDependentEvent: location.state.data.hasDependentEvent,
        id: location.state.data.eventId,
        isDraft: location.state.data.isDraft,
        locationEnabled: location.state.data.locationEnabled,
        meritTemplates: [],
        name: location.state.data.name,
        offlineModeEnabled: location.state.data.offlineModeEnabled,
        policyCheck: Some(location.state.data.policyIds) && location.state.data.policyIds.length > 0,
        policyList: [],
        publishStatus: location.state.data.publishStatus,
        successMessage: location.state.data.checkinSuccessMessage,
        timezone: location.state.data.timezone,
      };
      this.title = location.state.data.title;
      this.message = location.state.data.message;
      this.checkInMTId = location.state.data.checkInMTId;
    }
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      activeStep: 0,
      error: "",
      eventForm: eventForm as eventForm,
      eventList: [],
      isLoader: location.state !== undefined && true,
      isPolicyFound: true,
      message: "",
      open: false,
      placeholderForPolicy: "Choose Policies",
      policies: [],
      policyShowMessage: false,
      requiredMeritTemplates: [],
      validation: this.validator.valid(),
    };
  }

  getOrgMeritTemplates = async () => {
    try {
      const response = await getOrgTemplates();
      this.orgMeritTemplates = response.item;
    } catch (error) {
      const { errorHandler } = this.props;
      errorHandler(error);
    }
  };

  updateRequiredMeritTemplates = (selectedPolicies: Policy[]) => {
    const requiredMeritTemplates: Template[] = [];
    if (this.policyList.length && selectedPolicies.length) {
      selectedPolicies.forEach(({ id }) => {
        const policy = this.policyList.find((policy) => policy.id === id);
        if (Some(policy)) {
          const {
            rules: { own },
          } = policy;
          own.forEach((item) => {
            if (item.arguments.length > 1) {
              item.arguments.forEach((templateId, index) => {
                if (index > 0) {
                  const template = this.orgMeritTemplates.find((template) => template.id === templateId);
                  if (Some(template)) {
                    const findIndex = requiredMeritTemplates.findIndex(({ id }) => id === template.id);
                    if (findIndex === -1) {
                      requiredMeritTemplates.push(template);
                    }
                  }
                }
              });
            }
          });
        }
      });
    }
    this.setState({ requiredMeritTemplates });

    return requiredMeritTemplates;
  };

  getPolicies = async () => {
    this.setState({ isLoader: true });
    await this.getOrgMeritTemplates();
    const { eventForm } = this.state;
    const { location } = this.props;
    let { policies } = this.state;

    let policyIds: string;
    if (location.state && Some(location.state.data.policyIds) && location.state.data.policyIds.length) {
      policyIds = location.state.data.policyIds;
    }

    if (policies.length === 0) {
      try {
        const response = await getAllPolicies();
        if (response.item.length === 0) {
          this.setState({
            isPolicyFound: false,
            placeholderForPolicy: "No Policies found",
          });
        } else {
          this.policyList = response.item;
          const res = response.item.map((item: PolicyResponse) => {
            if (location.state && Some(location.state.data.policyIds) && location.state.data.policyIds.length) {
              const isPolicyInSelectedList = policyIds.split(",").findIndex((value) => value === item.id);
              if (isPolicyInSelectedList > -1) {
                eventForm.policyList.push({ description: item.description, id: item.id, name: item.name });
              }
            }

            return { description: item.description, id: item.id, name: item.name };
          });
          policies = res;
        }
        const requiredMeritTemplates = this.updateRequiredMeritTemplates(eventForm.policyList);

        if (location.state && Some(location.state.data.checkInMTId) && location.state.data.checkInMTId.length) {
          const checkInMTIds = location.state.data.checkInMTId.split(",");
          const selectedMeritTemplates: Template[] = [];
          checkInMTIds.forEach((checkInMTId) => {
            const meritTemplate = this.orgMeritTemplates.find((template) => template.id === checkInMTId);
            if (Some(meritTemplate)) {
              const isMeritAvailableInPolicy = requiredMeritTemplates.find((template) => template.id === checkInMTId);
              if (Some(isMeritAvailableInPolicy)) {
                selectedMeritTemplates.push(meritTemplate);
              }
            }
          });
          eventForm.meritTemplates = selectedMeritTemplates;
        }
        this.setState({
          eventForm,
          isLoader: false,
          policies,
        });
      } catch (error: unknown) {
        const { errorHandler } = this.props;
        errorHandler(error);
      }
    }
  };

  getAllEvents = async () => {
    const { notify } = this.props;
    try {
      const responseEvents = await getAllActivities();
      const events = responseEvents.data.item.filter((event) => event.publishStatus && !event.isDraft);
      this.setState({
        eventList: events,
      });
    } catch (error) {
      notify("Unable to fetch events for dependent event option", "error");
    }
  };

  getAllCheckOutAutomations = async () => {
    const { notify, location } = this.props;
    const { eventForm } = this.state;
    try {
      const {
        data: { item },
      } = await getCheckOutAutomations();
      this.checkOutAutomations = item;
      if (
        location.state &&
        Some(location.state.data.eventCheckOutAutomationMapping) &&
        location.state.data.eventCheckOutAutomationMapping.length
      ) {
        const {
          data: { eventCheckOutAutomationMapping },
        } = location.state;
        eventForm["checkOutAutomations"] = eventCheckOutAutomationMapping
          .map((eventAutomation) => {
            return item.find((e) => e.checkOutAutomationId === eventAutomation.checkOutAutomationId);
          })
          .filter(Some);
        this.setState({
          eventForm,
        });
      }
    } catch (error) {
      notify("Unable to fetch checkout automations", "error");
    }
  };

  sortByTemplateTitle = (currentMeritTemplate: MeritTemplate, nextMeritTemplate: MeritTemplate) => {
    if (isNaN(parseInt(currentMeritTemplate.title[0], 10)) === isNaN(parseInt(nextMeritTemplate.title[0], 10))) {
      return currentMeritTemplate.title.localeCompare(nextMeritTemplate.title);
    } else if (isNaN(parseInt(currentMeritTemplate.title[0], 10))) {
      return -1;
    }

    return 1;
  };

  formValid = () => {
    let isFormValid = true;
    let error = "";
    let message = "";
    const { eventForm } = this.state;
    if (eventForm.policyCheck) {
      if (eventForm.policyList.length === 0) {
        isFormValid = false;
        error = "Please select a policy.";
      }
    }
    if (eventForm.hasDependentEvent && None(eventForm.dependentEventId)) {
      isFormValid = false;
      message = "Please select an activity";
    }
    this.setState({
      error: error,
      message: message,
    });

    return isFormValid;
  };

  handleError = (error: ErrorData) => {
    const { notify } = this.props;
    this.setState({ isLoader: false });
    const defaultMessage = "Something went wrong saving/updating the event";
    try {
      if (typeof error.response.data === "string") {
        notify(error.response.data, "error");
      } else {
        const message = error.response.data?.error?.message ?? defaultMessage;
        notify(message, "error");
      }
    } catch (err: unknown) {
      notify(defaultMessage, "error");
    }
  };

  getButtonName = (buttonName: string) => {
    if (buttonName === "draft") {
      return "Save_As_Draft";
    }

    return "Publish";
  };

  handlePublishEvent = async (t: string) => {
    this.setState({ isLoader: true });
    const v = true;
    const { eventForm } = this.state;
    const { notify, location } = this.props;
    if (v) {
      const params: params = {
        allowCheckinMerits: eventForm.allowSendMerits,
        checkinFailedMessage: eventForm.failedMessage
          ? eventForm.failedMessage
          : "Sorry, you do not have the necessary merits to check-in to this activity. Please see a staff member for help.",
        checkinSuccessMessage: eventForm.successMessage ? eventForm.successMessage : "You’re all set! Thank you.",
        copyQualification: eventForm.copyQualification,
        dependentEventId: eventForm.dependentEventId,
        description: eventForm.description,
        expirationEnabled: eventForm.expirationEnabled,
        expirationPeriodType: eventForm.expirationPeriodType,
        expirationPeriodValue: eventForm.expirationPeriodValue,
        hasDependentEvent: eventForm.dependentEventId ? eventForm.hasDependentEvent : false,
        hasQualification: false,
        hasWaiver: false,
        isCheckOutReq: eventForm.checkOut,
        isDraft: t !== "publish",
        locationEnabled: eventForm.locationEnabled,
        meritTemplates: eventForm.meritTemplates,
        name: eventForm.name,
        offlineModeEnabled: eventForm.offlineModeEnabled,
        publishStatus: t === "publish",
        timezone: eventForm.timezone,
      };
      if (eventForm.policyList.length) {
        params.policyIds = eventForm.policyList.map((value) => value.id).join();
      }

      if (eventForm.checkOut && eventForm.checkOutAutomations.length) {
        params.checkOutAutomationIds = eventForm.checkOutAutomations.map((e) => e.checkOutAutomationId);
      }

      if (eventForm.id === undefined) {
        try {
          const response = await createEvent(params);
          if (response.data.success) {
            history.push("/events");
            notify("Activity created successfully.", "success");
          }
        } catch (error) {
          this.handleError(error);
        }
      } else {
        params.eventId = eventForm.id;
        try {
          const response = await updateEvent(params);
          if (response.data.success) {
            if (this.hasOriginAndTab()) {
              history.push({
                pathname: location.state.data.originSource,
                state: { data: { activeTab: location.state.data.activeTab } },
              });
              notify("Activity updated successfully.", "success");
            } else {
              history.push("/events");
              notify("Activity updated successfully.", "success");
            }
          }
        } catch (error) {
          this.handleError(error);
        }
      }
    }
  };

  handleClickEvent = (v: boolean) => {
    if (v) {
      if (this.formValid()) {
        this.setState((state) => ({
          activeStep: state.activeStep + 1,
        }));
      }
    }
  };

  handlePreviousClick = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };
  // goBack = () => {
  //   history.push("/events");
  // };

  handleChange = (data: { name: any; val: any }) => {
    const { eventForm } = this.state;
    let error: string;
    if (data.name === "policyList") {
      if (data.val.length) {
        eventForm["policyCheck"] = true;
        const requiredMeritTemplates = this.updateRequiredMeritTemplates(data.val);
        if (eventForm["meritTemplates"].length) {
          const selectedMeritTemplates: Template[] = [];
          eventForm["meritTemplates"].forEach((meritTemplate) => {
            const isMeritAvailableInPolicy = requiredMeritTemplates.find(
              (template) => template.id === meritTemplate.id,
            );
            if (Some(isMeritAvailableInPolicy)) {
              selectedMeritTemplates.push(meritTemplate);
            }
          });
          eventForm["meritTemplates"] = selectedMeritTemplates;
        }
      } else {
        eventForm["policyCheck"] = false;
        eventForm["meritTemplates"] = [];
        error = "";
        this.updateRequiredMeritTemplates([]);
      }
    } else if (data.name === "meritTemplates") {
      const { val } = data;
      eventForm["meritTemplates"] = val;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    eventForm[data.name] = data.val;
    const { validation: stateValidation } = this.state;
    let validation: validations = stateValidation;
    if (data.name === "name" || data.name === "failedMessage" || data.name === "successMessage") {
      validation = this.validator.validate(eventForm);
    }
    this.setState({
      error: error!,
      eventForm,
      policyShowMessage: eventForm["policyCheck"],
      validation,
    });
  };

  handleSelectCheckOutAutomation = (selectedAutomations: CheckOutAutomation[]) => {
    if (selectedAutomations.length > 3) {
      const { notify } = this.props;
      notify("You can select a maximum of three checkout automations.", "error");

      return;
    }
    const { eventForm } = this.state;
    eventForm["checkOutAutomations"] = selectedAutomations;
    this.setState({
      eventForm,
    });
  };

  handleSelectMeritTemplate = (selectedMeritTemplate: Template[]) => {
    this.handleChange({ name: "meritTemplates", val: selectedMeritTemplate });
  };

  hasOriginAndTab = () => {
    const {
      location: { state },
    } = this.props;

    return Some(state) && Some(state.data) && Some(state.data.originSource) && Some(state.data.activeTab);
  };

  goToPreviousPage = () => {
    const { location } = this.props;
    if (this.hasOriginAndTab()) {
      history.push({
        pathname: location.state.data.originSource,
        state: { data: { activeTab: location.state.data.activeTab } },
      });
    } else {
      history.push("/events");
    }
  };

  goBack = () => {
    this.setState({
      open: true,
    });
  };
  closeConfirmation = () => {
    this.setState({
      open: false,
    });
  };

  getPolicy() {
    const { eventForm } = this.state;
    this.setState({ policyShowMessage: eventForm.policyCheck });
  }
  componentDidMount() {
    this.setState({
      isLoader: false,
    });
    this.getPolicies();
    this.getPolicy();
    this.getAllEvents();
    this.getAllCheckOutAutomations();
  }

  handleExpirationPeriod = ({ target: { value, name } }: React.ChangeEvent<HTMLInputElement>) => {
    const { eventForm } = this.state;
    if (value.match(/^[0-9]+$/u) && parseInt(value, 10) > 0) {
      const newState = { ...eventForm, [name]: value };
      this.setState({ eventForm: newState });
    } else {
      const newState = { ...eventForm, [name]: "" };
      this.setState({ eventForm: newState });
    }
  };

  handleExpirationPeriodType = (value: string) => {
    const { eventForm } = this.state;
    const newState = { ...eventForm, expirationPeriodType: value };
    this.setState({ eventForm: newState });
  };

  render() {
    const { allowOfflineMode } = this.props;
    const {
      isLoader,
      eventForm,
      eventList,
      policies,
      isPolicyFound,
      error,
      message,
      placeholderForPolicy,
      policyShowMessage,
      open,
      requiredMeritTemplates,
    } = this.state;
    const { locationEnabled, offlineModeEnabled } = eventForm;

    return (
      <div>
        <AppBar position="static" className="innerAppbar appbar">
          <Grid container spacing={16}>
            <Grid item sm={8} className="create_title" data-testid="activityHeaderTitle">
              {this.title}
            </Grid>
            <Grid item sm={4} className="create_close" onClick={this.goBack} data-testid="closeButton">
              <CloseIcon onClick={this.goBack} className="cursor_pointer" />
            </Grid>
          </Grid>
        </AppBar>
        {isLoader ? (
          <LoadingScreen minHeight="calc(100vh - 125px)" />
        ) : (
          <main className="main_background activity-padding">
            <Grid container spacing={0} justify="center" direction="column">
              <Grid item className="align_left">
                <div className="create_event_modal">
                  <EventInfo
                    id="event-info"
                    title={this.title}
                    message={this.message}
                    name={eventForm.name}
                    handleClickEvent={this.handleClickEvent}
                    handleChange={this.handleChange}
                    description={eventForm.description}
                  />
                  <EventPolicies
                    id="event-qualification"
                    policies={policies}
                    policyCheck={eventForm.policyCheck}
                    selectPolicyList={eventForm.policyList}
                    handleChange={this.handleChange}
                    handleClickEvent={this.handleClickEvent}
                    isPolicyFound={isPolicyFound}
                    error={error}
                    placeholderForPolicy={placeholderForPolicy}
                  />
                  <EventPostCheckOut
                    id="eventpost-checkout"
                    handlePreviousClick={this.handlePreviousClick}
                    handleChange={this.handleChange}
                    handleClickEvent={this.handleClickEvent}
                    checkOut={eventForm.checkOut}
                    successMessage={eventForm.successMessage}
                    failedMessage={eventForm.failedMessage}
                    timezone={eventForm.timezone}
                    policyCheck={policyShowMessage}
                    selectedMTIds={eventForm.meritTemplates.map((merit) => merit.id)}
                    handleSelectMeritTemplate={this.handleSelectMeritTemplate}
                    meritTemplateList={requiredMeritTemplates}
                    checkOutAutomationList={this.checkOutAutomations}
                    handleSelectCheckOutAutomation={this.handleSelectCheckOutAutomation}
                    selectedAutomationIds={
                      eventForm.checkOutAutomations.map((automation) => automation.checkOutAutomationId) ?? []
                    }
                    selectedAutomations={eventForm.checkOutAutomations}
                  />
                  <EventLocation locationEnabled={locationEnabled} handleChange={this.handleChange} />
                  {allowOfflineMode && (
                    <EventOfflineMode offlineModeEnabled={offlineModeEnabled} handleChange={this.handleChange} />
                  )}
                  {Some(eventList) && eventList.length > 0 && (
                    <EventDependentActivity
                      handleChange={this.handleChange}
                      handleClickEvent={this.handleClickEvent}
                      eventList={eventList}
                      message={message}
                      hasDependentEvent={eventForm.hasDependentEvent}
                      dependentEventId={eventForm.dependentEventId}
                      selectedEvent={eventList.find(({ eventId }) => eventId === Number(eventForm.id))}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </main>
        )}
        {!isLoader && (
          <div className="innerAppbar appbar">
            <div className="mui-container mui--text-center" style={{ paddingTop: "1%" }}>
              <Button
                data-testid="publishButton"
                variant="contained"
                color="primary"
                style={{ float: "right" }}
                onClick={() => {
                  this.handlePublishEvent("publish");
                }}
                // TODO: Allow to publish an activity when specific merits are slected due to policies chosen
                disabled={
                  !this.validator.validate(eventForm).isValid ||
                  this.submitted ||
                  (eventForm.hasDependentEvent && !eventForm.dependentEventId)
                }
              >
                Publish
              </Button>
              {eventForm.isDraft === true && (
                <Button
                  data-testid="saveAsDraftButton"
                  variant="contained"
                  color="secondary"
                  style={{ float: "right", marginRight: "10px" }}
                  onClick={() => {
                    this.handlePublishEvent("draft");
                  }}
                  // TODO: Allow to save draft an activity when specific merits are selected due to policies chosen
                  disabled={
                    !this.validator.validate(eventForm).isValid ||
                    this.submitted ||
                    (eventForm.hasDependentEvent && !eventForm.dependentEventId)
                  }
                >
                  Save as draft
                </Button>
              )}
            </div>
          </div>
        )}
        <ConfirmationBox
          open={open}
          closeConfirmation={this.closeConfirmation}
          goToPreviousPage={this.goToPreviousPage}
          message="Your changes are not saved. Still want to go back?"
          testId="confirmationModal"
        />
      </div>
    );
  }
}

export default withRouter(WithErrorHandler(CreateEvent));
