import "./EventCheckins.css";
import { Collapse, Dropdown, Menu, Table, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { approveMatchedCheckout, getMatchesForCheckinLog, rejectMatchedCheckout } from "../../../constant/api";
import { Some } from "../../../utils/Some";
import action from "../../../assets/Images/Icons/action.svg";

const { Panel } = Collapse;
const { Text } = Typography;

type State = {
  readonly isLoading: boolean;
  readonly matchedCheckouts: MatchForCheckinLog[];
};

type TableColumns = {
  readonly dataIndex: string;
  readonly title: string;
  readonly render?: (text: string, event: MatchForCheckinLog) => React.ReactNode;
  readonly className: string;
};

interface Props {
  readonly notify: (message: string, status: string) => void;
  readonly eventAttendeeId: string;
  readonly onClose: () => void;
}

export const MatchesTable = ({ notify, eventAttendeeId, onClose }: Props) => {
  const [tableState, setTableState] = useState<State>({
    isLoading: false,
    matchedCheckouts: [],
  });
  const [selectedCheckOut, setSelectedCheckOut] = useState<MatchForCheckinLog["checkOuts"][number]>();

  const getMatches = useCallback(async () => {
    try {
      setTableState((prev) => ({
        ...prev,
        isLoading: true,
      }));

      const {
        data: { item: matchesData },
      } = await getMatchesForCheckinLog(eventAttendeeId);

      setTableState({
        isLoading: false,
        matchedCheckouts: matchesData,
      });

      if (matchesData.length > 0) {
        setSelectedCheckOut(matchesData[0].checkOuts[0]);
      }
    } catch (error) {
      setTableState((prev) => ({
        ...prev,
        isLoading: false,
      }));
      notify("Error in fetching matches for a checkin log", "error");
    }
  }, []);

  useEffect(() => {
    getMatches();
  }, []);

  const approveMatch = async (matchSessionData: MatchForCheckinLog) => {
    try {
      if (Some(selectedCheckOut)) {
        const { eventAttendeeId } = matchSessionData;
        const { checkoutSessionId, checkoutSessionLogId } = selectedCheckOut;
        await approveMatchedCheckout(checkoutSessionId, { checkoutSessionLogId, eventAttendeeId });
        setTableState((prev) => ({
          ...prev,
          matchedCheckouts: prev.matchedCheckouts.filter((_) => _.eventAttendeeId !== eventAttendeeId),
        }));
      }
      onClose();
      notify("Match approved successfully.", "success");
    } catch (error) {
      notify("Error in approving the matched checkin checkouts", "error");
    }
  };

  const rejectMatch = async () => {
    try {
      if (Some(selectedCheckOut)) {
        const { checkoutSessionId, checkoutSessionLogId } = selectedCheckOut;
        await rejectMatchedCheckout(checkoutSessionId, { checkoutSessionLogId });
        await getMatches();
      }
    } catch (error) {
      notify("Error in rejecting the matched checkin checkouts", "error");
    }
  };

  const onSelectMatch = (checkOutData: MatchForCheckinLog["checkOuts"][number]) => {
    setSelectedCheckOut({ ...checkOutData });
  };

  const columns: Array<TableColumns> = [
    {
      className: "checkin-table-header",
      dataIndex: "firstName",
      title: "First Name",
    },
    {
      className: "checkin-table-header",
      dataIndex: "lastName",
      title: "Last Name",
    },
    {
      className: "checkin-table-header",
      dataIndex: "email",
      title: "Email",
    },
    {
      className: "checkin-table-header",
      dataIndex: "activityName",
      title: "Activity Name",
    },
    {
      className: "checkin-table-header",
      dataIndex: "checkedIn",
      title: "Checked In",
    },
    {
      className: "checkin-table-header",
      dataIndex: "checkOuts",
      // eslint-disable-next-line react/no-multi-comp
      render: (_, matchCheckinData) => {
        const { checkOuts } = matchCheckinData;

        if (checkOuts.length === 1) {
          return <Text>{checkOuts[0].checkedOut}</Text>;
        }

        return (
          <Collapse bordered={false}>
            <Panel
              header={
                <Text style={{ paddingLeft: 4 }}>{Some(selectedCheckOut) ? selectedCheckOut.checkedOut : ""}</Text>
              }
              key="1"
              style={{ borderBottom: "none" }}
            >
              {checkOuts.map(
                ({ checkedOut, checkoutSessionId, checkoutSessionLogId }) =>
                  Some(selectedCheckOut) &&
                  checkoutSessionLogId !== selectedCheckOut.checkoutSessionLogId && (
                    <div
                      className="checkout_column"
                      onClick={() => {
                        onSelectMatch({ checkedOut, checkoutSessionId, checkoutSessionLogId });
                      }}
                    >
                      <Text>{checkedOut}</Text>
                    </div>
                  ),
              )}
            </Panel>
          </Collapse>
        );
      },
      title: "Checked Out",
    },
    {
      className: "checkin-table-header",
      dataIndex: "menu-action",
      // eslint-disable-next-line react/no-multi-comp
      render: (_, matchCheckinData) => {
        return (
          <Dropdown
            overlayClassName="all_offline_action_menu"
            trigger={["click"]}
            overlay={
              <Menu>
                {["Approve", "Reject"].map((option) => {
                  return (
                    <Menu.Item
                      key={option}
                      onClick={() => {
                        if (option === "Approve") {
                          approveMatch(matchCheckinData);
                        } else if (option === "Reject") {
                          rejectMatch();
                        }
                      }}
                      className="menu_item"
                    >
                      <span style={{ fontSize: "16px" }}>{option}</span>
                    </Menu.Item>
                  );
                })}
              </Menu>
            }
            placement="bottomLeft"
          >
            <img alt="" src={action} className="checkinlog_cursor_pointer" />
          </Dropdown>
        );
      },
      title: "",
    },
  ];

  return (
    <Table
      loading={tableState.isLoading}
      dataSource={tableState.matchedCheckouts}
      columns={columns}
      rowKey={(_, index) => `${index}+${_.email}`}
      locale={{
        emptyText: "There are no potential matches found. Perhaps this Person is still checked in.",
      }}
    />
  );
};
