import React, { useCallback, useEffect, useState } from "react";
import CheckinBulkDetails from "./Modals/CheckinBulkDetailsModal";
import { None } from "../../../utils/None";
// eslint-disable-next-line no-unused-vars
import { PaginationConfig } from "antd/lib/table/interface";
import { Table } from "antd";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { fetchBulkCheckins } from "../../../constant/api";

type TableState = {
  readonly bulkCheckinUploads: CheckinBulk[];
  readonly isLoading: boolean;
  readonly pagination?: PaginationConfig;
};

type Props = {
  readonly notify: (message: string, status: string) => void;
};

type TableColumns = {
  readonly dataIndex: string;
  readonly title: string;
  readonly render?: (text: string, event: CheckinBulk) => React.ReactNode;
  readonly className: string;
};

export const CheckinBulkTable = ({ notify }: Props) => {
  const [tableState, setTableState] = useState<TableState>({
    bulkCheckinUploads: [],
    isLoading: false,
  });
  const [showUnsuccessfulRowsModal, setShowUnsuccessfulRowsModal] = useState<boolean>(false);
  const [bulkUploadId, setBulkUploadId] = useState<string>("");

  const getBulkCheckins = useCallback(async (pagination: PaginationConfig) => {
    try {
      setTableState((prev) => ({
        ...prev,
        isLoading: true,
      }));

      if (None(pagination.current)) {
        throw new Error(`Incomplete pagination object passed: ${JSON.stringify(pagination)}`);
      }

      const sessionsData = await fetchBulkCheckins(pagination.current);

      setTableState({
        bulkCheckinUploads: sessionsData.data.item,
        isLoading: false,
        pagination: {
          ...pagination,
          pageSize: sessionsData.data.metadata.pageSize,
          total: sessionsData.data.metadata.total,
        },
      });
    } catch (error) {
      setTableState((prev) => ({
        ...prev,
        isLoading: false,
      }));
      notify("Error in fetching the bulk checkin uploads", "error");
    }
  }, []);

  useEffect(() => {
    getBulkCheckins({ current: 1 });
  }, [getBulkCheckins]);

  const onView = async ({ bulkUploadId }: CheckinBulk) => {
    setBulkUploadId(String(bulkUploadId));
    setShowUnsuccessfulRowsModal(true);
  };

  const columns: Array<TableColumns> = [
    {
      className: "checkin-table-header",
      dataIndex: "fileName",
      title: "File Name",
    },
    {
      className: "checkin-table-header",
      dataIndex: "uploadDate",
      title: "Upload Date",
    },
    {
      className: "checkin-table-header",
      dataIndex: "activityName",
      title: "Activity Name",
    },
    {
      className: "checkin-table-header",
      dataIndex: "successfulCount",
      title: "Successful Count",
    },
    {
      className: "checkin-table-header",
      dataIndex: "unsuccessfulCount",
      title: "Unsuccessful Count",
    },
    {
      className: "checkin-table-header",
      dataIndex: "unsuccessfulRowsDetails",
      // eslint-disable-next-line react/no-multi-comp
      render: (_, bulkCheckinUpload) => {
        return (
          <VisibilityIcon
            color="action"
            fontSize="small"
            onClick={() => onView(bulkCheckinUpload)}
            data-testid={`${bulkCheckinUpload.fileName}-ViewIcon-CheckinBulkTable`}
          />
        );
      },
      title: "Details",
    },
  ];

  return (
    <>
      <Table
        loading={tableState.isLoading}
        dataSource={tableState.bulkCheckinUploads}
        columns={columns}
        rowKey="bulkCheckinId"
        onChange={(pagination) => {
          getBulkCheckins(pagination);
        }}
        pagination={{ ...tableState.pagination }}
      />
      {showUnsuccessfulRowsModal && (
        <CheckinBulkDetails
          bulkUploadId={bulkUploadId}
          show={showUnsuccessfulRowsModal}
          handleClose={() => {
            setShowUnsuccessfulRowsModal(false);
          }}
          notify={notify}
        />
      )}
    </>
  );
};
