import { Button, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../theme/Colors";
import { Fonts } from "../../theme/Fonts";
import { Footer } from "./Layout/Footer";
import { Header } from "./Layout/Header";
import LoadingScreen from "../UI-Components/LoadingScreen";
import { Some } from "../../utils/Some";
import checkinIcon from "../../../src/assets/Images/Icons/check-in-icon.svg";
import checkoutIcon from "../../../src/assets/Images/Icons/check-out-icon.svg";
import defaultOrg from "../../assets/Images/Icons/defaultOrg.svg";
import { getVirtualKioskActivities } from "./constant/api";
import { handleLocation } from "../../utils/GeoLocationHelper";
import history from "../../history";
import { useAuth0 } from "@auth0/auth0-react";

const styles = StyleSheet.create({
  alignText: {
    "@media (min-width: 480px)": {
      textAlign: "center",
    },
  },
  cardContainer: {
    background: Colors.white,
    marginTop: "10px",
    width: "327px",
  },
  checkinText: {
    color: Colors.blackPearl,
    fontSize: "24px",
    fontWeight: 500,
    letterSpacing: "0.25px",
    lineHeight: "36px",
  },
  checkoutText: {
    color: Colors.blackPearl,
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "28px",
  },
  contentHeader: {
    width: "327px",
  },
  contentWrapper: {
    fontFamily: Fonts.roboto,
  },
  image: {
    height: "32px",
    width: "40px",
  },
  imageContainer: {
    float: "right",
    width: "25%",
  },
  orgText: {
    color: Colors.sherpa,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "0.15px",
    lineHeight: "30px",
    textAlign: "left",
  },
  primaryButton: {
    alignItems: "center",
    background: Colors.defaultButtonBg,
    color: Colors.white,
    display: "flex",
    fontFamily: Fonts.roboto,
    fontSize: "32px",
    fontWeight: 500,
    height: "80px",
    justifyContent: "space-between",
    letterSpacing: "1.25px",
    lineHeight: "16px",
    marginBottom: "15px",
    textTransform: "uppercase",
    width: "327px",
  },
  subText: {
    color: Colors.blackPearl,
    fontSize: "24px",
    letterSpacing: "0.25px",
    lineHeight: "32px",
    textAlign: "left",
  },
  textContainer: {
    float: "left",
    width: "75%",
  },
  textUnderline: {
    color: Colors.white,
    fontSize: "32px",
    fontWeight: 500,
    letterSpacing: "0.25px",
    lineHeight: "36px",
    marginLeft: "10px",
    textDecorationLine: "underline",
  },
});

interface Props {
  readonly orgDetails: {
    readonly logoUrl: string;
    readonly name: string;
  };
}

interface CardDetails {
  readonly buttonText: string;
  readonly image: string;
  readonly innerButtonElement: React.ReactElement;
}

export const WebCheckinHome: React.FC<Props> = ({ orgDetails }) => {
  const { Content } = Layout;
  const { name, logoUrl } = orgDetails;
  const [loading, setLoading] = useState<boolean>(true);
  const [isLocationLoader, setIsLocationLoader] = useState<boolean>(false);
  const { push } = history;
  const checkInButtonText = "Check in";
  const checkOutButtonText = "Check out";
  const { isAuthenticated, logout } = useAuth0();

  localStorage.setItem("name", name);
  localStorage.setItem("logoUrl", logoUrl);

  useEffect(() => {
    localStorage.removeItem("locationData");
    localStorage.removeItem("deviceLocation");
    localStorage.removeItem("manualLocation");

    handleHomeScreenNavigation();
  }, []);

  const handleHomeScreenNavigation = async () => {
    const response = await getVirtualKioskActivities();
    const kioskEventsResponse = response.data.item;
    await localStorage.setItem("activitiesCount", kioskEventsResponse.length.toString());

    const checkedOutEvents = await kioskEventsResponse.filter((event: EventObject) => {
      return event.event.isCheckOutReq === true;
    });
    localStorage.setItem("checkoutCount", checkedOutEvents.length.toString());

    if (checkedOutEvents.length > 0) {
      localStorage.setItem("homePage", "home");
      setLoading(false);
    } else if (kioskEventsResponse.length === 1) {
      const eventData = kioskEventsResponse[0];
      localStorage.setItem("homePage", "checkInWith");
      if (eventData.event.locationEnabled) {
        setIsLocationLoader(true);
        handleLocation({ actionType: "checkin", eventData, hasBackButton: false });
      } else {
        push({ pathname: "/web-checkin/checkInWith", state: { eventData } });
      }
    } else if (kioskEventsResponse.length > 1) {
      localStorage.setItem("homePage", "selectactivity");
      push("/web-checkin/selectactivity");
    }
  };

  const cardDetails: Array<CardDetails> = [
    {
      buttonText: checkInButtonText,
      image: checkinIcon,
      innerButtonElement: <span className={css(styles.textUnderline)}>in</span>,
    },
    {
      buttonText: checkOutButtonText,
      image: checkoutIcon,
      innerButtonElement: <span className={css(styles.textUnderline)}>out</span>,
    },
  ];

  const handleNavigation = async (buttonText: string) => {
    const response = await getVirtualKioskActivities();
    const kioskEventsResponse = response.data.item;
    if (buttonText === checkInButtonText) {
      if (kioskEventsResponse.length > 1) {
        push("/web-checkin/selectactivity");
      } else {
        const eventData = kioskEventsResponse[0];
        if (eventData.event.locationEnabled) {
          setLoading(true);
          setIsLocationLoader(true);
          handleLocation({ actionType: "checkin", eventData, hasBackButton: true });
        } else {
          push({ pathname: "/web-checkin/checkInWith", state: { eventData } });
        }
      }
    } else if (buttonText === checkOutButtonText) {
      const eventsWithLocationEnabled = kioskEventsResponse.filter((eventList) => {
        const {
          event: { isCheckOutReq, locationEnabled },
        } = eventList;

        return locationEnabled === true && isCheckOutReq === true;
      });
      const eventData = Some(eventsWithLocationEnabled[0]) ? eventsWithLocationEnabled[0] : kioskEventsResponse[0];
      if (eventData.event.locationEnabled) {
        setLoading(true);
        setIsLocationLoader(true);
        handleLocation({ actionType: "checkout", eventData, hasBackButton: true });
      } else {
        push({ pathname: "/web-checkin/checkoutwith", state: { eventData } });
      }
    }
  };

  if (isAuthenticated) {
    logout({
      logoutParams: {
        returnTo: window.location.href,
      },
    });

    return null;
  }

  const handleCardView = () => {
    return cardDetails.map((cardDetail: CardDetails) => {
      const { buttonText, image, innerButtonElement } = cardDetail;

      return (
        <>
          <Button
            block
            className={css(styles.primaryButton)}
            name={buttonText}
            onClick={() => handleNavigation(buttonText)}
            data-testid={`${buttonText.split(" ").join("")}Button-WebCheckinHome`}
          >
            <span data-testid={`${buttonText.split(" ").join("")}ButtonText-WebCheckinHome`}>
              Check{innerButtonElement}
            </span>
            <img src={image} alt="" className={css(styles.image)} />
          </Button>
        </>
      );
    });
  };

  return (
    <React.Fragment>
      {loading ? (
        <LoadingScreen
          key={1}
          minHeight="calc(100vh - 125px)"
          deviceLocationMessage={isLocationLoader ? "Please enable location" : undefined}
        />
      ) : (
        <React.Fragment>
          <Header
            data-testid="orgLogo-WebCheckinHome"
            orgLogo={logoUrl ? logoUrl : defaultOrg}
            exitButton={false}
            backArrow={false}
          />
          <Content className={css(styles.contentWrapper)}>
            <Row type="flex" justify="space-around" align="middle" style={{ flexDirection: "column" }}>
              <div className={css(styles.contentHeader, styles.alignText)}>
                <p className={css(styles.orgText)} data-testid="greeting-WebCheckinHome">
                  Welcome to {name}
                </p>
                <p className={css(styles.subText)} data-testid="actionText-WebCheckinHome">
                  What would you like to do?
                </p>
              </div>
              {handleCardView()}
            </Row>
          </Content>
          <Footer />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
