import { Button, Card, Layout, Row } from "antd";
import React, { useMemo } from "react";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../theme/Colors";
import ErrorOutline from "../../assets/Images/Icons/error_outline.svg";
import { Fonts } from "../../theme/Fonts";
import history from "../../history";
import { useAuth0 } from "@auth0/auth0-react";

const styles = StyleSheet.create({
  accountText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "18px",
    marginTop: "5px",
    textAlign: "left",
  },
  buttonWrapper: {
    background: Colors.defaultButtonBg,
    color: Colors.white,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    fontWeight: 500,
    height: "48px",
    letterSpacing: "1.25px",
    lineHeight: "16px",
    textAlign: "center",
    textTransform: "uppercase",
    width: "327px",
  },
  cardInnerWrapper: {
    height: "auto",
    marginLeft: "2%",
    marginRight: "2%",
    width: "96%",
  },
  cardWrapper: {
    borderRadius: "4px",
    height: "auto",
    width: "327px",
  },
  checkinText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "24px",
    letterSpacing: "0.25px",
    marginTop: "10px",
    textAlign: "center",
  },
  contentWrapper: {
    alignItems: "center",
    height: "auto",
    justifyContent: "center",
    marginTop: "20px",
    minWidth: "375px",
  },
  flexWrapper: {
    paddingLeft: 50,
    paddingRight: 50,
    textAlign: "left",
  },
  footerWrapper: {
    alignItems: "center",
    backgroundColor: Colors.white,
    display: "flex",
    height: "100px",
    justifyContent: "center",
    minWidth: "375px",
  },
  imageWidth: {
    width: "44px",
  },
  imageWrapper: {
    alignItems: "center",
    marginTop: "20px",
    textAlign: "center",
  },
  rowWrapper: {
    marginTop: "7px",
    width: "100%",
  },
});

interface LocationState {
  attendeeDetails: {
    readonly firstName: string;
    readonly lastName?: string;
  };
  missingCheckIn: string;
}

interface Props extends RouteComponentProps<{}, {}, LocationState> {}

const CheckInFailureDependent = ({ location }: Props) => {
  const { Content } = Layout;
  const { logout, isAuthenticated } = useAuth0();

  const eventData = localStorage.getItem("eventData");
  const eventDetails = JSON.parse(eventData!);
  const { name: eventName } = eventDetails.event;
  const { push } = history;

  const attendeeDetails = useMemo(
    () => location.state?.attendeeDetails ?? JSON.parse(localStorage.getItem("attendeeDetails") ?? "{}"),
    [location.state?.attendeeDetails],
  );
  localStorage.setItem("attendeeDetails", JSON.stringify(attendeeDetails));

  const missingCheckIn: string = useMemo(
    () => location.state?.missingCheckIn ?? localStorage.getItem("missingCheckIn") ?? "",
    [location.state?.missingCheckIn],
  );
  localStorage.setItem("missingCheckIn", missingCheckIn);

  const { firstName } = attendeeDetails;

  if (isAuthenticated) {
    logout({
      logoutParams: {
        returnTo: window.location.href,
      },
    });

    return null;
  }

  return (
    <>
      <Content>
        <div className={css(styles.contentWrapper)}>
          <Row type="flex" justify="space-around" align="middle">
            <Row className={css(styles.flexWrapper)} justify="center">
              <p className={css(styles.imageWrapper)}>
                <img className={css(styles.imageWidth)} src={ErrorOutline} />
              </p>
              <h2 className={css(styles.checkinText)} data-testid="failureText-CheckInFailureDependent">
                {firstName}, cannot check in to {eventName} because you are not checked in to other required activities.
                Please check in to the required activities in order to be able to check in to {eventName}
              </h2>
            </Row>
            <Row className={css(styles.flexWrapper)} justify="center">
              <Card className={css(styles.cardWrapper)}>
                <div className={css(styles.cardInnerWrapper)}>
                  <Row className={css(styles.rowWrapper)}>
                    <h3
                      className={css(styles.accountText)}
                      data-testid="requiredActivitiesText-CheckInFailureDependent"
                    >
                      Required Activities:
                    </h3>
                  </Row>
                  <br />
                  <Row className={css(styles.rowWrapper)}>
                    <h3 data-testid="missingActivity-CheckInFailureDependent">{missingCheckIn}</h3>
                  </Row>
                </div>
              </Card>
            </Row>
          </Row>
        </div>
      </Content>
      <div className={css(styles.footerWrapper)}>
        <Button
          className={css(styles.buttonWrapper)}
          data-testid="doneButton-CheckInFailureDependent"
          onClick={() => {
            push("/web-checkin/home");
          }}
        >
          Done
        </Button>
      </div>
    </>
  );
};

export default withRouter(CheckInFailureDependent);
