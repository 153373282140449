/* eslint-disable no-unused-vars */
import { AppBar, Tab, Tabs, WithStyles, createStyles, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { WithErrorHandler, WithErrorHandlerProps } from "../../WithErrorHandler";
import { getAllActivities, getCheckOutAutomationWithEvent } from "../../../constant/api";
import CheckoutAutomation from "./CheckoutAutomation";
import { Colors } from "../../../theme/Colors";
import CommonHeader from "../../UI-Components/CommonHeader";
import { Some } from "../../../utils/Some";
import history from "../../../history";

interface locationType {
  data: {
    activeTab?: number;
  };
}

interface Props extends WithErrorHandlerProps, WithStyles<typeof styles>, RouteComponentProps<{}, {}, locationType> {
  readonly notify: (arg1: string, arg2: string) => void;
}

const Workflows = ({ classes, errorHandler, location, notify }: Props) => {
  const [activeTab, setActiveTab] = useState(0);
  const [checkoutAutomation, setCheckoutAutomation] = useState<CheckOutAutomationWithEvent[]>([]);
  const [eventList, setEventList] = useState<EventList[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchAutomationAndEvents = async () => {
    try {
      setLoading(true);
      const {
        data: { item },
      } = await getCheckOutAutomationWithEvent();
      setCheckoutAutomation(item);
      const eventsResponse = await getAllActivities();
      const events = eventsResponse.data.item.filter(
        (event) => event.publishStatus && !event.isDraft && event.isCheckOutReq,
      );
      setEventList(events);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (location && location.state && Some(location.state.data) && Some(location.state.data.activeTab)) {
      setActiveTab(location.state.data.activeTab);
    }
    fetchAutomationAndEvents();
  }, [location.state]);

  const handleChange = (_: any, activeTab: number) => {
    setActiveTab(activeTab);
  };

  const handleClick = () => {
    history.push("/create-automation-checkout");
  };

  return (
    <div className="tabs_container">
      <AppBar position="static" className="appbar app_bar">
        <CommonHeader
          buttonText={activeTab === 2 ? "Create automation" : ""}
          handleClick={handleClick}
          name="Workflows"
          text="Workflows can be created to add on to Activities. A Workflow on an Activity may add Statuses to check-ins, or automate check-outs for people."
          testId="workflows"
        />
      </AppBar>
      <Tabs
        centered
        value={activeTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabs}
      >
        <Tab label="Status Requirements" data-testid="statusRequirementsTab" />
        <div className={classes.verticalLine} />
        <Tab label="Automate Check-outs" data-testid="automateCheckoutsTab" />
      </Tabs>
      {activeTab === 2 && (
        <CheckoutAutomation
          checkoutAutomations={checkoutAutomation}
          events={eventList}
          isLoadingAutomationData={loading}
          notify={notify}
        />
      )}
    </div>
  );
};

const styles = () =>
  createStyles({
    tabs: { backgroundColor: Colors.white, textAlign: "center", width: "104%" },
    verticalLine: {
      borderRight: `1px solid grey`,
      height: 20,
      marginTop: 12,
    },
  });

export default withStyles(styles)(withRouter(WithErrorHandler(Workflows)));
