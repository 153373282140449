import { Col, Row, Tooltip } from "antd";
import React from "react";
import Switches from "../../../UI-Components/Switches";
import questionMark from "../../../../assets/Images/Icons/question-mark.svg";

interface Props {
  readonly offlineModeEnabled: boolean;
  readonly handleChange: (data: { name: string; val: boolean }) => void;
}

export function EventOfflineMode({ offlineModeEnabled, handleChange }: Props) {
  return (
    <Row className="grid grid_hr">
      <Col md={15} lg={14} xl={9} xxl={7}>
        <h6 className="step4_subtitle" style={{ marginTop: "4px" }}>
          Enable to use check-in and check-out without network
          <Tooltip placement="top" title="Enables to use check-in and check-out without network">
            <img alt="" src={questionMark} style={{ paddingLeft: "8px" }} />
          </Tooltip>
        </h6>
      </Col>
      <Col span={1}>
        <Row className="step2_switch2 margin_0_top">
          <Switches
            id="offlineModeEnabled"
            name="offlineModeEnabled"
            checked={offlineModeEnabled}
            handleChange={handleChange}
          />
        </Row>
      </Col>
    </Row>
  );
}
