import * as React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
// eslint-disable-next-line no-unused-vars
import type { FCWithChildren } from "../../constant/component";
import { None } from "../../utils/None";
import { allAuthScopes } from "../../constant/common";

const AuthProvider: FCWithChildren = ({ children }) => {
  const { AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_AUDIENCE_URL } = process.env;

  if (None(AUTH0_CLIENT_ID) || None(AUTH0_DOMAIN)) {
    return null;
  }

  return (
    <Auth0Provider
      authorizationParams={{ audience: AUTH0_AUDIENCE_URL, scope: allAuthScopes }}
      cacheLocation={window.location.pathname.includes("web-checkin") ? "memory" : "localstorage"}
      clientId={AUTH0_CLIENT_ID}
      domain={AUTH0_DOMAIN}
    >
      {children}
    </Auth0Provider>
  );
};

export { AuthProvider };
