/* eslint-disable no-unused-vars */
import { Button, Grid, Modal, WithStyles, createStyles, withStyles } from "@material-ui/core";
import { DataSource, SelectedRowKeys, TableColumn } from "../../../../UI-Components/EnhancedTable/type";
import { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from "../../../../UI-Components/EnhancedTable/constants";
import EnhancedTable from "../../../../UI-Components/EnhancedTable";
import React from "react";

type Props = WithStyles<typeof chooseMeritStyle> & {
  showModal: boolean;
  showManageActivitiesModal: () => void;
  readonly eventList: EventList[];
  selectedEventIds: number[];
  onSelectEvent: (value: number[]) => void;
  onSave: () => void;
};

const ManageActivities: React.FC<Props> = ({
  showModal,
  showManageActivitiesModal,
  classes,
  eventList,
  onSelectEvent,
  selectedEventIds,
  onSave,
}: Props) => {
  const [events, setEvents] = useState<DataSource[]>([]);
  const columns: TableColumn[] = [
    { key: "name", label: "Name" },
    { key: "description", label: "Description" },
  ];
  const getFormattedData = (eventList: EventList[]) => {
    const formattedEvenList = eventList.map((e) => ({
      description: e.description ?? "",
      key: e.eventId,
      name: e.name,
    }));

    return formattedEvenList;
  };

  useEffect(() => {
    const eventListOnPage = eventList.slice(0, DEFAULT_ROWS_PER_PAGE_OPTIONS[0]);
    setEvents(getFormattedData(eventListOnPage));
  }, [eventList]);

  const onChangePageOrRows = (page: number, rowsPerPage: number) => {
    const dataonPage = eventList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    setEvents(getFormattedData(dataonPage));
  };

  const tableFooter = () => (
    <Grid className={classes.tableFooterContainer} justify="flex-end">
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={onSave}
        data-testid="submitButton-ManageActivities"
      >
        Submit
      </Button>
    </Grid>
  );

  return (
    <Modal
      aria-labelledby="Add activities to automation modal"
      aria-describedby="modal used to select one or multiple activities"
      disableBackdropClick
      open={showModal}
      onClose={showManageActivitiesModal}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
    >
      <div className={classes.paper}>
        <Grid container>
          <div style={{ position: "absolute", right: 4, top: 7, zIndex: 2 }}>
            <CloseIcon
              onClick={showManageActivitiesModal}
              className="close_icon"
              data-testid="closeIcon-ManageActivities"
            />
          </div>
          <EnhancedTable
            footer={tableFooter()}
            paginationProps={{
              onChangePageOrRows: onChangePageOrRows,
              rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
              totalDatalength: eventList.length,
            }}
            tableContentHeight="calc(100vh - 280px)"
            columns={columns}
            title="Select activities"
            idColumn="key"
            dataSource={events}
            allowToSelectRow
            onSelect={(selectedRows: SelectedRowKeys[]) => onSelectEvent(selectedRows as number[])}
            selectedRowKeys={selectedEventIds}
            dataTestIdKey="name"
          />
        </Grid>
      </div>
    </Modal>
  );
};

const chooseMeritStyle = (theme: any) =>
  createStyles({
    backdrop: {
      background: "linear-gradient(67.73deg, #0E69F1 1.08%, #20A2F9 100%);",
      backgroundColor: "black",
      opacity: 0.9,
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "6px",
      boxShadow: theme.shadows[5],
      left: "50%",
      position: "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: theme.spacing.unit * 120,
    },
    tableFooterContainer: {
      borderTop: "1px solid gray",
      display: "flex",
      padding: "15px 24px",
      width: "100%",
    },
  });

export default withStyles(chooseMeritStyle)(ManageActivities);
