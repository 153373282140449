import { Button, Col, Input, Modal, Row } from "antd";
import React, { useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../../../theme/Colors";
import { Fonts } from "../../../../theme/Fonts";
import { editCheckinLogName } from "../../../../constant/api";

const styles = StyleSheet.create({
  buttonBody: {
    marginLeft: "165px",
    marginTop: "50px",
  },

  inputText: {
    background: Colors.inputBGColor,
    border: "1px solid Colors.borderColor",
    boxSizing: "border-box",
    color: Colors.darkBlack,
    fontFamily: Fonts.lato,
    fontSize: "16px",
    lineHeight: "19px",
  },
  modalBody: {
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    height: "200px",
    letterSpacing: "0.5px",
    lineHeight: "24px",
    paddingRight: "20px",
  },
  paragraphStyle: {
    color: Colors.gulfBlue,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.5px",
    lineHeight: "24px",
    width: "158%",
  },
  paragraphText: {
    color: Colors.gulfBlue,
    fontFamily: Fonts.lato,
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "17px",
    paddingBottom: "25px",
  },
  submitButton: {
    background: Colors.blue,
    borderRadius: "4px",
    color: Colors.white,
    fontFamily: Fonts.lato,
    fontSize: "16px",
    fontWeight: "bold",
    height: "44px",
    letterSpacing: "0.05em",
    lineHeight: "19px",
    marginLeft: "148px",
    position: "absolute",
    textAlign: "center",
    width: "136px",
  },
});
interface Props {
  readonly visible: boolean;
  readonly handleCloseEditModal: () => void;
  readonly firstName: string;
  readonly lastName: string;
  readonly eventAttendeeId: number;
  readonly notify: (message: string, status: string) => void;
}
type Details = Pick<Props, "firstName" | "lastName" | "eventAttendeeId">;

export const EditMemberNameModal: React.FC<Props> = ({
  visible,
  handleCloseEditModal,
  firstName,
  lastName,
  eventAttendeeId,
  notify,
}) => {
  const [details, setDetails] = useState<Details>({
    eventAttendeeId,
    firstName,
    lastName,
  });
  const [showFirstNameError, setShowFirstNameError] = useState<boolean>(false);
  const [showLastNameError, setShowLastNameError] = useState<boolean>(false);

  const onSave = async () => {
    try {
      await editCheckinLogName(details);
      handleCloseEditModal();
      notify("Updated", "success");
    } catch (error) {
      notify("Failed", "error");
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "firstName") {
      if (value.length > 25) {
        event.preventDefault();
        setShowFirstNameError(true);
      } else {
        setShowFirstNameError(false);
        setDetails(Object.assign({}, details, { [name]: value }));
      }
    }
    if (name === "lastName") {
      if (value.length > 40) {
        event.preventDefault();
        setShowLastNameError(true);
      } else {
        setShowLastNameError(false);
        setDetails(Object.assign({}, details, { [name]: value }));
      }
    }
  };

  return (
    <Modal
      title="Edit member name"
      visible={visible}
      onCancel={handleCloseEditModal}
      onOk={onSave}
      footer={false}
      wrapClassName="edit_member_name_modal"
      className="edit-member-modal-center"
    >
      <div className={css(styles.modalBody)}>
        <div className={css(styles.paragraphStyle)}>
          <Input.Group size="large">
            <Row className={css(styles.paragraphText)}>
              <Col span={8}>
                <span>First name</span>
              </Col>
              <Col span={8}>
                <span>Last name</span>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={8}>
                <Input
                  type="text"
                  className={css(styles.inputText)}
                  value={details.firstName}
                  placeholder="First name"
                  onChange={handleChange}
                  name="firstName"
                />
              </Col>
              <Col span={8}>
                <Input
                  type="text"
                  className={css(styles.inputText)}
                  value={details.lastName}
                  placeholder="Last name"
                  onChange={handleChange}
                  name="lastName"
                />
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={8}>
                {details.firstName.trim().length === 0 && <span style={{ color: "red" }}>Missing first name</span>}
                {showFirstNameError && <span style={{ color: "red" }}>Max 25 characters allowed</span>}
              </Col>
              <Col span={8}>
                {details.lastName.trim().length === 0 && <span style={{ color: "red" }}>Missing last name</span>}
                {showLastNameError && <span style={{ color: "red" }}>Max 40 characters allowed</span>}
              </Col>
            </Row>
          </Input.Group>
        </div>
        <div className={css(styles.buttonBody)}>
          <Button
            className={css(styles.submitButton)}
            type="primary"
            onClick={onSave}
            disabled={details.firstName.trim().length === 0 || details.lastName.trim().length === 0}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};
