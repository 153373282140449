import { Button, Icon, Layout } from "antd";
import React, { useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../../theme/Colors";
import { ExitModal } from "./ExitModal";
import { Fonts } from "../../../theme/Fonts";

const styles = StyleSheet.create({
  arrowLeft: {
    color: Colors.sherpa,
    fontSize: "16px",
    verticalAlign: "10px",
  },
  exitButton: {
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    height: "30px",
    marginTop: "12px",
    textTransform: "uppercase",
    width: "70px",
  },
  flexWrapper: {
    display: "flex",
    justifyContent: "space-between",
    minWidth: "327px",
  },
  headerWrapper: {
    alignItems: "center",
    background: "none",
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
  },
  image: {
    height: "30px",
    marginBottom: "29px",
    width: "30px",
  },
  imageContainer: {
    background: Colors.white,
    border: `1px solid ${Colors.imageContainerBorder}`,
    borderRadius: "20px",
    height: "40px",
    marginTop: "12px",
    textAlign: "center",
    width: "40px",
  },
  orgImageContainer: {
    background: Colors.white,
    border: `1px solid ${Colors.imageContainerBorder}`,
    borderRadius: "4px",
    height: "40px",
    textAlign: "center",
    width: "40px",
  },
  orgText: {
    color: Colors.sherpa,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "0.15px",
    lineHeight: "30px",
    marginLeft: "16px",
    marginTop: "5px",
    position: "absolute",
  },
});

interface Props {
  readonly orgLogo?: string;
  readonly orgName?: string;
  readonly backArrow: boolean;
  readonly exitButton: boolean;
  readonly handleExit?: () => void;
  readonly handleGoBack?: () => void;
}

export const Header: React.FC<Props> = ({ orgLogo, backArrow, exitButton, handleGoBack, orgName }) => {
  const { Header } = Layout;
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleClose = () => {
    setShowModal(false);
  };

  const showExitModal = () => {
    setShowModal(true);
  };

  return (
    <Header className={css(styles.headerWrapper)}>
      <div className={css(styles.flexWrapper)}>
        <div className={css(backArrow ? styles.imageContainer : styles.orgImageContainer)}>
          {backArrow ? (
            <Icon
              className={css(styles.arrowLeft)}
              data-testid="backIcon-Header"
              type="arrow-left"
              onClick={handleGoBack}
            />
          ) : (
            <div>
              <img src={orgLogo} className={css(styles.image)} data-testid="orgLogo-Header" />
              <span className={css(styles.orgText)} data-testid="orgName-Header">
                {orgName}
              </span>
            </div>
          )}
        </div>
        <div>
          {exitButton && (
            <div>
              <Button className={css(styles.exitButton)} data-testid="exitButton-Header" onClick={showExitModal}>
                Exit
              </Button>
            </div>
          )}
          {showModal && <ExitModal show={showModal} handleCloseModal={handleClose} />}
        </div>
      </div>
    </Header>
  );
};
