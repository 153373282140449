import { Col, Icon, Layout, Row } from "antd";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../theme/Colors";
import { Fonts } from "../../theme/Fonts";
import React from "react";

interface Props {
  readonly handleClose?: () => void;
  readonly headerText: string;
}

const Styles = StyleSheet.create({
  headerText: {
    color: Colors.gulfBlue,
    fontFamily: Fonts.lato,
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "17px",
  },
  headerWrapper: {
    backgroundColor: Colors.white,
    height: "76px !important",
  },
  iconStyle: {
    color: Colors.closeIcon,
    height: "14px",
    width: "14px",
  },
});

export const Header: React.SFC<Props> = ({ handleClose, headerText }) => {
  const { Header } = Layout;

  return (
    <Header className={css(Styles.headerWrapper)}>
      <Row type="flex" justify="center">
        <Col span={12}>
          <span className={css(Styles.headerText)} data-testid="kioskHeaderText-KioskHeader">
            {headerText}
          </span>
        </Col>
        <Col span={12} style={{ textAlign: "end" }}>
          <Icon type="close" className={css(Styles.iconStyle)} onClick={handleClose} />
        </Col>
      </Row>
    </Header>
  );
};
