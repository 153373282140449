import { CheckinBulkTable } from "./CheckinBulkTable";
import { Grid } from "@material-ui/core";
import React from "react";

interface Props {
  readonly notify: (message: string, status: string) => void;
}

export default function BulkCheckinsWrapper({ notify }: Props) {
  return (
    <div>
      <Grid container direction="column">
        <Grid item>
          <CheckinBulkTable notify={notify} />
        </Grid>
      </Grid>
    </div>
  );
}
