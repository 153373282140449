/* eslint-disable react/no-multi-comp */
import "./VirtualKioskTable.css";
import { Button, Col, Divider, Empty, Icon, Row, Table, Tooltip } from "antd";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../../theme/Colors";
import { Fonts } from "./../../../theme/Fonts";
import React from "react";
import history from "../../../history";
import moment from "moment";

const styles = StyleSheet.create({
  linkButton: {
    color: Colors.defaultButtonBg,
    fontFamily: Fonts.roboto,
    fontSize: "12px",
    letterSpacing: "0.4px",
    lineHeight: "15px",
  },
  tableRows: {
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    lineHeight: "24px",
  },
  tableWrapper: {
    height: "calc(100vh - 250px)",
    overflow: "auto",
    width: "100%",
  },
  viewURL: {
    fontFamily: Fonts.sfCompactDisplayRegular,
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "14px",
  },
  viewURLButton: {
    background: Colors.blue,
    borderRadius: "4px",
    color: Colors.white,
    fontFamily: Fonts.sfCompactDisplayLight,
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "14px",
  },
});

interface TableColumns {
  readonly align?: "right";
  readonly dataIndex: string;
  readonly key: string;
  readonly title: string;
  readonly render?: (text: string | number, event: KioskEvent) => React.ReactNode;
  readonly width?: string;
}

interface Props {
  activities: Array<VirtualKiosk>;
  handleKioskNameModal: (event: VirtualKiosk) => void;
  handleShowShareURLModal: (UUID: string) => void;
  handleShowActivateModal: (kioskId: number, active: boolean) => void;
  activeTab: number;
  readonly isVirtualKioskTabActive: boolean;
}

export const VirtualKioskTable: React.FC<Props> = ({
  activities,
  handleKioskNameModal,
  handleShowShareURLModal,
  handleShowActivateModal,
  activeTab,
  isVirtualKioskTabActive,
}) => {
  const { push } = history;

  const kioskNameModal = (event: VirtualKiosk) => {
    handleKioskNameModal(event);
  };

  const showShareURLModal = (UUID: string) => {
    handleShowShareURLModal(UUID);
  };

  const showActivateModal = (kioskId: number, active: boolean) => {
    handleShowActivateModal(kioskId, active);
  };
  const columns: Array<TableColumns> = [
    {
      dataIndex: "name",
      key: "name",
      render: (name: string, event: VirtualKiosk) => (
        <span className={css(styles.tableRows)}>
          <span data-testid={`${name}-KioskName-VirtualKioskTable`}>{name}</span>
          <span style={{ paddingLeft: "4px" }}>
            <Button
              type="link"
              className={css(styles.linkButton)}
              data-testid={`${name}-KioskEditButton-VirtualKioskTable`}
              onClick={() => kioskNameModal(event)}
            >
              Edit
            </Button>
          </span>
        </span>
      ),
      title: "Kiosk name",
      width: "30%",
    },
    {
      dataIndex: "activities",
      key: "activities",
      render: (text: string, event: VirtualKiosk) => (
        <span className={css(styles.tableRows)}>
          {getActivitiesCount(event)} activities
          <span style={{ paddingLeft: "4px" }}>
            <a>
              <Button
                type="link"
                className={css(styles.linkButton)}
                data-testid={`${event.name}-ActivityViewButton-VirtualKioskTable`}
                onClick={() => handleViewActivities(event)}
              >
                View
              </Button>
            </a>
            <a>
              <Button
                type="link"
                className={css(styles.linkButton)}
                data-testid={`${event.name}-ActivityEditButton-VirtualKioskTable`}
                onClick={() => handleEditActivities(event)}
              >
                Edit
              </Button>
            </a>
          </span>
        </span>
      ),
      title: "Activities",
      width: "30%",
    },
    {
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) => (
        <span className={css(styles.tableRows)}>{moment(createdAt).format("MMM DD, YYYY")}</span>
      ),
      title: "Created on",
      width: "20%",
    },
    {
      align: "right",
      dataIndex: "",
      key: "x",
      render: (text: string, event: VirtualKiosk) => (
        <span className={css(styles.viewURL)}>
          <span>
            <a>
              {event.active && (
                <Button
                  data-testid={`${event.name}Button-VirtualKioskTable`}
                  type="primary"
                  className={css(styles.viewURLButton)}
                  onClick={() => showShareURLModal(event.UUID ? event.UUID : "")}
                >
                  View URLs
                </Button>
              )}
            </a>
            <Divider type="vertical" />
            <a>
              <Tooltip placement="bottom" title={event.active ? "Deactivate virtual kiosk" : "Activate virtual kiosk"}>
                {event.active ? (
                  <Icon
                    data-testid={`${event.name}-DeactivateIcon-VirtualKioskTable`}
                    type="down-square"
                    onClick={() => showActivateModal(event.kioskId!, event.active!)}
                    style={{ color: Colors.virtualkioskIcon, fontSize: "20px" }}
                  />
                ) : (
                  <Icon
                    data-testid={`${event.name}-ActivateIcon-VirtualKioskTable`}
                    type="up-square"
                    onClick={() => showActivateModal(event.kioskId!, event.active!)}
                    style={{ color: Colors.virtualkioskIcon, fontSize: "20px" }}
                  />
                )}
              </Tooltip>
            </a>
          </span>
        </span>
      ),
      title: "",
      width: "20%",
    },
    {
      dataIndex: "",
      key: "",
      title: "",
      width: "100%",
    },
  ];

  const noDataMessage = activeTab === 1 ? "You have no active kiosks" : "You have no inactive kiosks";

  const handleViewActivities = (event: VirtualKiosk) => {
    const { kioskId } = event;
    push({
      pathname: "/viewactivities",
      state: { isVirtualKioskTabActive, kioskId },
    });
  };

  const handleEditActivities = (event: VirtualKiosk) => {
    const { kiosk_events, kioskId } = event;
    const selectedKioskEventIds: Array<number> = [];
    kiosk_events!.map((event: KioskEvent) => {
      if (event.active && event.event.publishStatus) {
        selectedKioskEventIds.push(event.eventId);
      }

      return event;
    });
    push({
      pathname: "/chooseactivities",
      state: { isVirtualKioskTabActive, kioskId, selectedKioskEventIds },
    });
  };

  const getActivitiesCount = (event: VirtualKiosk) => {
    const count = event.kiosk_events!.filter((kiosk_event) => {
      return kiosk_event.event.publishStatus && kiosk_event.active;
    }).length;

    return count;
  };

  return (
    <React.Fragment>
      <Row type="flex" justify="space-around" align="middle">
        <Col span={24}>
          <div id="table" className={css(styles.tableWrapper)}>
            <div className="table_container">
              <Table
                className="virtual_kiosk_table"
                columns={columns}
                pagination={false}
                dataSource={activities}
                locale={{
                  emptyText: (
                    <Empty data-testid="noVirtualKioskMessage-VirtualKioskTable" description={noDataMessage} />
                  ),
                }}
                rowKey="name"
              />
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
