import "./Waiver.css";
import { AppBar, Grid } from "@material-ui/core";
import React, { PureComponent } from "react";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import DOMPurify from "dompurify";
import ReactHtmlParser from "react-html-parser";
import { getWaiverContent } from "../../../constant/api";
import history from "../../../history";

interface Props extends RouteComponentProps<{}, {}, { data: { waiverId: string } }> {}
interface State {
  data?: string;
}

class PreviewWaiver extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: localStorage.getItem("html")!,
    };
    this.getWaiverContent();
  }

  getWaiverContent = async () => {
    const { location } = this.props;
    if (location.state !== undefined) {
      if (location.state.data.waiverId !== undefined) {
        const response: any = await getWaiverContent(location.state.data.waiverId);
        this.setState({
          data: encodeURIComponent(response.data.item.content),
        });
      }
    }
  };
  goBack = () => {
    if ((window as any).previousLocation === undefined) {
      history.push("/create-waiver");
    } else {
      history.push((window as any).previousLocation.pathname);
    }
  };

  render() {
    const { data } = this.state;
    const decodedHTML = decodeURIComponent(data!);
    const cleanedHTML = decodedHTML.replace("\\", "");

    return (
      <div>
        <AppBar position="static" className="appbar">
          <Grid container spacing={16}>
            <Grid item sm={4} />
            <Grid data-testid="previewLabel" item sm={4} className="preview_label">
              Preview
            </Grid>
            <Grid item sm={4} className="create_close close">
              <CloseIcon data-testid="previewCloseIcon" onClick={this.goBack} className="cursor_pointer" />
            </Grid>
          </Grid>
        </AppBar>
        <main className="preview_main" data-testid="previewMainBody">
          <Grid container spacing={0} direction="column">
            <Grid item style={{ minWidth: "60%" }}>
              <Grid container direction="row" spacing={8} style={{ backgroundColor: "white" }}>
                <Grid item xs={12}>
                  <div style={{ padding: "30px" }}>{ReactHtmlParser(DOMPurify.sanitize(cleanedHTML))}</div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </main>
      </div>
    );
  }
}

export default withRouter(PreviewWaiver);
