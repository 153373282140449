import { Col, Layout, Row } from "antd";
import { InputAdornment, TextField } from "@material-ui/core";
import React, { useState } from "react";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../../theme/Colors";
import { Footer } from "../../UI-Components/Footer";
import { Header } from "../../UI-Components/KioskHeader";
import PhonelinkSharpIcon from "@material-ui/icons/PhonelinkSharp";
import { checkVirtualKioskName } from "../../../constant/api";
import history from "../../../history";

const styles = StyleSheet.create({
  cardContainer: {
    marginLeft: "25%",
    marginTop: "40px",
    width: "50%",
  },
  contentStyle: {
    width: "100%",
  },
  footerStyle: {
    background: Colors.white,
    height: "90px",
    width: "100%",
  },
  headerStyle: {
    background: Colors.white,
    boxShadow: Colors.boxShadow,
    height: "60px",
    width: "100%",
  },
  inputContainer: {
    height: "60px",
    marginLeft: "10px",
    marginRight: "10px",
    marginTop: "5px",
    width: "97%",
  },
  textfieldContainer: {
    background: Colors.textfieldColor,
    height: "60px",
    marginTop: "25px",
    width: "100%",
  },
});

interface Props extends RouteComponentProps<{}, {}, { virtualKioskName: string }> {
  readonly notify: (message: string, status: string) => void;
}

export const VirtualKioskName: React.FC<Props> = ({ notify, location }) => {
  const { Content } = Layout;
  const { push } = history;

  const [virtualKioskName, setVirtualKioskName] = useState<string>(
    location.state ? location.state.virtualKioskName : "",
  );
  const [showError, setShowError] = useState<boolean>(false);

  const handleNext = async () => {
    if (virtualKioskName.trim().length > 0) {
      try {
        const params = { name: virtualKioskName };
        const response = await checkVirtualKioskName(params);
        if (response.data.success) {
          push({
            pathname: "/chooseactivities",
            state: { virtualKioskName },
          });
        }
      } catch (error) {
        notify("Name already exists", "error");
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length > 30) {
      e.preventDefault();
      setShowError(true);
    } else {
      setShowError(false);
      setVirtualKioskName(e.target.value);
    }
  };

  return (
    <div className="activities-wrapper">
      <div className="width_100">
        <Layout>
          <Header
            handleClose={() =>
              push({
                pathname: "/kiosk",
                state: { isVirtualKioskTabActive: true, isWebTabActive: true },
              })
            }
            headerText="Create a Virtual Kiosk"
          />
          <div className="kioskbody">
            <Content className={css(styles.contentStyle)}>
              <Layout className={css(styles.cardContainer)}>
                <Row>
                  <Col span={12}>
                    <h1 className="kiosknameTitle">Virtual kiosk name</h1>
                  </Col>
                  <Col span={12}>
                    <h1 className="setupTitle">Setup 1 of 2</h1>
                  </Col>
                </Row>
                <p className="kiosknamedesc">
                  You can customize the name for the virtual kiosk. The name will be reflected in all check-in
                  timestamps
                </p>
                <Layout className={css(styles.textfieldContainer)}>
                  <TextField
                    className={css(styles.inputContainer)}
                    id="input-with-icon-textfield"
                    label="Virtual kiosk name"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhonelinkSharpIcon color="action" />
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      "data-testid": "nameInputField-VirtualKioskName",
                    }}
                    value={virtualKioskName}
                    onChange={handleChange}
                  />
                  {showError && <p style={{ color: "red" }}>Maximum 30 characters are allowed</p>}
                </Layout>
              </Layout>
            </Content>
          </div>
          <Footer buttonTitle="Next" handleButton={handleNext} testId="next" virtualKioskName={virtualKioskName} />
        </Layout>
      </div>
    </div>
  );
};

export default withRouter(VirtualKioskName);
