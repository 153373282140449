import { ERROR_MSG_SHOW_DURATION, SUCCESS_MSG_SHOW_DURATION } from "../../config/constants";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { createElement } from "react";
import { toast } from "react-toastify";

const toatMessageStyleProps = { style: { marginLeft: 10 } };
interface ToastProps {
  autoClose?: number;
  message: string;
  onClose?: () => void;
  position?: (typeof toast.POSITION)[keyof typeof toast.POSITION];
}

export const showErrorToast = ({
  autoClose = ERROR_MSG_SHOW_DURATION,
  message,
  onClose,
  position = toast.POSITION.TOP_RIGHT,
}: ToastProps) => {
  toast.error(
    createElement(
      "span",
      { "data-testid": "errorMessage-ToastHelper", style: { alignItems: "center", display: "flex" } },
      createElement(ReportProblemIcon, toatMessageStyleProps),
      createElement("span", toatMessageStyleProps, message),
    ),
    {
      autoClose,
      closeOnClick: true,
      draggable: false,
      hideProgressBar: true,
      onClose,
      pauseOnHover: false,
      position,
    },
  );
};

export const showSuccessToast = ({
  autoClose = SUCCESS_MSG_SHOW_DURATION,
  message,
  position = toast.POSITION.TOP_RIGHT,
}: ToastProps) => {
  toast.success(
    createElement(
      "span",
      { style: { alignItems: "center", display: "flex" } },
      createElement(CheckCircleOutlineIcon, toatMessageStyleProps),
      createElement("span", toatMessageStyleProps, message),
    ),
    {
      autoClose,
      closeOnClick: true,
      draggable: false,
      hideProgressBar: true,
      pauseOnHover: false,
      position,
    },
  );
};
