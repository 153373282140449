import React, { PureComponent } from "react";
import { createStyles, withStyles } from "@material-ui/core";
import CheckIn from "../Web/EventCheckins/Modals/CheckInModal";
import Sidebar from "./SideBar";

interface State {
  readonly showCheckIns: boolean;
  readonly activeNav: string;
}
interface Props {
  readonly classes: {
    readonly checkinButton: string;
  };
  readonly activeNav: string;
  readonly orgDetails: {
    readonly logoUrl: string;
  };
  readonly getAllCheckins: (number: number) => void;
  readonly notify: (arg1: string, arg2: string) => void;
  key: any;
  width: any;
}

class Header extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { activeNav } = this.props;
    this.state = {
      activeNav: activeNav,
      showCheckIns: false,
    };
  }

  checkIn = () => {
    this.setState({
      showCheckIns: true,
    });
  };
  handleCloseCheckIn = () => {
    this.setState({ showCheckIns: false });
  };
  getAllCheckins = () => {
    const { getAllCheckins } = this.props;
    getAllCheckins(1);
  };
  notify = (message: string, status: string) => {
    const { notify } = this.props;
    notify(message, status);
  };

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState({
      activeNav: nextProps.activeNav,
    });
  }

  render() {
    const { activeNav, showCheckIns } = this.state;
    const { orgDetails } = this.props;

    return (
      <div>
        <div>
          <Sidebar activeNav={activeNav} orgDetails={orgDetails} />
        </div>
        {showCheckIns && (
          <CheckIn
            notify={this.notify}
            getAllCheckins={this.getAllCheckins}
            handleClose={this.handleCloseCheckIn}
            show={showCheckIns}
          />
        )}
      </div>
    );
  }
}
const styles = () =>
  createStyles({
    checkinButton: {
      position: "absolute !important" as "absolute",
      right: "24px",
      top: "31px",
      zIndex: 9,
    },
  });

export default withStyles(styles)(Header);
