/* eslint-disable no-unused-vars */
import {
  Button,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { bulkCheckIns, getAllActivities } from "../../../../constant/api";
import CloseIcon from "@material-ui/icons/Close";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import GetAppIcon from "@material-ui/icons/GetApp";
import LoadingScreen from "../../../UI-Components/LoadingScreen";
import { None } from "../../../../utils/None";
import Papa from "papaparse";
import Select from "react-select";
import { Some } from "../../../../utils/Some";
import moment from "moment";
import { useDropzone } from "react-dropzone";

interface Props extends WithStyles<typeof styles> {
  readonly currentDate: string;
  readonly notify: (arg1: string, arg2: string) => void;
  readonly show: boolean;
  readonly handleClose: () => void;
  readonly getAllCheckins: (number: number) => void;
}

enum StatusType {
  ALL = "all",
  SUCCESS = "success",
  FAILED = "failed",
}

interface OptionEvent extends EventList {
  label: string;
}

const customStyles = {
  menuList: (provided: any) => ({
    ...provided,
    fontFamily: "Lato",
  }),
};

interface option {
  data: {
    name: string;
  };
}

type Error = {
  readonly field: string;
  readonly message: string;
};

type FileData = {
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly checkInTime: string;
  readonly checkOutTime?: string;
};

const CheckInBulkModal = (props: Props) => {
  const [events, setEvents] = useState<OptionEvent[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<OptionEvent>();
  const [error, setError] = useState<Error>();
  const [isValidFormat, setIsValidFormat] = useState(false);
  const [attendees, setAttendees] = useState<FileData[]>([]);
  const [checkInData, setCheckInData] = useState<BulkCheckinResponse[]>([]);
  const [csvFileName, setCsvFileName] = useState<string>();
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (!file) {
        setError({
          field: "uploadFile",
          message: "Please select a file.",
        });

        return;
      }

      if (file.type !== "text/csv") {
        setError({
          field: "uploadFile",
          message: "Invalid file type. Please select a CSV file.",
        });

        return;
      }

      setCsvFileName(file.name);

      setError(undefined);

      const reader = new FileReader();

      reader.onload = (event: any) => {
        const csvData = event.target.result;

        parseCsvData(csvData)
          .then((data: FileData[]) => {
            const isValid = data.every(({ checkInTime, email, firstName, lastName }, index) => {
              if (None(firstName) || firstName.trim() === "") {
                setError({
                  field: "uploadFile",
                  message: `First Name is missing in row no: ${index + 1}`,
                });

                return false;
              } else if (firstName.length > 25) {
                setError({
                  field: "uploadFile",
                  message: `First Name exceeds 25 characters in row no: ${index + 1}`,
                });

                return false;
              } else if (None(lastName) || lastName.trim() === "") {
                setError({
                  field: "uploadFile",
                  message: `Last Name is missing in row no: ${index + 1}`,
                });

                return false;
              } else if (lastName.length > 40) {
                setError({
                  field: "uploadFile",
                  message: `Last Name exceeds 40 characters in row no: ${index + 1}`,
                });

                return false;
              } else if (None(email) || email.trim() === "") {
                setError({
                  field: "uploadFile",
                  message: `Email is missing in row no: ${index + 1}`,
                });

                return false;
              } else if (None(checkInTime) || checkInTime.trim() === "") {
                setError({
                  field: "uploadFile",
                  message: `Check-in Time is missing in row no: ${index + 1}`,
                });

                return false;
              }

              return true;
            });

            if (isValid) {
              setError(undefined);
              setAttendees(data);
            }

            setIsValidFormat(isValid);
          })
          .catch(() => {
            setIsValidFormat(false);
          });
      };

      reader.readAsText(file);
    },
    [selectedEvent],
  );

  const parseCsvData = (csvData: any) => {
    return new Promise((resolve, reject) => {
      Papa.parse(csvData, {
        complete: (result) => {
          const parsedData = result.data.map((attendee: any) => ({
            checkInTime: attendee["Check-in Time"],
            checkOutTime: attendee["Check-out Time"],
            email: attendee["Email"],
            firstName: attendee["First Name"],
            lastName: attendee["Last Name"],
          }));
          resolve(parsedData);
        },
        dynamicTyping: true,
        error: (error) => {
          reject(error);
        },
        header: true,
        skipEmptyLines: true,
      });
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "text/csv": [".csv"] },
    onDrop,
  });

  const selectEvent = (value: any) => {
    if (None(value)) {
      setSelectedEvent(undefined);
      setError({
        field: "selectEvent",
        message: "Please select an event",
      });
    } else {
      setSelectedEvent(value);
      if (Some(error) && error.field === "selectEvent") {
        setError(undefined);
      }
    }
  };

  const getEvents = async () => {
    const publishedEvents = await getAllActivities().then((_) =>
      _.data.item.filter((_) => _.publishStatus && !_.isDraft),
    );

    if (publishedEvents.length === 0) {
      setEvents([]);
    } else {
      const res: OptionEvent[] = publishedEvents.map((item) => {
        const itemVal = item;
        itemVal.label = item.name;
        itemVal.value = item.eventId;

        return itemVal as OptionEvent;
      });
      setEvents(res);
    }
  };

  const customFilter = (option: option, searchText: string) => {
    if (option.data.name.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    }

    return false;
  };

  const checkInBulk = async () => {
    setLoading(true);
    const { notify, getAllCheckins } = props;

    if (None(selectedEvent)) {
      notify("Please select an event to check into any activity.", "error");

      return;
    }

    if (None(csvFileName)) {
      notify("Please upload a csv file with checkin details.", "error");

      return;
    }

    const eventAttendees = attendees.map((attendee) => {
      const { checkInTime, checkOutTime, email, firstName, lastName } = attendee;

      return {
        checkInTime,
        email,
        firstName,
        lastName,
        ...(Some(checkOutTime) && { checkOutTime }),
      };
    });

    const params = {
      eventAttendees,
      eventId: selectedEvent.eventId,
      fileName: csvFileName,
    };

    try {
      const response = await bulkCheckIns(params);
      setCheckInData(response.data.item);
      const successCount = response.data.item.filter(({ status }) => status === StatusType.SUCCESS).length;
      const failureCount = response.data.item.length - successCount;

      if (successCount === 0) {
        notify("Failed to check in attendees", "error");
      } else if (failureCount === 0) {
        notify("All entries checked in successfully", "success");
      } else {
        notify(
          `Some entries failed to check in. ${successCount} entries checked in successfully, ${failureCount} entries failed`,
          "error",
        );
      }
      getAllCheckins(1);
    } catch {
      setCheckInData([]);
      notify("Could not check in.", "error");
    }
    setLoading(false);
  };

  const { classes, show, handleClose } = props;
  const formatOptionLabel = (data: { label: string }) => (
    <div data-testid={`${data.label}-OptionLabel`}>{data.label}</div>
  );

  function downloadSampleFile() {
    const csvContent = `First Name,Last Name,Email,Check-in Time,Check-out Time
John,Doe,john.doe@example.com,2024-03-19 07:53:02,2024-03-20 07:53:02
Jane,Smith,jane.smith@example.com,2024-03-20 07:53:02`;

    const csvData = encodeURIComponent(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", `data:text/csv;charset=utf-8,${csvData}`);
    link.setAttribute("download", "checkin-bulk-sample.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={show}
      onClose={handleClose}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
    >
      <Grid
        container
        spacing={0}
        alignItems="center"
        className={classes.activityModelCenter}
        justify="center"
        direction="column"
        style={{ minHeight: "100vh" }}
      >
        <Grid item>
          <Grid container className={classes.paper} direction="column">
            <Grid item>
              <Grid container direction="row" style={{ justifyContent: "space-between" }}>
                <Grid item>
                  <h4 data-testid="header-CheckInBulkModal">Activity Check-ins</h4>
                  <h6 data-testid="title-CheckInBulkModal">Check-in multiple participants into one activity</h6>
                </Grid>
                <Grid item>
                  <Grid container spacing={32}>
                    <Grid item>
                      <Button
                        aria-label="Add"
                        variant="contained"
                        color="primary"
                        onClick={() => downloadSampleFile()}
                        data-testid="downloadSampleFileButton-CheckInBulkModal"
                      >
                        <GetAppIcon style={{ marginRight: 10 }} />
                        Sample file
                      </Button>
                    </Grid>
                    <Grid item>
                      <CloseIcon
                        onClick={() => {
                          if (!loading) {
                            handleClose();
                          }
                        }}
                        className="close_icon"
                        style={{ color: loading ? "grey" : undefined }}
                        data-testid="closeIcon-CheckInBulkModal"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={8} style={{ alignItems: "center", justifyContent: "space-between" }}>
                <Grid item xs={12}>
                  <Select
                    isSearchable
                    isClearable
                    value={selectedEvent}
                    options={events}
                    onChange={selectEvent}
                    filterOption={customFilter}
                    getOptionValue={(option) => option["label"]}
                    styles={customStyles}
                    formatOptionLabel={formatOptionLabel}
                  />
                  <span className="error-msg highlight" id="event-name-error">
                    {Some(error) && error.field === "selectEvent" && error.message}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.info}>
              <h6>
                <b>Note:</b> The check-in/out times in your file upload should be in the activity&#39;s timezone.
              </h6>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={8} style={{ alignItems: "center" }}>
                <Grid item {...getRootProps()}>
                  <Button
                    className={classes.uploadFileButton}
                    disabled={None(selectedEvent)}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setCsvFileName(undefined);
                      setCheckInData([]);
                      if (Some(error) && error.field === "uploadFile") {
                        setError(undefined);
                      }
                    }}
                    style={{ float: "left" }}
                    data-testid="uploadFileButton-CheckInBulkModal"
                  >
                    <CloudUploadIcon style={{ marginRight: 10 }} />
                    <input {...getInputProps()} />
                    Upload file
                  </Button>
                </Grid>
                <Grid item className={classes.uploadFileError}>
                  {Some(error) && error.field === "uploadFile" ? (
                    <span className="error-msg highlight" id="upload-file-error">
                      {error.message}
                    </span>
                  ) : (
                    Some(csvFileName) && <span>{csvFileName}</span>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={None(selectedEvent) || None(csvFileName) || !isValidFormat || checkInData.length > 0}
                variant="contained"
                color="primary"
                onClick={checkInBulk}
                style={{ float: "right" }}
                data-testid="checkInButton-CheckInBulkModal"
              >
                Check-in
              </Button>
            </Grid>
            {loading ? (
              <Grid>
                <LoadingScreen key={1} minHeight="100px" />
                <span data-testid="processYourRecordsText-CheckInBulkModal">
                  We are processing your records. Please check these again on the Check-in bulk log tab under Admin log.
                </span>
              </Grid>
            ) : (
              checkInData.length > 0 && (
                <Grid item xs={12} style={{ marginTop: 10 }}>
                  <Paper
                    style={{ maxHeight: checkInData.length > 5 ? "300px" : "auto", overflowX: "auto", paddingLeft: 10 }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell data-testid="firstNameHeader-CheckInBulkModal">First Name</TableCell>
                          <TableCell data-testid="lastNameHeader-CheckInBulkModal">Last Name</TableCell>
                          <TableCell data-testid="emailHeader-CheckInBulkModal">Email</TableCell>
                          <TableCell data-testid="checkInTimeHeader-CheckInBulkModal">Check-in Time</TableCell>
                          <TableCell data-testid="statusHeader-CheckInBulkModal">Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {checkInData.map(({ error, eventAttendee, status }) => (
                          <TableRow key={`${eventAttendee.email}`}>
                            <TableCell data-testid={`${eventAttendee.email}-FirstName-CheckInBulkModal`}>
                              {eventAttendee.firstName}
                            </TableCell>
                            <TableCell data-testid={`${eventAttendee.email}-LastName-CheckInBulkModal`}>
                              {eventAttendee.lastName}
                            </TableCell>
                            <TableCell data-testid={`${eventAttendee.email}-Email-CheckInBulkModal`}>
                              {eventAttendee.email}
                            </TableCell>
                            <TableCell
                              style={{ width: 150 }}
                              data-testid={`${eventAttendee.email}-CheckInTime-CheckInBulkModal`}
                            >
                              {eventAttendee.checkInTime}
                            </TableCell>
                            <TableCell data-testid={`${eventAttendee.email}-Status-CheckInBulkModal`}>
                              {status === StatusType.FAILED ? (
                                <Tooltip title={error} data-testid="errorMessage-CheckInBulkModal">
                                  <span style={{ color: "red", cursor: "pointer", textDecoration: "underline" }}>
                                    {status}
                                  </span>
                                </Tooltip>
                              ) : (
                                status
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

const styles = (theme: { spacing: { unit: number }; palette: { background: { paper: any } }; shadows: any[] }) =>
  createStyles({
    activityModelCenter: {
      alignItems: "center",
    },
    backdrop: {
      background: "linear-gradient(67.73deg, #0E69F1 1.08%, #20A2F9 100%);",
      backgroundColor: "black",
      opacity: 0.9,
    },
    info: {
      marginTop: 20,
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "6px",
      boxShadow: theme.shadows[5],
      maxWidth: "100%",
      minWidth: 550,
      padding: theme.spacing.unit * 2,
      position: "relative",
      textAlign: "left",
    },
    textFieldInput: {
      padding: "10px 6px",
    },
    uploadFileButton: {
      justifyContent: "space-arround",
    },
    uploadFileError: {
      marginTop: 10,
    },
  });

export default withStyles(styles)(CheckInBulkModal);
