import "./Waiver.css";
import { AppBar, Button, Grid, InputAdornment, TextField, createStyles, withStyles } from "@material-ui/core";
import React, { PureComponent } from "react";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getWaiverContent, getWaiverTags, saveWaiver, updateWaiver } from "../../../constant/api";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmationBox from "../../UI-Components/Confirmation";
import ControlledEditor from "../../UI-Components/TextEditor";
import FormValidator from "../../../constant/validation";
import LoadingScreen from "../../UI-Components/LoadingScreen";
import error_icon from "../../../assets/Images/Icons/error_icon.svg";
import history from "../../../history";
import preview from "../../../assets/Images/Icons/preview.svg";

interface Props extends RouteComponentProps<{}, {}, { data: { waiverId: string; waiverName: string }; key: string }> {
  readonly classes: {
    readonly button: string;
    readonly previewButton: string;
    readonly button1: string;
    readonly button2: string;
  };
  readonly setSideBar?: () => Promise<void>;
  readonly handleOnCreate?: () => void;
  readonly notify: (arg: string, arg1: string) => void;
}
interface validations {
  isValid: boolean;
  waiverName: {
    isInvalid: boolean;
    message: string;
  };
}
interface waiverData {
  content: string;
  waiverTitle: string;
}
interface State {
  waiverName?: string;
  validation: {
    isValid: boolean;
    waiverName: {
      isInvalid: boolean;
      message: string;
    };
  };
  waiverTags: { id: string; name: string }[];
  waiverData: waiverData;
  content: string;
  isLoader: boolean;
  open: boolean;
  isError: boolean;
}

class CreateWaiver extends PureComponent<Props, State> {
  readonly validator: {
    validate: (state: State) => validations;
    valid: () => validations;
  };
  submitted: boolean;

  editorInstance: any;
  constructor(props: Props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: "waiverName",
        message: "Please enter the form name.",
        method: "isEmpty",
        validWhen: false,
      },
    ]);
    let waiverName = localStorage.getItem("waiverName");
    if (waiverName === null) {
      waiverName = "";
    }
    const { location } = this.props;
    if (location.state !== undefined) {
      waiverName = location.state.data.waiverName;
      if (waiverName) {
        localStorage.setItem("waiverName", waiverName);
      }
      localStorage.setItem("waiverId", location.state.data.waiverId);
    }
    this.state = {
      content: "",
      isError: false,
      isLoader: location.state !== undefined,
      open: false,
      validation: this.validator.valid(),
      waiverData: {} as waiverData,
      waiverName: waiverName!,
      waiverTags: [],
    };
    this.submitted = false;
    this.getWaiverTaggedContent(location);
  }

  getWaiverTaggedContent = async (historyObj?: { state?: { data: { waiverId: string } } }) => {
    const response = await getWaiverTags();
    const res: any = response.data.item.map((item) => {
      return { id: item.waiverTagId, name: item.tagName };
    });
    let waiverData: waiverData = {} as any;
    if (historyObj?.state !== undefined) {
      const responseData: any = await getWaiverContent(historyObj.state.data.waiverId);
      waiverData = responseData.data.item;
      const waiverFields = JSON.parse(responseData.data.item.waiverFields);
      let responseText = responseData.data.item.content;
      waiverFields.map((field: { isRequired: boolean; elementId: any; placeholder: string }) => {
        if (field.isRequired) {
          responseText = responseText.replace(
            `<div style="padding-top:10px;padding-bottom:10px;"><input type="text" id="${field.elementId}" required placeholder="${field.placeholder}*" style="background: #F0F7FE; width: calc(100% - 30px);padding: 12px;border: 1px solid #ccc;border-radius: 4px;resize: vertical;"></div>`,
            `*[[${field.placeholder}]]*`,
          );
        } else {
          responseText = responseText.replace(
            `<div style="padding-top:10px;padding-bottom:10px;"><input type="text" id="${field.elementId}" placeholder="${field.placeholder}" style="background: #F0F7FE; width: calc(100% - 30px);padding: 12px;border: 1px solid #ccc;border-radius: 4px;resize: vertical;"></div>`,
            `[[${field.placeholder}]]`,
          );
        }

        return responseText;
      });
      localStorage.setItem("draft", encodeURIComponent(responseText));
    }
    this.setState({
      content: decodeURIComponent(localStorage.getItem("draft")!),
      isLoader: false,
      waiverData: waiverData,
      waiverTags: res,
    });
  };

  goToPreviousPage = () => {
    history.push("/waivers");
  };

  goBack = () => {
    this.setState({
      open: true,
    });
  };
  closeConfirmation = () => {
    this.setState({
      open: false,
    });
  };

  handleClick = () => {
    history.push("/preview");
  };

  saveWaiver = async () => {
    const { state } = this;
    const validation: validations = this.validator.validate(state);
    this.setState({ validation });
    this.submitted = true;
    if (validation.isValid) {
      const { location } = this.props;
      if (location.state !== undefined) {
        if (location.state.key === "Duplicate") {
          localStorage.removeItem("waiverId");
        }
      }
      if (localStorage.getItem("waiverId") === null) {
        const { waiverName } = this.state;
        const { notify } = this.props;
        const params = {
          content: decodeURIComponent(localStorage.getItem("html")!),
          name: waiverName,
          waiverFields: this.editorInstance.customOption.props.fields,
        };
        try {
          await saveWaiver(params);
          notify("Form created successfully.", "success");
          history.push("/waivers");
        } catch (error) {
          if (!error.response.data.success) {
            notify(`Form name ${params.name} already exist.`, "error");
          }
        }
      } else {
        const { waiverName } = this.state;
        const { notify } = this.props;
        const params = {
          content: decodeURIComponent(localStorage.getItem("html")!),
          name: waiverName,
          waiverFields: this.editorInstance.customOption.props.fields,
          waiverId: localStorage.getItem("waiverId"),
        };
        try {
          await updateWaiver(params);
          notify("Form updated successfully.", "success");
          history.push("/waivers");
        } catch (error) {
          if (!error.response.data.success) {
            notify(`Form name ${params.name} already exist.`, "error");
          }
        }
      }
    } else {
      this.setState({
        isError: true,
      });
    }
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 40) {
      event.preventDefault();
    } else if (event.target.value.length === 0) {
      this.setState({
        isError: true,
        waiverName: event.target.value,
      });
    } else {
      this.setState({ isError: false, waiverName: event.target.value });
      localStorage.setItem("waiverName", event.target.value);
    }
  };
  render() {
    const { isLoader, waiverName, isError, content, waiverTags, open, validation: stateValidation } = this.state;
    const focusUsernameInputField = (input: any) => {
      if (input !== null) {
        input.focus();
      }
    };
    const validation: validations = this.submitted ? this.validator.validate(this.state) : stateValidation;

    const { classes } = this.props;

    return (
      <div>
        {isLoader && <LoadingScreen minHeight="calc(100vh - 125px)" />}
        <div style={isLoader ? { display: "none" } : { display: "block" }}>
          <AppBar position="static" className="innerAppbar appbar">
            <Grid container spacing={16}>
              <Grid item sm={4} className="create_title" data-testid="headerTitle-CreateWaiver">
                Create form
              </Grid>
              <Grid item sm={4} className="create_textfield">
                <TextField
                  id="waiverName"
                  placeholder="Form name"
                  value={waiverName}
                  onChange={this.handleChange}
                  margin="none"
                  error={isError}
                  className={isError ? "error_background  form_name" : "form_name"}
                  inputRef={focusUsernameInputField}
                  variant="filled"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end"> {isError ? <img alt="" src={error_icon} /> : ""}</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    "data-testid": "formName",
                    maxLength: 40,
                  }}
                  helperText={validation.waiverName.message === "" ? " " : validation.waiverName.message}
                />
              </Grid>
              <Grid item sm={4} className="create_close">
                <CloseIcon data-testid="createFormCloseIcon" onClick={this.goBack} className="cursor_pointer close" />
              </Grid>
            </Grid>
          </AppBar>
          <main className="main_background create_waiver_modal">
            <Grid container spacing={16}>
              <Grid item style={{ width: "100%" }}>
                <h4 className="header">Create digital Forms</h4>
                <h6 className="waiver_desc_text">
                  To create a form or waiver, copy and paste your text into the input box below.
                </h6>
                <Grid item className="waiver_subtitle">
                  <h6 className="waiver_desc_text">
                    To add a field that gets filled out during check-in, select a field on the right or type the field
                    name in double brackets. For example, [[Emergency Contact]].
                  </h6>
                </Grid>
                <Grid item className="waiver_subtitle_1">
                  <h6 className="waiver_desc_text">
                    To make a field required, add asterisks outside the brackets. For example, *[[Emergency Contact]]*
                  </h6>
                </Grid>
              </Grid>
              <Grid
                data-testid="editorContainer-CreateWaiver"
                item
                xs={12}
                sm={9}
                style={{ minHeight: "calc(100vh - 264px)" }}
              >
                <ControlledEditor
                  ref={(instance: any) => {
                    this.editorInstance = instance;
                  }}
                  content={content}
                />
              </Grid>
              <Grid item sm={3}>
                <div className="tag_container">
                  {waiverTags.map((obj) => {
                    const id = waiverTags.indexOf(obj);

                    return (
                      <div
                        key={id}
                        // value={obj.name}
                        className="tags active-field"
                        onClick={this.editorInstance.addField.bind(this, obj.name)}
                      >
                        <span data-testid={`${obj.name}Tag`} style={{ marginRight: "20px" }}>
                          +
                        </span>
                        {obj.name}
                      </div>
                    );
                  })}
                </div>
              </Grid>
            </Grid>
          </main>
          <div className="innerAppbar appbar">
            <div className="mui-container mui--text-center" style={{ paddingTop: "1%" }}>
              <Button
                data-testid="saveForm"
                variant="contained"
                color="primary"
                className={classes.button1}
                onClick={this.saveWaiver}
              >
                Save
              </Button>
              <Button
                data-testid="previewForm"
                variant="contained"
                className={classes.button2}
                onClick={this.handleClick}
              >
                <img alt="" src={preview} style={{ paddingRight: "5px" }} /> Preview
              </Button>
            </div>
          </div>
        </div>
        <ConfirmationBox
          open={open}
          closeConfirmation={this.closeConfirmation}
          goToPreviousPage={this.goToPreviousPage}
          message="Your changes are not saved. Still want to go back?"
          testId="confirmationModal"
        />
      </div>
    );
  }
}
const styles = () =>
  createStyles({
    button1: {
      background:
        "linear-gradient(0deg, #157ef4, #157ef4), linear-gradient(0deg, #2a85f4, #2a85f4), linear-gradient(140.37deg, #147df4 0%, rgba(20, 125, 244, 0.3) 100%), #ffffff",
      borderRadius: "4px",
      color: "#ffffff !important",
      fontFamily: "Lato !important",
      fontWeight: "bold !important" as "bold",
      height: "44px",
      margin: "10px !important",
      position: "absolute !important" as "absolute",
      right: "30px !important",
      textTransform: "capitalize !important" as "capitalize",
      width: "150px",
    },
    button2: {
      background: "#D3E6FD",
      borderRadius: "4px",
      color: "#147DF4 !important",
      fontFamily: "Lato !important",
      fontWeight: "bold !important" as "bold",
      height: "44px",
      margin: "10px !important",
      position: "absolute !important" as "absolute",
      right: "200px !important",
      textTransform: "capitalize !important" as "capitalize",
      width: "150px",
    },
  });

export default withStyles(styles)(withRouter(CreateWaiver));
