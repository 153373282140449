import { Modal, Tabs } from "antd";
import { StyleSheet, css } from "aphrodite";
import { MatchesTable } from "../MatchesTable";
import { NoMatchesTable } from "../NoMatchesTable";
import React from "react";

const styles = StyleSheet.create({
  modalBody: {
    fontSize: "20px",
    height: "60%",
    marginTop: "50px",
    width: "70%",
  },
});

interface Props {
  readonly isVisible: boolean;
  readonly onClose: () => void;
  readonly notify: (message: string, status: string) => void;
  readonly sessionId: string;
}

export const MatchCheckoutModal = ({ isVisible, notify, onClose, sessionId }: Props) => {
  const { TabPane } = Tabs;

  return (
    <Modal
      visible={isVisible}
      onCancel={onClose}
      footer={false}
      wrapClassName="match_session_modal_wrapper"
      className={css(styles.modalBody)}
    >
      <Tabs tabBarStyle={{ backgroundColor: "inherit", textAlign: "left" }}>
        <TabPane tab="Potential Matches" key="1">
          <MatchesTable notify={notify} sessionId={sessionId} />
        </TabPane>
        <TabPane tab="No Matches" key="2">
          <NoMatchesTable notify={notify} sessionId={sessionId} />
        </TabPane>
      </Tabs>
    </Modal>
  );
};
