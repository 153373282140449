import { Col, Layout, Row } from "antd";
import { StyleSheet, css } from "aphrodite";
import { Fonts } from "../../theme/Fonts";
import { Footer } from "./Layout/Footer";
import React from "react";

const styles = StyleSheet.create({
  contentWrapper: {
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 40,
  },
  footerWrapper: {
    alignItems: "center",
    bottom: 0,
    display: "flex",
    height: "10vh",
    justifyContent: "center",
    position: "sticky",
  },
  middleContainer: {
    marginBottom: "40px",
    marginTop: "40px",
  },
  text: {
    fontFamily: Fonts.roboto,
    fontSize: "22px",
    textAlign: "center",
  },
});

export const BookmarkComponent = () => {
  const { Content } = Layout;

  return (
    <div className="webcheckin" style={{ backgroundColor: "#f0f2f5" }}>
      <Layout>
        <Content>
          <Row>
            <Col md={{ offset: 8, span: 8 }} sm={{ offset: 3, span: 18 }} xs={{ span: 24 }}>
              <div className={css(styles.contentWrapper)}>
                <h4 className={css(styles.text)}>Uh oh!</h4>
                <h4 className={css(styles.text)}>It looks like you bookmarked this page.</h4>
                <div className={css(styles.middleContainer)}>
                  <h4 className={css(styles.text)}>We are very sorry, but that won&apos;t work.</h4>
                </div>
                <h4 className={css(styles.text)}>
                  Please find the check in/out URL that was shared with you and use that instead.
                </h4>
              </div>
            </Col>
          </Row>
        </Content>
        <div className={css(styles.footerWrapper)}>
          <Footer />
        </div>
      </Layout>
    </div>
  );
};
