import React, { PureComponent } from "react";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core";

const rows = [
  { disablePadding: true, id: "waiverName", label: "Form name", numeric: false },
  { disablePadding: false, id: "createdAt", label: "Created on", numeric: false },
  { disablePadding: false, id: "updatedAt", label: "Last edited", numeric: false },
];

class EnhancedTableHead extends PureComponent {
  render() {
    return (
      <TableHead>
        <TableRow>
          {rows.map(
            (row) => (
              <TableCell
                key={row.id}
                align={row.numeric ? "right" : "left"}
                padding={row.disablePadding ? "none" : "default"}
                className={`table-head-title ${row.id}`}
              >
                <TableSortLabel style={{ cursor: "default" }}>{row.label}</TableSortLabel>
              </TableCell>
            ),
            this,
          )}
        </TableRow>
      </TableHead>
    );
  }
}

export default EnhancedTableHead;
