import { Button, Checkbox, Icon, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { StyleSheet, css } from "aphrodite";
// eslint-disable-next-line no-unused-vars
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Colors } from "../../../theme/Colors";
import { Fonts } from "../../../theme/Fonts";
interface Props {
  isOpen: boolean;
  handleModalClose: () => void;
  sendEventData: (data: Array<number>) => void;
  readonly activities: Array<ActivityData>;
}

interface ActivityData {
  eventId: number;
  name: string;
}

const styles = StyleSheet.create({
  activityName: {
    color: Colors.darkBlack,
    fontFamily: Fonts.lato,
    fontSize: "16px",
    lineHeight: "19px",
  },
  checkboxArea: {
    maxHeight: "200px",
    overflowX: "hidden",
    overflowY: "scroll",
  },
});

export const AllActivities: React.SFC<Props> = ({ isOpen, handleModalClose, sendEventData, activities }) => {
  const { Search } = Input;
  const [filteredActivities, setFilteredActivities] = useState<Array<ActivityData>>([]);
  const [eventIds, setEventIds] = useState<Array<number>>([]);
  const [searchEvent, setSearchEvent] = useState<string>("");

  useEffect(() => {
    const events = activities.filter((activity: ActivityData) =>
      activity.name.toLowerCase().includes(searchEvent.toLowerCase()),
    );
    setFilteredActivities(events);
  }, [searchEvent]);

  const handleChange = (e: CheckboxChangeEvent) => {
    let eventIdsList: Array<number> = [];
    const selectedEventId = e.target.value;
    if (eventIds.includes(selectedEventId)) {
      eventIdsList = eventIds.filter((eventId) => eventId !== selectedEventId);
    } else {
      eventIdsList = eventIds.concat(selectedEventId);
    }
    setEventIds(eventIdsList);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchEvent(e.target.value);
  };

  const handleDone = () => {
    setEventIds([]);
    setSearchEvent("");
    handleModalClose();
    sendEventData(eventIds);
  };

  const handleSelectAll = () => {
    const eventIdsList: Array<number> = [];
    filteredActivities.map((event: ActivityData) => eventIdsList.push(event.eventId));
    setEventIds(eventIdsList);
  };

  const handleActivitiesView = (): React.ReactNode => {
    return filteredActivities.length > 0
      ? filteredActivities.map((activity: ActivityData) => {
          return (
            <p key={activity.eventId}>
              <Checkbox value={activity.eventId} onChange={handleChange} checked={eventIds.includes(activity.eventId)}>
                <span className={css(styles.activityName)} data-testid={`${activity.name}-AllActivitiesModal`}>
                  {activity.name}
                </span>
              </Checkbox>
            </p>
          );
        })
      : "No data available";
  };

  return (
    <React.Fragment>
      <Modal
        width={350}
        closeIcon={<Icon data-testid="closeIcon-AllActivitiesModal" type="close" onClick={() => handleModalClose()} />}
        visible={isOpen}
        footer={[
          <Button data-testid="doneButton-AllActivitiesModal" type="primary" ghost onClick={handleDone}>
            Done
          </Button>,
        ]}
        maskStyle={{ backgroundColor: Colors.blue }}
      >
        <br />
        <Search
          data-testid="searchInputField-AllActivitiesModal"
          placeholder="Search for activities"
          onChange={handleSearch}
          value={searchEvent}
        />
        <br />
        <br />
        <div className={css(styles.checkboxArea)}>{handleActivitiesView()}</div>
        <br />
        <Button data-testid="selectAllButton-AllActivitiesModal" type="link" onClick={handleSelectAll}>
          Select all
        </Button>
        |
        <Button data-testid="clearAllButton-AllActivitiesModal" type="link" onClick={() => setEventIds([])}>
          Clear all
        </Button>
      </Modal>
    </React.Fragment>
  );
};
