import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../../theme/Colors";
import Cookies from "js-cookie";
import { Fonts } from "../../../theme/Fonts";
import history from "../../../history";
const styles = StyleSheet.create({
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "34px",
    width: "100%",
  },
  continueButton: {
    color: Colors.darkBlue,
    fontSize: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
  },
  modalBody: {
    fontFamily: Fonts.roboto,
    fontSize: "20px",
    height: "213px",
    marginTop: "50px",
    width: "336px",
  },
  modalText: {
    color: Colors.headerText,
    fontSize: "16px",
    textAlign: "center",
  },
  modalTitle: {
    color: Colors.headerText,
    fontSize: "20px",
    fontWeight: "bold",
    textAlign: "left",
  },
  resetButton: {
    color: Colors.darkGrey,
    fontSize: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
  },
  root: {
    backgroundColor: Colors.white,
    fontFamily: Fonts.roboto,
    overflowX: "hidden",
    overflowY: "auto",
    width: "282px",
  },
});

export const Timer = () => {
  const { push } = history;
  let myInterval: NodeJS.Timer;
  const [isActive, setActive] = useState<boolean>(false);
  const [seconds, setSeconds] = useState<number>(90);
  const virtualKioskType = Number(Cookies.get("virtualKioskType"));

  useEffect(() => {
    loadTimer();

    return () => {
      clearInterval(myInterval);
    };
  }, []);

  const loadTimer = () => {
    if (virtualKioskType === 1) {
      myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds((previous) => previous - 1);
        }
      }, 1000);
    }
  };

  const handleCloseModal = () => {
    setActive(false);
    setSeconds(90);
  };

  useEffect(() => {
    if (seconds < 30) {
      setActive(true);
    }

    if (seconds <= 0) {
      clearInterval(myInterval);
      push("/web-checkin/home");
    }
  }, [seconds]);

  return (
    <Modal
      title={`Session Timeout in 0:${seconds}`}
      visible={isActive}
      onCancel={handleCloseModal}
      footer={false}
      wrapClassName="exit_modal_title"
      className={css(styles.modalBody)}
      maskClosable={false}
    >
      <div className={css(styles.root)}>
        <span className={css(styles.modalText)}>
          This session will reset due to inactivity. If you need more time, please click continue.
        </span>
      </div>
      <div className={css(styles.buttonWrapper)}>
        <Button className={css(styles.resetButton)} type="link" onClick={() => push("/web-checkin/home")}>
          reset
        </Button>
        <Button className={css(styles.continueButton)} type="link" onClick={handleCloseModal}>
          continue
        </Button>
      </div>
    </Modal>
  );
};
