import { Button, Modal } from "antd";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../../../theme/Colors";
import React from "react";
const styles = StyleSheet.create({
  buttonWrapper: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
    marginTop: "30px",
    width: "100%",
  },
  cancelButton: {
    borderColor: Colors.blue,
    borderWidth: "1px",
    color: Colors.blue,
    fontSize: "16px",
    height: "44px",
    letterSpacing: "1.25px",
    marginRight: "3%",
    width: "128px",
  },
  deleteButton: {
    backgroundColor: Colors.darkRed,
    borderColor: Colors.darkRed,
    borderWidth: "1px",
    color: Colors.white,
    fontSize: "16px",
    height: "44px",
    letterSpacing: "1.25px",
    marginLeft: "3%",
    width: "128px",
  },
  modalBody: {
    fontSize: "20px",
    height: "320px",
    marginTop: "50px",
    width: "400px",
  },
  root: {
    backgroundColor: "FFF",
    height: "auto",
    width: "400px",
  },
});
interface Props {
  readonly show: boolean;
  readonly handleClose: () => void;
  readonly selectedAttendeesCount: number;
  readonly deleteCheckin: () => void;
}

export const DeleteCheckinModal: React.FC<Props> = ({ show, handleClose, selectedAttendeesCount, deleteCheckin }) => {
  return (
    <Modal
      title="Are you sure?"
      visible={show}
      onCancel={handleClose}
      footer={false}
      wrapClassName="delete_modal_wrapper"
      className={css(styles.modalBody)}
    >
      <div className={css(styles.root)} data-testid="deleteActivity-DeleteCheckinModal">
        <h1 className="delete_header">Delete check-in(s)</h1>
        <p className="delete_description">
          This will permanently delete {selectedAttendeesCount} check-in(s). Deleting a check-in will also revoke any
          merit automatically issued for successfully checking into the activity
        </p>
        <div className={css(styles.buttonWrapper)}>
          <Button
            className={css(styles.cancelButton)}
            type="link"
            onClick={handleClose}
            data-testid="cancelButton-DeleteCheckinModal"
          >
            Cancel
          </Button>
          <Button
            className={css(styles.deleteButton)}
            type="primary"
            onClick={() => deleteCheckin()}
            data-testid="deleteButton-DeleteCheckinModal"
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};
