import { Grid } from "@material-ui/core";
import React from "react";
import Switches from "../../../UI-Components/Switches";

interface Props {
  readonly locationEnabled: boolean;
  readonly handleChange: (data: { name: string; val: boolean }) => void;
}

export const EventLocation: React.FC<Props> = ({ locationEnabled, handleChange }) => {
  return (
    <div className="grid">
      <Grid item xs={7}>
        <h6 className="step4_subtitle" style={{ marginTop: "4px" }}>
          Enable location collection for this activity
        </h6>
        <div className="step2_switch2 margin_0_top">
          <Switches id="locationEnabled" name="locationEnabled" checked={locationEnabled} handleChange={handleChange} />
        </div>
      </Grid>
      <p className="margin_0 time-zone-text">Add a location confirmation to check-in / check-out.</p>
      <p className="margin_0 time-zone-text">
        Location permission will be requested from the participant’s device in addition to manual input.
      </p>
    </div>
  );
};
