/* eslint-disable no-unused-vars */
import { DataSource, TableColumn } from "../../../UI-Components/EnhancedTable/type";
import { DialogContentText, Grid, WithStyles, createStyles, withStyles } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { WithErrorHandler, WithErrorHandlerProps } from "../../../WithErrorHandler";
import {
  deleteCheckOutAutomation,
  getAllActivities,
  getCheckOutAutomation,
  getCheckOutAutomationWithEvent,
  mapCheckOutAutomationToEvents,
} from "../../../../constant/api";
import ActionPopUp from "./ActionPopUp";
import ActivitiesPopUp from "./ActivitiesPopUp";
import ConfirmationBox from "../../../UI-Components/Confirmation";
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from "../../../UI-Components/EnhancedTable/constants";
import EnhancedTable from "../../../UI-Components/EnhancedTable";
import { Fonts } from "../../../../theme/Fonts";
import LoadingScreen from "../../../UI-Components/LoadingScreen";
import ManageActivities from "./Modals/ManageActivities";
import { Some } from "../../../../utils/Some";
import history from "../../../../history";

interface Props extends WithErrorHandlerProps, WithStyles<typeof styles> {
  readonly checkoutAutomations: CheckOutAutomationWithEvent[];
  readonly events: EventList[];
  readonly isLoadingAutomationData: boolean;
  readonly notify: (arg1: string, arg2: string) => void;
}
interface NewEventListProps extends EventList {
  flag: string;
}
interface EventListType extends EventList {
  checkoutAutomationCount: number;
}

const CheckoutAutomation = ({
  classes,
  checkoutAutomations,
  errorHandler,
  events,
  isLoadingAutomationData,
  notify,
}: Props) => {
  const [checkoutAutomation, setCheckoutAutomation] = useState<CheckOutAutomationWithEvent[]>([]);
  const [currentCheckoutAutomation, setCurrentCheckoutAutomation] = useState<CheckOutAutomationWithEvent[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [showManageActivitiesModal, setShowManageActivitiesModal] = useState<boolean>(false);
  const [showConfirmationBox, setShowConfirmationBox] = useState({
    open: false,
    sourceActivity: "",
    title: "",
    width: 400,
  });
  const [eventList, setEventList] = useState<EventListType[]>([]);
  const [selectedEventIds, setSelectedEventIds] = useState<number[]>([]);
  const [newEventList, setNewEventList] = useState<NewEventListProps[]>([]);
  const [currentCheckoutAutomationRow, setCurentCheckoutAutomationRow] = useState<CheckOutAutomationWithEvent>();
  const [currentTableData, setCurrentTableData] = useState({ page: 0, rowsPerPage: 10 });
  const [defaultTableData, setDefaultTableData] = useState(currentTableData);

  const refreshData = async () => {
    try {
      setLoading(true);
      const {
        data: { item },
      } = await getCheckOutAutomationWithEvent();
      setCheckoutAutomation(item);
      const dataonPage = item.slice(
        currentTableData.page * currentTableData.rowsPerPage,
        currentTableData.page * currentTableData.rowsPerPage + currentTableData.rowsPerPage,
      );
      setDefaultTableData({ page: currentTableData.page, rowsPerPage: currentTableData.rowsPerPage });
      setCurrentCheckoutAutomation(dataonPage);

      const responseEvents = await getAllActivities();
      const events = responseEvents.data.item.filter(
        (event) => event.publishStatus && !event.isDraft && event.isCheckOutReq,
      );
      const modifiedEvents = events.map((event) => {
        const checkoutAutomationCount = item.reduce(
          (count, automation) =>
            count +
            automation.eventCheckOutAutomationMapping.filter(
              (mapping) => mapping.eventId === event.eventId && event.isCheckOutReq,
            ).length,
          0,
        );

        return { ...event, checkoutAutomationCount };
      });
      setEventList(modifiedEvents);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (!isLoadingAutomationData) {
      setCheckoutAutomation(checkoutAutomations);
      setCurrentCheckoutAutomation(checkoutAutomations.slice(0, DEFAULT_ROWS_PER_PAGE_OPTIONS[0]));
      const modifiedEvents = events.map((event) => {
        const checkoutAutomationCount = checkoutAutomations.reduce(
          (count, automation) =>
            count +
            automation.eventCheckOutAutomationMapping.filter(
              (mapping) => mapping.eventId === event.eventId && event.isCheckOutReq,
            ).length,
          0,
        );

        return { ...event, checkoutAutomationCount };
      });
      setEventList(modifiedEvents);
      setLoading(isLoadingAutomationData);
    }
  }, [isLoadingAutomationData, checkoutAutomations, events]);

  const columns: TableColumn[] = [
    { customHeadTextAlign: "left", key: "name", label: "Name" },
    { customHeadTextAlign: "center", customTextAlign: "center", key: "activities", label: "#Activities" },
    { customHeadTextAlign: "center", customTextAlign: "center", key: "actions", label: "Actions" },
  ];

  const mapCheckOutAutomation = async () => {
    setShowConfirmationBox({ ...showConfirmationBox, open: false });
    try {
      await mapCheckOutAutomationToEvents({
        checkOutAutomationId: currentCheckoutAutomationRow?.checkOutAutomationId ?? 1,
        eventIds: selectedEventIds,
      });
      refreshData();
      notify("Checkout automation updated successfully", "success");
      setShowManageActivitiesModal(false);
    } catch (error) {
      notify("Error while mapping activities to the checkout automation", "error");
    } finally {
      setNewEventList([]);
    }
  };

  const createCopyOrEditHandler = async (automation: CheckOutAutomationWithEvent, name: string) => {
    const {
      data: { item },
    } = await getCheckOutAutomation(automation.checkOutAutomationId);
    if (Some(item)) {
      history.push({
        pathname: "/create-automation-checkout",
        state: { data: { action: name === "Edit" ? name : "Copy", checkOutAutomation: item } },
      });
    } else {
      notify("Check-out automation not found. Cannot create a copy of it", "error");
    }
  };
  const deleteButtonHandler = (automation: CheckOutAutomationWithEvent) => {
    setShowConfirmationBox({
      open: true,
      sourceActivity: "deleteButtonHandler",
      title: `Do you want to delete ${automation.name} automation?`,
      width: automation.eventCheckOutAutomationMapping.length ? 500 : 400,
    });
  };

  const buttonsArr = [
    { clickHandler: () => setShowManageActivitiesModal(true), name: "Manage activities" },
    {
      clickHandler: (automation: CheckOutAutomationWithEvent, name: string) =>
        createCopyOrEditHandler(automation, name),
      name: "Edit",
    },
    {
      clickHandler: (automation: CheckOutAutomationWithEvent, name: string) =>
        createCopyOrEditHandler(automation, name),
      name: "Create a copy",
    },
    { clickHandler: (automation: CheckOutAutomationWithEvent) => deleteButtonHandler(automation), name: "Delete" },
  ];

  const setSelectedEventIdsHandler = (eventIds: number[]) => {
    setSelectedEventIds(eventIds);
  };

  const setCurentCheckoutAutomationRowHandler = (automation: CheckOutAutomationWithEvent) => {
    setCurentCheckoutAutomationRow(automation);
  };

  const selectedCheckoutAutomation: DataSource[] = useMemo(
    () =>
      currentCheckoutAutomation.map((e) => ({
        actions: (
          <ActionPopUp
            automation={e}
            setSelectedEventIds={setSelectedEventIdsHandler}
            setCurentCheckoutAutomationRow={setCurentCheckoutAutomationRowHandler}
            buttonsArr={buttonsArr}
          />
        ),
        activities: (
          <ActivitiesPopUp
            activities={e.eventCheckOutAutomationMapping}
            checkoutAutomation={checkoutAutomation}
            checkOutAutomationId={e.checkOutAutomationId}
          />
        ),
        key: e.checkOutAutomationId,
        name: (
          <div className={classes.name}>
            <p
              className={`${classes.automationText} ${classes.automationName}`}
              data-testid={`${e.name}-Name-CreateAutomationCheckout`}
            >
              {e.name}
            </p>
            <p
              className={`${classes.automationText} ${classes.automationDesc}`}
              data-testid={`${e.name}-Description-CreateAutomationCheckout`}
            >
              {e.description}
            </p>
          </div>
        ),
      })),
    [currentCheckoutAutomation],
  );

  const onChangePageOrRows = (page: number, rowsPerPage: number) => {
    const dataonPage = checkoutAutomation.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    setCurrentTableData({ page, rowsPerPage });
    setCurrentCheckoutAutomation(dataonPage);
  };

  const paginationProps = checkoutAutomation.length
    ? {
        onChangePageOrRows: onChangePageOrRows,
        rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
        totalDatalength: checkoutAutomation.length,
      }
    : undefined;

  const enhancedTable = useMemo(
    () => (
      <EnhancedTable
        paginationProps={paginationProps}
        tableContentHeight="auto"
        columns={columns}
        title=""
        idColumn="key"
        dataSource={selectedCheckoutAutomation}
        dataTestIdKey="key"
        headerStyle={{ color: "rgb(0,0,0,0.85)", fontFamily: Fonts.default, fontSize: 14 }}
        defaultPage={defaultTableData.page}
        defaultRowsPerPage={defaultTableData.rowsPerPage}
      />
    ),
    [selectedCheckoutAutomation],
  );

  const helperText = () => (
    <main className={classes.mainBackground}>
      <Grid container spacing={0} justify="center" direction="column" className={classes.createAutomationText}>
        <p>
          For some Activities, it can be valuable to automate check-outs for participants. <br />
          Automating check-outs can ensure that people are checked out at appropriate times and maintains consistency
          within an Activity.
        </p>
        <br />
        <p>
          A <i>Workflow</i> can be added to an Activity during Activity-editing. People checked-in to that Activity will
          be checked-out in accordance with the <i>Workflow</i>.
        </p>
        <br />
        <p>
          Example:
          <br />A &quot;Check-out at 5pm&quot; workflow is added to the &apos;Shift B&apos; activity. <br />
          Based on the rules and settings of the workflow, people who check in to &apos;Shift B&apos; are automatically
          checked out at 5pm every day.
        </p>
      </Grid>
    </main>
  );

  const compareEvents = (currentEventIds: number[], newEventIds: number[]) => {
    const addedEventIds = newEventIds.filter((id) => !currentEventIds.includes(id));
    const removedEventIds = currentEventIds.filter((id) => !newEventIds.includes(id));

    return [
      ...eventList
        .filter((event) => addedEventIds.includes(event.eventId))
        .map((event) => ({ ...event, flag: "added" })),
      ...eventList
        .filter((event) => removedEventIds.includes(event.eventId))
        .map((event) => ({ ...event, flag: "removed" })),
    ];
  };

  const confirmationBoxHandler = async () => {
    if (
      showConfirmationBox.sourceActivity === "addEventClose" ||
      (newEventList.length === 0 && showConfirmationBox.sourceActivity === "addEventSave")
    ) {
      setShowManageActivitiesModal(false);
      setShowConfirmationBox({ ...showConfirmationBox, open: false });
      setTimeout(() => {
        setNewEventList([]);
      }, 200);

      return;
    }
    setShowConfirmationBox({ ...showConfirmationBox, open: false });
    try {
      if (showConfirmationBox.sourceActivity === "deleteButtonHandler") {
        const response = await deleteCheckOutAutomation(currentCheckoutAutomationRow?.checkOutAutomationId);
        if (response.data.success) {
          refreshData();
          notify("Check-out automation deleted successfully.", "success");
        } else {
          notify("Unable to delete check-out automation", "error");
        }
      }

      if (showConfirmationBox.sourceActivity === "addEventSave") {
        mapCheckOutAutomation();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const confirmationBoxCustomComponent = (sourceActivity: string) => {
    switch (sourceActivity) {
      case "deleteButtonHandler":
        if (currentCheckoutAutomationRow?.eventCheckOutAutomationMapping.length) {
          return (
            <>
              <DialogContentText data-testid="header-DialogContentText">
                <span className={classes.bold}>{currentCheckoutAutomationRow?.name}</span> automation is associated with
                the following{" "}
                {currentCheckoutAutomationRow.eventCheckOutAutomationMapping.length > 1 ? "activities" : "activity"}. Do
                you still want to delete it?
              </DialogContentText>
              <br />
              <DialogContentText>
                <ul className={classes.customComponentUl}>
                  {currentCheckoutAutomationRow?.eventCheckOutAutomationMapping.map((act, index) => (
                    <li key={act.event.name} data-testid={`${act.event.name}-ActivityName-DialogContentText`}>
                      {index + 1}. {act.event.name}
                    </li>
                  ))}
                </ul>
              </DialogContentText>
            </>
          );
        }

        return (
          <DialogContentText data-testid="messageHeader-DialogContentText">
            Do you want to delete <span className={classes.bold}>{currentCheckoutAutomationRow?.name}</span> automation?
          </DialogContentText>
        );

      case "addEventSave":
        return (
          <>
            <DialogContentText data-testid="header-DialogContentText">
              {newEventList.length ? (
                <>
                  The following {newEventList.length > 1 ? "activities have been" : "activity has been"} modified for
                  the <span className={classes.bold}>{currentCheckoutAutomationRow?.name}</span> automation. Would you
                  like to proceed?
                </>
              ) : (
                <>
                  No activities have been modified for the{" "}
                  <span className={classes.bold}>{currentCheckoutAutomationRow?.name}</span> automation. Would you like
                  to proceed?
                </>
              )}
            </DialogContentText>
            {newEventList.length > 0 && <br />}
            <DialogContentText>
              <ul className={classes.customComponentUl}>
                {newEventList.map((act, index) => (
                  <li
                    key={act.name}
                    className={act.flag === "added" ? classes.added : classes.removed}
                    data-testid={`${act.name}-ActivityName-DialogContentText`}
                  >
                    {index + 1}. {act.name}
                  </li>
                ))}
              </ul>
            </DialogContentText>
          </>
        );
      case "addEventClose":
        return (
          <>
            <DialogContentText>
              {newEventList.length ? (
                "Your changes are not saved. Still want to go back?"
              ) : (
                <>
                  No activities have been modified for the{" "}
                  <span className={classes.bold}>{currentCheckoutAutomationRow?.name}</span> automation. Would you like
                  to proceed?
                </>
              )}
            </DialogContentText>
          </>
        );

      default:
        return showConfirmationBox.title;
    }
  };

  const handleSelectedEvent = (eventIds: number[]) => {
    const currentEventIds = currentCheckoutAutomationRow?.eventCheckOutAutomationMapping.map((auto) => auto.eventId);
    const eventsArr = compareEvents(currentEventIds || [], eventIds);
    let errorFlag = false;
    eventsArr.forEach((event) => {
      if (eventIds.includes(event.eventId) && event.flag === "added" && event.checkoutAutomationCount > 2) {
        notify(
          `The ${event.name} activity has reached its limit of 3 automations, further additions cannot be made`,
          "error",
        );
        errorFlag = true;
      }
    });
    if (!errorFlag) {
      setNewEventList(eventsArr);
      setSelectedEventIds(eventIds);
    }
  };

  const handleEventSave = () => {
    setShowConfirmationBox({
      open: true,
      sourceActivity: "addEventSave",
      title: "Do you want to continue?",
      width: newEventList.length ? 500 : 400,
    });
  };

  const showManageActivitiesModalHandler = () => {
    setShowConfirmationBox({ ...showConfirmationBox, open: true, sourceActivity: "addEventClose", width: 400 });
  };

  return (
    <>
      <div className={`${classes.container}`}>
        <main className={`main_background ${classes.main}`}>
          {!loading && !checkoutAutomation.length && helperText()}
          {loading ? <LoadingScreen key={1} minHeight="calc(100vh - 250px)" /> : enhancedTable}
        </main>
      </div>
      <ConfirmationBox
        open={showConfirmationBox.open}
        closeConfirmation={() => setShowConfirmationBox({ ...showConfirmationBox, open: false })}
        goToPreviousPage={confirmationBoxHandler}
        message={confirmationBoxCustomComponent(showConfirmationBox.sourceActivity)}
        testId="confirmationModal"
        contentWidth={showConfirmationBox.width ?? 400}
        fullScreen={false}
      />
      <ManageActivities
        showModal={showManageActivitiesModal}
        showManageActivitiesModal={showManageActivitiesModalHandler}
        selectedEventIds={selectedEventIds}
        eventList={eventList}
        onSelectEvent={handleSelectedEvent}
        onSave={handleEventSave}
      />
    </>
  );
};

const styles = () =>
  createStyles({
    added: { color: "green" },
    automationDesc: { color: "rgb(0,0,0,0.65)", fontFamily: Fonts.default, fontSize: 13 },
    automationName: { color: "rgb(0,0,0,0.65)", fontFamily: Fonts.default, fontSize: 14 },
    automationText: { margin: "0", textAlign: "left" },
    bold: { fontWeight: "bold" },
    container: { display: "flex", flexDirection: "column", overflow: "hidden", paddingLeft: "97px" },
    createAutomationText: {
      background: "#ffffff",
      boxShadow: "0px 1px 0px #dce5ef",
      marginBottom: "20px",
      padding: "20px",
    },
    customComponentUl: { "&>li": { listStyleType: "none" }, marginBottom: 0, maxHeight: "120px", overflowY: "auto" },
    main: { height: "calc(100vh - 172px)" },
    mainBackground: {
      background: "#f5f6f8",
    },
    name: { width: "650px" },
    removed: { color: "red" },
  });

export default withStyles(styles)(WithErrorHandler(CheckoutAutomation));
