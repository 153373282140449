/* eslint-disable no-irregular-whitespace*/
import { Card, Icon, Layout, Row } from "antd";
import React, { useEffect } from "react";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../theme/Colors";
import { Fonts } from "../../theme/Fonts";
import history from "../../history";

const styles = StyleSheet.create({
  activityText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "14px",
    height: "50",
    letterSpacing: "0.25px",
    lineHeight: "21px",
    marginBottom: "5px",
    marginTop: "5px",
    textAlign: "left",
  },
  activityTitle: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "20px",
    fontWeight: 500,
    height: "50",
    letterSpacing: "0.15px",
    lineHeight: "30px",
    marginBottom: "5px",
    marginTop: "5px",
    textAlign: "left",
  },
  cardInnerWrapper: {
    height: "auto",
    marginLeft: "8%",
    width: "94%",
  },
  cardTitle: {
    color: Colors.headerText,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    marginTop: "10px",
    textTransform: "uppercase",
  },
  cardWrapper: {
    height: "auto",
    width: "327px",
  },
  closeIcon: {
    color: Colors.sherpa,
    fontSize: "16px",
    marginTop: "23px",
    textAlign: "right",
  },
  contentWrapper: {
    fontFamily: Fonts.roboto,
  },
  headerFlex: {
    display: "flex",
  },
  line: {
    border: `.5px solid ${Colors.cardBorder}`,
    color: Colors.cardBorder,
  },
  orText: {
    color: Colors.darkGrey,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    marginTop: "5px",
    textTransform: "uppercase",
  },
  selectText: {
    color: Colors.blackPearl,
    fontSize: "24px",
    letterSpacing: "0.25px",
    marginLeft: "10%",
    marginRight: "10%",
    textAlign: "center",
    width: "284px",
    wordBreak: "break-all",
  },
  titleWrapper: {
    alignItems: "center",
    color: Colors.sherpa,
    fontSize: "16px",
    lineHeight: "24px",
    marginTop: "20px",
    textAlign: "center",
    width: "95%",
  },
});

interface Props
  extends RouteComponentProps<
    {},
    {},
    {
      qualification: {
        readonly qualificationName: string;
      };
      groups: {
        readonly meritId: string;
        readonly meritName: string;
        readonly orgName: string;
      }[][];
    }
  > {}

const ViewQualifications = ({ location }: Props) => {
  const { Content } = Layout;
  const { goBack } = history;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="qualification-wrapper">
      <React.Fragment>
        <Content className={css(styles.contentWrapper)}>
          <Row type="flex" justify="space-around" align="middle" style={{ flexDirection: "column" }}>
            <Row>
              <div className={css(styles.headerFlex)}>
                <p className={css(styles.titleWrapper)} data-testid="viewQualificationMessage-ViewQualifications">
                  Required merits to qualify
                </p>
                <Icon
                  type="close"
                  className={css(styles.closeIcon)}
                  data-testid="closeButton-ViewQualifications"
                  onClick={() => goBack()}
                />
              </div>
              <p
                className={css(styles.selectText)}
                data-testid={`${location.state.qualification.qualificationName}-ViewQualifications`}
              >
                {location.state.qualification.qualificationName}
              </p>
              {location.state.groups.map((group: Array<Group>, index: number) => (
                <div>
                  <p className={css(styles.cardTitle)}>Requirement {index + 1}</p>
                  {group.map((subGroup: Group, index: number) => (
                    <div>
                      {index === 0 && (
                        <Card className={css(styles.cardWrapper)}>
                          <div className={css(styles.cardInnerWrapper)}>
                            <h3
                              className={css(styles.activityTitle)}
                              data-testid={`${subGroup.meritName}-ViewQualifications`}
                            >
                              {subGroup.meritName}
                            </h3>
                            <h4
                              className={css(styles.activityText)}
                              data-testid={`${subGroup.orgName}-ViewQualifications`}
                            >
                              {subGroup.orgName}
                            </h4>
                          </div>
                        </Card>
                      )}
                      {index === 1 && <h3 className={css(styles.orText)}>OR </h3>}
                      {index > 0 && (
                        <Card className={css(styles.cardWrapper)}>
                          <div className={css(styles.cardInnerWrapper)}>
                            <div>
                              <h3
                                className={css(styles.activityTitle)}
                                data-testid={`${subGroup.meritName}-ViewQualifications`}
                              >
                                {subGroup.meritName}
                              </h3>
                              <h4
                                className={css(styles.activityText)}
                                data-testid={`${subGroup.orgName}-ViewQualifications`}
                              >
                                {subGroup.orgName}
                              </h4>
                            </div>
                          </div>
                        </Card>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </Row>
          </Row>
        </Content>
      </React.Fragment>
    </div>
  );
};

export default withRouter(ViewQualifications);
