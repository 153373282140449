import { Button, Card, CardContent, createStyles, withStyles } from "@material-ui/core";
import React, { PureComponent } from "react";
import AddIcon from "@material-ui/icons/Add";
import { Some } from "../../utils/Some";
import close from "../../assets/Images/Icons/close-icon.svg";
import history from "../../history";

interface Props {
  readonly id: number;
  readonly name: string;
  readonly rectangle: string;
  readonly arrow: string;
  readonly btn1?: string;
  readonly btn2?: string;
  readonly btn3?: string;
  readonly btn1Text?: string;
  readonly btn2Text?: string;
  readonly btn3Text?: string;
  readonly colour: string;
  readonly btn1Title?: string;
  readonly btn2Title?: string;
  readonly btn3Title?: string;
  readonly btn1Link?: string;
  readonly btn2Link?: string;
  readonly btn3Link?: string;
  readonly btn1TargetTab?: number;
  readonly btn2TargetTab?: number;
  readonly btn3TargetTab?: number;
  readonly classes: {
    readonly card: string;
    readonly title: string;
    readonly cardDetails: string;
    readonly childCard: string;
    readonly parentBtn: string;
    readonly longArrow: string;
    readonly childBtn: string;
    readonly linkLeft: string;
    readonly childCardDetails: string;
    readonly cardTitle: string;
    readonly labelId: string;
  };
}

interface State {
  isPopoverOpen: boolean;
  marginTop: number;
  btnLink?: string;
  btnTitle?: string;
  cardText?: string;
  cardTitle?: string;
  btnTargetTab?: number;
}
class HomeCard extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      btnLink: "",
      btnTargetTab: undefined,
      btnTitle: "",
      cardText: "",
      cardTitle: "",
      isPopoverOpen: false,
      marginTop: 0,
    };
  }

  getButtonName = (link: string) => {
    switch (link) {
      case "/create-event":
        return "Create_Activity";
      case "/create-qualification":
        return "Add_Qualifications";
      case "/create-waiver":
        return "Add_Forms";
      case "/members": {
        return "See_Check_In_Log";
      }
      default:
        return "";
    }
  };

  handleNavigation = (link: string, btnTargetTab: number | undefined) => {
    if (Some(btnTargetTab)) {
      history.push({ pathname: link, state: { data: { activeTab: btnTargetTab } } });
    } else {
      history.push(link);
    }
  };

  render() {
    const {
      classes,
      name,
      rectangle,
      arrow,
      id,
      btn1,
      btn2,
      btn3,
      colour,
      btn1Text,
      btn2Text,
      btn3Text,
      btn1Title,
      btn2Title,
      btn3Title,
      btn1Link,
      btn2Link,
      btn3Link,
      btn1TargetTab,
      btn2TargetTab,
      btn3TargetTab,
    } = this.props;
    const { isPopoverOpen, marginTop, cardTitle, cardText, btnLink, btnTitle, btnTargetTab } = this.state;

    return (
      <div>
        <Card
          className={classes.card}
          style={{
            backgroundImage: `url(${rectangle})`,
            backgroundSize: "cover",
            boxShadow: "none",
            overflow: "hidden",
          }}
        >
          <CardContent className={classes.cardDetails} data-testid={`${name}-Step-HomeCard`}>
            <label style={{ color: colour }} className={classes.labelId}>
              {id}
            </label>
            {id !== 3 && <img className={classes.longArrow} src={arrow} />}
            <h5 className={classes.cardTitle} data-testid={`${name}-StepTitle-HomeCard`}>
              {name}
            </h5>

            {isPopoverOpen && (
              <Card className={classes.childCard} style={{ marginTop: marginTop }}>
                <div>
                  <img
                    alt=""
                    className="close-margin"
                    src={close}
                    onClick={() => this.setState({ isPopoverOpen: false })}
                  />{" "}
                </div>
                <CardContent className={classes.childCardDetails} data-testid={`${cardTitle}-Modal-HomeCard`}>
                  <h4
                    className="sectiontitle create-event-title create-event-close"
                    data-testid={`${cardTitle}-ModalTitle-HomeCard`}
                  >
                    {cardTitle}
                  </h4>
                  <p className="create-event-text" data-testid={`${cardTitle}-ModalDescription-HomeCard`}>
                    {cardText}
                  </p>
                  {cardTitle === "Download the app" && (
                    <div className={classes.linkLeft}>
                      <a data-testid="appDownloadLink-HomeCard" target="_new" href={btnLink}>
                        <img className="margin_top_20" src={btnTitle} />
                      </a>
                    </div>
                  )}
                  {cardTitle !== "Download the app" && btnTitle && (
                    <div>
                      <Button
                        color="primary"
                        className={classes.childBtn}
                        data-testid="modalButton-HomeCard"
                        onClick={() => btnLink && this.handleNavigation(btnLink, btnTargetTab)}
                        variant="contained"
                      >
                        {btnTitle}
                      </Button>
                    </div>
                  )}
                </CardContent>
              </Card>
            )}
            {btn1 && (
              <Button
                className={classes.parentBtn}
                data-testId={`${btn1}-Button-HomeCard`}
                style={{
                  backgroundColor: colour,
                }}
                variant="contained"
                onClick={() =>
                  this.setState({
                    btnLink: btn1Link,
                    btnTargetTab: btn1TargetTab,
                    btnTitle: btn1Title,
                    cardText: btn1Text,
                    cardTitle: btn1,
                    isPopoverOpen: !isPopoverOpen,
                    marginTop: 0,
                  })
                }
              >
                <AddIcon />
                {btn1}
              </Button>
            )}
            {btn2 && (
              <Button
                data-testId={`${btn2}-Button-HomeCard`}
                style={{
                  backgroundColor: colour,
                }}
                variant="contained"
                className={classes.parentBtn}
                onClick={() =>
                  this.setState({
                    btnLink: btn2Link,
                    btnTargetTab: btn2TargetTab,
                    btnTitle: btn2Title,
                    cardText: btn2Text,
                    cardTitle: btn2,
                    isPopoverOpen: !isPopoverOpen,
                    marginTop: 54,
                  })
                }
              >
                <AddIcon />
                {btn2}
              </Button>
            )}
            {btn3 && (
              <Button
                data-testId={`${btn3}-Button-HomeCard`}
                style={{
                  backgroundColor: colour,
                }}
                className={classes.parentBtn}
                variant="contained"
                onClick={() =>
                  this.setState({
                    btnLink: btn3Link,
                    btnTargetTab: btn3TargetTab,
                    btnTitle: btn3Title,
                    cardText: btn3Text,
                    cardTitle: btn3,
                    isPopoverOpen: !isPopoverOpen,
                    marginTop: 108,
                  })
                }
              >
                <AddIcon />
                {btn3}
              </Button>
            )}
          </CardContent>
        </Card>
      </div>
    );
  }
}
const styles = () =>
  createStyles({
    card: {
      background: "#f5f5f5",
      height: 320,
      width: 360,
      "z-index": 0,
    },
    cardDetails: {
      WebkitBoxOrient: "vertical",
      display: "-webkit-box",
      fontFamily: "Lato !important",
      fontSize: "16px",
      lineHeight: "24px",
      margin: "35px 19px",
      padding: 3,
      textOverflow: "ellipsis",
    },

    cardTitle: {
      fontFamily: "avant",
      fontWeight: "normal",
      marginBottom: "30px !important",
      marginTop: "30px !important",
      paddingLeft: 35,
    },
    childBtn: {
      justifyContent: "center",
      marginLeft: 35,
      marginTop: 15,
      position: "relative",
      width: "78%",
    },
    childCard: {
      background: "#FFFFFF",
      border: "1px solid #E5E5E5",
      borderRadius: "4px",
      boxSize: "border-box",
      marginLeft: "35px",
      position: "absolute",
      textAlign: "justify",
      width: 330,
      "z-index": 11,
    },
    childCardDetails: {
      WebkitBoxOrient: "vertical",
      display: "-webkit-box",
      fontFamily: "Lato !important",
      fontSize: "14px",
      lineHeight: "24px",
      margin: "2px 22px",
      padding: 3,
      textOverflow: "ellipsis",
    },
    labelId: {
      fontSize: 35,
      paddingLeft: 35,
    },
    linkLeft: {
      marginLeft: "60px",
    },
    longArrow: {
      float: "right",
      marginRight: 64,
      marginTop: "-25px",
      paddingTop: "30px",
    },
    parentBtn: {
      justifyContent: "left",
      marginBottom: "10px !important",
      marginLeft: 35,
      position: "relative",
      width: "86%",
    },
    title: {
      fontSize: 14,
    },
  });
export default withStyles(styles)(HomeCard);
