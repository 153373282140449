import * as validator from "validator";
class FormValidator {
  validations: any;
  constructor(validations: any) {
    this.validations = validations;
  }

  validate(state: any) {
    const validation: any = this.valid();
    this.validations.forEach(
      (rule: { field: string | number; args: never[]; method: string; validWhen: any; message: any }) => {
        if (!validation[rule.field].isInvalid) {
          const field_value = state[rule.field].toString();
          const args = rule.args || [];
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          const validation_method = typeof rule.method === "string" ? validator[rule.method] : rule.method;
          if (validation_method(field_value, ...args, state) !== rule.validWhen) {
            validation[rule.field] = { isInvalid: true, message: rule.message };
            validation.isValid = false;
          }
        }
      },
    );

    return validation;
  }

  valid() {
    const validation = {} as any;
    /* eslint no-return-assign: ["off"] */
    this.validations.map(
      (rule: { field: string | number }) => (validation[rule.field] = { isInvalid: false, message: "" }),
    );

    return { isValid: true, ...validation };
  }
}

export default FormValidator;
