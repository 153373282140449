/* eslint-disable no-unused-vars */
import { Button, WithStyles, createStyles, withStyles } from "@material-ui/core";
import { Dropdown, Menu } from "antd";
import { MoreHorizOutlined } from "@material-ui/icons";
import React from "react";

interface Props extends WithStyles<typeof styles> {
  automation: CheckOutAutomationWithEvent;
  setCurentCheckoutAutomationRow: (automation: CheckOutAutomationWithEvent) => void;
  setSelectedEventIds: (eventIds: number[]) => void;
  buttonsArr: { name: string; clickHandler: (automation?: CheckOutAutomationWithEvent, name?: string) => void }[];
}

const ActionPopUp = ({
  automation,
  classes,
  setCurentCheckoutAutomationRow,
  setSelectedEventIds,
  buttonsArr,
}: Props) => {
  const handleClickForBtn = () => {
    setCurentCheckoutAutomationRow(automation);
    setSelectedEventIds(automation.eventCheckOutAutomationMapping.map((auto) => auto.eventId));
  };

  return (
    <>
      <Dropdown
        overlayClassName="all_checkins_action_menu"
        trigger={["click"]}
        overlay={
          <Menu className={classes.list}>
            {buttonsArr.map(({ name, clickHandler }) => (
              <Menu.Item
                className="menu_item"
                onClick={() => clickHandler(automation, name)}
                data-testid={`${name}-ActionButton-ActionPopUp`}
              >
                {name}
              </Menu.Item>
            ))}
          </Menu>
        }
        placement="bottomCenter"
      >
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleClickForBtn}
          data-testid={`${automation.name}-ActionsButton-ActionPopUp`}
        >
          <MoreHorizOutlined />
        </Button>
      </Dropdown>
    </>
  );
};

const styles = () =>
  createStyles({
    list: {
      alignItems: "center",
      borderRadius: 4,
      display: "grid",
      height: 180,
      justifyContent: "center",
      paddingBottom: 8,
      paddingTop: 8,
      textAlign: "center",
      width: 134,
    },
  });

export default withStyles(styles)(ActionPopUp);
