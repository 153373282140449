import { Button, Modal } from "antd";
import React, { useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../../../theme/Colors";
import { None } from "../../../../utils/None";
import { TextField } from "@material-ui/core";

const styles = StyleSheet.create({
  buttonWrapper: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
    marginTop: "30px",
    width: "100%",
  },
  cancelButton: {
    borderColor: Colors.blue,
    borderWidth: "1px",
    color: Colors.blue,
    fontSize: "16px",
    height: "44px",
    letterSpacing: "1.25px",
    marginRight: "3%",
    width: "128px",
  },
  deleteButton: {
    backgroundColor: Colors.darkRed,
    borderColor: Colors.darkRed,
    borderWidth: "1px",
    color: Colors.white,
    fontSize: "16px",
    height: "44px",
    letterSpacing: "1.25px",
    marginLeft: "3%",
    width: "128px",
  },
  modalBody: {
    fontSize: "20px",
    height: "320px",
    marginTop: "50px",
    width: "fit-content",
  },
  root: {
    backgroundColor: "FFF",
    height: "auto",
    width: "400px",
  },
  textField: {
    marginLeft: "10%",
    marginTop: "3%",
    width: "80%",
  },
});

interface Props {
  readonly isVisible: boolean;
  readonly onClose: () => void;
  readonly onDelete: (notes: string | undefined) => void;
}

export const DeleteUnMatchedCheckoutModal = ({ isVisible, onClose, onDelete }: Props) => {
  const [notes, setNotes] = useState<string>();

  return (
    <Modal
      title="Are you sure?"
      visible={isVisible}
      onCancel={onClose}
      footer={false}
      wrapClassName="delete_modal_wrapper"
      className={css(styles.modalBody)}
    >
      <div className={css(styles.root)}>
        <h1 className="delete_header" style={{ paddingLeft: 20 }}>
          Delete offline checkout
        </h1>
        <TextField
          className={css(styles.textField)}
          onChange={(e) => {
            setNotes(e.target.value.trim());
          }}
          variant="outlined"
          label="Reason"
          placeholder="Why do you want to delete the log?"
        />
        <div className={css(styles.buttonWrapper)}>
          <Button className={css(styles.cancelButton)} type="link" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={None(notes) || notes === ""}
            className={css(styles.deleteButton)}
            type="primary"
            onClick={() => onDelete(notes)}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};
