/* eslint-disable no-irregular-whitespace*/
import { Button, Card, Col, Layout, Row } from "antd";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import CheckCircle from "../../assets/Images/Icons/check-circle.svg";
import { Colors } from "../../theme/Colors";
import Cookies from "js-cookie";
import { Fonts } from "../../theme/Fonts";
import LocationIcon from "../../assets/Images/Icons/location-icon.svg";
import React from "react";
import { getSelectedAddress } from "../../utils/GeoLocationHelper";
import { getUserFullName } from "../../utils/getUserFullName";
import history from "../../history";
import moment from "moment-timezone";
import { useAuth0 } from "@auth0/auth0-react";

const styles = StyleSheet.create({
  activityText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    fontWeight: 300,
    height: "50",
    letterSpacing: "0.15px",
    lineHeight: "24px",
    marginBottom: "5px",
    marginTop: "5px",
    textAlign: "left",
    wordBreak: "break-word",
  },
  activityTitle: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    fontWeight: "bold",
    height: "50",
    letterSpacing: "0.15px",
    lineHeight: "28px",
    marginBottom: "5px",
    marginTop: "5px",
    textAlign: "left",
    wordBreak: "break-word",
  },
  buttonWrapper: {
    background: Colors.checkoutButtonBg,
    border: `1px solid ${Colors.imageContainerBorder}`,
    borderRadius: "4px",
    color: Colors.sherpa,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    height: "50px",
    marginTop: "25px",
    textAlign: "center",
    textTransform: "uppercase",
    width: "300px",
  },
  cardInnerWrapper: {
    height: "auto",
    marginLeft: "2%",
    marginRight: "2%",
    width: "96%",
  },
  cardWrapper: {
    borderRadius: "4px",
    height: "auto",
    marginBottom: "8px",
    width: "350px",
  },
  checkOutButtonWrapper: {
    background: Colors.defaultButtonBg,
    border: `1px solid ${Colors.imageContainerBorder}`,
    borderRadius: "4px",
    color: Colors.white,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    height: "50px",
    marginTop: "25px",
    textAlign: "center",
    textTransform: "uppercase",
    width: "300px",
  },
  checkoutTime: {
    fontFamily: Fonts.roboto,
    fontSize: "64px",
    fontWeight: 500,
    lineHeight: "81px",
    textAlign: "center",
  },
  contentWrapper: {
    alignItems: "center",
    height: "auto",
    justifyContent: "center",
    minWidth: "375px",
  },
  flexWrapper: {
    textAlign: "left",
  },
  footerWrapper: {
    alignItems: "center",
    backgroundColor: Colors.white,
    bottom: 0,
    height: "100px",
    justifyContent: "center",
    marginTop: "20px",
    minWidth: "375px",
    position: "sticky",
    textAlign: "center",
  },
  imageWidth: {
    width: "44px",
  },
  imageWrapper: {
    alignItems: "center",
    marginTop: "20px",
    textAlign: "center",
  },
  line: {
    border: `.5px solid ${Colors.cardBorder}`,
    color: Colors.cardBorder,
  },
  locationIcon: {
    height: "16px",
    width: "16px",
  },
  locationText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "15px",
    lineHeight: "20px",
    marginLeft: "10px",
    marginTop: "2px",
    wordBreak: "break-word",
  },
  selectText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "34px",
    letterSpacing: "0.25px",
    lineHeight: "43px",
    textAlign: "center",
  },
});

interface LocationDetails {
  readonly relatedTo: "checkin" | "checkout";
  readonly deviceLatitude: string;
  readonly deviceLongitude: string;
  readonly deviceLocation: string;
  readonly locationType: "device" | "manual";
  readonly manualLatitude: string;
  readonly manualLongitude: string;
  readonly manualLocation: string;
  readonly remarks: string;
}
interface CheckOutDetails {
  readonly firstName: string;
  readonly lastName: string;
  readonly checkIn: string;
  readonly checkoutTime: string;
  readonly location?: LocationDetails;
  readonly locationEnabled: boolean;
  readonly timezone: string;
  readonly name: string;
}

interface Props extends RouteComponentProps<{}, {}, { checkOutDetails?: CheckOutDetails[] }> {}

const CheckOutSuccess = ({ location }: Props) => {
  const { Content } = Layout;
  const { logout, isAuthenticated } = useAuth0();

  const checkOutDetails =
    location.state?.checkOutDetails ?? JSON.parse(localStorage.getItem("checkOutDetails") ?? "{}");
  localStorage.setItem("checkOutDetails", JSON.stringify(checkOutDetails));

  const virtualKioskType = Cookies.get("virtualKioskType");
  const activitiesCount = localStorage.getItem("activitiesCount");
  const { push } = history;

  const handleDuration = (checkInTime: string, checkoutTime: string) => {
    const checkIn = moment(checkInTime);
    const checkOut = moment(checkoutTime);
    checkIn.set({ millisecond: 0, second: 0 });
    checkOut.set({ millisecond: 0, second: 0 });

    const mins = checkOut.diff(checkIn, "minutes");

    return `${mins} minutes`;
  };

  // eslint-disable-next-line react/no-multi-comp
  const handleFooterButton = (): React.ReactNode => {
    if (virtualKioskType === "1") {
      return (
        <div className={css(styles.footerWrapper)}>
          <Button
            className={css(styles.checkOutButtonWrapper)}
            data-testid="doneButton-CheckOutSuccess"
            onClick={() => {
              push("/web-checkin/home");
            }}
          >
            Done
          </Button>
        </div>
      );
    }

    if (virtualKioskType === "2") {
      if (activitiesCount && activitiesCount !== "1") {
        return (
          <div className={css(styles.footerWrapper)}>
            <Button
              className={css(styles.buttonWrapper)}
              data-testid="checkIntoActivityButton-CheckOutSuccess"
              onClick={() => {
                push("/web-checkin/selectactivity");
              }}
            >
              check in to activity
            </Button>
          </div>
        );
      }
    }

    return <div> </div>;
  };

  if (isAuthenticated) {
    logout({
      logoutParams: {
        returnTo: window.location.href,
      },
    });

    return null;
  }

  return (
    <React.Fragment>
      <Content>
        <div className={css(styles.contentWrapper)}>
          <Row type="flex" justify="space-around" align="middle">
            <Row className={css(styles.flexWrapper)} justify="space-around">
              <p className={css(styles.imageWrapper)}>
                <img className={css(styles.imageWidth)} src={CheckCircle}></img>
              </p>
              <h2 className={css(styles.selectText)} data-testid="checkedOutSuccessMessage-CheckOutSuccess">
                Checked out
              </h2>
              <h2 className={css(styles.checkoutTime)} data-testid="checkedOutTime-CheckOutSuccess">
                {checkOutDetails.length === 1
                  ? moment(checkOutDetails[0].checkoutTime).tz(checkOutDetails[0].timezone).format("h:mm A z")
                  : moment(checkOutDetails[0].checkoutTime).format("LT")}
              </h2>
              <Card className={css(styles.cardWrapper)}>
                <div className={css(styles.cardInnerWrapper)}>
                  <h3 className={css(styles.activityText)}>
                    <b className={css(styles.activityTitle)} data-testid="checkedOutUserName-CheckOutSuccess">
                      {getUserFullName(checkOutDetails[0].firstName, checkOutDetails[0].lastName)}
                    </b>
                    , you are checked out from
                  </h3>
                  {checkOutDetails.map((checkout: CheckOutDetails) => (
                    <div>
                      <hr className={css(styles.line)}></hr>
                      <h3
                        className={css(styles.activityTitle)}
                        data-testid={`${checkout.name}-CheckedOutActivity-CheckOutSuccess`}
                      >
                        {checkout.name}
                      </h3>

                      {checkout.locationEnabled && (
                        <Row>
                          <Col span={1}>
                            <img className={css(styles.locationIcon)} src={LocationIcon} />
                          </Col>
                          <Col span={23}>
                            <h2 className={css(styles.locationText)} data-testid="checkedOutLocation-CheckOutSuccess">
                              {getSelectedAddress(checkout.location)}
                            </h2>
                          </Col>
                        </Row>
                      )}
                      <h4 className={css(styles.activityText)}>
                        Check-in
                        <span data-testid="checkInDateTimeInformation-CheckOutSuccess" style={{ paddingLeft: "27px" }}>
                          {moment(checkout.checkIn).tz(checkout.timezone).format("MMM D, YYYY h:mm a z")}
                        </span>
                      </h4>
                      <h4 className={css(styles.activityText)}>
                        Check-out
                        <span data-testid="checkOutDateTimeInformation-CheckOutSuccess" style={{ paddingLeft: "15px" }}>
                          {moment(checkout.checkoutTime).tz(checkout.timezone).format("MMM D, YYYY h:mm a z")}
                        </span>
                      </h4>
                      <h4 className={css(styles.activityText)}>
                        Duration
                        <span data-testid="durationInformation-CheckOutSuccess" style={{ paddingLeft: "27px" }}>
                          {handleDuration(checkout.checkIn, checkout.checkoutTime)}
                        </span>
                      </h4>
                    </div>
                  ))}
                </div>
              </Card>
            </Row>
          </Row>
        </div>
      </Content>
      {handleFooterButton()}
    </React.Fragment>
  );
};

export default withRouter(CheckOutSuccess);
