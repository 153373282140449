import React, { PureComponent } from "react";
import AddPolicy from "../Modals/AddPolicy";
import { Button } from "@material-ui/core";
import { PoliciesTable } from "./PoliciesTable";

interface dataObject {
  name: string;
  val: any;
}

interface Props {
  readonly id: string;
  readonly handleChange: (data: dataObject) => void;
  readonly selectPolicyList: Policy[];
  readonly handleClickEvent: (v: boolean) => void;
  policies: Policy[];
  readonly policyCheck: boolean;
  isPolicyFound: boolean;
  error: string;
  placeholderForPolicy: string;
}

interface State {
  viewAddPolicy: boolean;
}

class EventPolicies extends PureComponent<Props, State> {
  submitted: boolean;
  constructor(props: Props) {
    super(props);
    this.state = {
      viewAddPolicy: false,
    };
    this.submitted = false;
  }
  hideShowPolicy = () => {
    const { viewAddPolicy } = this.state;
    this.setState({
      viewAddPolicy: !viewAddPolicy,
    });
  };

  render() {
    const { viewAddPolicy } = this.state;
    const { policies, selectPolicyList, handleChange, policyCheck } = this.props;

    return (
      <div className="paper_popup">
        <div className="grid grid_hr">
          <h4 className="marginbottom_0 margin_10">Add a policy</h4>
          <h6 className="message_subtitle">
            A policy validates that the person has the required merits. A person must pass all policy criteria in order
            to be able to check-in. More than one policy may be added. Policies can only be added to activities owned by
            the same Org.
          </h6>
          <div className="step2_button">
            <Button
              data-testid="addPolicyButton"
              variant="contained"
              color="secondary"
              style={{ float: "right", marginLeft: "2px" }}
              onClick={this.hideShowPolicy}
            >
              Add
            </Button>
          </div>
          {policyCheck && (
            <div className="marginbottom_10">
              <PoliciesTable
                policyList={selectPolicyList}
                handleChange={handleChange}
                selectPolicyList={selectPolicyList}
                isRemoveAllow
              />
            </div>
          )}
        </div>
        {viewAddPolicy && (
          <AddPolicy
            handleClose={this.hideShowPolicy}
            show={viewAddPolicy}
            policyList={policies}
            handleChange={handleChange}
            selectPolicyList={selectPolicyList}
          />
        )}
      </div>
    );
  }
}

export default EventPolicies;
