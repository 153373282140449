export const Colors = {
  backArrowShadow: "rgba(0, 0, 0, 0.24)",
  backgroundColor: "#f0f2f5",
  black: "#000000",
  blackPearl: "#012129",
  blue: "#147DF4",
  blueColumnText: "rgb(20, 125, 244, 0.85)",
  borderColor: "#BBD6FB",
  boxShadow: "0 0 8px rgba(0, 0, 0, 0.6)",
  cardBorder: "#E5E5E5",
  checkOutShadow: "#EBEBEB",
  checkinButtonBg: "#2D7CCC",
  checkoutButtonBg: "#F7F9F9",
  closeIcon: "#687FA3",
  cyanBlue: "#A6B8CC",
  darkBlack: "#02081B",
  darkBlue: "#286DB3",
  darkGrey: "#9E9E9E",
  darkRed: "#F04A4E",
  defaultButtonBg: "#2D7CCC",
  gray: "#F7F9F9",
  green: "#01B27E",
  gulfBlue: "#33415C",
  headerText: "rgba(0, 0, 0, 0.87)",
  imageContainerBorder: "#B7C8CC",
  inActiveGray: "#D7D5D5",
  inputBGColor: "#F7FAFF",
  lightBlue: "#0D64DA",
  lightGray: "#56657F",
  lightWhite: "#F5F6F8",
  lineBackground: "rgba(0, 0, 0, 0.54)",
  mediumBlack: "rgba(0, 0, 0, 0.6)",
  searchbarColor: "#CFE4FD",
  sherpa: "#003D4C",
  solitude: "#E5EEF9",
  textfieldColor: "rgba(0, 0, 0, 0.04)",
  virtualkioskIcon: "#687FA3",
  white: "#fff",
  yellow: "#FFC300",
};
