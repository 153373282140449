import {
  Button,
  Checkbox,
  Grid,
  GridList,
  GridListTile,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React, { PureComponent } from "react";
import CloseIcon from "@material-ui/icons/Close";
import LoadingScreen from "../../../UI-Components/LoadingScreen";
import Select from "react-select";
import { ShowEventAttendeeDetails } from "../ShowEventAttendeeDetails";
import { getAllMeritTemplates } from "../../../../constant/api";
import { sendMerits } from "../../../../constant/api";

const styles = (theme: { spacing: { unit: number }; palette: { background: { paper: any } }; shadows: any[] }) =>
  createStyles({
    backdrop: {
      background: "linear-gradient(67.73deg, #0E69F1 1.08%, #20A2F9 100%);",
      backgroundColor: "black",
      opacity: 0.9,
    },
    button: {
      margin: theme.spacing.unit,
    },
    cssLabel: {
      color: "#687FA3",
    },
    input: {
      height: 7,
    },
    notchedOutline: {
      borderColor: "#687FA3 !important",
      borderWidth: "1px",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "6px",
      boxShadow: theme.shadows[5],
      left: "50%",
      maxHeight: "calc(100vh - 230px)",
      overflowY: "auto",
      padding: theme.spacing.unit * 2,
      position: "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: theme.spacing.unit * 50,
    },
    root: {
      height: "200px",
      marginTop: "60px",
      overflowX: "hidden",
      overflowY: "auto",
      width: "100%",
    },
    table: {
      minWidth: 350,
    },
  });
const customStyles = {
  menuList: (provided: any) => ({
    ...provided,
    fontFamily: "Lato",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "black",
    fontFamily: "Lato",
  }),
};
interface Props {
  readonly show: boolean;
  readonly notify: (status: string, message: string) => void;
  readonly handleClose: () => void;
  readonly classes: {
    readonly paper: string;
    readonly button: string;
    readonly textField: string;
    readonly backdrop: string;
    readonly cssFocused: string;
    readonly cssLabel: string;
    readonly notchedOutline: string;
    readonly root: string;
    readonly table: string;
    readonly input: string;
  };
  // readonly selectedRow: {
  //   readonly firstName: string,
  //   readonly lastName: string,
  //   readonly email: string,
  // },
  selectedAttendees: any;
  meritTemplates: { data: MeritTemplate[] };
  getMeritTemplate: (res: any) => void;
}

interface State {
  selectedEvent: { id?: string };
  meritTemplates: any;
  meritTemplatesData: any;
  showFields: boolean;
  fields: any;
  error: string;
  checkedB: boolean;
  isLoader: boolean;
  statusText: string;
  color: string;
  selectedAttendees: any;
  hasNext: boolean;
  nextPage: string;
  isLoading: boolean;
}

class SendMerits extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { meritTemplates, selectedAttendees } = this.props;
    this.state = {
      checkedB: false,
      color: "",
      error: "",
      fields: {},
      hasNext: false,
      isLoader: meritTemplates === undefined,
      isLoading: false,
      meritTemplates: [],
      meritTemplatesData: [],
      nextPage: "",
      selectedAttendees: selectedAttendees,
      selectedEvent: {},
      showFields: false,
      statusText: "",
    };
  }
  componentDidMount() {
    this.getMeritTemplates();
  }
  getMeritTemplates = async () => {
    const { getMeritTemplate } = this.props;
    const { nextPage } = this.state;
    const response = await getAllMeritTemplates(nextPage);

    if (response) {
      const res = response.data.templateData.map((item) => {
        return { label: item.title, value: item.id };
      });
      this.setState({
        hasNext: response.data.pagingInfo.hasNextPage,
        isLoader: false,
        meritTemplates: res,
        meritTemplatesData: response.data.templateData,
        nextPage: response.data.pagingInfo.after,
      });
    }
    getMeritTemplate(response);
  };

  handleScroll = async () => {
    const { hasNext, nextPage, meritTemplates, meritTemplatesData } = this.state;
    if (hasNext) {
      this.setState({ isLoading: true });
      const response = await getAllMeritTemplates(nextPage);
      const res = response.data.templateData.map((item) => {
        return { label: item.title, value: item.id };
      });
      const meritTemplatesArray = [...meritTemplates, ...res];
      const meritTemplatesDataArray = [...meritTemplatesData, ...response.data.templateData];
      meritTemplatesArray.sort((meritTemplatesA: MeritTemplates$Response, meritTemplatesB: MeritTemplates$Response) =>
        meritTemplatesA.label.toLowerCase() > meritTemplatesB.label.toLowerCase() ? 1 : -1,
      );
      this.setState({
        hasNext: response.data.pagingInfo.hasNextPage,
        isLoader: false,
        isLoading: false,
        meritTemplates: meritTemplatesArray,
        meritTemplatesData: meritTemplatesDataArray,
        nextPage: response.data.pagingInfo.after,
      });
    }
  };
  handleChange = (e: any, field: any) => {
    e.preventDefault();
    const { fields } = this.state;
    if (field.fieldType === "LongText") {
      if (e.target.value.length > 1000) {
        this.setState({ error: `Please enter only 1000 characters in ${field.fieldName}` });
      } else {
        fields[e.target.id] = { required: field.required, type: field.fieldType, value: e.target.value };
        this.setState({ error: "", fields: fields });
      }
    } else if (field.fieldType === "ShortText") {
      if (e.target.value.length > 60) {
        this.setState({ error: `Please enter only 60 characters in ${field.fieldName}` });
        e.preventDefault();
      } else {
        fields[e.target.id] = { required: field.required, type: field.fieldType, value: e.target.value };
        this.setState({ error: "", fields: fields });
      }
    } else {
      fields[e.target.id] = { required: field.required, type: field.fieldType, value: e.target.value };
      this.setState({ fields: fields });
    }
  };
  handleChangeCheckbox = (
    e: { target: { id: string | number; checked: any } },
    field: { required: any; fieldType: any },
  ) => {
    const { fields } = this.state;
    fields[e.target.id] = { required: field.required, type: field.fieldType, value: e.target.checked };
    this.setState({ fields: fields });
  };
  fieldFullName = (id: string | number, type: any, required: any) => {
    const { fields } = this.state;
    fields[id] = { required: required, type: type, value: "" };
    this.setState({ fields: fields });
  };
  addFields = (id: string | number, type: string, required: any) => {
    const { fields } = this.state;
    if (fields[id] === undefined) {
      if (type === "Checkbox") {
        fields[id] = { required: required, type: type, value: false };
      } else {
        fields[id] = { required: required, type: type, value: "" };
      }
      this.setState({ fields: fields });
    }
  };

  selectEvent = (data: any) => {
    const selectedData = {
      meritName: data.label,
      name: "send_merits",
      val: {
        id: data.value,
        name: data.label,
      },
    };
    if (this.checkRequiredFields(selectedData)) {
      this.setState({
        error: "",
        fields: {},
        selectedEvent: selectedData.val,
        showFields: true,
        statusText: "",
      });
    } else {
      this.setState({
        error: "You can't send this merit.",
        selectedEvent: {},
        showFields: false,
      });
    }
  };
  checkRequiredFields = (data: { val: { id: any } }) => {
    const { meritTemplatesData } = this.state;
    const selectedEvent = data.val.id;
    const selectedTemplateData: any = meritTemplatesData.find((item: { id: any }) => {
      return item.id === selectedEvent;
    });
    let requiredFields = selectedTemplateData.field.filter((item: { required: any; fieldType: string }) => {
      if (item.required) {
        if (!["FullName", "ShortText", "LongText", "Date"].includes(item.fieldType)) {
          return item;
        }
      }

      return null;
    });
    requiredFields = requiredFields.filter((_: any) => _ !== null);

    if (requiredFields.length !== 0) {
      return false;
    }

    return true;
  };

  fieldValidations = () => {
    const { fields: fieldObj } = this.state;
    let fields: any = Object.keys(fieldObj).filter((field) => {
      if (fieldObj[field].required && fieldObj[field].type !== "FullName" && fieldObj[field].type !== "Checkbox") {
        if (fieldObj[field].value === "") {
          return fieldObj[field];
        }
      }

      return null;
    });
    fields = fields.filter((_: any) => _ !== null);

    if (fields.length !== 0) {
      return false;
    }

    return true;
  };

  sendMerits = async () => {
    if (this.fieldValidations()) {
      const { selectedAttendees } = this.props;
      const attendees = selectedAttendees.map(
        (item: { email: any; eventAttendeeId: any; firstName: any; lastName: any; sigmaMemberId: any }) => {
          const obj = {
            email: item.email,
            eventAttendeeId: item.eventAttendeeId,
            firstName: item.firstName,
            lastName: item.lastName,
            sigmaMemberId: item.sigmaMemberId,
          };

          return obj;
        },
      );
      const { selectedEvent, fields } = this.state;
      const params = {
        eventAttendeeDetails: attendees,
        fields: fields,
        meritTemplateId: selectedEvent.id,
      };
      const response = await sendMerits(params);
      const failedMerits: any = [];
      response.data.map((data: { status: string; eventAttendeeId: any }) => {
        if (data.status === "failed") {
          failedMerits.push(data.eventAttendeeId);
        }

        return data;
      });

      const failedAttendees = attendees.filter((attendee: { eventAttendeeId: any }) =>
        failedMerits.includes(attendee.eventAttendeeId),
      );
      if (failedAttendees.length === 0) {
        this.setState({
          error: "",
        });
        const { notify, handleClose } = this.props;
        notify("Merit sent successfully.", "success");
        handleClose();
      } else {
        const statusText =
          failedAttendees.length > 1
            ? "The remaining individuals already have this merit"
            : "This person already has this merit";
        this.setState({
          color: "red",
          error: "",
          selectedAttendees: failedAttendees,
          statusText: statusText,
        });
      }
    } else {
      this.setState({
        error: " Please fill the required fields.",
      });
    }
  };
  selectMerit = () => {
    const { classes } = this.props;
    const { meritTemplatesData, selectedEvent: SEvent } = this.state;
    const state: any = this.state;
    const selectedEvent = SEvent.id;
    const selectedTemplateData: any = meritTemplatesData.find((item: { id: string | undefined }) => {
      return item.id === selectedEvent;
    });

    const finalFields = selectedTemplateData.field.map(
      (field: { fieldType: any; fieldName?: any; required: any; fieldId?: any }) => {
        const id = selectedTemplateData.field.indexOf(field);
        if (field.fieldType === "FullName") {
          this.fieldFullName(field.fieldId, field.fieldType, field.required);
        } else if (field.fieldType === "ShortText" || field.fieldType === "LongText") {
          this.addFields(field.fieldId, field.fieldType, field.required);
          let max;
          if (field.fieldType === "LongText") {
            max = "1001";
          } else {
            max = "61";
          }

          return (
            <GridListTile cols={1} key={id}>
              <TextField
                className="send_merit_textfield"
                id={field.fieldId}
                placeholder={field.required ? `*  ${field.fieldName}` : field.fieldName}
                value={state[field.fieldId]}
                variant="outlined"
                margin="normal"
                onChange={(e) => this.handleChange(e, field)}
                InputProps={{
                  classes: {
                    input: classes.input,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                inputProps={{
                  maxLength: max,
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                  },
                }}
              />
            </GridListTile>
          );
        } else if (field.fieldType === "Date") {
          this.addFields(field.fieldId, field.fieldType, field.required);

          return (
            <GridListTile cols={1} key={id}>
              {field.required && <label style={{ color: "red" }}>*</label>}
              <TextField
                id={field.fieldId}
                label={field.fieldName}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={state[field.fieldId]}
                onChange={(e) => this.handleChange(e, field)}
              />
            </GridListTile>
          );
        } else if (field.fieldType === "Checkbox") {
          this.addFields(field.fieldId, field.fieldType, field.required);

          return (
            <GridListTile cols={1} key={id}>
              <label
                style={{
                  color: "#33415C",
                  fontFamily: "Lato",
                  fontSize: "14px",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  marginLeft: "3px",
                }}
              >
                {field.fieldName}
              </label>
              <Checkbox
                id={field.fieldId}
                checked={state[field.fieldId]}
                onChange={(e) => this.handleChangeCheckbox(e, field)}
                value={state[field.fieldId]}
                color="primary"
              />
            </GridListTile>
          );
        }

        return null;
      },
    );

    return finalFields;
  };
  render() {
    const { classes, show, handleClose } = this.props;
    const {
      selectedAttendees: rows,
      isLoader,
      color,
      statusText,
      error,
      meritTemplates,
      showFields,
      isLoading,
    } = this.state;
    const formatOptionLabel = (data: { label: string }) => (
      <div data-testid={`${data.label}-OptionLabel`}>{data.label}</div>
    );

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        disableBackdropClick
        open={show}
        onClose={handleClose}
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
      >
        {isLoader ? (
          <div className={classes.paper}>
            <LoadingScreen minHeight="calc(100vh - 125px)" />
          </div>
        ) : (
          <div className={classes.paper} data-testid="activity-SendMeritsModal">
            <Grid container>
              <Grid item>
                <CloseIcon onClick={handleClose} className="close_icon" />
              </Grid>
              <Grid item xs={12}>
                <h4 className="modal-title" data-testid="sendMeritHeaderTitle-SendMeritsModal">
                  Send Merit
                </h4>
                <h6 className="modal_text" data-testid="sendMeritHeaderDesc-SendMeritsModal">
                  Send Merits to the selected people
                </h6>
                <span
                  style={{
                    color: color,
                  }}
                  className="status-text"
                >
                  {statusText}
                </span>
                <span
                  style={{
                    color: "red",
                  }}
                  className="status-text"
                >
                  {error}
                </span>
                <div data-testid="meritTemplateField-SendMeritsModal">
                  <Select
                    options={meritTemplates}
                    placeholder="Choose Merit Template"
                    onChange={(data) => this.selectEvent(data)}
                    onMenuScrollToBottom={this.handleScroll}
                    isSearchable={false}
                    maxMenuHeight={230}
                    isLoading={isLoading}
                    styles={customStyles}
                    formatOptionLabel={formatOptionLabel}
                  />
                </div>
              </Grid>

              {showFields && (
                <GridList cellHeight={60} cols={1} className="modal-title">
                  {this.selectMerit()}
                </GridList>
              )}
            </Grid>
            <Button
              variant="contained"
              color="primary"
              className="send_merit"
              onClick={this.sendMerits}
              data-testid="sendMeritsButton-SendMeritsModal"
            >
              Send Merits
            </Button>
            {rows.length !== 0 && (
              <Paper className={classes.root}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell className="record_data">Full Name</TableCell>
                      <TableCell className="record_data">Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map(({ eventAttendeeId, firstName, lastName, email }: EventAttendee) => (
                      <ShowEventAttendeeDetails
                        eventAttendeeId={eventAttendeeId}
                        firstName={firstName}
                        lastName={lastName}
                        email={email}
                      />
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            )}
          </div>
        )}
      </Modal>
    );
  }
}

export default withStyles(styles)(SendMerits);
