import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { StyleSheet, css } from "aphrodite";
import Cookies from "js-cookie";
import MeritIcon from "../assets/Images/Icons/merit_icon.svg";
import { Typography } from "@material-ui/core";
import { logOut } from "../constant/api";
import { useLogout } from "../hooks/auth";

const styles = StyleSheet.create({
  button: {
    backgroundColor: "transparent",
    border: 0,
    color: "#1890ff",
    cursor: "pointer",
    outline: "none",
    textDecoration: "none",
  },
  meritLogo: {
    marginBottom: 15,
    width: 80,
  },
  noPaddingRight: {
    paddingRight: 0,
  },
  row: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 50,
    textAlign: "center",
  },
});

export function Error500Page() {
  const logoutAuth0 = useLogout();

  const returnCheckin = async () => {
    Cookies.remove("auth");
    await logOut();
    logoutAuth0();
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      returnCheckin();
    }, 10000); // Redirect after 10 seconds

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Row className={css(styles.row)}>
      <Col>
        <img alt="Merit logo" className={css(styles.meritLogo)} src={MeritIcon}></img>
        <Typography gutterBottom variant="h3">
          Something went wrong
        </Typography>
        <Typography gutterBottom variant="subtitle1">
          Looks like something isn’t quite right. You will be redirected to
          <button className={css(styles.button)} onClick={returnCheckin} type="button">
            {window.location.hostname}
          </button>
          in 10 seconds, or click
          <button className={css(styles.button, styles.noPaddingRight)} onClick={returnCheckin} type="button">
            here
          </button>
          .
        </Typography>
      </Col>
    </Row>
  );
}
