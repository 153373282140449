import { Dropdown, Menu, Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { approveMatchedCheckout, getMatchedCheckouts, rejectMatchedCheckout } from "../../../constant/api";
import action from "../../../assets/Images/Icons/action.svg";

type State = {
  readonly isLoading: boolean;
  readonly matchedCheckouts: MatchOfflineCheckoutType[];
};

type TableColumns = {
  readonly dataIndex: string;
  readonly title: string;
  readonly render?: (text: string, event: MatchOfflineCheckoutType) => React.ReactNode;
  readonly className: string;
};

interface Props {
  readonly notify: (message: string, status: string) => void;
  readonly sessionId: string;
}

export const MatchesTable = ({ notify, sessionId }: Props) => {
  const [tableState, setTableState] = useState<State>({
    isLoading: false,
    matchedCheckouts: [],
  });

  const getMatches = useCallback(async () => {
    try {
      setTableState((prev) => ({
        ...prev,
        isLoading: true,
      }));

      const sessionsData = await getMatchedCheckouts(sessionId);

      setTableState({
        isLoading: false,
        matchedCheckouts: sessionsData.data.item,
      });
    } catch (error) {
      setTableState((prev) => ({
        ...prev,
        isLoading: false,
      }));
      notify("Error in fetching the matched offline checkout", "error");
    }
  }, []);

  useEffect(() => {
    getMatches();
  }, [getMatches]);

  const approveMatch = async (sessionId: string, matchSessionData: MatchOfflineCheckoutType) => {
    try {
      const { checkoutSessionLogId, eventAttendeeId } = matchSessionData;
      await approveMatchedCheckout(sessionId, { checkoutSessionLogId, eventAttendeeId });
      await getMatches();
    } catch (error) {
      notify("Error in approving the matched checkin checkouts", "error");
    }
  };

  const rejectMatch = async (sessionId: string, checkoutSessionLogId: string) => {
    try {
      await rejectMatchedCheckout(sessionId, { checkoutSessionLogId });
      await getMatches();
    } catch (error) {
      notify("Error in rejecting the matched checkin checkouts", "error");
    }
  };

  const columns: Array<TableColumns> = [
    {
      className: "checkin-table-header",
      dataIndex: "firstName",
      title: "First Name",
    },
    {
      className: "checkin-table-header",
      dataIndex: "lastName",
      title: "Last Name",
    },
    {
      className: "checkin-table-header",
      dataIndex: "email",
      title: "Email",
    },
    {
      className: "checkin-table-header",
      dataIndex: "activityName",
      title: "Activity Name",
    },
    {
      className: "checkin-table-header",
      dataIndex: "checkedIn",
      title: "Checked In",
    },
    {
      className: "checkin-table-header",
      dataIndex: "checkedOut",
      title: "Checked Out",
    },
    {
      className: "checkin-table-header",
      dataIndex: "menu-action",
      // eslint-disable-next-line react/no-multi-comp
      render: (_, matchSessionData) => {
        return (
          <Dropdown
            overlayClassName="all_offline_action_menu"
            trigger={["click"]}
            overlay={
              <Menu>
                {["Approve", "Reject"].map((option) => {
                  return (
                    <Menu.Item
                      key={option}
                      onClick={() => {
                        if (option === "Approve") {
                          approveMatch(sessionId, matchSessionData);
                        } else if (option === "Reject") {
                          rejectMatch(sessionId, matchSessionData.checkoutSessionLogId);
                        }
                      }}
                      className="menu_item"
                    >
                      <span style={{ fontSize: "16px" }}>{option}</span>
                    </Menu.Item>
                  );
                })}
              </Menu>
            }
            placement="bottomLeft"
          >
            <img alt="" src={action} className="checkinlog_cursor_pointer" />
          </Dropdown>
        );
      },
      title: "",
    },
  ];

  return (
    <Table
      loading={tableState.isLoading}
      dataSource={tableState.matchedCheckouts}
      columns={columns}
      rowKey={(_, index) => `${index}+${_.email}`}
    />
  );
};
