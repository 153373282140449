// eslint-disable-next-line no-unused-vars
import { DataSource, SelectedRowKeys, TableColumn } from "../../../UI-Components/EnhancedTable/type";
// eslint-disable-next-line no-unused-vars
import { Grid, Modal, WithStyles, createStyles, withStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import EnhancedTable from "../../../UI-Components/EnhancedTable";
import React from "react";

type Props = WithStyles<typeof chooseMeritStyle> & {
  isShowModal: boolean;
  showHideChooseAutomationModal: () => void;
  readonly checkOutAutomationList: CheckOutAutomation[];
  selectedAutomationIds: number[];
  handleSelectAutomation: (value: number[]) => void;
};

export const DEFAULT_ROWS_PER_PAGE_OPTIONS = [10, 20, 40];

const AddCheckOutAutomation: React.FC<Props> = ({
  isShowModal,
  showHideChooseAutomationModal,
  classes,
  checkOutAutomationList,
  handleSelectAutomation,
  selectedAutomationIds,
}: Props) => {
  const [checkoutAutomation, setCheckoutAutomation] = useState<DataSource[]>([]);
  const columns: TableColumn[] = [
    { key: "name", label: "Name" },
    { key: "description", label: "Description" },
  ];
  const getFormattedData = (automationList: CheckOutAutomation[]) => {
    const formattedMeritList = automationList.map((e) => ({
      description: e.description ?? "",
      key: e.checkOutAutomationId,
      name: e.name,
    }));

    return formattedMeritList;
  };

  useEffect(() => {
    const checkOutAutomationOnPage = checkOutAutomationList.slice(0, DEFAULT_ROWS_PER_PAGE_OPTIONS[0]);
    setCheckoutAutomation(getFormattedData(checkOutAutomationOnPage));
  }, [checkOutAutomationList]);

  const onChangePageOrRows = (page: number, rowsPerPage: number) => {
    const dataonPage = checkOutAutomationList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    setCheckoutAutomation(getFormattedData(dataonPage));
  };

  const onSelect = (selectedRows: SelectedRowKeys[]) => {
    handleSelectAutomation(selectedRows as number[]);
  };

  return (
    <Modal
      aria-labelledby="add checkout automation modal"
      aria-describedby="modal use to select one or multiple checkout automation"
      disableBackdropClick
      open={isShowModal}
      onClose={showHideChooseAutomationModal}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
    >
      <div className={classes.paper}>
        <Grid container>
          <div style={{ position: "absolute", right: 4, top: 7, zIndex: 2 }}>
            <CloseIcon
              onClick={showHideChooseAutomationModal}
              className="close_icon"
              data-testid="closeIcon-AddCheckOutAutomation"
            />
          </div>
          <EnhancedTable
            paginationProps={{
              onChangePageOrRows: onChangePageOrRows,
              rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
              totalDatalength: checkOutAutomationList.length,
            }}
            tableContentHeight="calc(100vh - 300px)"
            columns={columns}
            title="Select checkout automation"
            idColumn="key"
            dataSource={checkoutAutomation}
            allowToSelectRow
            onSelect={onSelect}
            selectedRowKeys={selectedAutomationIds}
            dataTestIdKey="name"
          />
        </Grid>
      </div>
    </Modal>
  );
};

const chooseMeritStyle = (theme: any) =>
  createStyles({
    backdrop: {
      background: "linear-gradient(67.73deg, #0E69F1 1.08%, #20A2F9 100%);",
      backgroundColor: "black",
      opacity: 0.9,
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "6px",
      boxShadow: theme.shadows[5],
      height: "calc(100vh - 215px)",
      left: "50%",
      position: "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: theme.spacing.unit * 120,
    },
  });

export default withStyles(chooseMeritStyle)(AddCheckOutAutomation);
