// eslint-disable-next-line no-unused-vars
import { AppState, useStore } from "../../../store/store";
import { Grid, Hidden, Typography, createStyles, withStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { LoginButton } from "../../UI-Components/LoginButton";
import { SelectOrgModal } from "../../SelectOrgModal";
import { Some } from "../../../utils/Some";
import { TermsOfServiceModal } from "../../TermsOfServiceModal";
import history from "../../../history";
import loginImage from "../../../assets/Images/login/link-with-sigma.svg";
import { useAuth0 } from "@auth0/auth0-react";

interface Props {
  readonly classes: {
    readonly button: string;
    readonly login: string;
    readonly buttonImage: string;
    readonly loginContainer: string;
    readonly loginPara: string;
    readonly loginImage: string;
  };
}

const landingPagePathSelector = (state: AppState) => state.landingPagePath;
const intermediateScreenSelector = (state: AppState) => state.intermediateScreen;

const Login = (props: Props) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const landingPagePath = useStore(landingPagePathSelector);
  const intermediateScreen = useStore(intermediateScreenSelector);

  useEffect(() => {
    async function getAccessToken() {
      const accessToken = await getAccessTokenSilently();
      if (accessToken && Some(landingPagePath)) {
        history.push(landingPagePath);
      }
    }
    if (isAuthenticated) {
      getAccessToken();
    }
  }, [landingPagePath, isAuthenticated]);

  const { classes } = props;

  if (isAuthenticated && intermediateScreen === "termsOfService") {
    return <TermsOfServiceModal />;
  }

  if (isAuthenticated && intermediateScreen === "selectOrg") {
    return <SelectOrgModal />;
  }

  return (
    <div className={`${classes.login} primary-background`}>
      <Grid container spacing={0} style={{ marginTop: "100px" }}>
        <Grid item xs={12} sm={6} className={classes.loginContainer}>
          <Typography className="text-white login-header" gutterBottom variant="h3">
            Welcome to Merit Check-in
          </Typography>
          <Typography className={`text-white ${classes.loginPara}`} gutterBottom variant="subtitle1">
            Create and customize activities with policies for customer check-in
          </Typography>
          <LoginButton />
        </Grid>
        <Grid item sm={6} className="image_container">
          <Hidden xsDown>
            <img className={classes.loginImage} src={loginImage} alt="Login" />
          </Hidden>
        </Grid>
      </Grid>
    </div>
  );
};

const styles = () =>
  createStyles({
    button: {
      "&:hover": {
        backgroundColor: "#b1ceff",
        color: "#0e69f1",
      },
      color: "#0B56BC",
      fontSize: "14px",
      fontWeight: 700,
      letterSpacing: "0.01em",
      marginTop: "30px",
      paddingBottom: "9px",
      paddingTop: "9px",
    },
    buttonImage: {
      marginRight: "10px",
      maxHeight: "18px",
    },
    login: {
      display: "flex",
      minHeight: "100vh",
    },
    loginContainer: {
      padding: "0 55px",
      textAlign: "left",
      width: "80%",
    },
    loginImage: {
      maxHeight: "90%",
      maxWidth: "90%",
      mixBlendMode: "luminosity",
    },
    loginPara: {
      width: "80%",
    },
  });

export default withStyles(styles)(Login);
