import { Button, Grid, Modal, TextField, createStyles, withStyles } from "@material-ui/core";
import React, { PureComponent } from "react";
// eslint-disable-next-line no-unused-vars
import { WithErrorHandler, WithErrorHandlerProps } from "../../../WithErrorHandler";
import { checkIn, getAllActivities } from "../../../../constant/api";
import CloseIcon from "@material-ui/icons/Close";
import FormValidator from "../../../../constant/validation";
import Select from "react-select";

interface Props extends WithErrorHandlerProps {
  readonly notify: (arg1: string, arg2: string) => void;
  readonly show: boolean;
  readonly handleClose: () => void;
  readonly classes: {
    readonly paper: string;
    readonly button: string;
    readonly backdrop: string;
    readonly notchedOutline: string;
    readonly cssLabel: string;
    readonly cssFocused: string;
    readonly activityModelCenter: string;
  };
  readonly getAllCheckins: (number: number) => void;
}

interface validations {
  isValid: boolean;
  firstName: {
    isInvalid: boolean;
    message: string;
  };
  lastName: {
    isInvalid: boolean;
    message: string;
  };
  email: {
    isInvalid: boolean;
    message: string;
  };
  selectedEvent: {
    isInvalid: boolean;
    message: string;
  };
}

interface OptionEvent extends EventList {
  label: string;
}

interface State {
  reload: boolean;
  firstName: string;
  lastName: string;
  email: string;
  showFirstNameError: boolean;
  showLastNameError: boolean;
  name: string;
  selectedEvent: any;
  events: OptionEvent[];
  validation: {
    isValid: boolean;
    firstName: {
      isInvalid: boolean;
      message: string;
    };
    lastName: {
      isInvalid: boolean;
      message: string;
    };
    email: {
      isInvalid: boolean;
      message: string;
    };
    selectedEvent: {
      isInvalid: boolean;
      message: string;
    };
  };
  isEventFound: boolean;
  [key: string]: any;
}

const customStyles = {
  menuList: (provided: any) => ({
    ...provided,
    fontFamily: "Lato",
  }),
};

interface option {
  data: {
    name: string;
  };
}

class CheckIn extends PureComponent<Props, State> {
  readonly validator: {
    validate: (state: State) => validations;
    valid: () => validations;
  };
  submitted: boolean;
  constructor(props: Props) {
    super(props);
    this.submitted = false;
    this.validator = new FormValidator([
      {
        field: "firstName",
        message: "Please enter first name.",
        method: "isEmpty",
        validWhen: false,
      },
      {
        field: "lastName",
        message: "Please enter last name.",
        method: "isEmpty",
        validWhen: false,
      },
      {
        field: "email",
        message: "Please enter email Id.",
        method: "isEmpty",
        validWhen: false,
      },
      {
        field: "email",
        message: "That is not a valid email.",
        method: "isEmail",
        validWhen: true,
      },
      {
        field: "selectedEvent",
        message: "Please select activity.",
        method: "isEmpty",
        validWhen: false,
      },
    ]);
    this.state = {
      email: "",
      events: [],
      firstName: "",
      isEventFound: true,
      lastName: "",
      name: "",
      reload: false,
      selectedEvent: "",
      showFirstNameError: false,
      showLastNameError: false,
      validation: this.validator.valid(),
    };

    this.getEvents();
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "firstName") {
      if (value.length > 25) {
        event.preventDefault();
        this.setState({
          showFirstNameError: true,
        });

        return;
      }
    }
    if (name === "lastName") {
      if (value.length > 40) {
        event.preventDefault();
        this.setState({
          showLastNameError: true,
        });

        return;
      }
    }

    this.setState({
      [name]: value,
      showFirstNameError: false,
      showLastNameError: false,
    });
  };

  selectEvent = (value: any) => {
    if (value === null) {
      this.setState({ selectedEvent: "" });
    } else {
      this.setState({ selectedEvent: value });
    }
  };

  getEvents = async () => {
    try {
      const publishedEvents = await getAllActivities().then((_) =>
        _.data.item.filter((_) => _.publishStatus && !_.isDraft),
      );

      if (publishedEvents.length === 0) {
        this.setState({
          events: [],
          isEventFound: false,
        });
      } else {
        const res: OptionEvent[] = publishedEvents.map((item) => {
          const itemVal = item;
          itemVal.label = item.name;
          itemVal.value = item.eventId;

          return itemVal as OptionEvent;
        });
        this.setState({
          events: res,
          isEventFound: true,
        });
      }
    } catch (error: unknown) {
      const { errorHandler } = this.props;
      errorHandler(error);
    }
  };
  customFilter = (option: option, searchText: string) => {
    if (option.data.name.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    }

    return false;
  };

  checkIn = async () => {
    const { state } = this;
    const validation: validations = this.validator.validate(state);
    this.setState({ validation });
    this.submitted = true;
    const { firstName, lastName, email, selectedEvent, reload } = this.state;
    const { notify, getAllCheckins, handleClose } = this.props;
    const params = {
      checkIn: new Date(),
      email: email,
      eventId: selectedEvent.eventId,
      firstName: firstName,
      lastName: lastName,
    };
    try {
      await checkIn(params);
      notify(`${firstName} has been checked in.`, "success");
      getAllCheckins(1);
      handleClose();
    } catch (error) {
      const errorMsg = error.message || "Could not check in.";
      notify(errorMsg, "error");
      this.submitted = false;
      this.setState({
        reload: !reload,
      });
    }
  };

  render() {
    const focusUsernameInputField = (input: HTMLInputElement) => {
      if (input !== null) {
        input.focus();
      }
    };
    const {
      validation: stateValidation,
      selectedEvent,
      events,
      showFirstNameError,
      showLastNameError,
      firstName,
      lastName,
    } = this.state;
    const validation: validations = this.submitted ? this.validator.validate(this.state) : stateValidation;

    const { classes, show, handleClose } = this.props;
    const formatOptionLabel = (data: { label: string }) => (
      <div data-testid={`${data.label}-OptionLabel`}>{data.label}</div>
    );

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={show}
        onClose={handleClose}
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
      >
        <Grid
          container
          spacing={0}
          alignItems="center"
          className={classes.activityModelCenter}
          justify="center"
          direction="column"
          style={{ minHeight: "100vh" }}
        >
          <Grid item>
            <Grid container direction="row">
              <Grid item className={classes.paper}>
                <CloseIcon onClick={handleClose} className="close_icon" />
                <Grid container spacing={16}>
                  <h4 className="check_in_title" data-testid="activityCheckinHeaderTitle-CheckInModal">
                    Activity Check-in
                  </h4>
                  <h6 className="check_in_title" data-testid="activityCheckinDesc-CheckInModal">
                    Enter the persons name, email and the activity they are checking into
                  </h6>
                  <Grid item xs={6}>
                    <TextField
                      id="firstName"
                      label="First Name"
                      placeholder="First Name"
                      margin="normal"
                      variant="outlined"
                      name="firstName"
                      className="check_in_textbox"
                      onChange={this.handleChange}
                      inputRef={focusUsernameInputField}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                        },
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      inputProps={{
                        "data-testid": "firstNameInputField-CheckInModal",
                      }}
                      value={firstName}
                    />
                    <span className="error-msg highlight" id="event-name-error">
                      {validation.firstName.message}
                    </span>
                    {showFirstNameError && <p style={{ color: "red" }}>Max 25 characters allowed</p>}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="lastName"
                      label="Last Name"
                      placeholder="Last Name"
                      margin="normal"
                      variant="outlined"
                      className="check_in_textbox"
                      name="lastName"
                      onChange={this.handleChange}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                        },
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      inputProps={{
                        "data-testid": "lastNameInputField-CheckInModal",
                      }}
                      value={lastName}
                    />
                    <span className="error-msg highlight" id="event-name-error">
                      {validation.lastName.message}
                    </span>
                    {showLastNameError && <p style={{ color: "red" }}>Max 40 characters allowed</p>}
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="email"
                      label="Email"
                      type="email"
                      name="email"
                      autoComplete="email"
                      margin="normal"
                      className="check_in_textbox"
                      variant="outlined"
                      onChange={this.handleChange}
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                        },
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      inputProps={{
                        "data-testid": "emailInputField-CheckInModal",
                      }}
                    />
                    <span className="error-msg highlight" id="event-name-error">
                      {validation.email.message}
                    </span>
                  </Grid>
                  <Grid item xs={12} data-testid="selectActivity-CheckInModal">
                    <Select
                      isSearchable
                      isClearable
                      value={selectedEvent}
                      options={events}
                      onChange={this.selectEvent}
                      filterOption={this.customFilter}
                      getOptionValue={(option) => option["label"]}
                      styles={customStyles}
                      formatOptionLabel={formatOptionLabel}
                    />
                    <span className="error-msg highlight" id="event-name-error">
                      {validation.selectedEvent.message}
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      data-testid="checkinButton-CheckInModal"
                      disabled={!this.validator.validate(this.state).isValid || this.submitted}
                      variant="contained"
                      color="primary"
                      onClick={this.checkIn}
                      style={{ float: "right" }}
                    >
                      Check-in
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    );
  }
}

const styles = (theme: { spacing: { unit: number }; palette: { background: { paper: any } }; shadows: any[] }) =>
  createStyles({
    activityModelCenter: {
      alignItems: "center",
    },
    backdrop: {
      background: "linear-gradient(67.73deg, #0E69F1 1.08%, #20A2F9 100%);",
      backgroundColor: "black",
      opacity: 0.9,
    },
    button: {
      margin: theme.spacing.unit,
    },
    cssFocused: {
      color: "#687FA3",
    },
    cssLabel: {
      color: "#687FA3",
    },
    notchedOutline: {
      borderColor: "#687FA3 !important",
      borderWidth: "1px",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "6px",
      boxShadow: theme.shadows[5],
      maxWidth: "100%",
      padding: theme.spacing.unit * 2,
      position: "relative",
      textAlign: "left",
      width: theme.spacing.unit * 61,
    },
  });

export default withStyles(styles)(WithErrorHandler(CheckIn));
