import { CircularProgress, Grid } from "@material-ui/core";
import React, { PureComponent } from "react";
import { Some } from "../../utils/Some";

interface Props {
  readonly minHeight: string;
  readonly deviceLocationMessage?: string;
}

class LoadingScreen extends PureComponent<Props> {
  render() {
    const { minHeight, deviceLocationMessage } = this.props;

    return (
      // align="center"
      <Grid
        className="loadingScreen"
        container
        spacing={0}
        alignItems="center"
        justify="center"
        direction="column"
        style={{ minHeight: minHeight }}
      >
        <Grid item>
          <CircularProgress />
        </Grid>
        {Some(deviceLocationMessage) && <h1 className="loadingText">{deviceLocationMessage}</h1>}
      </Grid>
    );
  }
}

export default LoadingScreen;
