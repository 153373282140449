/* eslint-disable no-unused-vars */
export enum AutomationCheckoutRule {
  FCFS = "first come, first serve",
  DESIGNATED_Date_Time = "designated datetime met",
  REQUIRED_TIME_PASSED = "required time passed",
  ALL_TRUE = "all true",
}

export enum AutomationRadioGroup {
  YES = "yes",
  NO = "no",
}

export enum TimeInterval {
  DAILY = "daily",
  EVERY_OTHER_DAY = "every other day",
  WEEKLY = "weekly",
  CUSTOM_DATE = "date",
}

export enum WeeklySchedule {
  MONDAY = "monday",
  TUESDAY = "tuesday",
  WEDNESDAY = "wednesday",
  THURSDAY = "thursday",
  FRIDAY = "friday",
  SATURDAY = "saturday",
  SUNDAY = "sunday",
}

export enum TimeUnitOption {
  HOURS = "hours",
  MINUTES = "minutes",
}
/* eslint-enable no-unused-vars */

export type CheckoutAutomationType = {
  name: string;
  description: string;
  hasSpecificTime: AutomationRadioGroup;
  specificTime?: string;
  timeInterval: TimeInterval;
  weeklyOnDay?: WeeklySchedule;
  customDate?: string;
  hasSetAmountOfTime: AutomationRadioGroup;
  setAmountOfTime?: string;
  automationCheckoutRule?: AutomationCheckoutRule;
  setAmountOfTimeUnit: TimeUnitOption;
  checkOutRule?: string;
  checkOutAutomationId?: number;
};
