/* eslint-disable no-irregular-whitespace*/
import Cookies from "js-cookie";
import { None } from "../../../utils/None";
import axios from "../../../utils/ApiInterceptor";
import { getAuthHeader } from "../../../constant/api";

interface response<T> {
  data: {
    item: T[];
    success: boolean;
    status: string;
    groups?: T[];
  };
}

interface checkout<T> {
  data: {
    item: { checkedinEvents: T[] };
    success: boolean;
  };
}

const getCookie = () => Cookies.get("auth");

export async function getEnvironment() {
  return await axios.get<EnvironmentResponse>("/api/environment");
}

export async function getVirtualKioskActivities(): Promise<response<EventObject>> {
  const orgId = JSON.parse(Cookies.get("virtualKioskIdentification") ?? "{}").sigmaOrgId;
  const url = `/api/kiosk-event/${orgId}`;
  const URL: Promise<response<EventObject>> = axios.get(url, {
    headers: { Authorization: `Bearer  ${getCookie()}` },
  });
  const response = await URL;

  return response;
}

export async function getMemberLogin(eventId: number): Promise<response<{ readonly url: string }>> {
  const URL: Promise<response<{ readonly url: string }>> = axios.get(
    `/api/event-attendee/member?eventId=${eventId}&webcheckin=true`,
    {
      headers: { Authorization: `Bearer ${getCookie()}` },
    },
  );
  const response = await URL;

  return response;
}

export async function addCheckin(query: string, params: any): Promise<response<CheckinResponse>> {
  const URL: Promise<response<CheckinResponse>> = axios.post(`/api/event-attendee/member-success${query}`, params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function retrieveMerits(query: string, params: any): Promise<response<ValidateMeritResponse>> {
  const orgId = JSON.parse(Cookies.get("virtualKioskIdentification") ?? "{}").sigmaOrgId;

  if (None(orgId)) {
    throw new Error("No orgId found");
  }

  const URL: Promise<response<RetrieveMeritResponse>> = axios.post(`/api/org/${orgId}/retrieveMerits${query}`, params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function validateMerits(query: string, params: any): Promise<response<ValidateMeritResponse>> {
  const orgId = JSON.parse(Cookies.get("virtualKioskIdentification") ?? "{}").sigmaOrgId;

  if (None(orgId)) {
    throw new Error("No orgId found");
  }

  const URL: Promise<response<ValidateMeritResponse>> = axios.post(`/api/org/${orgId}/validateMerits${query}`, params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function guestCheckin(params: {}): Promise<response<CheckinResponse>> {
  const URL: Promise<response<CheckinResponse>> = axios.post(`/api/event-attendee/new-member`, params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function getWaiver(waiverId: number): Promise<response<{}>> {
  const URL: Promise<response<{}>> = axios.get(`/api/waiver/get-waiver?waiverId=${waiverId}`, {
    headers: { Authorization: `Bearer ${getCookie()}` },
  });
  const response = await URL;

  return response;
}

export async function getWavierSignIn(params: {
  readonly eventAttendeeId: number;
  readonly fieldIds: Array<{ readonly id: string; readonly value: string }>;
  readonly signImg: string;
  readonly waiverId: number;
}): Promise<response<{ readonly status: string }>> {
  const URL: Promise<response<{ readonly status: string }>> = axios.post("/api/waiver/sign-waiver", params, {
    headers: { Authorization: `Bearer ${getCookie()}` },
  });
  const response = await URL;

  return response;
}

export async function scanCheckInQr(params: {
  readonly data: string;
  readonly eventId: number;
  readonly allowDuplicates: boolean;
}): Promise<response<CheckinResponse>> {
  const URL: Promise<response<CheckinResponse>> = axios.post("/api/event-attendee/member-login-with-scan", params, {
    headers: { Authorization: `Bearer ${getCookie()}` },
  });
  const response = await URL;

  return response;
}

export async function getQualification(qualificationId: number): Promise<response<any>> {
  const URL: Promise<response<any>> = axios.get(
    `/api/qualification/get-qualification?qualificationId=${qualificationId}`,
    {
      headers: { Authorization: `Bearer ${getCookie()}` },
    },
  );
  const response = await URL;

  return response;
}

export async function getCheckOutEvents(data: string): Promise<checkout<CheckedOutEvents>> {
  const URL: Promise<checkout<CheckedOutEvents>> = axios.get(
    `/api/event-attendee/checkout-success?memberIdToken=${data}&isKiosk=1`,
    {
      headers: { ...(await getAuthHeader()) },
    },
  );
  const response = await URL;

  return response;
}

export async function checkOut(params: { eventAttendeeId: number[] }): Promise<response<Array<CheckOutResponse>>> {
  const URL: Promise<response<Array<CheckOutResponse>>> = axios.post("/api/event-attendee/checkout", params, {
    headers: { ...(await getAuthHeader()) },
  });
  const response = await URL;

  return response;
}

export async function getCheckedMemberLogin(): Promise<response<{ readonly url: string }>> {
  const URL: Promise<response<{ readonly url: string }>> = axios.get(
    "/api/event-attendee/checked-in-member-login?webcheckin=true",
    {
      headers: { Authorization: `Bearer ${getCookie()}` },
    },
  );
  const response = await URL;

  return response;
}

export async function getCheckInAccessMerit(params: {
  readonly eventId: number;
  readonly memberId: number;
  readonly allowDuplicates: boolean;
}): Promise<response<AccessMerit>> {
  const URL: Promise<response<AccessMerit>> = axios.post("/api/event-attendee/member-check-access-merit", params, {
    headers: { Authorization: `Bearer ${getCookie()}` },
  });
  const response = await URL;

  return response;
}

export async function getReverseGeocodingInfo(latitude: number, longitude: number) {
  const MAPS_URL = `https://maps.googleapis.com/maps/api/geocode/json?address=${latitude},${longitude}&key=${process.env.MAPS_API_KEY}`;
  const response = await axios.get(MAPS_URL);

  return response;
}
