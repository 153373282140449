import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import WebKioskCard from "../../UI-Components/WebKioskCard";
import arrow from "../../../assets/Images/Icons/arrow-black-right.svg";
import communication from "../../../assets/Images/Icons/communication.svg";
import fileDownload from "../../../assets/Images/Icons/file-download.svg";
import flag from "../../../assets/Images/Icons/flag.svg";
import history from "../../../history";
import rectangle_1 from "../../../assets/Images/Icons/rectangle_1.svg";
import rectangle_2 from "../../../assets/Images/Icons/rectangle_2.svg";
import rectangle_3 from "../../../assets/Images/Icons/rectangle_3.svg";

interface WebCardList {
  appStore: string;
  arrow?: string;
  description: string;
  iconImg: string;
  id: number;
  name: string;
  rectangle: string;
}

const webCardList: WebCardList[] = [
  {
    appStore: "",
    arrow: arrow,
    description:
      "Create activities for your participants to check in. If desired, include policies to your activities.",
    iconImg: flag,
    id: 1,
    name: "Create activities",
    rectangle: rectangle_1,
  },
  {
    appStore: "",
    arrow: arrow,
    description: "Name your virtual kiosk and select which activities are assigned to it.",
    iconImg: fileDownload,
    id: 2,
    name: "Create virtual kiosk and add activities",
    rectangle: rectangle_2,
  },
  {
    appStore: "",
    description:
      "Use the virtual kiosk URL to create a kiosk on any device or send it directly to people you want to check in.",
    iconImg: communication,
    id: 3,
    name: "Use virtual kiosk URLs to check people in",
    rectangle: rectangle_3,
  },
];

const webCardListView = webCardList.map(
  ({ id, iconImg, rectangle, name, description, appStore, arrow }: WebCardList) => {
    return (
      <Grid key={id} item className="event-card-container card-min-width" justify="center">
        <WebKioskCard
          iconImg={iconImg}
          rectangle={rectangle}
          name={name}
          description={description}
          arrow={arrow!}
          appStore={appStore}
        />
      </Grid>
    );
  },
);

export const VirtualKioskTab = () => {
  return (
    <Grid className="web_tab_container">
      <Typography gutterBottom variant="h6" className="virtualTitle">
        Follow these steps to create a virtual kiosk
      </Typography>
      <Grid container spacing={40} className="grid_container" justify="center">
        {webCardListView}
      </Grid>
      <Grid className="buttonCenter">
        <Typography gutterBottom variant="h6">
          <h1 className="virtualkioskTitle" data-testid="createVirtualKioskTitle-VirtualKioskTab">
            Create a virtual kiosk
          </h1>
        </Typography>
        <Typography gutterBottom variant="h6">
          <h2 className="webkioskDesc">Create a virtual kiosk for people to check-in from anywhere, from any device</h2>
        </Typography>
        <Button
          className="virtual-kiosk-button"
          aria-label="Add"
          variant="contained"
          color="primary"
          onClick={() => history.push("/virtualkioskname")}
        >
          Create virtual kiosk
        </Button>
      </Grid>
    </Grid>
  );
};
