import { Button, Card, Icon, Layout, Row, Spin } from "antd";
import React, { useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../theme/Colors";
import { Fonts } from "../../theme/Fonts";
import { Footer } from "./Layout/Footer";
import { Header } from "./Layout/Header";
import { InputAdornment } from "@material-ui/core";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { None } from "../../utils/None";
import { TextField } from "@material-ui/core";
import { guestCheckin } from "./constant/api";
import history from "../../history";
import { showErrorToast } from "../../utils/ToastHelper";

const styles = StyleSheet.create({
  accountText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "18px",
    marginTop: "5px",
    textAlign: "left",
  },
  activityText: {
    color: Colors.sherpa,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.15px",
    marginTop: "16px",
    textAlign: "left",
    wordBreak: "break-all",
  },
  buttonWrapper: {
    alignItems: "center",
    background: Colors.defaultButtonBg,
    display: "flex",
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    fontWeight: 500,
    height: "48px",
    justifyContent: "space-between",
    letterSpacing: "1.25px",
    lineHeight: "16px",
    marginTop: "20px",
    textTransform: "uppercase",
    width: "263px",
  },
  cardWrapper: {
    alignItems: "center",
    borderRadius: "4px",
    display: "flex",
    height: "349px",
    justifyContent: "center",
    width: "327px",
  },
  checkinText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "24px",
    letterSpacing: "0.25px",
    marginTop: "10px",
    textAlign: "left",
  },
  contentWrapper: {
    alignItems: "center",
    height: "auto",
    justifyContent: "center",
    minWidth: "375px",
  },
  flexWrapper: {
    textAlign: "left",
    width: "330px",
  },
  meritText: {
    color: Colors.darkBlue,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.5px",
    marginTop: "15px",
    textAlign: "left",
  },
  rowWrapper: {
    marginTop: "7px",
    width: "100%",
  },
  spinner: {
    color: Colors.white,
    fontSize: 24,
  },
  spinnerWrapper: {
    alignContent: "center",
    marginTop: 3,
    width: "100%",
  },
  textInput: {
    backgroundColor: Colors.textfieldColor,
    borderRadius: "4px 4px 0px 0px",
    color: Colors.headerText,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    lineHeight: "24px",
  },
  textInputMargin: {
    marginTop: "24px",
  },
});

const spinIcon = <Icon type="loading" className={css(styles.spinner)} spin />;

export const GuestCheckIn = () => {
  const { Content } = Layout;
  const { push, goBack } = history;
  const eventData = localStorage.getItem("eventData");
  const eventDetails = JSON.parse(eventData!);
  const { name, eventId, locationEnabled } = eventDetails.event;
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (firstName && lastName && email) {
      if (validateEmail(email)) {
        try {
          const locationData = localStorage.getItem("locationData");

          if (locationEnabled && None(locationData)) {
            throw new Error("Expecting location data in localStorage, but none found.");
          }

          const attendeeDetails = {
            email: email,
            eventId: eventId,
            firstName: firstName,
            lastName: lastName,
            location: locationData ? JSON.parse(locationData) : null,
          };
          setIsLoading(true);
          const response: any = await guestCheckin(attendeeDetails);
          const { eventAttendeeId, hasWaiver } = response.data.item;

          if (response.data.success) {
            if (hasWaiver) {
              localStorage.setItem("logInWith", "guestCheckin");
              push({
                pathname: "/web-checkin/waiver-form",
                state: { attendeeDetails: attendeeDetails, eventAttendeeId: eventAttendeeId },
              });
            } else {
              push("/web-checkin/guestcheckinsuccess", attendeeDetails);
            }
          }
        } catch (e: any) {
          showErrorToast({
            message: e.message,
          });
        }
        setIsLoading(false);
      } else {
        setError("Invalid Email");
      }
    } else {
      setError("Please enter the fields");
    }
  };

  const validateEmail = (email: string) => {
    // eslint-disable-next-line no-useless-escape
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/u;

    return re.test(email);
  };

  return (
    <React.Fragment>
      <Header backArrow exitButton handleExit={() => push("/web-checkin/home")} handleGoBack={() => goBack()} />
      <Content>
        <div className={css(styles.contentWrapper)}>
          <Row type="flex" justify="space-around" align="middle">
            <Row className={css(styles.flexWrapper)} justify="space-around">
              <span className={css(styles.activityText)}>{name}</span>
              <h2 className={css(styles.checkinText)}>Guest check-in</h2>
              <Card className={css(styles.cardWrapper)}>
                <div className="guest_checkin" style={{ display: "grid" }}>
                  <div data-testid="errorMessage-GuestCheckIn" style={{ color: Colors.darkRed }}>
                    {error}
                  </div>
                  <TextField
                    type="text"
                    id="filled-basic"
                    label="First name"
                    variant="filled"
                    className={css(styles.textInput)}
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    inputProps={{
                      "data-testid": "firstNameInputField-GuestCheckIn",
                    }}
                  />
                  <TextField
                    type="text"
                    id="filled-basic"
                    label="Last name"
                    variant="filled"
                    className={css(styles.textInput, styles.textInputMargin)}
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    inputProps={{
                      "data-testid": "lastNameInputField-GuestCheckIn",
                    }}
                  />
                  <TextField
                    type="email"
                    className={css(styles.textInput, styles.textInputMargin)}
                    id="input-with-icon-textfield"
                    label="Email"
                    variant="filled"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <MailOutlineIcon style={{ color: "#9E9E9E" }} />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    inputProps={{
                      "data-testid": "emailInputField-GuestCheckIn",
                    }}
                  />
                  <Button
                    className={css(styles.buttonWrapper)}
                    disabled={isLoading}
                    type="primary"
                    size="large"
                    data-testid="checkInButton-GuestCheckIn"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {isLoading ? (
                      <div className={css(styles.spinnerWrapper)}>
                        <Spin indicator={spinIcon} />
                      </div>
                    ) : (
                      <>
                        <span>check-in</span>
                        <Icon type="right" />
                      </>
                    )}
                  </Button>
                </div>
              </Card>
            </Row>
          </Row>
        </div>
      </Content>
      <Footer />
    </React.Fragment>
  );
};
