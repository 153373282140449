import { Button, Card, Icon, Layout, Row } from "antd";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../theme/Colors";
import { Fonts } from "../../theme/Fonts";
import Info from "../../assets/Images/Icons/info.svg";
import React from "react";
import history from "../../history";

const styles = StyleSheet.create({
  accountText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "18px",
    marginTop: "5px",
    textAlign: "left",
  },
  activityText: {
    color: Colors.sherpa,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.15px",
    marginTop: "10px",
    textAlign: "left",
  },
  buttonWrapper: {
    background: Colors.defaultButtonBg,
    color: Colors.white,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    height: "50px",
    marginTop: "20px",
    textAlign: "center",
    textTransform: "uppercase",
    width: "300px",
  },
  cardInnerWrapper: {
    height: "auto",
    marginLeft: "2%",
    marginRight: "2%",
    width: "96%",
  },
  cardWrapper: {
    borderRadius: "4px",
    height: "auto",
    width: "350px",
  },
  checkinText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "24px",
    letterSpacing: "0.25px",
    marginTop: "10px",
    textAlign: "center",
  },
  closeIcon: {
    color: Colors.sherpa,
    marginTop: "15px",
    textAlign: "right",
  },
  contentWrapper: {
    alignItems: "center",
    height: "auto",
    justifyContent: "center",
    marginTop: "20px",
    minWidth: "375px",
  },
  flexWrapper: {
    textAlign: "left",
  },
  footerWrapper: {
    alignItems: "center",
    backgroundColor: Colors.white,
    height: "100px",
    justifyContent: "center",
    marginTop: "20px",
    minWidth: "375px",
    textAlign: "center",
  },
  guestCheckinText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.25px",
    lineHeight: "24px",
    marginTop: "10px",
    textAlign: "center",
  },
  headerFlex: {
    display: "flex",
  },
  imageWidth: {
    width: "44px",
  },
  imageWrapper: {
    alignItems: "center",
    marginTop: "20px",
    textAlign: "center",
    width: "97%",
  },
  meritText: {
    color: Colors.darkBlue,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.5px",
    marginTop: "15px",
    textAlign: "left",
  },
  rowWrapper: {
    marginTop: "7px",
    width: "100%",
  },
  successText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.25px",
    lineHeight: "24px",
    marginTop: "10px",
    textAlign: "left",
  },
  textInput: {
    backgroundColor: Colors.textfieldColor,
    border: "none",
    marginTop: "10px",
  },
});

export const NothingToCheckOut = () => {
  const { Content } = Layout;
  const { push } = history;

  return (
    <React.Fragment>
      <Content>
        <div className={css(styles.contentWrapper)}>
          <Row type="flex" justify="space-around" align="middle">
            <Row className={css(styles.flexWrapper)} justify="space-around">
              <div className={css(styles.headerFlex)}>
                <p className={css(styles.imageWrapper)}>
                  <img className={css(styles.imageWidth)} src={Info} />
                </p>
                <Icon type="close" className={css(styles.closeIcon)} onClick={() => push("/web-checkin/home")} />
              </div>
              <h2 className={css(styles.checkinText)} data-testid="nothingToCheckOutMessage-NothingToCheckOut">
                Nothing to check-out
              </h2>
              <Card className={css(styles.cardWrapper)}>
                <div className={css(styles.cardInnerWrapper)}>
                  <Row className={css(styles.rowWrapper)}>
                    <div className={css(styles.successText)} data-testid="noActivitiesToCheckOutText-NothingToCheckOut">
                      You have no activities to check-out. Did you need to check-in to one first?
                    </div>
                  </Row>
                </div>
              </Card>
            </Row>
          </Row>
        </div>
      </Content>
      <div className={css(styles.footerWrapper)}>
        <Button
          className={css(styles.buttonWrapper)}
          data-testid="checkInToActivityButton-NothingToCheckOut"
          onClick={() => {
            push("/web-checkin/home");
          }}
        >
          Check-In To Activity
        </Button>
      </div>
    </React.Fragment>
  );
};
