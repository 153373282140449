import { Card, CardContent, IconButton, Menu, MenuItem, Typography, createStyles, withStyles } from "@material-ui/core";
import React, { PureComponent } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import history from "../../history";

interface Props {
  readonly id: string;
  readonly name: string;
  readonly pathName: string;
  readonly dataObj: dataObj;
  readonly options: string[];
  readonly classes: {
    readonly card: string;
    readonly cardDetails: string;
  };
  unpublish: (dataObj: dataObj) => void;
  readonly testId?: string;
}
interface State {
  anchorEl?: null | HTMLElement | ((element: HTMLElement) => HTMLElement);
}

interface dataObj {
  eventId?: string;
  title: string;
  message?: string;
}

class Cards extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClick = () => {
    const { id } = this.props;

    const target: any = document.getElementById(id);
    const { anchorEl } = this.state;
    if (anchorEl) {
      this.setState({ anchorEl: null });
    } else {
      this.setState({ anchorEl: target });
    }
  };

  handleClose = (e: any, option: any) => {
    // To Do handle this in parent.
    this.setState({ anchorEl: null });
    const { dataObj, pathName, unpublish } = this.props;
    if (option === "Edit") {
      dataObj["title"] = "Edit activity";
      history.push({
        pathname: pathName,
        state: { data: dataObj },
      });
    } else if (option === "Duplicate") {
      if (pathName === "/create-event") {
        dataObj["title"] = "Duplicate activity";
        delete dataObj["eventId"];
        delete dataObj["message"];
      }
      history.push({
        pathname: pathName,
        state: { data: dataObj, key: "Duplicate" },
      });
    } else if (option === "View Check-ins") {
      history.push({
        pathname: "/members",
        state: { data: dataObj },
      });
    } else if (option === "Unpublish" || option === "Publish") {
      unpublish(dataObj);
    }
  };
  render() {
    const { classes, name, id, options, testId } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <Card className={classes.card} onClick={this.handleClick} data-testid={testId && `${testId}`}>
        <CardContent className={classes.cardDetails}>
          <Typography variant="h5" component="h2" className="card_color">
            {name}
          </Typography>
        </CardContent>
        <div>
          <IconButton
            id={id}
            className="icon"
            aria-label="More"
            aria-owns={open ? "long-menu" : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <MoreVertIcon data-testid="menuIcon" />
          </IconButton>
          <Menu id="long-menu" anchorEl={anchorEl} open={open}>
            {options.map((option) => (
              <MenuItem
                key={option}
                onClick={(e) => this.handleClose(e, option)}
                data-testid={option && `${option.charAt(0).toLowerCase() + option.slice(1)}-Link`}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </Card>
    );
  }
}
const styles = createStyles({
  card: {
    background: "#FFFFFF",
    borderRadius: "4px",
    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    height: 192,
  },
  cardDetails: {
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    display: "-webkit-box",
    height: 80,
    lineHeight: "30px",
    margin: "27px 32px",
    maxHeight: "60px",
    overflow: "hidden",
    padding: 0,
    textOverflow: "ellipsis",
    wordBreak: "break-all",
  },
});
export default withStyles(styles)(Cards);
