import { Button, Layout, Row } from "antd";
import React, { useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../theme/Colors";
import DOMPurify from "dompurify";
import { Fonts } from "../../theme/Fonts";
import LoadingScreen from "../UI-Components/LoadingScreen";
import { getWaiver } from "./constant/api";
import history from "../../history";
import parse from "html-react-parser";
import thumbDown from "../../assets/Images/Icons/thumb_down.svg";
import thumbUp from "../../assets/Images/Icons/thumb_up.svg";

const styles = StyleSheet.create({
  activityName: {
    color: Colors.blackPearl,
    fontSize: "24px",
    letterSpacing: "0.25px",
    lineHeight: "32px",
  },
  button: {
    borderRadius: "4px",
    color: Colors.white,
    fontSize: "16px",
    fontWeight: 500,
    height: "48px",
    letterSpacing: "1.25px",
    lineHeight: "16px",
    textTransform: "uppercase",
    width: "263px",
  },
  cancelButton: {
    background: Colors.darkRed,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    marginBottom: "40px",
    marginTop: "10px",
  },
  contentWrapper: {
    fontFamily: Fonts.roboto,
  },
  formContainer: {
    textAlign: "center",
    width: "327px !important",
  },
  headerContainer: {
    paddingTop: "30px",
    textAlign: "center",
    width: "327px",
  },
  image: {
    float: "left",
    height: "15px",
    width: "16.5px",
  },
  signAcceptButton: {
    background: Colors.green,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
  },
  waiverTitle: {
    color: Colors.blackPearl,
    fontSize: "24px",
    letterSpacing: "0.25px",
    lineHeight: "32px",
  },
});

interface Props
  extends RouteComponentProps<
    {},
    {},
    {
      eventAttendeeId: number;
      attendeeDetails: {
        readonly firstName: string;
        readonly lastName?: string;
      };
    }
  > {
  readonly notify: (message: string, status: string) => void;
}

const DisplayWaiverForm: React.FC<Props> = ({ notify, location }) => {
  const { Content } = Layout;
  const { event } = JSON.parse(localStorage.getItem("eventData")!);
  const { push } = history;
  const [waiverInfo, setWaiverInfo] = useState<WaiverInfo>({
    content: "",
    waiverFields: "",
    waiverTitle: "",
  });
  const [requiredFieldNames, setRequiredFieldNames] = useState<Array<string>>([]);
  const [optionalFieldNames, setOptionalFieldNames] = useState<Array<string>>([]);
  const [fieldsInput, setFieldsInput] = useState<{ readonly [key: string]: any }>({});
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (waiverInfo.content.length === 0) {
      getWaiverInfo();
    } else {
      const fieldsData = requiredFieldNames.concat(optionalFieldNames);
      if (fieldsData.length > 0) {
        fieldsData.forEach((field: string) => {
          const input = document.getElementById(field);
          input!.setAttribute("name", field);
          input!.setAttribute("data-testid", `${input!.getAttribute("placeholder")}-DisplayWaiverForm`);
          input!.addEventListener("keyup", handleChange);
        });
      }
    }
  }, [requiredFieldNames, optionalFieldNames]);

  const handleChange = (event: KeyboardEvent) => {
    const { name, value } = event.target as HTMLInputElement;
    if (name.includes("req")) {
      document.getElementById(name)!.style.border = "1px solid rgb(204, 204, 204)";
    }
    const input = Object.assign(fieldsInput, { [name]: value });
    setFieldsInput(input);
  };

  const getWaiverInfo = async () => {
    const requiredFieldNames: Array<string> = [];
    const optionalFieldNames: Array<string> = [];

    const response: any = await getWaiver(event.waiverId);
    const waiverData = response.data.item;
    setWaiverInfo(waiverData);
    const waiverFieldData = JSON.parse(response.data.item.waiverFields);
    waiverFieldData.forEach((field: WaiverFields) => {
      const { elementId } = field;
      if (field.isRequired) {
        requiredFieldNames.push(elementId);
      } else {
        optionalFieldNames.push(elementId);
      }
    });
    setLoading(false);
    setRequiredFieldNames(requiredFieldNames);
    setOptionalFieldNames(optionalFieldNames);
  };

  const handleHtmlView = () => {
    const cleanedHTML = waiverInfo.content.replace("\\", "");

    return parse(DOMPurify.sanitize(cleanedHTML));
  };

  const handleSignAndAccept = () => {
    const validationResponse = { failedFiledIds: [] as string[], isValid: true };
    for (const index in requiredFieldNames) {
      const fieldId = requiredFieldNames[index];
      if (!(fieldsInput[fieldId] && fieldsInput[fieldId].length > 0)) {
        validationResponse.isValid = false;
        validationResponse.failedFiledIds.push(fieldId);
        document.getElementById(fieldId)!.style.border = "1px solid red";
        notify("Please fill required fields", "error");
      }
    }
    if (validationResponse.isValid) {
      const fields = requiredFieldNames.concat(optionalFieldNames);
      const fieldsData = fields.map((field: string) => {
        return {
          id: field,
          value: fieldsInput[field],
        };
      });
      push({
        pathname: "/web-checkin/waiver-form-sign",
        state: {
          attendeeDetails: location.state?.attendeeDetails,
          eventAttendeeId: location.state?.eventAttendeeId,
          fieldsData,
        },
      });
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <LoadingScreen key={1} minHeight="calc(100vh - 125px)" />
      ) : (
        <Content className={css(styles.contentWrapper)}>
          <Row type="flex" justify="space-around" align="middle" style={{ flexDirection: "column" }}>
            <div className={css(styles.headerContainer)}>
              <p className={css(styles.activityName)} data-testid="activityName-DisplayWaiverForm">
                {event.name}
              </p>
              <p className={css(styles.waiverTitle)} data-testid="formName-DisplayWaiverForm">
                {waiverInfo.waiverTitle}
              </p>
            </div>
            <div className={css(styles.formContainer)}>
              {handleHtmlView()}
              <div className="buttonContainer">
                <Button
                  block
                  className={css([styles.button, styles.signAcceptButton])}
                  data-testid="signAndAcceptButton-DisplayWaiverForm"
                  onClick={handleSignAndAccept}
                >
                  <img src={thumbUp} className={css(styles.image)} />
                  <span>sign &amp; accept</span>
                </Button>
                <Button
                  block
                  className={css([styles.button, styles.cancelButton])}
                  data-testid="cancelButton-DisplayWaiverForm"
                  onClick={() => {
                    push("/web-checkin/selectactivity");
                  }}
                >
                  <img src={thumbDown} className={css(styles.image)} />
                  <span>cancel</span>
                </Button>
              </div>
            </div>
          </Row>
        </Content>
      )}
    </React.Fragment>
  );
};

export default withRouter(DisplayWaiverForm);
