// eslint-disable-next-line no-unused-vars
import React, { ComponentType, FC } from "react";
import { useDefaultErrorHandler } from "../utils/useDefaultErrorHandler";

export interface WithErrorHandlerProps {
  errorHandler: (err: unknown) => Promise<void>;
}

/**
 * A Higher Order Component that provides the `errorHandler` prop to the wrapped component.
 *
 * @template T - The props type of the wrapped component.
 * @param {React.ComponentType<T>} Component - The component to be wrapped.
 *
 * @returns {React.FC<Omit<T, keyof WithAuth0Props>>} - The wrapped component
 */
export const WithErrorHandler = <T extends WithErrorHandlerProps>(Component: ComponentType<T>) => {
  const WrapperComponent: FC<Omit<T, keyof WithErrorHandlerProps>> = (props) => {
    const { errorHandler } = useDefaultErrorHandler();

    return <Component {...(props as T)} errorHandler={errorHandler} />;
  };

  return WrapperComponent;
};
