import { Col, Row } from "antd";
import React, { useMemo, useState } from "react";
import { Button } from "@material-ui/core";
import ChooseMerit from "../Modals/ChooseMerit";
import { Close } from "@material-ui/icons";
import EnhancedTable from "../../../UI-Components/EnhancedTable";
import { Some } from "../../../../utils/Some";
// eslint-disable-next-line no-unused-vars
import { TableColumn } from "../../../UI-Components/EnhancedTable/type";

interface Props {
  readonly handleSelectMeritTemplate: (selectedMeritTemplate: Template[]) => void;
  readonly meritTemplateList: Template[];
  readonly selectedMTIds: string[];
}

export const AddMeritTemplate: React.FC<Props> = ({ handleSelectMeritTemplate, meritTemplateList, selectedMTIds }) => {
  const [isShowModal, setShowModal] = useState(false);
  const handleSelectMerit = (meritTemplateIds: string[]) => {
    const selectedMerit: Template[] = [];
    meritTemplateIds.forEach((meritTemplateId) => {
      const meritTemplate = meritTemplateList.find((template) => template.id === meritTemplateId);
      if (Some(meritTemplate)) {
        selectedMerit.push(meritTemplate);
      }
    });

    handleSelectMeritTemplate(selectedMerit);
  };

  const showHideChooseMeritModal = () => {
    setShowModal(!isShowModal);
  };

  const columns: TableColumn[] = [
    { key: "name", label: "Template Name" },
    { key: "description", label: "Template Description" },
    { key: "action", label: "" },
  ];
  const getFormattedData = (meritList: Template[]) => {
    const formattedMTList = meritList.map((e) => ({
      action: (
        <Close
          data-testid={`${e.id}-CloseIcon-AddMeritTemplate`}
          onClick={() => {
            const selectedMT = selectedMTIds.filter((mtId) => mtId !== e.id);
            handleSelectMerit(selectedMT);
          }}
        />
      ),
      description: e.description,
      key: e.id,
      name: e.name,
    }));

    return formattedMTList;
  };

  const selectedMTList = useMemo(
    () => selectedMTIds.map((selectedMTId) => meritTemplateList.find((mt) => mt.id === selectedMTId)).filter(Some),
    [meritTemplateList, selectedMTIds],
  );

  return (
    <React.Fragment key={meritTemplateList.join()}>
      <ChooseMerit
        isShowModal={isShowModal}
        handleSelectMerit={handleSelectMerit}
        showHideChooseMeritModal={showHideChooseMeritModal}
        meritTemplateList={meritTemplateList}
        selectedMTIds={selectedMTIds}
      />
      <div className=" grid grid_hr" style={{ padding: "10px 0" }}>
        <br />
        <Row>
          <Col span={12}>
            <h6 data-testid="chooseTemplateText-AddMeritTemplate">
              Which merit template would you like to use as Check-in merits?
            </h6>
          </Col>
          <Col span={12}>
            <div>
              <Button
                variant="contained"
                color="secondary"
                style={{ float: "right", marginLeft: "2px" }}
                onClick={showHideChooseMeritModal}
                data-testid="chooseTemplateButton-AddMeritTemplate"
              >
                Choose merit template
              </Button>
            </div>
          </Col>
        </Row>
        <br />
        {selectedMTIds.length > 0 && (
          <EnhancedTable
            tableContentHeight="auto"
            columns={columns}
            title=""
            idColumn="key"
            dataSource={getFormattedData(selectedMTList)}
            searchByColumns={["name", "description"]}
            dataTestIdKey="key"
          />
        )}
      </div>
    </React.Fragment>
  );
};
