import { Button, Card, Layout, Row } from "antd";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../theme/Colors";
import { Fonts } from "../../theme/Fonts";
import Info from "../../assets/Images/Icons/info.svg";
import React from "react";
import history from "../../history";
import { useAuth0 } from "@auth0/auth0-react";

const styles = StyleSheet.create({
  alreadyCheckinText: {
    color: Colors.checkinButtonBg,
    cursor: "pointer",
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.25px",
    textAlign: "center",
    textTransform: "uppercase",
  },
  buttonWrapper: {
    background: Colors.white,
    color: Colors.defaultButtonBg,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    height: "50px",
    textAlign: "center",
    textTransform: "uppercase",
    width: "300px",
  },
  cardInnerWrapper: {
    height: "auto",
    marginLeft: "2%",
    marginRight: "2%",
    width: "96%",
  },
  cardWrapper: {
    borderRadius: "4px",
    height: "auto",
    marginTop: "20px",
    width: "327px",
  },
  checkinText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "24px",
    letterSpacing: "0.25px",
    marginTop: "10px",
    textAlign: "center",
  },
  checkoutButton: {
    background: Colors.checkoutButtonBg,
    color: Colors.sherpa,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    height: "50px",
    marginTop: "20px",
    textAlign: "center",
    textTransform: "uppercase",
    width: "300px",
  },
  checkoutbuttonWrapper: {
    background: Colors.checkoutButtonBg,
    border: `1px solid ${Colors.imageContainerBorder}`,
    color: Colors.blackPearl,
    height: "50px",
    marginBottom: "15px",
    marginTop: "20px",
    textAlign: "center",
    textTransform: "uppercase",
    width: "300px",
  },
  contentWrapper: {
    alignItems: "center",
    height: "auto",
    justifyContent: "center",
    marginTop: "20px",
    minWidth: "375px",
    overflowY: "hidden",
  },
  flexWrapper: {
    textAlign: "left",
  },
  footerWrapper: {
    alignItems: "center",
    backgroundColor: Colors.white,
    bottom: "0px",
    justifyContent: "center",
    marginTop: "20px",
    minWidth: "375px",
    position: "sticky",
    textAlign: "center",
  },
  headerFlex: {
    display: "flex",
  },
  imageWidth: {
    width: "44px",
  },
  imageWrapper: {
    alignItems: "center",
    marginTop: "20px",
    textAlign: "center",
    width: "97%",
  },
  rowWrapper: {
    marginTop: "7px",
    width: "100%",
  },
  successText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.25px",
    lineHeight: "24px",
    marginTop: "10px",
    textAlign: "left",
    wordBreak: "break-all",
  },
});

interface LocationState {
  attendeeDetails: {
    readonly firstName: string;
    readonly email: string;
  };
}

interface Props extends RouteComponentProps<{}, {}, LocationState> {}

const AlreadyCheckIn = ({ location }: Props) => {
  const { Content } = Layout;
  const { push } = history;
  const { logout, isAuthenticated } = useAuth0();

  const eventData = localStorage.getItem("eventData");

  const eventDetails = JSON.parse(eventData!);
  const attendeeDetails =
    location.state?.attendeeDetails ?? JSON.parse(localStorage.getItem("attendeeDetails") ?? "{}");
  localStorage.setItem("attendeeDetails", JSON.stringify(attendeeDetails));

  const { firstName } = attendeeDetails;
  const { name } = eventDetails.event;
  const activitiesCount = localStorage.getItem("activitiesCount");

  if (isAuthenticated) {
    logout({
      logoutParams: {
        returnTo: window.location.href,
      },
    });

    return null;
  }

  // eslint-disable-next-line react/no-multi-comp
  const handleFooterButton = (): React.ReactNode => {
    if (activitiesCount !== "1") {
      return (
        <div className={css(styles.footerWrapper)}>
          <Button
            className={css(styles.checkoutbuttonWrapper)}
            data-testid="checkOutButton-AlreadyCheckIn"
            onClick={() => {
              push("/web-checkin/home");
            }}
          >
            Check-Out
          </Button>
          <p
            className={css(styles.alreadyCheckinText)}
            data-testid="checkIntoAnotherActivity-AlreadyCheckIn"
            onClick={() => {
              push("/web-checkin/selectactivity");
            }}
          >
            Check-in to another activity
          </p>
        </div>
      );
    }

    return (
      <div className={css(styles.footerWrapper)}>
        <Button
          className={css(styles.checkoutbuttonWrapper)}
          data-testid="checkOutButton-AlreadyCheckIn"
          onClick={() => {
            push("/web-checkin/home");
          }}
        >
          Check-Out
        </Button>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Content>
        <div className={css(styles.contentWrapper)}>
          <Row type="flex" justify="space-around" align="middle">
            <div className={css(styles.flexWrapper)}>
              <div className={css(styles.headerFlex)}>
                <p className={css(styles.imageWrapper)}>
                  <img className={css(styles.imageWidth)} src={Info} />
                </p>
              </div>
              <h2 className={css(styles.checkinText)} data-testid="headerTitle-AlreadyCheckIn">
                Already Check-in
              </h2>
              <Card className={css(styles.cardWrapper)}>
                <div className={css(styles.cardInnerWrapper)}>
                  <Row className={css(styles.rowWrapper)}>
                    <div className={css(styles.successText)} data-testid="alreadyCheckInMessage-AlreadyCheckIn">
                      <b>{firstName}</b>, you are already checked-in to <b>{name}</b>
                    </div>
                  </Row>
                </div>
              </Card>
            </div>
          </Row>
        </div>
      </Content>
      {handleFooterButton()}
    </React.Fragment>
  );
};

export default withRouter(AlreadyCheckIn);
