/* eslint-disable no-negated-condition*/
import { LogStatus, logger } from "../../utils/DDLoggerHelper";
import React, { useEffect } from "react";
import { addCheckin, validateMerits } from "./constant/api";
import { None } from "../../utils/None";
import { Some } from "../../utils/Some";
import { Spin } from "antd";
import history from "../../history";
import { showErrorToast } from "../../utils/ToastHelper";
import { useAuth0 } from "@auth0/auth0-react";

export const ValidateMemberLogin = () => {
  const { location, push } = history;
  const { isAuthenticated, logout } = useAuth0();
  useEffect(() => {
    if (isAuthenticated) {
      navigateToCheckInOrCheckOut();
    }
  }, [isAuthenticated]);

  const navigateToCheckInOrCheckOut = async () => {
    const logInWith = localStorage.getItem("logInWith");
    if (logInWith === "checkIn") {
      try {
        await getCheckin();
      } catch (e: any) {
        showErrorToast({
          message: e.message,
          onClose: () => {
            logout({
              logoutParams: {
                returnTo: `${window.location.origin}/web-checkin/home`,
              },
            });
          },
        });
      }
    } else if (logInWith === "checkOut") {
      const memberToken = location.search.split("&")[0].split("=")[1];
      push({
        pathname: "/web-checkin/checkout-events",
        state: { memberToken: memberToken },
      });
    }
  };

  const setCurrentScreen = () => {
    try {
      setTimeout(() => {
        (window as any).currentScreen = undefined;
      }, 2000);
    } catch (error) {
      logger("Failed to set current screen", LogStatus.ERROR, { currentScreen: (window as any).currentScreen }, error);
    }
  };

  const getCheckin = async () => {
    const eventData = await localStorage.getItem("eventData");
    const eventDetails = JSON.parse(eventData!);
    const { eventId, locationEnabled } = eventDetails.event;

    const locationData = localStorage.getItem("locationData");

    const urlQuery = `?isKiosk=1&eventId=${eventId}&allowDuplicates=false`;

    if (locationEnabled && None(locationData)) {
      throw new Error("Expecting location data in localStorage, but none found.");
    }

    const geolocation = locationData ? JSON.parse(locationData) : null;

    if ((window as any).currentScreen === undefined) {
      (window as any).currentScreen = "validateMember";
    } else if ((window as any).currentScreen === "validateMember") {
      return;
    }

    const response: any = await addCheckin(urlQuery, { location: geolocation });

    const {
      metQualification,
      isMemberAlreadyCheckedIn,
      eventAttendeeId,
      attendeeDetails,
      hasWaiver,
      policyRunResponse,
      nameOfDependentEvent,
    } = response.data.item;

    if (!isMemberAlreadyCheckedIn) {
      setCurrentScreen();
      if (metQualification) {
        if (hasWaiver) {
          push({
            pathname: "/web-checkin/waiver-form",
            state: { attendeeDetails: attendeeDetails, eventAttendeeId: eventAttendeeId },
          });
        } else {
          push({ pathname: "/web-checkin/checkin-success", state: { attendeeDetails: attendeeDetails } });
        }
      } else if (Some(nameOfDependentEvent)) {
        push({
          pathname: "/web-checkin/checkin-failure-dependent",
          state: { attendeeDetails: attendeeDetails, missingCheckIn: nameOfDependentEvent },
        });
      } else {
        const policyRunDetails: PolicyRunDetails[] = policyRunResponse;
        if (Some(policyRunDetails) && policyRunDetails.length > 0) {
          const templateIDs = policyRunDetails.reduce((templateIDs: string[], { policy }) => {
            const requiredTemplateIds = policy.rules.own.reduce((ids: string[], own) => {
              return own.arguments.length > 1 ? [...ids, ...own.arguments.slice(1)] : ids;
            }, []);

            return [...templateIDs, ...requiredTemplateIds];
          }, []);

          const urlQuery = `?isKiosk=1`;

          const response = await validateMerits(urlQuery, { templateIDs });
          const item = response.data.item;

          push({
            pathname: "/web-checkin/checkin-failure",
            state: {
              attendeeDetails: attendeeDetails,
              policyRunDetails: policyRunResponse,
              validateMeritsResponse: item,
            },
          });
        } else {
          push({
            pathname: "/web-checkin/checkin-failure",
            state: {
              attendeeDetails: attendeeDetails,
              policyRunDetails: policyRunResponse,
            },
          });
        }
      }
    } else {
      setCurrentScreen();
      push({ pathname: "/web-checkin/already-checkedin", state: { attendeeDetails: attendeeDetails } });
    }
  };

  return (
    <React.Fragment>
      <Spin size="large" tip="Please, don't refresh...." style={{ height: "100vh" }}>
        <div></div>
      </Spin>
    </React.Fragment>
  );
};
