import "@babel/polyfill";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import React, { PureComponent } from "react";
import { Switch, withRouter } from "react-router";
import Home from "./Home";
import { Loader } from "google-maps";
import Login from "./Web/Login/Login";
import { Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      raised: {
        fontFamily: "Lato",
        fontSize: "16px",
        fontStyle: "normal",
        letterSpacing: "0.05em",
        lineHeight: "normal",
        padding: "10px 24px",
        textAlign: "center",
        textTransform: "none",
      },
      raisedPrimary: {
        "&:disabled": {
          background: "#CADFFC",
          color: "#E8F1FE",
        },
        "&:hover": {
          backgroundColor: "#0D64DA",
        },
        backgroundColor: "#147DF4",
        color: "#FFFFFF",
      },
      raisedSecondary: {
        "&:disabled": {
          backgroundColor: "#CADFFC",
          border: "1px solid #147DF4",
          color: "#147DF4",
        },
        "&:hover": {
          backgroundColor: "#147DF4",
          color: "#FFFFFF",
        },
        backgroundColor: "#F7FAFF",
        color: "#147DF4",
      },
      text: {
        "&:hover": {
          backgroundColor: "transparent",
        },
        fontFamily: "Lato",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: "normal",
        textTransform: "none",
      },
      textPrimary: {
        "&:hover": {
          backgroundColor: "transparent",
          color: "#0d64da",
        },
        color: "#147DF4",
      },
      textSecondary: {
        "&:hover": {
          backgroundColor: "transparent",
          color: "#7b8a9c",
        },
        color: "#a6b8cc",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "12px",
      },
    },
  },
  palette: {
    primary: { main: "#147DF4" },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  typography: { useNextVariants: true },
});

class App extends PureComponent<any> {
  async loadGoogleMapsApi() {
    const options = { libraries: ["places"] };
    const loader = new Loader(process.env.MAPS_API_KEY, options);
    await loader.load();
  }

  async componentDidMount() {
    await this.loadGoogleMapsApi();
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <ToastContainer />
        <Switch>
          <Route component={Login} exact path="/"></Route>
          <Route component={Home} />
        </Switch>
      </MuiThemeProvider>
    );
  }
}

export default withRouter(App);
