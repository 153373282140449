import { UnreachableCaseError } from "./UnreachableCaseError";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

/* eslint-disable no-unused-vars */
export enum LogStatus {
  INFO = "info",
  WARN = "warn",
  ERROR = "error",
}
/* eslint-enable no-unused-vars */

export const logger = (message: string, status: LogStatus, context?: Record<string, any>, error?: Error) => {
  if (process.env.NODE_ENV === "development") {
    console.log(error);
  }
  datadogLogs.logger.setContext({ ...context, ...datadogRum.getInternalContext() });

  switch (status) {
    case LogStatus.INFO:
      datadogLogs.logger.info(message, context, error);
      break;
    case LogStatus.WARN:
      datadogLogs.logger.warn(message, context, error);
      break;
    case LogStatus.ERROR:
      datadogLogs.logger.error(message, context, error);
      break;
    default:
      throw new UnreachableCaseError(status);
  }
};
