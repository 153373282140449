import { Button, Col, Icon, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../../theme/Colors";
import { Fonts } from "../../../theme/Fonts";
import { getBreakDownData } from "../../../constant/api";
import history from "../../../history";
import { useDefaultErrorHandler } from "../../../utils/useDefaultErrorHandler";

interface TableColumns {
  align?: "right";
  dataIndex: string;
  key: string;
  sorter?: (a: BreakdownData, b: BreakdownData) => number;
  title: string;
  render?: (text: string | number, record?: BreakdownData) => React.ReactNode;
  width?: string;
  readonly sortOrder?: boolean | "descend" | "ascend";
}
interface Props {
  readonly allEventIds: Array<number>;
  readonly startDate: string | undefined;
  readonly endDate: string | undefined;
  readonly initialValue: number;
  readonly finalValue: number;
}

interface Sorter {
  readonly order: boolean | "descend" | "ascend";
  readonly columnKey: string;
}

const styles = StyleSheet.create({
  headerText: {
    color: Colors.headerText,
    fontFamily: Fonts.itcAvantGardeGothicStd,
    fontSize: "20px",
  },
});

export const DashboardTable: React.SFC<Props> = ({ allEventIds, startDate, endDate, initialValue, finalValue }) => {
  const [initialPosition, setInitialPosition] = useState<number>(initialValue);
  const [finalPosition, setFinalPosition] = useState<number>(finalValue);
  const [breakdownData, setBreakdownData] = useState<Array<BreakdownData>>([]);
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const [sorterDetails, setSorterDetails] = useState<Sorter>({
    columnKey: "checkinsCount",
    order: "descend",
  });
  const { errorHandler } = useDefaultErrorHandler();
  const formatNumber = (number: number) => {
    if (Number(number) > 1.0e6) {
      const formattedNumber = Math.abs(Number(number)) / 1.0e6;

      return `${formattedNumber.toFixed(1)}M`;
    }

    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/gu, ",");
  };

  useEffect(() => {
    getBreakdownsDetails();
  }, [allEventIds, startDate, endDate]);

  const getBreakdownsDetails = async () => {
    const allEventIdsLength = allEventIds.length;
    if (allEventIdsLength >= finalValue) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
    if (allEventIdsLength > 0) {
      try {
        const filteredIds = allEventIds.slice(initialValue, finalValue);
        setInitialPosition(initialValue);
        setFinalPosition(finalValue);
        const params = { endDate, eventIds: filteredIds, startDate };
        const response = await getBreakDownData(params);
        if (response.data.item.length > 0) {
          setBreakdownData(response.data.item);
        }
      } catch (error: unknown) {
        errorHandler(error);
      }
    }
  };

  const handleNavigateToCheckinPage = (eventData: BreakdownData) => {
    const data = eventData;
    data.uncheckedData = true;
    data.name = eventData.eventName;
    history.push({
      pathname: "/members",
      state: { data },
    });
  };
  const handleLoadMore = async () => {
    const allEventIdsLength = allEventIds.length;
    if (allEventIdsLength > 0) {
      try {
        const startingPosition = initialPosition + finalValue;
        const endingPosition = finalPosition + finalValue;
        const filteredIds = allEventIds.slice(startingPosition, endingPosition);
        if (filteredIds.length > 0) {
          setInitialPosition(startingPosition);
          setFinalPosition(endingPosition);
          const params = { endDate, eventIds: filteredIds, startDate };
          const response = await getBreakDownData(params);
          if (response.data.item.length > 0) {
            setBreakdownData(breakdownData.concat(response.data.item));
          }
        }
        if (allEventIdsLength >= endingPosition) {
          setButtonDisable(false);
        } else {
          setButtonDisable(true);
        }
      } catch (error: unknown) {
        errorHandler(error);
      }
    }
  };
  const columns: Array<TableColumns> = [
    {
      dataIndex: "eventName",
      key: "eventName",
      sortOrder: sorterDetails.columnKey === "eventName" && sorterDetails.order,
      sorter: (a: BreakdownData, b: BreakdownData) => {
        return a.eventName.localeCompare(b.eventName);
      },
      title: "Activity",
    },
    {
      align: "right",
      dataIndex: "checkinsCount",
      key: "checkinsCount",
      // eslint-disable-next-line react/no-multi-comp
      render: (checkinsCount: number, eventData: BreakdownData) => (
        <span data-testid={`${eventData.eventName}-CheckinsCount-DashboardTable`}>{formatNumber(checkinsCount)}</span>
      ),
      sortOrder: sorterDetails.columnKey === "checkinsCount" && sorterDetails.order,
      sorter: (a: BreakdownData, b: BreakdownData) => a.checkinsCount - b.checkinsCount,
      title: "Number of check-ins",
    },
    {
      align: "right",
      dataIndex: "loggedHours",
      key: "loggedHours",
      // eslint-disable-next-line react/no-multi-comp
      render: (loggedHour: number, eventData: BreakdownData) => (
        <span data-testid={`${eventData.eventName}-HoursLogged-DashboardTable`}>{formatNumber(loggedHour)}</span>
      ),
      sortOrder: sorterDetails.columnKey === "loggedHours" && sorterDetails.order,
      sorter: (a: BreakdownData, b: BreakdownData) => a.loggedHours - b.loggedHours,
      title: "Hours logged",
    },
    {
      align: "right",
      dataIndex: "unsuccessfulCheckins",
      key: "unsuccessfulCheckins",
      // eslint-disable-next-line react/no-multi-comp
      render: (unsuccessfulCheckins: number, eventData: BreakdownData) => (
        <span data-testid={`${eventData.eventName}-UnsuccessfulCheckins-DashboardTable`}>
          {formatNumber(unsuccessfulCheckins)}
        </span>
      ),
      sortOrder: sorterDetails.columnKey === "unsuccessfulCheckins" && sorterDetails.order,
      sorter: (a: BreakdownData, b: BreakdownData) => a.unsuccessfulCheckins - b.unsuccessfulCheckins,
      title: "Unsuccessful check-ins",
    },
    {
      align: "right",
      dataIndex: "notCheckedOut",
      key: "notCheckedOut",
      // eslint-disable-next-line react/no-multi-comp
      render: (tableValue: number, eventData: BreakdownData) =>
        tableValue > 0 && (
          <p data-testid={`${eventData.eventName}-NotCheckedOut-DashboardTable`}>
            {formatNumber(tableValue)}
            <span style={{ paddingLeft: "7px" }}>
              <Icon
                type="arrow-right"
                style={{ color: "#147DF4" }}
                onClick={() => handleNavigateToCheckinPage(eventData)}
              />
            </span>
          </p>
        ),
      sortOrder: sorterDetails.columnKey === "notCheckedOut" && sorterDetails.order,
      sorter: (a: BreakdownData, b: BreakdownData) => a.notCheckedOut - b.notCheckedOut,
      title: "Not checked-out",
    },
    {
      dataIndex: "",
      key: "",
      title: "",
      width: "10%",
    },
  ];

  const handleRowStyle = (row: any, index: number) => {
    if (index % 2 !== 0) {
      return "ant-table-tr-alternate-color";
    }

    return "";
  };

  const handleChange = (
    _pagination: { readonly position?: "top" | "bottom" | "both" },
    _filters: Record<keyof TableColumns, string[]>,
    sorter: Sorter,
  ) => {
    if (sorter.order) {
      setSorterDetails(sorter);
    } else {
      setSorterDetails(Object.assign({}, sorter, { order: "ascend" }));
    }
  };

  return (
    <div>
      <h3 className={css(styles.headerText)} data-testid="breakdownsTitle-DashboardTable">
        Breakdowns
      </h3>
      <Row type="flex" justify="space-around" align="middle">
        <Col span={24} data-testid="tableBody-DashboardTable">
          <Table
            columns={columns}
            dataSource={breakdownData}
            pagination={false}
            rowClassName={handleRowStyle}
            onChange={handleChange}
            rowKey="eventName"
          />
          <br />
        </Col>
        <Button
          type="primary"
          size="large"
          ghost
          onClick={handleLoadMore}
          data-testid="loadMoreButton-DashboardTable"
          disabled={buttonDisable}
        >
          Load more
        </Button>
      </Row>
    </div>
  );
};
