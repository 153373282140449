import "./Welcome.css";
import { Button, Grid } from "@material-ui/core";
import React, { PureComponent } from "react";
import history from "../../../history";

interface Props {
  readonly buttonText: string;
  readonly text: string;
  readonly header: string;
  readonly path?: string;
  readonly imagePath: string;
  readonly checkIn?: () => void;
  readonly testId?: string;
}
export default class Welcome extends PureComponent<Props> {
  goToCreate = () => {
    const { checkIn, path, buttonText } = this.props;
    if (buttonText === "Check-in participant") {
      if (checkIn) {
        checkIn();
      }
    } else {
      localStorage.removeItem("html");
      localStorage.removeItem("draft");
      history.push(path!);
    }
  };
  render() {
    const { imagePath, header, text, buttonText, testId } = this.props;

    return (
      <Grid
        className="align-items-center"
        container
        spacing={0}
        justify="center"
        direction="column"
        style={{ minHeight: "calc(100vh - 150px)", textAlign: "center" }}
      >
        <Grid item>
          <Grid container direction="row">
            <Grid item>
              <img alt="" src={imagePath} className="welcome_image" />
              <h2 className="span_activity" data-testid={testId && `${testId}WelcomeHeader`}>
                {header}
              </h2>
              <span className="text_span" data-testid={testId && `${testId}WelcomeText`}>
                {text}
              </span>
              <Grid item className="button_div">
                {buttonText !== "" && (
                  <Button
                    className="welcome_button"
                    data-testid={testId && `${testId}CreateButton`}
                    onClick={this.goToCreate}
                  >
                    {buttonText}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
