import {
  Button,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React, { PureComponent } from "react";
// eslint-disable-next-line no-unused-vars
import { WithErrorHandler, WithErrorHandlerProps } from "../../../WithErrorHandler";
import CloseIcon from "@material-ui/icons/Close";
import { ShowEventAttendeeDetails } from "../ShowEventAttendeeDetails";
import { Some } from "../../../../utils/Some";
import { manualCheckOut } from "../../../../constant/api";
import moment from "moment-timezone";

interface Props extends WithErrorHandlerProps {
  readonly show: boolean;
  readonly notify: (arg1: string, arg2: string) => void;
  readonly handleClose: () => void;
  readonly classes: {
    readonly paper: string;
    readonly button: string;
    readonly info: string;
    readonly root: string;
    readonly table: string;
    readonly backdrop: string;
  };
  readonly eventAttendee: number[];
  readonly clearEventAttendeeIds: () => void;

  selectedAttendees: any;
  currentDate: string;
  checkOutTz: string | null;
}
interface State {
  checkoutTime: string;
  error: string;
}

interface eventAttendeeData {
  eventAttendeeId: number;
  firstName: string;
  lastName: string;
  email: string;
}

class CheckOut extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { currentDate } = this.props;
    this.state = {
      checkoutTime: currentDate,
      error: "",
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState({ checkoutTime: nextProps.currentDate, error: "" });
  }

  checkOut = async () => {
    const { checkoutTime } = this.state;
    const { eventAttendee, notify, handleClose, clearEventAttendeeIds } = this.props;

    const params = {
      checkOutDateTime: checkoutTime,
      eventAttendeeId: eventAttendee,
    };
    try {
      await manualCheckOut(params);
      notify("Individual(s) checked out.", "success");
      handleClose();
      clearEventAttendeeIds();
    } catch (error) {
      const errorMsg = error.message || "Could not check out.";
      notify(errorMsg, "error");
    }
  };
  /**
   * Managing time zones for a single event attendee check-out
   * When we select or edit just one event attendee, this modal will receive the check-out timezone
   */
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { selectedAttendees, checkOutTz } = this.props;
    const { value } = event.target;
    const checkInDate =
      selectedAttendees.length === 1 && Some(checkOutTz)
        ? moment.tz(selectedAttendees[0].checkIn, selectedAttendees[0].checkInTz)
        : new Date(
            Math.max.apply(
              null,
              selectedAttendees.map(({ checkIn }: EventAttendee) => {
                return new Date(checkIn!);
              }),
            ),
          );

    if (!value) {
      this.setState({
        checkoutTime: value,
        error: "Please select valid date",
      });

      return;
    }

    const currentDateCondition = Some(checkOutTz)
      ? moment.tz(value, checkOutTz).isAfter(moment.tz(new Date(), checkOutTz))
      : new Date(value) > new Date();

    if (currentDateCondition) {
      this.setState({
        checkoutTime: value,
        error: "The Date and Time should be less than the current date.",
      });

      return;
    }

    if (checkInDate >= ((Some(checkOutTz) && moment.tz(value, checkOutTz)) || new Date(value))) {
      this.setState({
        checkoutTime: value,
        error: "The Date and Time should be greater than the check in date.",
      });

      return;
    }

    this.setState({
      checkoutTime: value,
      error: "",
    });
  };
  render() {
    const { classes, selectedAttendees: rows, show, handleClose } = this.props;
    const { checkoutTime, error } = this.state;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={show}
        disableBackdropClick
        onClose={handleClose}
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
      >
        <div className={classes.paper} data-testid="activity-CheckoutModal">
          <Grid container>
            <Grid item>
              <CloseIcon onClick={handleClose} className="close_icon" />
            </Grid>
            <Grid item>
              <h4 className="modal-title" data-testid="activityCheckoutHeaderTitle-CheckoutModal">
                Activity Check-out
              </h4>
              <h6 className="modal_text" data-testid="activityCheckoutDesc-CheckoutModal">
                Check-out the selected people
              </h6>
            </Grid>
            <Grid item className="image_padding">
              <TextField
                id="datetime-local"
                type="datetime-local"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  "data-testid": "checkoutDateField-CheckoutModal",
                }}
                value={checkoutTime}
                onChange={this.handleChange}
              />
            </Grid>
            <span className="error-msg highlight" id="waiver-error">
              {error}
            </span>
            <Grid item className={classes.info}>
              <h6>
                <b>Note:</b> The check-out time should be in the activity&#39;s timezone.
              </h6>
            </Grid>
            <Grid item xs={12}>
              <Button
                data-testid="checkOutButton-CheckOutModal"
                variant="contained"
                color="primary"
                className="common_buttons"
                style={{ float: "right", marginTop: "15px" }}
                onClick={this.checkOut}
                disabled={error.length > 0}
              >
                Check Out
              </Button>
            </Grid>
          </Grid>
          {rows.length !== 0 && (
            <Paper className={classes.root}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell className="record_data">Full Name</TableCell>
                    <TableCell className="record_data">Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(({ eventAttendeeId, firstName, lastName, email }: EventAttendee) => (
                    <ShowEventAttendeeDetails
                      eventAttendeeId={eventAttendeeId}
                      firstName={firstName}
                      lastName={lastName}
                      email={email}
                    />
                  ))}
                </TableBody>
              </Table>
            </Paper>
          )}
        </div>
      </Modal>
    );
  }
}

const styles = (theme: any) =>
  createStyles({
    backdrop: {
      background: "linear-gradient(67.73deg, #0E69F1 1.08%, #20A2F9 100%);",
      backgroundColor: "black",
      opacity: 0.9,
    },
    button: {
      margin: theme.spacing.unit,
    },
    cssFocused: {
      color: "#687FA3",
    },
    cssLabel: {
      color: "#687FA3",
    },
    info: {
      marginTop: 20,
      paddingLeft: 7,
    },
    notchedOutline: {
      borderColor: "#687FA3 !important",
      borderWidth: "1px",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "6px",
      boxShadow: theme.shadows[5],
      left: "50%",
      padding: theme.spacing.unit * 2,
      position: "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: theme.spacing.unit * 55,
    },
    root: {
      height: "200px",
      marginTop: theme.spacing.unit * 3,
      overflowX: "hidden",
      overflowY: "auto",
      width: "100%",
    },
    table: {
      minWidth: 350,
    },
  });

export default withStyles(styles)(WithErrorHandler(CheckOut));
