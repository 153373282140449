import { Col, Icon, Input, Layout, Row } from "antd";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import React, { useState } from "react";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import Close from "../../assets/Images/Icons/close-black.svg";
import { Colors } from "../../theme/Colors";
import { Fonts } from "../../theme/Fonts";
import { Footer } from "./Layout/Footer";
import MapMarker from "../../assets/Images/Icons/map-marker.svg";
import history from "../../history";

const { Content } = Layout;

const styles = StyleSheet.create({
  arrowLeft: {
    color: Colors.sherpa,
    fontSize: "16px",
    marginTop: 12,
  },
  contentWrapper: {
    height: "80vh",
    padding: "20px",
  },
  footerWrapper: {
    alignItems: "center",
    bottom: 0,
    display: "flex",
    height: "10vh",
    justifyContent: "center",
  },
  headerContainer: {
    display: "flex",
    width: "100%",
  },
  headerLeftSection: {
    width: "20%",
  },
  headerRightSection: {
    width: "80%",
  },
  headerText: {
    margin: 0,
    marginLeft: "14%",
    paddingTop: 6,
  },
  headerWrapper: {
    background: "none",
    padding: "20px",
  },
  imageContainer: {
    background: Colors.white,
    border: `1px solid ${Colors.imageContainerBorder}`,
    borderRadius: "20px",
    height: "40px",
    textAlign: "center",
    width: "40px",
  },
  inputWrapper: {
    margin: "20px 0",
    width: "100%",
  },
  notFoundDescription: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: 16,
    fontWeight: 400,
  },
  notFoundText: {
    color: Colors.blackPearl,
    fontFamily: Fonts.roboto,
    fontSize: 24,
    fontWeight: 400,
  },
  notFoundWrapper: {
    marginTop: 60,
    padding: 20,
    textAlign: "center",
  },
  suggestionsText: {
    wordBreak: "break-word",
  },
});

interface Props
  extends RouteComponentProps<
    {},
    {},
    {
      actionType: string;
      eventData: {
        readonly active: boolean;
        readonly createdAt: string;
        readonly createdBy?: null;
        readonly event: eventName;
        readonly eventId: number;
        readonly kioskEventId: number;
        readonly kioskId: number;
        readonly sequence?: null;
        readonly updatedAt: string;
        readonly updatedBy?: null;
        readonly visible: boolean;
      };
      hasBackButton: boolean;
      positionError: string;
    }
  > {
  readonly notify: (message: string, status: string) => void;
}

const ManualLocationForm = ({ notify, location }: Props) => {
  const [address, setAddress] = useState<string>();
  const [errorStatus, setErrorStatus] = useState<string>();
  const { push, goBack } = history;

  const handleChange = (address: string) => {
    setAddress(address);
    setErrorStatus(undefined);
  };

  const handleSelect = async (address: string) => {
    try {
      const results = await geocodeByAddress(address);
      const latLang = await getLatLng(results[0]);
      const locationDetails = { ...latLang, formatted_address: address, place_id: results[0].place_id };

      localStorage.setItem("manualLocation", JSON.stringify(locationDetails));
      push({
        pathname: "/web-checkin/devicelocation",
        state: {
          actionType: location.state.actionType,
          eventData: location.state.eventData,
          locationType: "manual",
          positionError: location.state.positionError,
        },
      });
    } catch (err) {
      notify("Unable to fetch the location. Please try again.", "error");
    }
  };

  return (
    <div>
      <Row justify="center" align="middle">
        <Col
          xs={{ offset: 0, span: 24 }}
          sm={{ offset: 6, span: 12 }}
          md={{ offset: 7, span: 10 }}
          lg={{ offset: 9, span: 6 }}
          xl={{ offset: 9, span: 6 }}
        >
          <div className={css(styles.headerWrapper)}>
            <div className={css(styles.headerContainer)}>
              <div className={css(styles.headerLeftSection)}>
                {(location.state.hasBackButton || location.state.hasBackButton === undefined) && (
                  <div className={css(styles.imageContainer)}>
                    <Icon
                      className={css(styles.arrowLeft)}
                      data-testid="backIcon-ManualLocationForm"
                      type="arrow-left"
                      onClick={() => goBack()}
                    />
                  </div>
                )}
              </div>
              <div className={css(styles.headerRightSection)}>
                <h4
                  className={css(styles.headerText)}
                  data-testid={`${location.state.actionType}LocationTitle-ManualLocationForm`}
                >
                  {location.state.actionType === "checkin" ? "Check in location" : "Check out location"}
                </h4>
              </div>
            </div>
          </div>
          <Content className="manualLocation">
            <div className={css(styles.contentWrapper)}>
              <Row type="flex" justify="space-around" align="middle">
                <PlacesAutocomplete
                  value={address}
                  onChange={handleChange}
                  onSelect={handleSelect}
                  onError={(status) => setErrorStatus(status)}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className={css(styles.inputWrapper)}>
                      <Input
                        {...getInputProps({
                          placeholder: "Search Places ...",
                        })}
                        data-testid="searchInputField-ManualLocationForm"
                        prefix={<img src={MapMarker} width="12" />}
                        suffix={
                          loading ? (
                            <Icon type="loading" style={{ fontSize: 20 }} spin />
                          ) : (
                            <img src={Close} width="12" onClick={() => setAddress(undefined)} />
                          )
                        }
                      />
                      <div className="autocomplete-dropdown-container">
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active ? "suggestion-item-active" : "suggestion-item";

                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                              })}
                            >
                              <span
                                className={css(styles.suggestionsText)}
                                data-testid={`${suggestion.placeId}-DropDownItem-ManualLocationForm`}
                              >
                                {suggestion.description}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                      {address && errorStatus === "ZERO_RESULTS" && suggestions.length === 0 && (
                        <div className={css(styles.notFoundWrapper)}>
                          <h3 className={css(styles.notFoundText)} data-testid="addressNotFound-ManualLocationForm">
                            Can’t find your address
                          </h3>
                          <p className={css(styles.notFoundDescription)}>
                            Unable to obtain location, please enter manually
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                </PlacesAutocomplete>
              </Row>
            </div>
          </Content>
          <div className={css(styles.footerWrapper)}>
            <Footer />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(ManualLocationForm);
