import { Empty, Icon, Table } from "antd";
import React, { useMemo } from "react";
import { Colors } from "../../../../theme/Colors";
import { Some } from "../../../../utils/Some";

export interface Props {
  policyList: Policy[];
  selectPolicyList: Policy[];
  readonly handleChange: (data: dataObject) => void;
  isRowSelectionAvailable?: boolean;
  isRemoveAllow?: boolean;
}

interface dataObject {
  name: string;
  val: any;
}

type TableColumns = {
  align?: "right";
  dataIndex: string;
  key: string;
  title: string | React.ReactNode;
  render?: (text: string | number, record?: Policy) => React.ReactNode;
  width?: string;
  className?: string;
};
export const PoliciesTable: React.FC<Props> = ({
  policyList,
  selectPolicyList,
  handleChange,
  isRowSelectionAvailable,
  isRemoveAllow,
}) => {
  const selectedRowKeys = useMemo(() => {
    return selectPolicyList.map((val) => val.id);
  }, [selectPolicyList]);

  const rowSelection = {
    onChange: (rowKeys: string[]) => {
      const finalSelectedPolicies: Policy[] = [];
      policyList.forEach((policy) => {
        if (Some(rowKeys.find((val) => val === policy.id))) {
          finalSelectedPolicies.push(policy);
        }
      });
      handleChange({ name: "policyList", val: finalSelectedPolicies });
    },
    selectedRowKeys,
  };

  const columns: Array<TableColumns> = [
    {
      dataIndex: "name",
      key: "name",
      title: "Policy name",
      width: "200px",
    },
    {
      dataIndex: "description",
      key: "description",
      title: "Policy description",
      width: "380px",
    },
  ];

  const columnsWithAllowRemove = [
    {
      dataIndex: "name",
      key: "name",
      title: "Policy name",
      width: "200px",
    },
    {
      dataIndex: "description",
      key: "description",
      title: "Policy description",
      width: "300px",
    },
    {
      dataIndex: "action",
      key: "action",
      render: (_: string, policy: Policy) => (
        <>
          <Icon
            type="close"
            onClick={() => {
              const finalSelectedPolicies: Policy[] = [];
              policyList.forEach((val) => {
                if (val.id !== policy.id) {
                  finalSelectedPolicies.push(val);
                }
              });
              handleChange({ name: "policyList", val: finalSelectedPolicies });
            }}
            style={{ color: Colors.blackPearl, cursor: "pointer", fontSize: "18px" }}
            data-testid={`${policy.name}-CloseIcon-PoliciesTable`}
          />
        </>
      ),
      title: "",
      width: "50px",
    },
  ];

  return (
    <Table
      rowSelection={isRowSelectionAvailable ? rowSelection : undefined}
      columns={isRemoveAllow ? columnsWithAllowRemove : columns}
      dataSource={policyList}
      pagination={false}
      locale={{
        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No Data</span>} />,
      }}
      rowKey={(policy) => `${policy?.id}`}
    />
  );
};
