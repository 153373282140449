import { Button, Layout } from "antd";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../theme/Colors";
import { Fonts } from "../../theme/Fonts";
import React from "react";

interface Props {
  readonly selectedActivitiesCount?: number;
  readonly handleButton?: () => void;
  readonly buttonTitle?: string;
  readonly virtualKioskName?: string;
  readonly handleEditButton?: () => void;
  readonly handleBackButton?: () => void;
  readonly backButton?: boolean;
  readonly testId?: string;
}

const styles = StyleSheet.create({
  backButton: {
    border: `1px solid ${Colors.blue}`,
    borderRadius: "4px",
    color: Colors.blue,
    fontFamily: Fonts.lato,
    fontSize: "16px",
    fontWeight: "bold",
    height: "44px",
    lineHeight: "19px",
    width: "128px",
  },
  editButton: {
    border: `1px solid ${Colors.blue}`,
    color: Colors.blue,
    fontFamily: Fonts.lato,
    fontSize: "16px",
    fontWeight: "bold",
    height: "44px",
    letterSpacing: "0.01em",
    lineHeight: "19px",
  },
  footerWrapper: {
    alignItems: "center",
    backgroundColor: Colors.white,
    bottom: 0,
    display: "flex",
    justifyContent: "space-between",
    position: "sticky",
  },
  saveButton: {
    backgroundColor: Colors.blue,
    color: Colors.white,
    fontFamily: Fonts.lato,
    fontSize: "16px",
    fontWeight: "bold",
    height: "44px",
    lineHeight: "19px",
    width: "136px",
  },
  selectedCount: {
    color: Colors.blue,
    fonWeight: "bold",
    fontFamily: Fonts.lato,
    fontSize: "16px",
    letterSpacing: "0.05em",
    lineHeight: "19px",
    paddingRight: "20px",
  },
});

export const Footer: React.SFC<Props> = ({
  selectedActivitiesCount,
  handleButton,
  buttonTitle,
  virtualKioskName,
  handleEditButton,
  handleBackButton,
  backButton,
  testId,
}) => {
  const { Footer } = Layout;

  const handleButtonDisable = (): boolean => {
    if ((buttonTitle === "Save" || buttonTitle === "Update") && selectedActivitiesCount === 0) {
      return true;
    }

    if (buttonTitle === "Next" && virtualKioskName!.length === 0) {
      return true;
    }

    return false;
  };

  return (
    <Footer className={css(styles.footerWrapper)}>
      <div style={{ textAlign: "left" }}>
        {backButton && (
          <Button type="default" className={css(styles.backButton)} onClick={handleBackButton}>
            Back
          </Button>
        )}
      </div>

      <div style={{ textAlign: "end" }}>
        <span className={css(styles.selectedCount)}>
          {selectedActivitiesCount! > 0 && `${selectedActivitiesCount} selected`}
        </span>
        {buttonTitle === "Done" && (
          <Button
            type="primary"
            ghost
            onClick={handleEditButton}
            className={css(styles.editButton)}
            data-testid="editActivitiesButton-Footer"
          >
            Edit activities
          </Button>
        )}
        &nbsp;&nbsp;
        <Button
          data-testid={testId && `${testId}Button-Footer`}
          type="primary"
          onClick={handleButton}
          className={css(styles.saveButton)}
          disabled={handleButtonDisable()}
        >
          {buttonTitle}
        </Button>
      </div>
    </Footer>
  );
};
