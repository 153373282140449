import { AppBar, Grid } from "@material-ui/core";
import React, { PureComponent } from "react";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
// eslint-disable-next-line no-unused-vars
import { WithErrorHandler, WithErrorHandlerProps } from "../../WithErrorHandler";
import { ActiveInactiveTabs } from "./ActiveInactiveTabs";
import CommonHeader from "../../UI-Components/CommonHeader";
import LoadingScreen from "../../UI-Components/LoadingScreen";
import { VirtualKioskTab } from "./VirtualKioskTab";
import { getVirtualKioskEvents } from "../../../constant/api";
import history from "../../../history";

interface Props
  extends WithErrorHandlerProps,
    RouteComponentProps<{}, {}, { isVirtualKioskTabActive: boolean; isWebTabActive: boolean }> {
  readonly notify: (arg1: string, arg2: string) => void;
}
interface State {
  activeVirtualKiosk: Array<VirtualKiosk>;
  inActiveVirtualKiosk: Array<VirtualKiosk>;
  loading: boolean;
  pageCount: number;
  totalPages: number;
  isVirtualKioskTabActive: boolean;
}
class Kiosk extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const { location } = this.props;
    this.state = {
      activeVirtualKiosk: [],
      inActiveVirtualKiosk: [],
      isVirtualKioskTabActive: location.state ? location.state.isVirtualKioskTabActive : true,
      loading: true,
      pageCount: 1,
      totalPages: 0,
    };
  }

  componentDidMount() {
    this.getActiveVirtualKioskEvents();
  }

  getActiveVirtualKioskEvents = async () => {
    const { pageCount } = this.state;
    try {
      const response = await getVirtualKioskEvents(1, pageCount);
      if (response.data.item.length > 0) {
        this.setState({ activeVirtualKiosk: response.data.item, totalPages: response.data.metadata.totalPages });
      }
      const inActiveKioskEvents = await getVirtualKioskEvents(0, pageCount);
      if (inActiveKioskEvents.data.item.length > 0) {
        this.setState({ inActiveVirtualKiosk: inActiveKioskEvents.data.item });
      }
      this.setState({ loading: false });
    } catch (error: unknown) {
      const { errorHandler } = this.props;
      errorHandler(error);
    }
  };

  render() {
    const { activeVirtualKiosk, inActiveVirtualKiosk, loading, totalPages, pageCount, isVirtualKioskTabActive } =
      this.state;
    const { notify } = this.props;

    const handleClick = () => {
      history.push("/virtualkioskname");
    };

    const handleVirtualKioskView = () => {
      if (loading) {
        return <LoadingScreen key={1} minHeight="calc(100vh - 125px)" />;
      } else if ((activeVirtualKiosk.length > 0 || inActiveVirtualKiosk.length > 0) && !loading) {
        return (
          <ActiveInactiveTabs
            notify={notify}
            activeVirtualKiosk={activeVirtualKiosk}
            pageCount={pageCount}
            totalPages={totalPages}
            isVirtualKioskTab={isVirtualKioskTabActive}
          />
        );
      }

      return <VirtualKioskTab />;
    };

    return (
      <div className="tabs_container">
        <AppBar position="static" className="appbar app_bar">
          <CommonHeader
            name="Virtual Kiosk"
            buttonText="Create virtual kiosk"
            handleClick={handleClick}
            text="The accompanying kiosk app lets your participants easily check themselves in to the activity you created."
            testId="createVirtualKiosk-Kiosk"
          />
        </AppBar>
        <Grid style={{ marginTop: 20 }}>{handleVirtualKioskView()}</Grid>
      </div>
    );
  }
}
export default withRouter(WithErrorHandler(Kiosk));
