import { Divider, Grid, Modal, createStyles, withStyles } from "@material-ui/core";
import React, { PureComponent } from "react";
import CloseIcon from "@material-ui/icons/Close";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";

const styles = (theme: { spacing: { unit: number }; shadows: any[] }) =>
  createStyles({
    backdrop: {
      background: "linear-gradient(67.73deg, #0E69F1 1.08%, #20A2F9 100%);",
      backgroundColor: "black",
      opacity: 0.9,
    },
    button: {
      margin: theme.spacing.unit,
    },
    cssFocused: {
      color: "#687FA3",
    },
    cssLabel: {
      color: "#687FA3",
    },
    notchedOutline: {
      borderColor: "#687FA3 !important",
      borderWidth: "1px",
    },
    paper: {
      backgroundColor: "#f7faff",
      borderRadius: "6px",
      boxShadow: theme.shadows[5],
      height: "400px",
      left: "50%",
      maxHeight: "calc(100vh - 230px)",
      overflowY: "auto",
      padding: theme.spacing.unit * 2,
      position: "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: "405px",
    },
    root: {
      height: "400px",
      marginTop: theme.spacing.unit * 3,
      overflowX: "hidden",
      overflowY: "auto",
      width: "100%",
    },
    table: {
      minWidth: 350,
    },
  });
interface Props {
  readonly show: boolean;
  readonly notify: (arg1: string, arg2: string) => void;
  readonly handleClose: () => void;
  readonly attendeeMerits: string;
  readonly classes: {
    readonly root: string;
    readonly table: string;
    readonly paper: string;
    readonly button: string;
    readonly notchedOutline: string;
    readonly cssLabel: string;
    readonly cssFocused: string;
    readonly backdrop: string;
  };
  readonly eventAttendee: number[];
  readonly clearEventAttendeeIds: () => void;

  selectedAttendees: any;
  note?: string;
}

interface Merit {
  orgName: string;
  meritTemplateTitle: string;
  name: {
    firstName: string;
  };
  sendDateTime: string;
}

class CopyQualification extends PureComponent<Props> {
  render() {
    const { selectedAttendees, attendeeMerits, handleClose, show } = this.props;
    const attendeeMerit = JSON.parse(attendeeMerits);
    const count = attendeeMerit.length;
    const selectedAttendee = selectedAttendees[0];
    const { classes } = this.props;
    const span = `<span>${count > 1 ? `These are the <strong>${count}</strong> merits` : `This is the merit`} <strong>${
      selectedAttendee.firstName
    } ${selectedAttendee.lastName}</strong> used to check-in to <strong>${
      selectedAttendee.event.name
    }</strong> at <strong>${moment(selectedAttendee.checkIn)
      .tz(selectedAttendee.checkInTz)
      .format("hh:mm a z on M/DD/YYYY")}</strong>.</span>`;

    return (
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        data-testid="meritsModal-CopyQualification"
        open={show}
        onClose={handleClose}
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
      >
        <div className={classes.paper}>
          <Grid container>
            <Grid item>
              <CloseIcon onClick={handleClose} className="close_icon" />
            </Grid>
            <Grid item xs={12}>
              <h4 className="margin_0_top">{`${selectedAttendee.firstName} ${selectedAttendee.lastName}'s Merits`}</h4>
              <h6>{ReactHtmlParser(span)}</h6>
              <Divider className="divider" />
            </Grid>
            <Grid item className="merit_details">
              {attendeeMerit.map((merit: Merit) => (
                <div className="merit_details">
                  <h4 data-testid={`${merit.meritTemplateTitle}-MeritTitle-CopyQualification`}>
                    {merit.meritTemplateTitle}
                  </h4>
                  <div>
                    <h6 className="record_data">Issued by</h6>
                    <h5 className="margin_0_top">{merit.orgName}</h5>
                  </div>
                  <h6 className="record_data">Issued to</h6>
                  <h5 className="margin_0_top">{merit.name.firstName}</h5>
                  <h6 className="record_data">Sent on</h6>
                  <h5 className="margin_0_top">{moment(merit.sendDateTime).format("DD/MM/YYYY")}</h5>
                  <Divider className="divider" />
                </div>
              ))}
            </Grid>
          </Grid>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(CopyQualification);
