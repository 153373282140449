import { Layout, Typography } from "antd";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../../theme/Colors";
import { Fonts } from "../../../theme/Fonts";
import React from "react";

const styles = StyleSheet.create({
  contentDescription: {
    color: Colors.cyanBlue,
    fontFamily: Fonts.lato,
    fontSize: "16px",
    marginBottom: "20%",
    textAlign: "center",
  },
  contentHeader: {
    fontFamily: Fonts.lato,
    fontSize: "32px",
    marginTop: "20%",
    textAlign: "center",
  },
  overViewWrapper: {
    background: Colors.white,
    height: "90%",
    justifyContent: "center",
  },
});
export const NoOverView = () => {
  const { Content } = Layout;
  const { Paragraph } = Typography;

  return (
    <div className={css(styles.overViewWrapper)}>
      <Layout>
        <Content>
          <h1 className={css(styles.contentHeader)} data-testid="homeNoOverViewText">
            No Overviews Yet
          </h1>
          <Paragraph>
            <h2 className={css(styles.contentDescription)}>
              The sight of people checking-in and out will be shown here.
            </h2>
          </Paragraph>
        </Content>
      </Layout>
    </div>
  );
};
