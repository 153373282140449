import React, { ReactNode } from "react";
import { Error500Page } from "./Error500Page";
import { ErrorBoundary } from "react-error-boundary";
import axios from "axios";
import { datadogRum } from "@datadog/browser-rum";

interface Props {
  readonly children: ReactNode;
}

export function DatadogErrorBoundary({ children }: Props) {
  return (
    <ErrorBoundary
      FallbackComponent={Error500Page}
      onError={(error) => {
        if (!axios.isAxiosError(error)) {
          datadogRum.addError(error);
        }
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
