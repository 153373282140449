import React, { useState } from "react";
import loginButton1 from "../../assets/Images/Icons/Link-with-Merit-Light.svg";
import loginButton2 from "../../assets/Images/Icons/Link-with-Merit Dark.svg";
import { useLogin } from "../../hooks/auth";

export const LoginButton = () => {
  const promptLogin = useLogin();
  const [buttonClicked, setButtonClicked] = useState(false);

  const login = async () => {
    setButtonClicked(true);
    try {
      await promptLogin();
    } catch (err: unknown) {
      setButtonClicked(false);
    }
  };

  if (buttonClicked) {
    return <img src={loginButton2} alt="login" />;
  }

  return (
    <img data-testid="linkWithMeritButton" src={loginButton1} onClick={login} alt="login" className="cursor_pointer" />
  );
};
