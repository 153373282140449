import React, { ReactNode, useEffect } from "react";
// eslint-disable-next-line no-unused-vars
import { MeritUserInfo } from "../types/user";
import { None } from "../utils/None";
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import jwt_decode from "jwt-decode";
import { useMeritAuth0 } from "../hooks/auth";

interface Props {
  readonly children: ReactNode;
  readonly environment?: string;
}

export function DataDogProvider({ children, environment }: Props) {
  const { accessToken } = useMeritAuth0();
  useEffect(() => {
    async function init() {
      const applicationId = process.env.DATADOG_APPLICATION_ID;
      const clientToken = process.env.DATADOG_CLIENT_TOKEN;
      const service = "merit-checkin";
      const env = environment === "production" ? "stellar-prod" : environment;
      const version = process.env.APP_VERSION;

      if (None(applicationId) || None(clientToken) || None(version) || None(env)) {
        return;
      }

      datadogRum.init({
        allowedTracingUrls: [
          "https://checkin.merits.com",
          "https://checkin.merits.dev",
          "https://checkin-rel.merits.dev",
          "https://checkin-intg.merits.dev",
        ],
        applicationId,
        clientToken,
        defaultPrivacyLevel: "mask-user-input",
        env: environment === "production" ? "stellar-prod" : environment,
        service: "merit-checkin",
        sessionReplaySampleRate: 100,
        sessionSampleRate: 100,
        site: "datadoghq.com",
        traceSampleRate: 100,
        trackLongTasks: true,
        trackResources: true,
        trackUserInteractions: true,
        version,
      });

      datadogLogs.init({
        clientToken,
        env,
        forwardConsoleLogs: ["warn", "error", "info"],
        forwardErrorsToLogs: true,
        service,
        sessionSampleRate: 100,
        site: "datadoghq.com",
        version,
      });

      datadogLogs.logger.info("DatadogLogs initiated");
    }

    init();
  }, [environment]);

  useEffect(() => {
    const isWebCheckinURL = window.location.pathname.includes("web-checkin");

    if (accessToken !== "" && !isWebCheckinURL) {
      const { entityID } = jwt_decode<MeritUserInfo>(accessToken);
      datadogRum.setUser({ id: entityID });
      datadogLogs.setUser({ id: entityID });
      datadogRum.removeUserProperty("checkinUserId");
      datadogLogs.removeUserProperty("checkinUserId");
    }
  }, [accessToken]);

  return <>{children}</>;
}
