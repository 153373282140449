import { Card, Col, Row } from "antd";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../../theme/Colors";
import { Fonts } from "../../../theme/Fonts";
import React from "react";

interface Props {
  snapShotData: SnapShotData;
}

const styles = StyleSheet.create({
  cardHeaderText: {
    color: Colors.headerText,
    fontFamily: Fonts.itcAvantGardeGothicStd,
    fontSize: "20px",
  },
});

const CardSection: React.SFC<Props> = ({ snapShotData }) => {
  const { unsuccessfulCheckins, notCheckedOut, loggedHours, checkinsCount } = snapShotData;
  const formatNumber = (number: number) => {
    if (number > 1.0e6) {
      const formattedNumber = Math.abs(Number(number)) / 1.0e6;

      return `${formattedNumber.toFixed(1)}M`;
    }

    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/gu, ",");
  };

  return (
    <div>
      <h3 className={css(styles.cardHeaderText)} data-testid="snapshotTitle-CardSection">
        Snapshot
      </h3>
      <Row gutter={[16, 16]}>
        <Col md={6} sm={8} xs={12}>
          <Card style={{ borderLeft: "3px solid #00E676" }}>
            <h2 className="cardNumberCount" data-testid="checkinCount-CardSection">
              {formatNumber(checkinsCount)}
            </h2>
            <p className="cardText">Check-ins</p>
          </Card>
        </Col>
        <Col md={6} sm={8} xs={12}>
          <Card style={{ borderLeft: "3px solid #0053CB" }}>
            <h2 className="cardNumberCount" data-testid="hoursLoggedCount-CardSection">
              {formatNumber(loggedHours)}
            </h2>
            <p className="cardText">Hours logged</p>
          </Card>
        </Col>
        <Col md={6} sm={8} xs={12}>
          <Card style={{ borderLeft: "3px solid #E53935" }}>
            <h2 className="cardNumberCount" data-testid="unsuccessfulCheckinCount-CardSection">
              {formatNumber(unsuccessfulCheckins)}
            </h2>
            <p className="cardText">Unsuccessful check-ins</p>
          </Card>
        </Col>
        <Col md={6} sm={8} xs={12}>
          <Card style={{ borderLeft: "3px solid #FFA000" }}>
            <h2 className="cardNumberCount" data-testid="notCheckedOutCount-CardSection">
              {formatNumber(notCheckedOut)}
            </h2>
            <p className="cardText">Not checked-out</p>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CardSection;
