import { Modal, Tabs } from "antd";
import { StyleSheet, css } from "aphrodite";
import { MatchesTable } from "../MatchesTable";
import React from "react";

const styles = StyleSheet.create({
  modalBody: {
    fontSize: "20px",
    height: "60%",
    marginTop: "50px",
    width: "70%",
  },
});

interface Props {
  readonly eventAttendeeId: string;
  readonly isVisible: boolean;
  readonly onClose: () => void;
  readonly notify: (message: string, status: string) => void;
}

export const CheckinMatchModal = ({ eventAttendeeId, isVisible, notify, onClose }: Props) => {
  const { TabPane } = Tabs;

  return (
    <Modal
      visible={isVisible}
      onCancel={onClose}
      footer={false}
      wrapClassName="match_session_modal_wrapper"
      className={css(styles.modalBody)}
    >
      <Tabs tabBarStyle={{ backgroundColor: "inherit", textAlign: "left" }}>
        <TabPane tab="Potential Matches" key="1">
          <MatchesTable notify={notify} eventAttendeeId={eventAttendeeId} onClose={onClose} />
        </TabPane>
      </Tabs>
    </Modal>
  );
};
