import { Menu } from "antd";
import React from "react";
import { Some } from "../../../utils/Some";

export interface Props {
  readonly actionOptions: Array<string>;
  readonly eventAttendee: EventAttendee;
  readonly handleSelectMenu: (index: number) => void;
}

export const ActionMenu: React.FC<Props> = ({ actionOptions, eventAttendee, handleSelectMenu }) => {
  const { event, checkOut } = eventAttendee;
  const disableMatchOption = !event.isCheckOutReq || Some(checkOut);

  return (
    <Menu>
      {actionOptions.map((option: string, index: number) => {
        return (
          <Menu.Item
            disabled={option === "Match" && disableMatchOption}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            onClick={() => handleSelectMenu(index)}
            className="menu_item"
          >
            <span style={{ fontSize: "16px" }}>{option}</span>
          </Menu.Item>
        );
      })}
    </Menu>
  );
};
