import "./webcheckin.less";
import { AboutMerit } from "./AboutMerit";
import AlreadyCheckIn from "./AlreadyCheckIn";
import CheckInAccessMerit from "./CheckInAccessMerit";
import CheckInCheckOutQrScan from "./CheckInCheckOutQrScan";
import CheckInFailure from "./CheckInFailure";
import CheckInFailureDependent from "./CheckInFailureDependent";
import CheckInSuccess from "./CheckInSuccess";
import CheckInWith from "./CheckInWith";
import CheckOutEvents from "./CheckOutEvents";
import CheckOutSuccess from "./CheckOutSuccess";
import { CheckOutWith } from "./CheckOutWith";
import DeviceLocation from "./DeviceLocation";
import DisplayWaiverForm from "./DisplayWaiverForm";
import { GuestCheckIn } from "./GuestCheckIn";
import GuestCheckInSuccess from "./GuestCheckInSuccess";
import { Layout } from "antd";
import { LinkExpired } from "./LinkExpired";
import ManualLocationForm from "./ManualLocationForm";
import { NothingToCheckOut } from "./NothingToCheckOut";
import React from "react";
import { Route } from "react-router-dom";
import { SelectActivity } from "./SelectActivity";
import { Timer } from "./Layout/Timer";
import { ValidateMemberLogin } from "./ValidateMemberLogin";
import ViewMerits from "./ViewMerits";
import ViewQualifications from "./ViewQualifications";
import WaiverFormSign from "./WaiverFormSign";
import { WebCheckinHome } from "./WebCheckinHome";

interface Props {
  readonly orgDetails: {
    readonly logoUrl: string;
    readonly name: string;
  };
  readonly notify: (message: string, status: string) => void;
}

export const WebCheckinRoute: React.FC<Props> = ({ orgDetails, notify }) => {
  return (
    <div className="webcheckin" style={{ backgroundColor: "#f0f2f5" }}>
      <Route
        exact
        path="/web-checkin/home"
        component={(): any => [
          <Layout>
            <WebCheckinHome orgDetails={orgDetails} />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/linkexpired"
        component={(): any => [
          <Layout style={{ background: "none" }}>
            <LinkExpired />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/checkinwith"
        component={(): any => [
          <Layout>
            <CheckInWith />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/checkoutwith"
        component={(): any => [
          <Layout>
            <CheckOutWith />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/aboutmerit"
        component={(): any => [
          <Layout>
            <AboutMerit />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/selectactivity"
        component={(): any => [
          <Layout style={{ background: "none" }}>
            <SelectActivity />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/validate-member-login"
        component={(): any => [
          <Layout>
            <ValidateMemberLogin />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/checkin-success"
        component={(): any => [
          <Layout>
            <CheckInSuccess />
            <Timer />
          </Layout>,
        ]}
      />

      <Route
        exact
        path="/web-checkin/checkin-failure"
        component={(): any => [
          <Layout>
            <CheckInFailure />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/checkin-failure-dependent"
        component={(): any => [
          <Layout>
            <CheckInFailureDependent />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/guestcheckin"
        component={(): any => [
          <Layout>
            <GuestCheckIn />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/devicelocation"
        component={(): any => [
          <Layout>
            <DeviceLocation notify={notify} />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/guestcheckinsuccess"
        component={(): any => [
          <Layout>
            <GuestCheckInSuccess />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/checkoutsuccess"
        component={(): any => [
          <Layout>
            <CheckOutSuccess />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/nothingtocheckout"
        component={(): any => [
          <Layout>
            <NothingToCheckOut />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/waiver-form"
        component={(): any => [
          <Layout>
            <DisplayWaiverForm notify={notify} />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/waiver-form-sign"
        component={(): any => [
          <Layout style={{ background: "none" }}>
            <WaiverFormSign notify={notify} />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/view-qualifications"
        component={(): any => [
          <Layout style={{ background: "none" }}>
            <ViewQualifications />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/view-merits"
        component={(): any => [
          <Layout style={{ background: "none" }}>
            <ViewMerits />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/qr-scan"
        component={(): any => [
          <Layout>
            <CheckInCheckOutQrScan notify={notify} />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/checkout-events"
        component={(): any => [
          <Layout>
            <CheckOutEvents notify={notify} />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/checkin-access"
        component={(): any => [
          <Layout style={{ background: "none" }}>
            <CheckInAccessMerit orgDetails={orgDetails} notify={notify} />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/already-checkedin"
        component={(): any => [
          <Layout>
            <AlreadyCheckIn />
            <Timer />
          </Layout>,
        ]}
      />
      <Route
        exact
        path="/web-checkin/input-location"
        component={(): any => [
          <Layout>
            <ManualLocationForm notify={notify} />
            <Timer />
          </Layout>,
        ]}
      />
    </div>
  );
};
