import React, { useEffect } from "react";
import { logOut, refreshToken } from "./constant/api";
import Cookies from "js-cookie";
import history from "./history";
import { useIdleTimer } from "react-idle-timer";

interface Props {
  readonly children: React.ReactNode;
}

export const IdleTimer: React.FC<Props> = ({ children }) => {
  let interval: NodeJS.Timer;

  const { isIdle, reset } = useIdleTimer({
    timeout: 10 * 60 * 1000,
  });

  const refreshAuthOrLogout = async () => {
    if (!location.pathname.includes("web-checkin")) {
      if (isIdle()) {
        await logOut();
        Cookies.remove("auth");
        clearInterval(interval);
        localStorage.clear();
        history.push("/");
      } else {
        await refreshToken();
      }
    }
  };

  useEffect(() => {
    if (Cookies.get("auth")) {
      reset();
      interval = setInterval(() => refreshAuthOrLogout(), 10 * 60 * 1000);
      refreshAuthOrLogout();
    }

    return () => clearInterval(interval);
  }, []);

  return <>{children}</>;
};
