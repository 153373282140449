// eslint-disable-next-line no-unused-vars
import { InputBase, Theme, Toolbar, Typography, WithStyles, createStyles, withStyles } from "@material-ui/core";
import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import { Some } from "../../../utils/Some";
// eslint-disable-next-line no-unused-vars
import { TableToolbarPropsProps } from "./type";

type Props = WithStyles<typeof toolbarStyles> &
  TableToolbarPropsProps & {
    containerPadding?: string;
  };

const EnhancedTableToolbar: React.FC<Props> = ({
  numSelected = 0,
  classes,
  onSearch,
  showSelectedCounter,
  selectedTitleProps,
  titleProps,
  title,
  searchTitlePlaceholder,
  searchByColumns,
  containerPadding = "24px",
}: Props) => (
  <div>
    {Some(title) && title.length > 0 ? (
      <Toolbar style={{ paddingLeft: containerPadding, paddingRight: containerPadding }}>
        <div className={classes.title}>
          {Some(showSelectedCounter) && numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1" {...selectedTitleProps}>
              {numSelected} selected
            </Typography>
          ) : (
            <Typography variant="h6" id="tableTitle" {...titleProps} data-testid="title-TableToolbar">
              {title}
            </Typography>
          )}
        </div>
      </Toolbar>
    ) : (
      <div style={{ padding: "10px" }} />
    )}
    {Some(searchByColumns) && searchByColumns.length && (
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder={Some(searchTitlePlaceholder) ? searchTitlePlaceholder : "Search…"}
          classes={{
            input: classes.inputInput,
            root: classes.inputRoot,
          }}
          data-testid="searchInputField-TableToolbar"
          onChange={onSearch}
        />
      </div>
    )}
  </div>
);

const toolbarStyles = (theme: Theme) =>
  createStyles({
    actions: {
      color: theme.palette.text.secondary,
    },
    inputInput: {
      backgroundColor: "#f2f2f2",
      borderRadius: theme.shape.borderRadius,
      paddingBottom: theme.spacing.unit,
      paddingLeft: theme.spacing.unit * 5,
      paddingRight: theme.spacing.unit,
      paddingTop: theme.spacing.unit,
      transition: theme.transitions.create("width"),
      [theme.breakpoints.up("sm")]: {
        "&:focus": {
          width: 400,
        },
        width: 140,
      },
      width: "100%",
    },
    inputRoot: {
      color: "inherit",
      marginLeft: "15px",
      width: "100%",
    },
    search: {
      borderRadius: theme.shape.borderRadius,
      position: "relative",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing.unit,
        width: "auto",
      },
    },
    searchIcon: {
      alignItems: "center",
      display: "flex",
      height: "100%",
      justifyContent: "center",
      pointerEvents: "none",
      position: "absolute",
      width: theme.spacing.unit * 9,
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
  });

export default withStyles(toolbarStyles)(EnhancedTableToolbar);
