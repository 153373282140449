import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../../theme/Colors";
import { Fonts } from "../../../theme/Fonts";
import { Layout } from "antd";
import React from "react";

const styles = StyleSheet.create({
  footerLinks: {
    color: Colors.black,
    fontFamily: Fonts.sfCompactDisplayRegular,
    fontSize: "12px",
    fontWeight: "bold",
    lineHeight: "20px",
    padding: "0 5px",
  },
  footerText: {
    fontFamily: `${Fonts.sfCompactDisplayThin} !important`,
  },
  footerWrapper: {
    background: "none",
    textAlign: "center",
  },
});

export const Footer = () => {
  const { Footer } = Layout;

  return (
    <Footer className={css(styles.footerWrapper)}>
      <a
        href="https://www.merits.com/knowledge/check-in-with-merits"
        target="_blank"
        className={css(styles.footerLinks)}
        data-testid="helpLink-Footer"
      >
        Help
      </a>
      <a
        href="https://app.merits.com/privacy-policy"
        target="_blank"
        className={css(styles.footerLinks)}
        data-testid="privacyLink-Footer"
      >
        Privacy
      </a>
      <a
        href="https://app.merits.com/terms-of-service"
        target="_blank"
        className={css(styles.footerLinks)}
        data-testid="termsOfServiceLink-Footer"
      >
        Terms
      </a>
      <p className={css(styles.footerText)} style={{ textTransform: "capitalize" }}>
        &copy;&nbsp;{new Date().getFullYear()} merit international inc.
      </p>
    </Footer>
  );
};
