import {
  AppBar,
  ClickAwayListener,
  Grid,
  Grow,
  InputBase,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React, { PureComponent } from "react";
import { getAllQualifications, searchQualification } from "../../../constant/api";
import CommonHeader from "../../UI-Components/CommonHeader";
import EnhancedTableHead from "./EnhancedTableHead";
import LoadingScreen from "../../UI-Components/LoadingScreen";
import SearchIcon from "@material-ui/icons/Search";
import Welcome from "../Welcome/Welcome";
import action from "../../../assets/Images/Icons/action.svg";
import history from "../../../history";
import moment from "moment-timezone";
import noQualification from "../../../assets/Images/Icons/noQualification.svg";

interface Qualifications {
  active: boolean;
  createdAt?: string;
  createdBy?: string;
  qualificationId: string;
  qualificationName: string;
  sigmaOrgId: string;
  updatedAt?: string;
  updatedBy?: string;
}

interface Props {
  readonly classes: {
    readonly fab?: string;
    readonly newBtn?: string;
    readonly tableWrapper?: string;
    readonly table?: string;
  };
  readonly handleOnCreate?: () => Promise<void>;
  readonly setSideBar?: () => Promise<void>;
}

interface State {
  anchorEl: any;
  searchText: string;
  isQualificationFound: boolean;

  Qualification: any[];
  pageCount: number;
  totalPages: number;
  selectedQualification: Qualifications;
  isSearch: boolean;
}

class Qualification extends PureComponent<Props, State> {
  isLoading: boolean;

  constructor(props: Props) {
    super(props);
    this.isLoading = true;

    this.state = {
      Qualification: [],
      anchorEl: null,
      isQualificationFound: false,
      isSearch: false,
      pageCount: 1,
      searchText: "",
      selectedQualification: {} as Qualifications,
      totalPages: 0,
    };
    this.getQualification(1);
  }

  registerScroll = () => {
    const { pageCount, totalPages, searchText } = this.state;
    const card_container = document.getElementById("table");
    if (card_container !== null) {
      card_container.onscroll = () => {
        if (card_container.scrollTop > card_container.scrollHeight - card_container.offsetTop - 600) {
          if (!this.isLoading && pageCount < totalPages && searchText.length === 0) {
            this.getQualification(pageCount + 1);
          }
          if (!this.isLoading && searchText.length >= 1 && pageCount < totalPages) {
            this.searchAllQualification(searchText, pageCount + 1);
          }
        }
      };
    }
  };
  handleClick = () => {
    history.push("/create-qualification");
  };
  getQualification = async (page: number) => {
    this.isLoading = true;
    const response = await getAllQualifications(page);
    this.isLoading = false;
    let qualificationsData;
    if (page === 1) {
      qualificationsData = response.data.item;
    } else {
      const { Qualification } = this.state;
      qualificationsData = Qualification.concat(response.data.item);
    }
    if (response.data.item.length === 0) {
      this.setState({
        Qualification: response.data.item,
        isQualificationFound: true,
      });
    } else {
      this.setState({
        Qualification: qualificationsData,
        isSearch: false,
        pageCount: page,
        totalPages: response.data.metadata.totalPages,
      });
    }
    this.registerScroll();
  };
  loading_data = () => {
    const { isQualificationFound } = this.state;
    if (isQualificationFound) {
      return (
        <Grid container>
          <Welcome
            imagePath={noQualification}
            header="No Qualifications"
            text="Create qualifications to add them to activities. You can reuse the qualifications you create across multiple activities."
            path="/create-qualification"
            buttonText="Create"
            testId="qualification"
          />
        </Grid>
      );
    }

    return <LoadingScreen key={1} minHeight="calc(100vh - 125px)" />;
  };
  changeDateFormat = (
    date:
      | string
      | number
      | void
      | moment.Moment
      | Date
      | (string | number)[]
      | moment.MomentInputObject
      | null
      | undefined,
  ) => {
    if (date) {
      const dateFormat = moment(date).format("MMM D, YYYY");

      return dateFormat;
    }

    return "";
  };
  handleClickAction = (event: React.MouseEvent, n: Qualifications) => {
    const { anchorEl } = this.state;
    if (anchorEl) {
      this.setState({ anchorEl: null });
    } else {
      this.setState({ anchorEl: event.currentTarget, selectedQualification: n });
    }
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleMenuItemClick = (event: React.MouseEvent, index: number) => {
    const { selectedQualification } = this.state;
    if (index === 0) {
      history.push({
        pathname: "/create-qualification",
        state: { data: selectedQualification },
      });
    } else if (index === 1) {
      history.push({
        pathname: "/create-qualification",
        state: { data: selectedQualification, key: "Duplicate" },
      });
    }
    this.handleClose();
  };
  searchQualification = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { searchText: oldSearchTerm } = this.state;
    const searchTerm = e.target.value;
    this.setState({
      searchText: e.target.value,
    });

    if (e.target.value.length >= 1) {
      this.searchAllQualification(e.target.value, 1);
    } else if (searchTerm.length < oldSearchTerm.length && searchTerm.length <= 2) {
      this.getQualification(1);
    } else {
      this.getQualification(1);
    }
  };
  searchAllQualification = async (searchTerm: string, page: number) => {
    this.isLoading = true;
    const response = await searchQualification(searchTerm, page);
    this.isLoading = false;
    if (response.data.item.length === 0) {
      this.setState({
        Qualification: response.data.item,
        isSearch: true,
      });
    } else {
      let qualificationList;
      if (page === 1) {
        qualificationList = response.data.item;
      } else {
        const { Qualification } = this.state;
        qualificationList = Qualification.concat(response.data.item);
      }

      this.setState({
        Qualification: qualificationList,
        isSearch: false,
        pageCount: page,
        totalPages: response.data.metadata.totalPages,
      });
    }
    this.registerScroll();
  };

  render() {
    const { classes } = this.props;
    const { anchorEl, Qualification: data, Qualification, isSearch, searchText } = this.state;
    const open = Boolean(anchorEl);
    const options = ["Edit", "Duplicate"];

    return (
      <div>
        <AppBar position="static" className="appbar app_bar">
          <CommonHeader
            name="Qualifications"
            buttonText="Create Qualification"
            handleClick={this.handleClick}
            text="Qualifications are merit requirements for successful check-ins. If participants don’t meet qualifications, they cannot check in."
            testId="qualification"
          />
        </AppBar>

        {Qualification.length === 0 && !isSearch ? (
          this.loading_data()
        ) : (
          <div className="card_container_form" id="card-container">
            <Grid container spacing={24} className="grid_container">
              <Grid item style={{ marginLeft: "470px" }}>
                <div
                  style={{
                    background: "#CFE4FD",
                    borderRadius: "24px",
                    display: "inline-block",
                    position: "relative",
                  }}
                >
                  <SearchIcon
                    style={{ height: 20, left: 0, paddingLeft: "10px", position: "absolute", top: "11px", width: 20 }}
                  />
                  <InputBase
                    placeholder="Search"
                    value={searchText}
                    id="search3"
                    onChange={this.searchQualification}
                    style={{
                      height: "40px",
                      paddingLeft: "30px",
                      width: "184px",
                    }}
                    inputProps={{
                      "data-testid": "searchInputField-Qualification",
                    }}
                  />
                </div>
              </Grid>
              <div className={classes.tableWrapper} id="table">
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <EnhancedTableHead />
                  {isSearch ? (
                    <div className="no_data" data-testid="noDataAvailable-Qualification">
                      No data available
                    </div>
                  ) : (
                    <TableBody data-testid="tableBody-Qualification">
                      {data.map((n) => {
                        return (
                          <TableRow
                            data-testid={`${n.qualificationName}Row-Qualification`}
                            key={n.qualificationId}
                            hover
                            tabIndex={-1}
                          >
                            <TableCell align="left">
                              <div className="table-content-title table-data-font content_width">
                                {n.qualificationName}
                              </div>
                            </TableCell>
                            <TableCell align="center">
                              <div className="table-content-title table-data-font">{n.groupCount}</div>
                            </TableCell>
                            <TableCell align="left">
                              <div className="table-content-title table-data-font">
                                {this.changeDateFormat(n.createdAt)}
                              </div>
                            </TableCell>
                            <TableCell align="left">
                              <div className="table-content-title table-data-font">
                                {this.changeDateFormat(n.updatedAt)}
                              </div>
                            </TableCell>
                            <TableCell align="left">
                              <div className="table-content-title table-data-font">
                                <span data-testid="hamburgerMenu-Qualification">
                                  <img
                                    alt=""
                                    src={action}
                                    onClick={(event) => this.handleClickAction(event, n)}
                                    className="cursor_pointer"
                                  />
                                </span>
                              </div>
                              <Popper open={open} anchorEl={anchorEl} transition disablePortal>
                                {({ TransitionProps, placement }) => (
                                  <Grow
                                    {...TransitionProps}
                                    style={{
                                      transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                                    }}
                                  >
                                    <Paper id="menu-list-grow">
                                      <ClickAwayListener onClickAway={this.handleClose}>
                                        <MenuList>
                                          {options.map((option, index) => (
                                            <MenuItem
                                              className="menu_item"
                                              data-testid={`${option}ContextMenu-Qualification`}
                                              key={option}
                                              onClick={(event) => this.handleMenuItemClick(event, index)}
                                            >
                                              {option}
                                            </MenuItem>
                                          ))}
                                        </MenuList>
                                      </ClickAwayListener>
                                    </Paper>
                                  </Grow>
                                )}
                              </Popper>
                              {/* </div> */}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  )}
                </Table>
              </div>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}
const styles = (theme: { spacing: { unit: number } }) =>
  createStyles({
    fab: {
      color: "rgba(11, 167, 138, 0.98)",
      fontSize: "55px",
      position: "fixed",
      right: "10%",
      top: "35px",
      zIndex: 1201,
    },
    newBtn: {
      backgroundColor: "#fff",
      border: "1px solid #147DF4",
      height: 39,
      position: "absolute",
      right: "175px",
    },
    root: {
      marginTop: theme.spacing.unit * 3,
      width: "100%",
    },
    table: {},
    tableWrapper: {
      height: "calc(100vh - 204px)",
      overflow: "auto",
      width: "100%",
    },
  });

export default withStyles(styles)(Qualification);
