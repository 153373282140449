export const stellarAuthScopes = [
  "create:agent_entity_link",
  "read:agent_entity_link",
  "read:container",
  "read:policy",
  "update:policy_request",
].join(" ");

export const minimumLoginAuthScopes = ["openid", "profile", "email", "offline_access"].join(" ");

export const allAuthScopes = [minimumLoginAuthScopes, stellarAuthScopes].join(" ");
