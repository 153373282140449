// eslint-disable-next-line no-unused-vars
import { DataSource, TableColumn } from "../../../UI-Components/EnhancedTable/type";
// eslint-disable-next-line no-unused-vars
import { Grid, Modal, WithStyles, createStyles, withStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from "../../../UI-Components/EnhancedTable/constants";
import EnhancedTable from "../../../UI-Components/EnhancedTable";
import React from "react";

type Props = WithStyles<typeof chooseMeritStyle> & {
  isShowModal: boolean;
  showHideChooseMeritModal: () => void;
  readonly meritTemplateList: Template[];
  selectedMTIds: string[];
  handleSelectMerit: (value: string[]) => void;
};

const ChooseMerit: React.FC<Props> = ({
  isShowModal,
  showHideChooseMeritModal,
  classes,
  meritTemplateList,
  handleSelectMerit,
  selectedMTIds,
}: Props) => {
  const [meritTemplates, setMeritTemplates] = useState<DataSource[]>([]);
  const columns: TableColumn[] = [
    { key: "name", label: "Template Name" },
    { key: "description", label: "Template Description" },
  ];
  const getFormattedData = (meritList: Template[]) => {
    const formattedMeritList = meritList.map((e) => ({
      description: e.description,
      key: e.id,
      name: e.name,
    }));

    return formattedMeritList;
  };

  useEffect(() => {
    const meritListOnPage = meritTemplateList.slice(0, DEFAULT_ROWS_PER_PAGE_OPTIONS[0]);
    setMeritTemplates(getFormattedData(meritListOnPage));
  }, [meritTemplateList]);

  const onChangePageOrRows = (page: number, rowsPerPage: number) => {
    const dataonPage = meritTemplateList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    setMeritTemplates(getFormattedData(dataonPage));
  };

  return (
    <Modal
      aria-labelledby="choose merit template modal"
      aria-describedby="modal use to select one or multiple merit templates"
      disableBackdropClick
      open={isShowModal}
      onClose={showHideChooseMeritModal}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
    >
      <div className={classes.paper}>
        <Grid container>
          <div style={{ position: "absolute", right: 4, top: 7, zIndex: 2 }}>
            <CloseIcon onClick={showHideChooseMeritModal} className="close_icon" data-testid="closeIcon-ChooseMerit" />
          </div>
          <EnhancedTable
            paginationProps={{
              onChangePageOrRows: onChangePageOrRows,
              rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
              totalDatalength: meritTemplateList.length,
            }}
            tableContentHeight="calc(100vh - 300px)"
            columns={columns}
            title="Select merit templates"
            idColumn="key"
            dataSource={meritTemplates}
            allowToSelectRow
            allowToSelectAll
            // searchByColumns={["name", "description"]}
            onSelect={handleSelectMerit}
            selectedRowKeys={selectedMTIds}
            dataTestIdKey="name"
          />
        </Grid>
      </div>
    </Modal>
  );
};

const chooseMeritStyle = (theme: any) =>
  createStyles({
    backdrop: {
      background: "linear-gradient(67.73deg, #0E69F1 1.08%, #20A2F9 100%);",
      backgroundColor: "black",
      opacity: 0.9,
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "6px",
      boxShadow: theme.shadows[5],
      height: "calc(100vh - 215px)",
      left: "50%",
      position: "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: theme.spacing.unit * 120,
    },
  });

export default withStyles(chooseMeritStyle)(ChooseMerit);
