/* eslint-disable sort-keys-fix/sort-keys-fix */
import "./Dashboard.less";
import { Button, DatePicker } from "antd";
import React from "react";
import moment from "moment";

interface Props {
  isCalendarOpen: boolean;
  handleCalendarModal: (startDate: moment.Moment, endDate: moment.Moment) => void;
  readonly closeCalendar: () => void;
}

export const CalendarModal: React.FC<Props> = ({ isCalendarOpen, handleCalendarModal, closeCalendar }) => {
  const { RangePicker } = DatePicker;

  const handleChange = (dates: moment.Moment[]) => {
    handleCalendarModal(dates[0], dates[1]);
  };

  const disabledDate = (current: moment.Moment) => {
    return current && current > moment().endOf("day");
  };

  return (
    <RangePicker
      className="hideDateRangeInput"
      format="MM-DD-YYYY"
      open={isCalendarOpen}
      ranges={{
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "day"), moment().subtract(1, "day")],
        "Last 7 days": [moment().subtract(7, "day"), moment()],
        "Last 30 days": [moment().subtract(30, "day"), moment()],
        "This month": [moment().startOf("month"), moment().endOf("month")],
        "Last month": [moment().subtract(1, "months").startOf("month"), moment().subtract(1, "months").endOf("month")],
      }}
      defaultPickerValue={[moment().subtract(1, "months"), moment()]}
      disabledDate={disabledDate}
      // fixing of typescript error causes eslint error
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      onChange={handleChange}
      renderExtraFooter={() => (
        <Button type="primary" ghost onClick={() => closeCalendar()}>
          Close
        </Button>
      )}
    />
  );
};
