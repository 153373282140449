import React, { PureComponent } from "react";
import { Grid } from "@material-ui/core";
import HomeCard from "../../UI-Components/HomeCard";
import appStore from "../../../assets/Images/Icons/appstore.svg";
import arrow from "../../../assets/Images/Icons/long-arrow.svg";
import box1 from "../../../assets/Images/Icons/box1.svg";
import box2 from "../../../assets/Images/Icons/box2.svg";
import box3 from "../../../assets/Images/Icons/box3.svg";

interface Props {
  readonly orgName: string;
}
class HomePage extends PureComponent<Props> {
  render() {
    const cardList = [
      {
        arrow: arrow,
        btn1: "Create activity",
        btn1Link: "/create-event",
        btn1Text:
          "Activities represent events where your participants check in. You can add required policies to your check-in process.",
        btn1Title: "CREATE",
        colour: "#FFC300",
        id: 1,
        name: "Setup your activities",
        rectangle: box1,
      },
      {
        arrow: arrow,
        btn1: "Download the app",
        btn1Link: "https://apps.apple.com/us/app/check-in-with-merits/id1459643211",
        btn1Text: "Download the Merit Check-in app from the app store. Use this app to create your check-in kiosk.",
        btn1Title: appStore,
        btn2: "Setup the device",
        btn2Link: "",
        btn2Text:
          "Open the Merit Check-in app in your tablet and follow the on-screen instructions to set up your device.",
        btn2Title: "",
        btn3: "Launch check-in mode",
        btn3Link: "",
        btn3Text:
          "Once you’re finished with setup, put the app into check-in kiosk mode. This will lock the tablet during the check-in experience so participants can easily use the kiosk to check in.",
        btn3Title: "",
        colour: "#E8A5B1",
        id: 2,
        name: "Check people in with the tablet app",
        rectangle: box2,
      },
      {
        arrow: arrow,
        btn1: "Manage check-ins",
        btn1Link: "/members",
        btn1TargetTab: undefined,
        btn1Text:
          "Track and manage check-ins and check-outs on the Check-in log page. You can also search by participant or activity and manually check participants in and out on the fly.",
        btn1Title: "SEE CHECK-IN LOG",
        btn2: "Export check-in log",
        btn2Link: "/members",
        btn2TargetTab: undefined,
        btn2Text: "Export check-in activity as an Excel file for easy reporting and time tracking.",
        btn2Title: "SEE CHECK-IN LOG",
        btn3: "Automate check-outs",
        btn3Link: "/workflows",
        btn3TargetTab: 2,
        btn3Text:
          "For some Activities, it can be valuable to automate check-outs for participants. Automating check-outs can ensure that people are checked out at appropriate times and maintains consistency within an Activity.",
        btn3Title: "SEE AUTOMATE CHECK-OUTS",
        colour: "#02B27F",
        id: 3,
        name: "Track & manage check-ins",
        rectangle: box3,
      },
    ];
    const lst = cardList.map((obj) => {
      return (
        <Grid key={obj.id} item xs={6} sm={4} className="event-card-container card-min-width">
          <HomeCard
            rectangle={obj.rectangle}
            name={obj.name}
            arrow={obj.arrow}
            id={obj.id}
            btn1={obj.btn1}
            btn2={obj.btn2}
            btn3={obj.btn3}
            btn1Text={obj.btn1Text}
            btn2Text={obj.btn2Text}
            btn3Text={obj.btn3Text}
            btn1Title={obj.btn1Title}
            btn2Title={obj.btn2Title}
            btn3Title={obj.btn3Title}
            btn1Link={obj.btn1Link}
            btn2Link={obj.btn2Link}
            btn3Link={obj.btn3Link}
            btn1TargetTab={obj.btn1TargetTab}
            btn2TargetTab={obj.btn2TargetTab}
            btn3TargetTab={obj.btn3TargetTab}
            colour={obj.colour}
          />
        </Grid>
      );
    });
    const { orgName } = this.props;

    return (
      <div className="homeContainer">
        <Grid container spacing={0} justify="center" className="filters">
          <Grid item xs={6}>
            <div className="homepagetitle1" data-testid="getStartedGreet-HomePage">
              Welcome {orgName}
            </div>
            <div className="homepagetitle2" data-testid="getStartedHeader-HomePage">
              Get Started with Merit Check-in!
            </div>
          </Grid>
        </Grid>
        <div className="card_container" id="card-container">
          <Grid container spacing={24} className="grid_container">
            {lst}
          </Grid>
        </div>
      </div>
    );
  }
}
export default HomePage;
