import { Grid, TextField, withStyles } from "@material-ui/core";
import React, { PureComponent } from "react";
import FormValidator from "../../../../constant/validation";
import history from "../../../../history";

interface Props {
  readonly message: string;
  readonly name: string;
  description: string;
  readonly handleChange: (data: { name: string; val: any }) => void;
  readonly handleClickEvent: (v: boolean) => void;
  readonly id: string;
  readonly title: string;
  readonly classes: {
    readonly input: string;
    readonly notchedOutline: string;
    readonly cssLabel: string;
  };
}
interface validations {
  isValid: boolean;
  name: {
    isInvalid: boolean;
    message: string;
  };
}
interface State {
  readonly name: string;
  description: string;
  validation: {
    isValid: boolean;
    name: {
      isInvalid: boolean;
      message: string;
    };
  };
}

class EventInfo extends PureComponent<Props, State> {
  readonly validator: {
    /* eslint-disable-next-line  no-empty-pattern*/
    validate: ({}) => validations;
    valid: () => validations;
  };
  submitted: boolean;
  constructor(props: Props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: "name",
        message: "Please enter the name.",
        method: "isEmpty",
        validWhen: false,
      },
    ]);
    const { name, description } = this.props;
    this.state = {
      description: description,
      name: name,
      validation: this.validator.valid(),
    };
    this.submitted = false;
  }

  handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 60) {
      event.preventDefault();
    } else {
      const value = event.target.value;
      const validation: validations = this.validator.validate({ name: value });
      this.setState({ name: value, validation });
      const data = { name: name, val: value };
      const { handleChange } = this.props;
      handleChange(data);
    }
  };
  handleChangeDescription = (description: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 300) {
      event.preventDefault();
    } else {
      this.setState({ description: event.target.value });
      const data = { name: description, val: event.target.value };
      const { handleChange } = this.props;
      handleChange(data);
    }
  };
  handleClickEvent = () => {
    this.submitted = true;
    const { validation } = this.state;
    const { handleClickEvent } = this.props;
    handleClickEvent(validation.isValid);
  };
  handleBack = () => {
    history.push("/events");
  };

  render() {
    const focusUsernameInputField = (input: HTMLInputElement) => {
      if (input !== null) {
        input.focus();
      }
    };
    const { classes, message } = this.props;
    const { validation: stateValidation, name, description } = this.state;
    const validation = this.submitted // if the form has been submitted at least once
      ? this.validator.validate(this.state) // then check validity every time we render
      : stateValidation;

    return (
      <div className="paper_popup">
        <div className="grid grid_hr">
          <h4 id="event-name-label" className="">
            Activity details
          </h4>
        </div>
        <div className="grid">
          <Grid item xs={7}>
            <h6 className="top-event-label">Name</h6>
            <TextField
              id="event-name"
              placeholder="Activity name"
              value={name}
              onChange={this.handleChange("name")}
              margin="dense"
              variant="outlined"
              inputRef={focusUsernameInputField}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                },
              }}
              InputProps={{
                classes: {
                  input: classes.input,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              inputProps={{
                "data-testid": "activityNameInputField",
                maxLength: 60,
              }}
            />
            <p>
              <span className="error-msg highlight" id="event-name-error">
                {validation.name.message}
              </span>
            </p>
          </Grid>
          <Grid item xs={6}>
            <h6 className="margin_bottom0 margin_0">Description (optional)</h6>
            <TextField
              id="event-description"
              placeholder="Activity description"
              multiline
              rowsMax="3"
              rows="3"
              value={description}
              onChange={this.handleChangeDescription("description")}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                },
              }}
              InputProps={{
                classes: {
                  input: classes.input,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              inputProps={{
                "data-testid": "descriptionInputField",
                maxLength: 300,
              }}
            />
          </Grid>
          <h6 className="margin_10 margin_bottom0">{message}</h6>
        </div>
      </div>
    );
  }
}

const styles = {
  cssLabel: {
    color: "#687FA3",
  },
  input: {
    height: 10,
    width: 340,
  },
  notchedOutline: {
    borderColor: "#687FA3 !important",
    borderWidth: "1px",
  },
};

export default withStyles(styles)(EventInfo);
