import { Button, Modal } from "antd";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../../theme/Colors";
import { Fonts } from "../../../theme/Fonts";
import React from "react";
import history from "../../../history";
const styles = StyleSheet.create({
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "34px",
    width: "100%",
  },
  continueButton: {
    color: Colors.darkBlue,
    fontSize: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
  },
  modalBody: {
    fontFamily: Fonts.roboto,
    fontSize: "20px",
    height: "213px",
    marginTop: "50px",
    width: "336px",
  },
  modalText: {
    color: Colors.headerText,
    fontSize: "16px",
    height: "42px",
    textAlign: "center",
    width: "282px",
  },
  resetButton: {
    color: Colors.darkGrey,
    fontSize: "16px",
    letterSpacing: "1.25px",
    textTransform: "uppercase",
  },
  root: {
    backgroundColor: Colors.white,
    fontFamily: Fonts.roboto,
    overflowX: "hidden",
    overflowY: "auto",
    width: "300px",
  },
});
interface Props {
  readonly show: boolean;
  readonly handleCloseModal: () => void;
}

export const ExitModal: React.SFC<Props> = ({ show, handleCloseModal }) => {
  const { push } = history;

  return (
    <Modal
      title={<span data-testid="title-ExitModal">Are you sure you want to exit?</span>}
      visible={show}
      onCancel={handleCloseModal}
      footer={false}
      wrapClassName="exit_modal_title"
      className={css(styles.modalBody)}
    >
      <div className={css(styles.root)} data-testid="modal-ExitModal">
        <span className={css(styles.modalText)}>
          Exiting will cancel any progress you have made to check-in to this activity.
        </span>
        <div className={css(styles.buttonWrapper)}>
          <Button
            className={css(styles.resetButton)}
            data-testid="cancelButton-ExitModal"
            type="link"
            onClick={handleCloseModal}
          >
            cancel
          </Button>
          <Button
            className={css(styles.continueButton)}
            data-testid="yesButton-ExitModal"
            type="link"
            onClick={() => push("/web-checkin/home")}
          >
            yes, exit
          </Button>
        </div>
      </div>
    </Modal>
  );
};
