/* eslint-disable no-unused-vars */
import { Button, Grid, Modal, Switch, Typography, WithStyles, createStyles, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { exportBulkCheckIns, fetchBulkCheckin } from "../../../../constant/api";
import CloseIcon from "@material-ui/icons/Close";
import { DEFAULT_ROWS_PER_PAGE_OPTIONS } from "../../../UI-Components/EnhancedTable/constants";
import EnhancedTable from "../../../UI-Components/EnhancedTable";
import LoadingScreen from "../../../UI-Components/LoadingScreen";
import { TableColumn } from "../../../UI-Components/EnhancedTable/type";

type DataOnPage = {
  checkInTime: string;
  checkOutTime: string;
  email: string;
  failureReason: string;
  fullName: string;
  key: string;
};

enum StatusType {
  ALL = "all",
  SUCCESS = "success",
  FAILED = "failed",
}

const columns: TableColumn[] = [
  { key: "fullName", label: "Full Name" },
  { key: "email", label: "Email" },
  { key: "checkInTime", label: "Check-in Time" },
  { key: "checkOutTime", label: "Check-out Time" },
  { key: "failureReason", label: "Failure Reason" },
];

const styles = (theme: any) =>
  createStyles({
    activityModelCenter: {
      alignItems: "center",
    },
    backdrop: {
      background: "linear-gradient(67.73deg, #0E69F1 1.08%, #20A2F9 100%);",
      backgroundColor: "black",
      opacity: 0.9,
    },
    filterText: {
      fontSize: 12,
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "6px",
      boxShadow: theme.shadows[5],
      maxWidth: "100%",
      minWidth: 550,
      padding: theme.spacing.unit * 2,
      position: "relative",
      textAlign: "left",
    },
  });
interface Props extends WithStyles<typeof styles> {
  readonly bulkUploadId: string;
  readonly show: boolean;
  readonly notify: (arg1: string, arg2: string) => void;
  readonly handleClose: () => void;
}

const CheckinBulkDetails = ({ bulkUploadId, classes, handleClose, notify, show }: Props) => {
  const [checkinBulkDetails, setCheckinBulkDetails] = useState<CheckinBulkDetails[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<StatusType>(StatusType.ALL);
  const [fileName, setFileName] = useState("");
  const [activityName, setActivityName] = useState("");
  const [dataOnPage, setDataOnPage] = useState<DataOnPage[]>([]);

  const download = () => {
    try {
      exportBulkCheckIns(bulkUploadId, filter).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data as unknown as BlobPart]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Bulk-check-ins-${bulkUploadId}.csv`);
        document.body.appendChild(link);
        link.click();
      });
    } catch {
      notify("Cannot export bulk checkins", "error");
    }
  };

  const onChangePageOrRows = (page: number, rowsPerPage: number) => {
    const dataOnPageSlice = checkinBulkDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    setDataOnPage(getFormattedData(dataOnPageSlice));
  };

  const getFormattedData = (bulkDetails: CheckinBulkDetails[]) => {
    const formattedMTList = bulkDetails.map((e) => ({
      checkInTime: e.checkInTime,
      checkOutTime: e.checkOutTime ?? "-",
      email: e.email,
      failureReason: e.failureReason ?? "-",
      fullName: `${e.firstName} ${e.lastName}`,
      key: e.rowId.toString(),
    }));

    return formattedMTList;
  };

  const getCheckinBulkDetails = async (status: StatusType) => {
    setLoading(true);
    try {
      const {
        data: { item },
      } = await fetchBulkCheckin(bulkUploadId, status);

      if (item.length > 0) {
        setFileName(item[0].fileName);
        setActivityName(item[0].activityName);
      }
      setCheckinBulkDetails(item);
      setDataOnPage(getFormattedData(item.slice(0, DEFAULT_ROWS_PER_PAGE_OPTIONS[0])));
    } catch (error) {
      notify("Error in fetching the unsuccessful checkin details", "error");
    }
    setLoading(false);
  };

  const applyFilter = (status: StatusType) => {
    setFilter(status);
  };

  useEffect(() => {
    getCheckinBulkDetails(filter);
  }, [filter]);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
      open={show}
      onClose={handleClose}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
    >
      <Grid
        container
        spacing={0}
        alignItems="center"
        className={classes.activityModelCenter}
        justify="center"
        direction="column"
        style={{ minHeight: "100vh" }}
      >
        <Grid item>
          <Grid container className={classes.paper} spacing={16} direction="column">
            <Grid item>
              <Grid container direction="row" style={{ alignItems: "center", justifyContent: "space-between" }}>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography data-testid="header-CheckinBulkDetailsModal" style={{ fontSize: 16 }}>
                        Check-in bulk details
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{ color: "grey", fontSize: 10 }}
                        data-testid="fileName-CheckinBulkDetailsModal"
                      >
                        <b>File Name:</b> {fileName}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{ color: "grey", fontSize: 10 }}
                        data-testid="activityName-CheckinBulkDetailsModal"
                      >
                        <b>Activity Name:</b> {activityName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row">
                    <Grid item>
                      <Grid container direction="row" style={{ alignItems: "center" }}>
                        <Typography className={classes.filterText}>All</Typography>
                        <Switch
                          checked={filter === StatusType.ALL}
                          onChange={() => applyFilter(StatusType.ALL)}
                          color="primary"
                          inputProps={{ "aria-label": "toggle" }}
                          data-testid="allSwitchToggle-CheckinBulkDetailsModal"
                        />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" style={{ alignItems: "center" }}>
                        <Typography className={classes.filterText}>Success</Typography>
                        <Switch
                          checked={filter === StatusType.SUCCESS}
                          onChange={() => applyFilter(StatusType.SUCCESS)}
                          color="primary"
                          inputProps={{ "aria-label": "toggle" }}
                          data-testid="successSwitchToggle-CheckinBulkDetailsModal"
                        />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" style={{ alignItems: "center" }}>
                        <Typography className={classes.filterText}>Failed</Typography>
                        <Switch
                          checked={filter === StatusType.FAILED}
                          onChange={() => applyFilter(StatusType.FAILED)}
                          color="primary"
                          inputProps={{ "aria-label": "toggle" }}
                          data-testid="failedSwitchToggle-CheckinBulkDetailsModal"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={32}>
                    <Grid item>
                      <Button
                        aria-label="Add"
                        variant="contained"
                        color="primary"
                        onClick={() => download()}
                        data-testid="exportButton-CheckinBulkDetailsModal"
                      >
                        Export
                      </Button>
                    </Grid>
                    <Grid item>
                      <CloseIcon
                        onClick={handleClose}
                        className="close_icon"
                        data-testid="closeIcon-CheckinBulkDetailsModal"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {loading ? (
              <Grid item xs={12} justify="center">
                <LoadingScreen key={1} minHeight="100px" />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <EnhancedTable
                  tableContentHeight="calc(100vh - 300px)"
                  columns={columns}
                  title=""
                  idColumn="key"
                  dataSource={dataOnPage}
                  paginationProps={{
                    onChangePageOrRows,
                    rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
                    totalDatalength: checkinBulkDetails.length,
                  }}
                  dataTestIdKey="email"
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default withStyles(styles)(CheckinBulkDetails);
