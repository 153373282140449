import { Grid, TextField, Tooltip, createStyles, withStyles } from "@material-ui/core";
import React, { PureComponent } from "react";
import { AddMeritTemplate } from "./AddMeritTemplate";
import { EventCheckOutAutomation } from "./EventCheckOutAutomation";
import FormValidator from "../../../../constant/validation";
import Select from "react-select";
import Switches from "../../../UI-Components/Switches";
import questionMark from "../../../../assets/Images/Icons/question-mark.svg";

interface Props {
  // readonly handlePublishEvent: (boolean: boolean) => Promise<void>;
  readonly handlePreviousClick: () => void;
  readonly handleChange: (data: { name: string; val: any }) => void;
  readonly handleClickEvent: (boolean: boolean) => void;
  // readonly onSelectChange: (ZoneType: any) => void;
  readonly successMessage: string;
  readonly failedMessage: string;
  readonly checkOut: boolean;
  readonly policyCheck: boolean;
  readonly classes: {
    readonly input: string;
    readonly notchedOutline: string;
    readonly cssLabel: string;
  };
  readonly timezone: string;
  id: string;
  readonly selectedMTIds: string[];
  readonly handleSelectMeritTemplate: (selectedMeritTemplate: Template[]) => void;
  readonly meritTemplateList: Template[];
  readonly checkOutAutomationList: CheckOutAutomation[];
  readonly handleSelectCheckOutAutomation: (selectedAutomations: CheckOutAutomation[]) => void;
  readonly selectedAutomationIds: number[];
  readonly selectedAutomations: CheckOutAutomation[];
}
interface validations {
  isValid: boolean;
  failedMessage: {
    isInvalid: boolean;
    message: string;
  };
  successMessage: {
    isInvalid: boolean;
    message: string;
  };
}

interface State {
  timezone: any;
  checkOut: boolean;
  successMessage: string;
  failedMessage: string;
  policyCheck: boolean;
  validation:
    | any
    | {
        isValid: boolean;
        failedMessage: {
          isInvalid: boolean;
          message: string;
        };
        successMessage: {
          isInvalid: boolean;
          message: string;
        };
      };
  [key: string]: any;
}

const timeZonesSupported = [
  { name: "Hawaii Standard Time (HST)", timezone: "Pacific/Honolulu" },
  { name: "Hawaii-Aleutian Time (HST/HDT)", timezone: "America/Adak" },
  { name: "Alaska Time (AKST/AKDT)", timezone: "America/Anchorage" },
  { name: "Pacific Time (PST/PDT)", timezone: "America/Los_Angeles" },
  { name: "Mountain Standard Time (MST)", timezone: "America/Phoenix" },
  { name: "Mountain Time (MST/MDT)", timezone: "America/Denver" },
  { name: "Central Time (CST/CDT)", timezone: "America/Chicago" },
  { name: "Eastern Time (EST/EDT)", timezone: "America/New_York" },
  { name: "Arabia Standard Time (AST)", timezone: "Asia/Riyadh" },
];

class EventPostCheckOut extends PureComponent<Props, State> {
  readonly validator: {
    /* eslint-disable-next-line*/
    validate: ({ }) => validations;
    valid: () => validations;
  };
  submitted: boolean;

  constructor(props: Props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: "failedMessage",
        message: "Please enter the message.",
        method: "isEmpty",
        validWhen: false,
      },
      {
        field: "successMessage",
        message: "Please enter the message.",
        method: "isEmpty",
        validWhen: false,
      },
    ]);
    const { successMessage, failedMessage, checkOut, timezone, policyCheck } = this.props;
    this.state = {
      checkOut: checkOut,
      failedMessage: failedMessage,
      policyCheck: policyCheck,
      successMessage: successMessage,
      timezone: timezone,
      validation: this.validator.valid(),
    };
    this.submitted = false;
  }

  handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { successMessage, failedMessage } = this.state;
    const { handleChange } = this.props;
    const value = event.target.value;
    if (value.length > 180) {
      event.preventDefault();
    } else {
      const validationData = { failedMessage: failedMessage, successMessage: successMessage };
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      validationData[name] = value;
      this.setState({ [name]: value, validation: null });
      const validation: validations = this.validator.validate(validationData);
      this.setState({ validation });
      const data = { name: name, val: value };
      handleChange(data);
    }
  };
  handleChangeSwitch = (data: any) => {
    const { handleChange } = this.props;
    this.setState({
      [data.name]: data.val,
    });
    handleChange(data);
  };
  onSelectChange = (zoneData: { name: string; timezone: string }) => {
    const { handleChange } = this.props;
    const data = {} as { name: string; val: string };
    data.name = "timezone";
    data.val = zoneData.timezone;
    handleChange(data);
    this.setState({ timezone: zoneData.timezone });
  };

  handleClickEvent = () => {
    const { handleClickEvent } = this.props;
    const { validation } = this.state;
    this.submitted = true;
    handleClickEvent(validation.isValid);
  };

  customFilter = (option: { data: { searchText: string } }, searchText: string) => {
    if (option.data.searchText.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    }

    return false;
  };

  render() {
    const {
      classes,
      policyCheck,
      meritTemplateList,
      selectedMTIds,
      handleSelectMeritTemplate,
      checkOutAutomationList,
      handleSelectCheckOutAutomation,
      selectedAutomationIds,
      selectedAutomations,
    } = this.props;
    const { validation: stateValidation, successMessage, failedMessage, checkOut, timezone } = this.state;
    const validation: validations = this.submitted ? this.validator.validate(this.state) : stateValidation;
    const selectedTimeZone = timeZonesSupported.find((supportedTimeZone) => supportedTimeZone.timezone === timezone);

    return (
      <div className="paper_popup">
        <div className="grid grid_hr">
          <h4 className="marginbottom_0 margin_10">Customize activity</h4>
          <h6 className="message_subtitle">Customize the check-in / check-out messages and set up the activity.</h6>
        </div>
        <div className="grid grid_hr">
          <Grid item xs={8}>
            <Tooltip title="Use this message to tell participants what they should do after they successfully check-in to this activity.">
              <h6 className="margin_0">
                Successful check-in message
                <img alt="" src={questionMark} style={{ paddingLeft: "8px" }} />
              </h6>
            </Tooltip>
            <TextField
              id="successMessage"
              placeholder="Success Message"
              multiline
              rowsMax="2"
              value={successMessage}
              onChange={this.handleChange("successMessage")}
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                },
              }}
              InputProps={{
                classes: {
                  input: classes.input,
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
            <p>
              <span className="error-msg highlight">{validation.successMessage.message}</span>
            </p>
          </Grid>
          {policyCheck ? (
            <Grid item xs={7}>
              <Tooltip title="Use this message to tell participants what they should do if they attempted to check-in but failed to meet the merit requirements in the policy.">
                <h6 className="margin_0">
                  Policy not met message
                  <img alt="" src={questionMark} style={{ paddingLeft: "8px" }} />
                </h6>
              </Tooltip>
              <TextField
                id="failedMessage"
                placeholder="Fail Message"
                multiline
                rowsMax="2"
                value={failedMessage}
                onChange={this.handleChange("failedMessage")}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                  },
                }}
                InputProps={{
                  classes: {
                    input: classes.input,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
              />
              <p>
                <span className="error-msg highlight">{validation.failedMessage.message}</span>
              </p>
            </Grid>
          ) : (
            ""
          )}
          <Grid item xs={7}>
            <div className="margin_0">
              <h6 className="step4_subtitle margin_0_top">Enable check-out to track time for this activity</h6>
              <div className="step2_switch2 margin_0_top">
                <Switches id="checkOut" name="checkOut" checked={checkOut} handleChange={this.handleChangeSwitch} />
              </div>
            </div>
          </Grid>
          <Grid item>
            {checkOut && (
              <EventCheckOutAutomation
                checkOutAutomationList={checkOutAutomationList}
                handleSelectCheckOutAutomation={handleSelectCheckOutAutomation}
                selectedAutomationIds={selectedAutomationIds}
                selectedAutomations={selectedAutomations}
              />
            )}
          </Grid>
          {policyCheck && (
            <div className="margin_10_bottom">
              <AddMeritTemplate
                handleSelectMeritTemplate={handleSelectMeritTemplate}
                selectedMTIds={selectedMTIds}
                meritTemplateList={meritTemplateList}
              />
            </div>
          )}
          <Grid item xs={5}>
            <h6 className="margin_0_top margin_10_bottom">Timezone</h6>
            <div data-testid="timezoneDropdown">
              <Select
                isClearable
                isSearchable
                name="color"
                value={selectedTimeZone}
                options={timeZonesSupported}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.timezone}
                onChange={this.onSelectChange}
              />
            </div>
            <h6 className="margin_10 time-zone-text">
              Changing timezone will affect all future check-ins and check-outs.
            </h6>
          </Grid>
        </div>
      </div>
    );
  }
}
const styles = createStyles({
  cssLabel: {
    color: "#687FA3",
  },
  input: {
    fontWeight: "normal",
    height: 40,
    width: 280,
  },
  notchedOutline: {
    borderColor: "#687FA3 !important",
    borderWidth: "1px",
  },
});

export default withStyles(styles)(EventPostCheckOut);
