import { Button, Modal } from "antd";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../../../theme/Colors";
import React from "react";

const styles = StyleSheet.create({
  buttonWrapper: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginBottom: "10px",
    marginTop: "30px",
    width: "100%",
  },
  cancelButton: {
    borderColor: Colors.blue,
    borderWidth: "1px",
    color: Colors.blue,
    fontSize: "16px",
    height: "44px",
    letterSpacing: "1.25px",
    marginRight: "3%",
    width: "128px",
  },
  deleteButton: {
    backgroundColor: Colors.darkRed,
    borderColor: Colors.darkRed,
    borderWidth: "1px",
    color: Colors.white,
    fontSize: "16px",
    height: "44px",
    letterSpacing: "1.25px",
    marginLeft: "3%",
    width: "128px",
  },
  modalBody: {
    fontSize: "20px",
    height: "320px",
    marginTop: "50px",
    width: "400px",
  },
  root: {
    backgroundColor: "FFF",
    height: "auto",
    width: "400px",
  },
});

interface Props {
  readonly isVisible: boolean;
  readonly onClose: () => void;
  readonly onDelete: () => void;
}

export const DeleteSessionModal = ({ isVisible, onClose, onDelete }: Props) => {
  return (
    <Modal
      title="Are you sure?"
      visible={isVisible}
      onCancel={onClose}
      footer={false}
      wrapClassName="delete_modal_wrapper"
      className={css(styles.modalBody)}
    >
      <div className={css(styles.root)}>
        <h1 className="delete_header">Delete session</h1>
        <p className="delete_description">This will permanently delete session.</p>
        <div className={css(styles.buttonWrapper)}>
          <Button className={css(styles.cancelButton)} type="link" onClick={onClose}>
            Cancel
          </Button>
          <Button className={css(styles.deleteButton)} type="primary" onClick={() => onDelete()}>
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};
