// draft.js library give flow errors so disabled flow.
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { ContentState, EditorState, Modifier, convertToRaw } from "draft-js";
import React, { PureComponent } from "react";

import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

interface Props {
  editorState?: any;
  onChange?: any;
  fields?: any;
  content?: any;
  ref?: any;
}

interface State {
  editorState: any;
}

class CustomOption extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  addField = (value: any) => {
    const text = `[[${value}]]`;
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      text,
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };
  handleChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.addField(event.target.value);
  };

  render() {
    return <div />;
  }
}
/* eslint react/no-multi-comp: 0 */
class ControlledEditor extends PureComponent<Props, State> {
  [x: string]: any;
  constructor(props: Props) {
    super(props);
    /* eslint no-return-assign: ["off"] */
    this.setDomEditorRef = (ref: any) => (this.domEditor = ref);
    let html: string | null;
    if (localStorage.getItem("draft") === null) {
      html = encodeURIComponent("<p>Enter or copy and paste your form here</p>");
    } else {
      html = localStorage.getItem("draft");
    }
    const contentBlock = htmlToDraft(decodeURIComponent(html!));
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
      };
    }
  }
  UNSAFE_componentWillReceiveProps() {
    const contentBlock = htmlToDraft(decodeURIComponent(localStorage.getItem("draft")!));
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        editorState,
      });
    }
  }
  addField = (field: any) => {
    this.customOption.addField(field);
    this.domEditor.focusEditor();
  };

  onEditorStateChange = (editorState: any) => {
    this.setState({
      editorState,
    });
  };

  render() {
    const { editorState } = this.state;
    let editor: any;
    let editorForWaiver;
    const fields = [] as any;

    if (editorState !== "") {
      editor = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      editorForWaiver = editor;
      localStorage.setItem("draft", encodeURIComponent(editorForWaiver));
      const regex = /(\*\[\[[^\]]+\]\]\*)/gu;
      const matches = editor.match(regex);
      if (matches !== null && matches.length !== 0) {
        matches.map((value: string, index: number) => {
          const value1 = value.replace("*[[", "").replace("]]*", "");
          fields.push({
            elementId: `field-req-${index}`,
            isRequired: true,
            placeholder: value1,
          });
          editor = editor.replace(
            value,
            `<div style="padding-top:10px;padding-bottom:10px;"><input type="text" id="field-req-${index}" required placeholder="${value1}*" style="background: #F0F7FE; width: calc(100% - 30px);padding: 12px;border: 1px solid #ccc;border-radius: 4px;resize: vertical;"></div>`,
          );

          return editor;
        });
      }
      const regex1 = /(\[\[[^\]]+\]\])/gu;
      const matchedFields = editor.match(regex1);
      if (matchedFields !== null && matchedFields.length !== 0) {
        matchedFields.map((value: string, index: number) => {
          const value1 = value.replace("[[", "").replace("]]", "");
          fields.push({
            elementId: `field-${index}`,
            isRequired: false,
            placeholder: value1,
          });
          editor = editor.replace(
            value,
            `<div style="padding-top:10px;padding-bottom:10px;"><input type="text" id="field-${index}" placeholder="${value1}" style="background: #F0F7FE; width: calc(100% - 30px);padding: 12px;border: 1px solid #ccc;border-radius: 4px;resize: vertical;"></div>`,
          );

          return editor;
        });
      }

      localStorage.setItem("html", encodeURIComponent(editor));
    }

    return (
      <div>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
          ref={this.setDomEditorRef}
          toolbar={{
            inline: {
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              inDropdown: false,
              options: ["bold", "italic", "underline"],
            },
            options: [
              "inline",
              "blockType",
              "fontSize",
              "fontFamily",
              "list",
              "textAlign",
              "colorPicker",
              "link",
              "remove",
              "history",
            ],
          }}
          toolbarCustomButtons={[
            <CustomOption
              ref={(instance: any) => {
                this.customOption = instance;
              }}
              fields={fields}
            />,
          ]}
        />
      </div>
    );
  }
}
export default ControlledEditor;
