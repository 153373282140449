import "./Offline.css";
import { Button, Tabs } from "antd";
import React, { useState } from "react";
import BulkCheckinsWrapper from "../CheckinBulk";
import { OfflineLogTable } from "./OfflineLogTable";
import { OrphanedCheckOutTable } from "./OrphanedCheckOutTable";
import { exportOrphanedCheckouts } from "../../../constant/api";
import history from "../../../history";

interface Props {
  readonly allowOfflineMode: boolean;
  readonly notify: (message: string, status: string) => void;
}

export default function AdminLogWrapper({ allowOfflineMode, notify }: Props) {
  const { TabPane } = Tabs;
  const [tab, setTab] = useState<string>("1");

  const downloadOrphanedCheckoutLog = async () => {
    try {
      const response = await exportOrphanedCheckouts();
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Orphaned Checkouts.xlsx");
      const body: HTMLElement = document.body;
      body.appendChild(link);
      link.click();
    } catch (error) {
      notify("Download failed", "error");
    }
  };

  const operations = (
    <>
      {tab === "3" && (
        <Button
          ghost
          type="primary"
          onClick={downloadOrphanedCheckoutLog}
          style={{ marginRight: "10px" }}
          data-testid="downloadButton-AdminLogWrapper"
        >
          Download
        </Button>
      )}
      <Button
        ghost
        type="primary"
        onClick={() => history.push("/members")}
        data-testid="backToCheckInLogButton-AdminLogWrapper"
      >
        Back to check-in log
      </Button>
    </>
  );

  return (
    <div className="common_padding_left" style={{ paddingTop: "16px" }}>
      <Tabs
        onChange={setTab}
        tabBarStyle={{ backgroundColor: "inherit", textAlign: "left" }}
        tabBarExtraContent={operations}
      >
        <TabPane tab="Check-in bulk log" key="1">
          <BulkCheckinsWrapper notify={notify} />
        </TabPane>
        {allowOfflineMode && (
          <TabPane tab="Offline log" key="2">
            <OfflineLogTable notify={notify} />
          </TabPane>
        )}
        {allowOfflineMode && (
          <TabPane tab="Check-out" key="3">
            <OrphanedCheckOutTable notify={notify} />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
}
