import { Button, Card, Col, Icon, Layout, Row } from "antd";
import React, { useState } from "react";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../theme/Colors";
import { Fonts } from "../../theme/Fonts";
import LoadingScreen from "../UI-Components/LoadingScreen";
import SignatureCanvas from "react-signature-canvas";
import { getWavierSignIn } from "./constant/api";
import history from "../../history";

const styles = StyleSheet.create({
  acceptButton: {
    background: Colors.defaultButtonBg,
    color: Colors.white,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
  },
  activityName: {
    color: Colors.sherpa,
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "0.15px",
    lineHeight: "30px",
    paddingLeft: "70px",
  },
  button: {
    borderRadius: "4px",
    fontSize: "16px",
    fontWeight: 500,
    height: "48px",
    letterSpacing: "1.25px",
    lineHeight: "16px",
    textTransform: "uppercase",
    width: "263px",
  },
  cancelButton: {
    background: Colors.checkoutButtonBg,
    color: Colors.sherpa,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    marginTop: "10px",
  },
  cardContainer: {
    background: Colors.white,
    border: `2px solid ${Colors.inActiveGray}`,
    borderRadius: "8px",
    height: "180px",
    marginBottom: "16px",
    marginTop: "52px",
    width: "327px",
  },
  clearButton: {
    color: Colors.checkinButtonBg,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "1.25px",
    lineHeight: "16px",
    marginBottom: "31px",
    padding: 0,
    textTransform: "uppercase",
  },
  close: {
    color: Colors.blackPearl,
    fontSize: "20px",
  },
  contentWrapper: {
    backgroundColor: Colors.white,
    fontFamily: Fonts.roboto,
  },
  headerContainer: {
    display: "flex",
    paddingTop: "30px",
    textAlign: "center",
    width: "327px",
  },
  iconContainer: {
    background: Colors.white,
    borderRadius: "24px",
    boxShadow: `0px 16px 64px ${Colors.backArrowShadow}`,
    height: "40px",
    transform: "matrix(1, 0, 0, -1, 0, 0)",
    width: "40px",
  },
  line: {
    background: Colors.lineBackground,
    borderRadius: "8px",
    height: "3px",
  },
  mainContainer: {
    textAlign: "center",
    width: "327px !important",
  },
  signText: {
    color: Colors.blackPearl,
    fontSize: "24px",
    letterSpacing: "0.25px",
    lineHeight: "32px",
  },
});

interface Props
  extends RouteComponentProps<
    {},
    {},
    {
      fieldsData: Array<{ readonly id: string; readonly value: string }>;
      eventAttendeeId: number;
      attendeeDetails: {
        readonly firstName: string;
        readonly lastName?: string;
      };
    }
  > {
  notify: (message: string, status: string) => void;
}

const WaiverFormSign: React.FC<Props> = ({ notify, location }) => {
  const { Content } = Layout;
  const { goBack, push } = history;
  const { event } = JSON.parse(localStorage.getItem("eventData")!);
  const logInWith = localStorage.getItem("logInWith");

  const [loading, setLoading] = useState<boolean>(false);

  let sign: SignatureCanvas | null = null;

  const getSign = async () => {
    if (sign!.isEmpty()) {
      notify("Please sign the waiver", "error");
    } else {
      setLoading(true);
      if (location.state !== undefined) {
        const { fieldsData, eventAttendeeId, attendeeDetails } = location.state;
        const formatSign = sign!.getTrimmedCanvas().toDataURL("image/png").split(",");
        const params = {
          eventAttendeeId,
          fieldIds: fieldsData,
          signImg: formatSign[1],
          waiverId: event.waiverId,
        };
        const response = await getWavierSignIn(params);
        if (response.data.status === "success") {
          if (logInWith === "guestCheckin") {
            localStorage.removeItem("logInWith");
            push("/web-checkin/guestcheckinsuccess", attendeeDetails);
          } else {
            push({
              pathname: "/web-checkin/checkin-success",
              state: { attendeeDetails: attendeeDetails },
            });
          }
        }
      }
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <LoadingScreen key={1} minHeight="calc(100vh - 125px)" />
      ) : (
        <Content className={css(styles.contentWrapper)}>
          <Row type="flex" justify="space-around" align="middle" style={{ flexDirection: "column" }}>
            <div className={css(styles.headerContainer)}>
              <div className={css(styles.iconContainer)}>
                <Icon
                  data-testid="backIcon-WaiverFormSign"
                  type="arrow-left"
                  style={{ paddingTop: "12px" }}
                  onClick={() => goBack()}
                />
              </div>
              <p className={css(styles.activityName)} data-testid="activityName-WaiverFormSign">
                {event.name}
              </p>
            </div>
            <p className={css(styles.signText)}>Please sign</p>
            <div className={css(styles.mainContainer)}>
              <div className="sign_area">
                <Card className={css(styles.cardContainer)}>
                  <Row type="flex" align="bottom">
                    <Col span={2}>
                      <Icon type="close" className={css(styles.close)} />
                    </Col>
                    <Col span={22}>
                      <span data-testid="canvas-WaiverFormSign">
                        <SignatureCanvas
                          penColor="black"
                          ref={(ref) => {
                            sign = ref;
                          }}
                          canvasProps={{ className: "sigCanvas", height: 110, width: 250 }}
                        />
                      </span>
                    </Col>
                  </Row>
                  <hr className={css(styles.line)} />
                </Card>
                <div style={{ textAlign: "left" }}>
                  <Button
                    className={css(styles.clearButton)}
                    type="link"
                    onClick={() => {
                      sign!.clear();
                    }}
                  >
                    Clear
                  </Button>
                </div>
                <div className="buttonContainer">
                  <Button
                    block
                    className={css([styles.button, styles.acceptButton])}
                    data-testid="acceptButton-WaiverFormSign"
                    onClick={getSign}
                  >
                    accept
                  </Button>
                  <Button
                    block
                    className={css([styles.button, styles.cancelButton])}
                    data-testid="cancelButton-WaiverFormSign"
                    onClick={() => goBack()}
                  >
                    cancel
                  </Button>
                </div>
              </div>
            </div>
          </Row>
        </Content>
      )}
    </React.Fragment>
  );
};

export default withRouter(WaiverFormSign);
