import "./Kiosk.less";
import { Checkbox, Empty, Icon, Input, Layout, List } from "antd";
import React, { useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
import { StyleSheet, css } from "aphrodite";
import { getAllActivities, updateKioskEvent, updateKioskEvents } from "../../../constant/api";
// eslint-disable-next-line no-unused-vars
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { Colors } from "../../../theme/Colors";
import { Fonts } from "../../../theme/Fonts";
import { Footer } from "../../UI-Components/Footer";
import { Header } from "../../UI-Components/KioskHeader";
import LoadingScreen from "../../UI-Components/LoadingScreen";
import history from "../../../history";
import { useDefaultErrorHandler } from "../../../utils/useDefaultErrorHandler";

const styles = StyleSheet.create({
  activityTitle: {
    color: Colors.lightBlue,
    fontFamily: Fonts.lato,
    fontSize: "18px",
    fontWeight: "bold",
    lineHeight: "22px",
    paddingLeft: "5px",
  },
  chooseText: {
    color: Colors.headerText,
    fontFamily: Fonts.roboto,
    fontSize: "34px",
    letterSpacing: "0.25px",
    lineHeight: "51px",
  },
  contentWrapper: {
    minHeight: "84vh",
    overflowX: "hidden",
    overflowY: "scroll",
    padding: "0 300px",
  },
  text: {
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.5px",
    lineHeight: "24px",
    paddingLeft: "5px",
  },
});

interface Props
  extends RouteComponentProps<
    {},
    {},
    {
      kioskId: number;
      isVirtualKioskTabActive: boolean;
      isWebTabActive: boolean;
      selectedKioskEventIds: number[];
      virtualKioskName: string;
    }
  > {
  readonly notify: (arg1: string, arg2: string) => void;
}
const ChooseActivities: React.FC<Props> = ({ notify, location }) => {
  const { Content } = Layout;
  const { push } = history;
  const buttonName = location.state.selectedKioskEventIds ? "Update" : "Save";
  const headerTitle = location.state.selectedKioskEventIds ? "Edit activities" : "Create a Virtual Kiosk";

  const [kioskEvents, setKioskEvents] = useState<Array<EventList>>([]);
  const [searchEvent, setSearchEvent] = useState<string>("");
  const [filteredEvents, setFilteredEvents] = useState<Array<EventList>>([]);
  const [kioskEventIds, setKioskEventIds] = useState<Array<number>>(
    location.state.selectedKioskEventIds ? location.state.selectedKioskEventIds : [],
  );
  const [loading, setLoading] = useState<boolean>(true);
  const { errorHandler } = useDefaultErrorHandler();

  useEffect(() => {
    getKioskEvents();
  }, []);

  useEffect(() => {
    const filteredKioskEvents = kioskEvents.filter((activity: EventList) =>
      activity.name.toLowerCase().includes(searchEvent.toLowerCase()),
    );
    setFilteredEvents(filteredKioskEvents);
  }, [searchEvent]);

  const getKioskEvents = async () => {
    try {
      const response = await getAllActivities();
      const kioskEventsResponse = response.data.item.filter((_) => _.publishStatus && !_.isDraft);
      setKioskEvents(kioskEventsResponse);
      const filteredKioskEvents = kioskEventsResponse.filter((activity: EventList) =>
        activity.name.toLowerCase().includes(searchEvent),
      );
      setFilteredEvents(filteredKioskEvents);
      setLoading(false);
    } catch (error: unknown) {
      errorHandler(error);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchEvent(e.target.value);
  };

  const handleChange = (e: CheckboxChangeEvent) => {
    let eventIdsList: Array<number> = [];
    const selectedEventId: number = e.target.value;
    if (kioskEventIds.includes(selectedEventId)) {
      eventIdsList = kioskEventIds.filter((eventId) => eventId !== selectedEventId);
    } else {
      eventIdsList = kioskEventIds.concat(selectedEventId);
    }
    setKioskEventIds(eventIdsList);
    if (location.state.selectedKioskEventIds) {
      const params = {
        active: e.target.checked,
        eventId: selectedEventId,
        kioskId: location.state.kioskId,
      };
      updateVirtualKioskEvents(params);
    }
  };

  const handleSelectAll = () => {
    const kioskEventIds: Array<number> = filteredEvents.map((event: EventList) => {
      return event.eventId;
    });
    setKioskEventIds(kioskEventIds);
    if (location.state.selectedKioskEventIds) {
      const params = {
        active: true,
        eventId: kioskEventIds,
        kioskId: location.state.kioskId,
      };
      updateVirtualKioskEvents(params);
    }
  };

  const handleDeselectAll = () => {
    setKioskEventIds([]);
    if (location.state.selectedKioskEventIds) {
      const params = {
        active: false,
        eventId: kioskEventIds,
        kioskId: location.state.kioskId,
      };
      updateVirtualKioskEvents(params);
    }
  };

  const saveKioskDetails = async () => {
    if (location.state.selectedKioskEventIds) {
      push({
        pathname: "/kiosk",
        state: { isVirtualKioskTabActive: location.state.isVirtualKioskTabActive, isWebTabActive: true },
      });
    } else {
      const virtualKioskName: string = location.state.virtualKioskName.trim() ?? "";
      const params = { eventId: kioskEventIds, name: virtualKioskName };
      try {
        const response = await updateKioskEvents(params);
        if (response.data.success) {
          notify("Virtual kiosk created successfully.", "success");
          push({
            pathname: "/kiosk",
            state: { isVirtualKioskTabActive: true, isWebTabActive: true },
          });
        }
      } catch (error) {
        notify(error.message, "error");
        push({
          pathname: "/kiosk",
          state: { isVirtualKioskTabActive: true, isWebTabActive: true },
        });
      }
    }
  };

  const updateVirtualKioskEvents = async (params: { active: boolean; eventId: number | number[]; kioskId: number }) => {
    try {
      await updateKioskEvent(params);
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <div className="activities-wrapper">
      {loading ? (
        <LoadingScreen key={1} minHeight="calc(100vh - 125px)" />
      ) : (
        <React.Fragment>
          <Header
            handleClose={() =>
              push({
                pathname: "/kiosk",
                state: { isVirtualKioskTabActive: true, isWebTabActive: true },
              })
            }
            headerText={headerTitle}
          />
          <div className="chooseActivities">
            <div className="content-wrapper">
              <Content className={css(styles.contentWrapper)}>
                <div style={{ marginTop: "30px" }}>
                  <span className={css(styles.chooseText)} data-testid="chooseActivitiesText-ChooseActivities">
                    Choose Activities
                  </span>
                  {!location.state.selectedKioskEventIds && (
                    <div style={{ textAlign: "end" }}>
                      <span className={css(styles.text)}>Setup 2 of 2</span>
                    </div>
                  )}
                  <p className={css(styles.text)}>Choose activities that you want to appear on this virtual kiosk</p>
                </div>
                <Input
                  placeholder="&nbsp;Search"
                  prefix={<Icon type="search" style={{ fontSize: "19px" }} />}
                  size="large"
                  onChange={handleSearch}
                  value={searchEvent}
                />
                <div style={{ marginTop: "10px" }}>
                  <List
                    header={
                      <div>
                        <Checkbox
                          onChange={
                            filteredEvents.length === kioskEventIds.length ? handleDeselectAll : handleSelectAll
                          }
                          checked={filteredEvents.length > 0 && filteredEvents.length === kioskEventIds.length}
                          data-testid="selectAllCheckbox-ChooseActivities"
                        >
                          <span className={css(styles.activityTitle)}>
                            {filteredEvents.length > 0 && filteredEvents.length === kioskEventIds.length
                              ? "Deselect all"
                              : "Select all"}
                          </span>
                        </Checkbox>
                      </div>
                    }
                    locale={{ emptyText: <Empty description="No activities match your search criteria" /> }}
                    dataSource={filteredEvents}
                    renderItem={(event: EventList) => (
                      <List.Item
                        key={event.eventId}
                        style={kioskEventIds.includes(event.eventId) ? { backgroundColor: Colors.white } : {}}
                      >
                        <Checkbox
                          value={event.eventId}
                          onChange={handleChange}
                          checked={kioskEventIds.includes(event.eventId)}
                        >
                          <span className={css(styles.text)} data-testid={`${event.name}-ChooseActivities`}>
                            {event.name}
                          </span>
                        </Checkbox>
                      </List.Item>
                    )}
                  />
                </div>
              </Content>
            </div>
          </div>
          <Footer
            selectedActivitiesCount={kioskEventIds.length}
            handleButton={saveKioskDetails}
            buttonTitle={buttonName}
            handleBackButton={() =>
              push({
                pathname: "/virtualkioskname",
                state: { virtualKioskName: location.state.virtualKioskName.trim() },
              })
            }
            backButton={buttonName !== "Update"}
            testId="save"
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default withRouter(ChooseActivities);
