import "./qualification.css";
import { AppBar, Button, Grid, InputAdornment, TextField } from "@material-ui/core";
import React, { Component } from "react";
// eslint-disable-next-line no-unused-vars
import { RouteComponentProps, withRouter } from "react-router-dom";
import { createQualification, getQualificationById, updateQualification } from "../../../constant/api";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmationBox from "../../UI-Components/Confirmation";
import FormValidator from "../../../constant/validation";
import LoadingScreen from "../../UI-Components/LoadingScreen";
import MeritModal from "../Qualification/meritModal";
import close from "../../../assets/Images/Icons/close.svg";
import error_icon from "../../../assets/Images/Icons/error_icon.svg";
import history from "../../../history";

interface validations {
  isValid: boolean;
  qualificationName: {
    isInvalid: boolean;
    message: string;
  };
}
interface value {
  id: string;
  name: string;
  orgName: string;
  altKey?: string;
}
interface meritData {
  meritName: string;
  name: string;
  orgName: string;
  val: value;
}

interface Merit {
  meritName: string;
  orgName: string;
}

interface Props extends RouteComponentProps<{}, {}, { data: { qualificationId: number }; key: string }> {
  readonly notify: (arg1: string, arg2: string) => void;
  readonly orgDetails?: {
    logoUrl: string;
    name: string;
  };
}

interface State {
  showQualification: boolean;
  merit: { id: string; name: string; orgName: string };
  setMerits: boolean;
  groupList: number[];
  selectedGroup: number;
  groups: any;
  qualificationName: string;
  validation: validations;
  error: string;
  qualificationId: string;
  isLoader: boolean;
  open: boolean;
  qualificationError: string;
  isError: boolean;
  [key: string]: any;
}
/* eslint-disable-next-line react/require-optimization */
class CreateQualification extends Component<Props, State> {
  readonly validator: {
    validate: (state: State) => validations;
    valid: () => validations;
  };
  submitted: boolean;
  constructor(props: Props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: "qualificationName",
        message: "Please enter the qualification name.",
        method: "isEmpty",
        validWhen: false,
      },
    ]);
    const { location } = this.props;
    this.state = {
      error: "",
      groupList: [],
      groups: {},
      isError: false,
      isLoader: location.state !== undefined && true,
      merit: {} as any,
      open: false,
      qualificationError: "",
      qualificationId: "",
      qualificationName: "",
      selectedGroup: 0,
      setMerits: false,
      showQualification: false,
      validation: this.validator.valid(),
    };
    this.submitted = false;
    if (location.state !== undefined) {
      this.getQualificationByID(location.state.data.qualificationId);
    }
  }

  getQualificationByID = async (id: number) => {
    const { location } = this.props;
    if (location.state !== undefined) {
      const response: any = await getQualificationById(id);
      const state = {} as any;
      const groupList = Object.keys(response.data.groups).map((group) => {
        state[group] = response.data.groups[group];

        return parseInt(group, 10);
      });
      state.groups = response.data.groups;
      state.groupList = groupList;
      state.qualificationName = response.data.qualification[0].qualificationName;
      state.qualificationId = location.state.key === "Duplicate" ? "" : response.data.qualification[0].qualificationId;
      state.isLoader = false;
      this.setState(state);
    }
  };
  addMerit = (groupId: number) => {
    this.setState({
      error: "",
      selectedGroup: groupId,
      showQualification: true,
    });
  };
  handleClose = (val: value, meritName: string, orgName: string) => {
    if (val.altKey === undefined) {
      const { groups, groupList, selectedGroup: groupId } = this.state;
      let meritList = [];
      if (groupList.includes(groupId)) {
        const orgState: any = this.state;
        meritList = orgState[groupId];
      } else {
        groupList.push(groupId);
      }
      if (meritList.length === 0) {
        meritList.push({ meritId: val.id, meritName: meritName, orgName: orgName });
        this.setState({
          showQualification: false,
        });
      } else {
        const pos = meritList
          .map((e: { meritId: number }) => {
            return e.meritId;
          })
          .indexOf(val.id);
        if (pos === -1) {
          meritList.push({ meritId: val.id, meritName: meritName, orgName: orgName });
          this.setState({
            showQualification: false,
          });
        } else {
          this.setState({
            error: "This merit is already used, please select another merit.",
          });
        }
      }
      groups[groupId] = meritList;
      this.setState({
        [groupId]: meritList,
        groupList,
        groups,
        qualificationError: "",
      });
    } else {
      this.setState({
        showQualification: false,
      });
    }
  };
  saveMerit = async () => {
    const { state } = this;
    const validation: validations = this.validator.validate(state);
    this.setState({ validation });
    this.submitted = true;
    const { groupList, qualificationId, qualificationName, groups } = this.state;
    for (const x in groups) {
      if (groups[x].length === 0) {
        delete groups[x];
      }
    }
    const { notify } = this.props;
    if (validation.isValid) {
      if (groupList.length === 0) {
        this.setState({
          qualificationError: "Please select at least one merit.",
        });
      } else if (qualificationId === "") {
        const params = {
          groups: groups,
          qualificationName: qualificationName,
        };
        try {
          const response = await createQualification(params);
          if (response.data.success) {
            notify("Qualification created successfully.", "success");
            history.push("/qualification");
          }
        } catch (error) {
          if (!error.response.data.success) {
            notify(`Qualification name ${params.qualificationName} already exist.`, "error");
          }
        }
      } else {
        const params = {
          groups: groups,
          qualificationId: qualificationId,
          qualificationName: qualificationName,
        };
        try {
          const response = await updateQualification(params);
          if (response.data.success) {
            notify("Qualification updated successfully.", "success");
            history.push("/qualification");
          }
        } catch (error) {
          if (!error.response.data.success) {
            notify(`Qualification name ${params.qualificationName} already exist.`, "error");
          }
        }
      }
    } else {
      this.setState({
        isError: true,
      });
    }
  };

  removeMerit = (e: any, group: number, id: string) => {
    e.preventDefault();
    const { groupList: newGroupList, groupList } = this.state;
    const state: any = this.state;
    const newMeritList = state[group];
    newMeritList.splice(id, 1);
    if (newMeritList.length === 0) {
      const groupIndex = groupList.indexOf(group);
      newGroupList.splice(groupIndex, 1);
    }
    this.setState({
      [group]: newMeritList,
      groupList: newGroupList,
    });
  };
  setMerit = (data: meritData) => {
    this.setState({
      merit: data.val,
    });
    this.handleClose(data.val, data.meritName, data.orgName);
  };
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 40) {
      event.preventDefault();
    } else if (event.target.value.length === 0) {
      this.setState({
        isError: true,
        qualificationName: event.target.value,
      });
    } else {
      this.setState({ isError: false, qualificationName: event.target.value });
    }
  };
  goToPreviousPage = () => {
    history.push("/qualification");
  };

  goBack = () => {
    this.setState({
      open: true,
    });
  };
  closeConfirmation = () => {
    this.setState({
      open: false,
    });
  };
  render() {
    const focusUsernameInputField = (input: HTMLInputElement) => {
      if (input !== null) {
        input.focus();
      }
    };
    let groupCount;
    const {
      groupList,
      validation: stateValidation,
      isLoader,
      qualificationName,
      isError,
      qualificationError,
      showQualification,
      error,
      open,
    } = this.state;
    const state: any = this.state;
    const { orgDetails } = this.props;
    if (groupList.length === 0) {
      groupCount = 1;
    } else {
      groupCount = groupList[groupList.length - 1] + 1;
    }
    const validation: validations = this.submitted ? this.validator.validate(this.state) : stateValidation;
    let groupComp = [] as any;

    if (groupList.length > 0) {
      groupComp = groupList.map((group) => {
        const id = groupList.indexOf(group);
        let meritList = [];
        if (state[group].length !== 0) {
          if (state[group]) {
            meritList = state[group].map((merit: Merit) => {
              const id = state[group].indexOf(merit);

              return (
                <div key={id}>
                  <div className="Text" key={id} data-testid={`${merit.meritName}-Merit-CreateQualification`}>
                    {merit.meritName}
                    <div>{merit.orgName}</div>
                    <img alt="" src={close} onClick={(e) => this.removeMerit(e, group, id)} className="close_hover" />
                  </div>
                  <h6 className="or_text">OR</h6>
                </div>
              );
            });
          }
        }
        if (meritList.length !== 0) {
          return (
            <div key={id} className="requirement_div">
              <h6 className="requirement_label">Requirement</h6>
              {meritList}
              <Button
                data-testid="addORMerit-Button"
                className="ORMerit_Button"
                onClick={this.addMerit.bind(this, group)}
              >
                +
              </Button>
              <h6 className="common_text">Any one of the Merits added to this list will qualify as a requirement</h6>

              {id + 1 < 20 && <h6 className="and_button">AND</h6>}
            </div>
          );
        }

        return null;
      });
    }

    return (
      <div>
        {isLoader ? (
          <LoadingScreen minHeight="calc(100vh - 125px)" />
        ) : (
          <div>
            <AppBar position="static" className="innerAppbar appbar">
              <Grid container spacing={16}>
                <Grid item sm={4} className="create_title" data-testid="headerTitle-CreateQualification">
                  Create qualification
                </Grid>
                <Grid item sm={4} className="create_textfield">
                  <TextField
                    id="qualification-name"
                    placeholder="Qualification Name"
                    value={qualificationName}
                    onChange={this.handleChange}
                    inputRef={focusUsernameInputField}
                    margin="none"
                    error={isError}
                    className={isError ? "error_background  form_name" : "form_name"}
                    variant="filled"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {" "}
                          {isError ? <img alt="" src={error_icon} /> : ""}
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      "data-testid": "qualificationNameInputField",
                      maxLength: 40,
                    }}
                    helperText={
                      validation.qualificationName.message === "" ? " " : validation.qualificationName.message
                    }
                  />
                </Grid>
                <Grid item sm={4} className="create_close">
                  <CloseIcon
                    data-testid="closeIcon-CreateQualification"
                    onClick={this.goBack}
                    className="cursor_pointer close"
                  />
                </Grid>
              </Grid>
            </AppBar>
            <main className="main_background create_qualification_modal">
              <div className="align_center">
                <h4 className="header">Add Merits to your Qualification</h4>
                <h6 className="subtitle">
                  Each column represents a requirement for your Qualification. To learn more, see our
                  <a
                    target="_new"
                    href="https://www.merits.com/knowledge/how-do-i-set-up-qualifications-and-credential-requirements-for-my-event"
                  >
                    <span className="link"> Qualification guide.</span>
                  </a>
                </h6>
                <div className="error-msg highlight">{qualificationError}</div>
              </div>
              <div id="button-div" className="button_container">
                {groupComp}
                {groupList.length < 20 && (
                  <div className="requirement_div">
                    <h6 className="requirement_label">Requirement</h6>
                    <Button
                      className="AddMerit_Button"
                      onClick={this.addMerit.bind(this, groupCount)}
                      data-testid="addMeritButton"
                    >
                      {groupList.length > 0 ? "+ Add Requirement" : "+ Add Merit"}
                    </Button>
                    <h6 className="or_text">OR</h6>
                    <Button className="ORMerit_Button" disabled>
                      +
                    </Button>
                    <h6 className="common_text">
                      Any one of the Merits added to this list will qualify as a requirement
                    </h6>
                  </div>
                )}

                <MeritModal
                  showQualification={showQualification}
                  handleClose={this.handleClose}
                  setMerit={this.setMerit}
                  error={error}
                  orgDetails={orgDetails}
                />
              </div>
            </main>
            <div className="innerAppbar appbar">
              <Button className="save_merit_button" onClick={this.saveMerit} data-testid="saveButton">
                Save
              </Button>
            </div>
          </div>
        )}
        <ConfirmationBox
          open={open}
          closeConfirmation={this.closeConfirmation}
          goToPreviousPage={this.goToPreviousPage}
          message="Your changes are not saved. Still want to go back?"
          testId="confirmationModal"
        />
      </div>
    );
  }
}
export default withRouter(CreateQualification);
