// eslint-disable-next-line no-unused-vars
import { DataSource, Order, SelectedRowKeys, TableProps } from "./type";
import React, { useEffect, useMemo, useState } from "react";
// eslint-disable-next-line no-unused-vars
import { WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { EnhancedTableHead } from "./TableHead";
import EnhancedTableToolbar from "./TableToolbar";
import Paper from "@material-ui/core/Paper";
import { Some } from "../../../utils/Some";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

type Props = WithStyles<typeof tableStyles> & TableProps;

const EnhancedTable: React.FC<Props> = ({
  classes,
  dataSource,
  idColumn,
  searchByColumns,
  allowToSelectAll = false,
  allowToSelectRow = false,
  columns,
  dataTestIdKey,
  headerStyle,
  title,
  tableContentHeight,
  paginationProps,
  onSelect,
  selectedRowKeys = [],
  footer,
  defaultPage,
  defaultRowsPerPage,
}: Props) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(paginationProps?.rowsPerPageOptions[0] ?? 5);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (Some(defaultPage)) {
      setPage(defaultPage);
    }
  }, [defaultPage]);

  useEffect(() => {
    if (Some(defaultRowsPerPage)) {
      setRowsPerPage(defaultRowsPerPage);
    }
  }, [defaultRowsPerPage]);

  const sortByOrder = (a: DataSource, b: DataSource, orderBy: string) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }

    return 0;
  };

  const getSorting = (order: Order, orderBy: string) => {
    return order === "desc"
      ? (a: DataSource, b: DataSource) => sortByOrder(a, b, orderBy)
      : (a: DataSource, b: DataSource) => -sortByOrder(a, b, orderBy);
  };

  const stableSort = (array: DataSource[], cmp: (a: DataSource, b: DataSource) => number) => {
    const sortedArray = array.sort((a: DataSource, b: DataSource) => {
      const order = cmp(a, b);

      return order;
    });

    return sortedArray;
  };

  const handleRequestSort = (property: string) => {
    if (orderBy === property && order === "desc") {
      setOrder("asc");
    } else {
      setOrder("desc");
    }
    setOrderBy(property);
  };

  const handleClick = (_: React.MouseEvent<HTMLTableRowElement, MouseEvent>, idColumnValue: SelectedRowKeys) => {
    const selectedIndex = selectedRowKeys.indexOf(idColumnValue);
    if (selectedIndex > -1) {
      const newSelected = selectedRowKeys.filter((selectedRowKey) => selectedRowKey !== idColumnValue);
      if (Some(onSelect)) {
        onSelect(newSelected);
      }

      return;
    }

    const newSelected = selectedRowKeys.concat([idColumnValue]);
    if (Some(onSelect)) {
      onSelect(newSelected);
    }
  };

  const handleChangePage = (_: any, page: number) => {
    setPage(page);
    if (paginationProps) {
      paginationProps.onChangePageOrRows(page, rowsPerPage);
    }
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.target.value);
    if (paginationProps) {
      paginationProps.onChangePageOrRows(page, event.target.value);
    }
  };

  const isSelected = (idColumn: SelectedRowKeys) => {
    return selectedRowKeys.findIndex((rowId) => rowId === idColumn) !== -1;
  };

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filterBySearch = (dataSource: DataSource[]) => {
    if (Some(searchByColumns) && searchByColumns.length) {
      return dataSource.filter((rowData) => {
        return (
          searchByColumns
            .map((searchByColumn) =>
              rowData[searchByColumn].toString().toLocaleLowerCase().includes(searchText.toLocaleLowerCase()),
            )
            .filter((e) => !!e).length > 0
        );
      });
    }

    return dataSource;
  };

  const dataSourceAfterFilter = useMemo(() => {
    return filterBySearch(stableSort(dataSource, getSorting(order, orderBy)));
  }, [dataSource, order, orderBy, searchText]);

  const isAllSelected = useMemo(() => {
    if (dataSourceAfterFilter.length > 0 && selectedRowKeys.length > 0) {
      return (
        dataSourceAfterFilter
          .map((dataSource) => {
            return selectedRowKeys.find((rowKey) => rowKey === dataSource[idColumn]) ? dataSource : undefined;
          })
          .filter(Some).length === dataSourceAfterFilter.length
      );
    }

    return false;
  }, [dataSourceAfterFilter, selectedRowKeys]);

  const handleSelectAllClick = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (dataSourceAfterFilter.length === 0) {
      return;
    }
    if (checked) {
      const selectKeys = filterBySearch(dataSource).map((merit) => merit[idColumn]);
      if (Some(onSelect)) {
        onSelect(selectKeys as string[]);
      }

      return;
    }
    if (Some(onSelect)) {
      onSelect([]);
    }
  };

  return (
    <Paper className={classes.root}>
      <EnhancedTableToolbar
        searchByColumns={searchByColumns}
        numSelected={selectedRowKeys.length}
        onSearch={onSearch}
        showSelectedCounter
        title={title}
        containerPadding={allowToSelectAll || allowToSelectRow ? undefined : "16px"}
      />
      <div className={classes.tableWrapper} style={{ height: tableContentHeight }}>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={dataSource.length}
            columns={columns}
            allowToSelectRow={allowToSelectRow}
            allowToSelectAll={allowToSelectAll}
            isSelectedAll={isAllSelected}
            headerStyle={headerStyle}
          />
          <TableBody style={{ overflow: "scroll" }}>
            {dataSourceAfterFilter.map((row) => {
              const isSelectedRow = isSelected(row[idColumn]);

              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row[idColumn])}
                  role="checkbox"
                  aria-checked={isSelectedRow}
                  tabIndex={-1}
                  key={row[idColumn] as React.Key}
                  selected={isSelectedRow}
                >
                  {(allowToSelectAll || allowToSelectRow) && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isSelectedRow}
                        color="primary"
                        {...(Some(dataTestIdKey) && { "data-testid": `${row[dataTestIdKey]}-CheckBox` })}
                      />
                    </TableCell>
                  )}
                  {columns.map((column) =>
                    column.numeric ? (
                      <TableCell
                        align="right"
                        {...(Some(dataTestIdKey) && {
                          "data-testid": `${row[dataTestIdKey]}-${`${column.key
                            .charAt(0)
                            .toUpperCase()}${column.key.substring(1)}`}-AddMeritTemplate`,
                        })}
                      >
                        {row[column.key]}
                      </TableCell>
                    ) : (
                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        {...(Some(column.customTextAlign) && { align: column.customTextAlign })}
                        {...(Some(dataTestIdKey) && {
                          "data-testid": `${row[dataTestIdKey]}-${`${column.key
                            .charAt(0)
                            .toUpperCase()}${column.key.substring(1)}`}-AddMeritTemplate`,
                        })}
                      >
                        {row[column.key]}
                      </TableCell>
                    ),
                  )}
                </TableRow>
              );
            })}
            {dataSourceAfterFilter.length === 0 && (
              <TableRow style={{ height: 57 }}>
                <TableCell
                  colSpan={columns.length + (allowToSelectAll || allowToSelectRow ? 1 : 0)}
                  style={{ textAlign: "center" }}
                >
                  No Data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {Some(paginationProps) && paginationProps.totalDatalength > 0 && (
        <TablePagination
          rowsPerPageOptions={paginationProps.rowsPerPageOptions}
          component="div"
          count={paginationProps.totalDatalength}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          labelDisplayedRows={({ count }) => `${page + 1} of ${Math.ceil(count / rowsPerPage)}`}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      {Some(footer) && footer}
    </Paper>
  );
  // }
};

const tableStyles = () =>
  createStyles({
    root: {
      width: "100%",
    },
    table: {
      overflowX: "hidden",
    },
    tableWrapper: {
      overflowX: "auto",
      overflowY: "auto",
    },
  });

export default withStyles(tableStyles)(EnhancedTable);
