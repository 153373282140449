// eslint-disable-next-line no-unused-vars
import { AppState, useStore } from "../store/store";
import { Button, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { StyleSheet, css } from "aphrodite";
import { getAdminOrgs, webLoginSuccess } from "../constant/api";
import { useLogout, useMeritAuth0 } from "../hooks/auth";
import CloseIcon from "@material-ui/icons/Close";
import { Fonts } from "../theme/Fonts";
import LoadingScreen from "./UI-Components/LoadingScreen";
// eslint-disable-next-line no-unused-vars
import { OrgInfo } from "../types/auth";
import { Some } from "../utils/Some";
import { Typography } from "@material-ui/core";
import { showErrorToast } from "../utils/ToastHelper";
import { useDefaultErrorHandler } from "../utils/useDefaultErrorHandler";

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    borderRadius: 4,
    fontFamily: Fonts.sfCompactDisplayRegular,
    padding: "30px",
    paddingHorizontal: 32,
    paddingVertical: 32,
  },
  dropdown: {
    width: "100%",
  },
  footer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: 30,
  },
  header: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  wrapper: {
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    flex: 1,
    height: "100vh",
    justifyContent: "center",
  },
});

const setIntermediateScreenSelector = (state: AppState) => state.setIntermediateScreen;
const setLandingPagePathSelector = (state: AppState) => state.setLandingPagePath;
const setSelectedOrgSelector = (state: AppState) => state.setSelectedOrg;

export const SelectOrgModal = () => {
  const dropdownName = "selectOrgDropdown";
  const setIntermediateScreen = useStore(setIntermediateScreenSelector);
  const setLandingPagePath = useStore(setLandingPagePathSelector);
  const setSelectedOrg = useStore(setSelectedOrgSelector);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dropdownSelectedOrg, setDropdownSelectedOrg] = useState<OrgInfo>();
  const [orgs, setOrgs] = useState<OrgInfo[]>([]);
  const [showSpinner, setShowSpinner] = useState(true);
  const { isAuthenticated, isLoading } = useMeritAuth0();
  const logout = useLogout();
  const { errorHandler } = useDefaultErrorHandler();

  const getOrgs = async () => {
    try {
      const results = await getAdminOrgs();

      if (results.length === 1) {
        await onOrgSelected(results[0]);

        return;
      }

      setOrgs(results);
      setShowSpinner(false);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getOrgs();
    } else if (!isAuthenticated && !isLoading) {
      logout();
    }
  }, [isAuthenticated, isLoading]);

  const onOrgSelected = async (selectedOrg: OrgInfo) => {
    const redirectPath = await webLoginSuccess(selectedOrg.id);
    // Update the store with selected org only when login is successfull
    setSelectedOrg(selectedOrg);
    setIntermediateScreen(undefined);
    setLandingPagePath(redirectPath);
  };

  const onContinue = async () => {
    if (Some(dropdownSelectedOrg)) {
      setIsSubmitting(true);
      try {
        await onOrgSelected(dropdownSelectedOrg);
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      showErrorToast({ message: "Please select org to proceed" });
    }
  };

  const handleDropdownChange = (event: React.ChangeEvent<{ value: string }>) => {
    const selectedOrg = orgs.find((org) => org.id === event.target.value);
    setDropdownSelectedOrg(selectedOrg);
  };

  const onCancel = () => {
    logout();
  };

  if (showSpinner) {
    return <LoadingScreen minHeight="100vh" />;
  }

  return (
    <Grid container className={css(styles.wrapper)}>
      <Grid item xs={11} sm={6}>
        <Grid container className={css(styles.container)}>
          <Grid item xs={12}>
            <Grid container className={css(styles.header)}>
              <Typography variant="h6" data-testid="header-SelectOrgModal">
                Select Organisation
              </Typography>
              <CloseIcon onClick={onCancel} width={14} height={14} cursor="pointer" style={{ float: "right" }} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" className={css(styles.dropdown)}>
              <InputLabel htmlFor="select-org">Please select organisation</InputLabel>
              <Select
                displayEmpty
                value={dropdownSelectedOrg?.id || ""}
                onChange={handleDropdownChange}
                input={<OutlinedInput name={dropdownName} labelWidth={185} id="select-org" />}
                name={dropdownName}
                data-testid="selectOrgDropdown-SelectOrgModal"
              >
                {orgs.map((org) => (
                  <MenuItem key={org.id} value={org.id} data-testid={`${org.name}-Option-SelectOrgModal`}>
                    {org.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Grid container className={css(styles.footer)} spacing={16}>
              <Grid item>
                <Button
                  aria-label="Add"
                  variant="outlined"
                  color="default"
                  onClick={onCancel}
                  disabled={isSubmitting}
                  data-testid="cancelButton-SelectOrgModal"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  aria-label="Add"
                  variant="contained"
                  color="primary"
                  onClick={onContinue}
                  disabled={!dropdownSelectedOrg || isSubmitting}
                  data-testid="continueButton-SelectOrgModal"
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
