import { Checkbox, TableHead, TableRow, TableSortLabel, Tooltip } from "@material-ui/core";
import { Fonts } from "../../../theme/Fonts";
import React from "react";
import { Some } from "../../../utils/Some";
import TableCell from "@material-ui/core/TableCell";
// eslint-disable-next-line no-unused-vars
import { TableHeadProps } from "./type";

export const EnhancedTableHead: React.FC<TableHeadProps> = ({
  onSelectAllClick,
  order,
  orderBy,
  onRequestSort,
  columns,
  tableCellProps,
  allowToSelectAll = false,
  allowToSelectRow = false,
  headerStyle,
  isSelectedAll,
}: TableHeadProps) => (
  <TableHead>
    <TableRow>
      {allowToSelectAll && (
        <TableCell padding="checkbox" style={{ width: "10px" }}>
          <Checkbox
            checked={isSelectedAll}
            onChange={onSelectAllClick}
            color="primary"
            data-testid="selectAllCheckBox-TableHead"
          />
        </TableCell>
      )}
      {!allowToSelectAll && allowToSelectRow && <TableCell padding="checkbox" style={{ width: "8px" }} />}
      {columns.map(({ customHeadTextAlign, key, label, numeric, sortable }) => (
        <TableCell
          key={key}
          align={numeric ? "right" : customHeadTextAlign ?? "left"}
          sortDirection={orderBy === key ? order : false}
          component="th"
          style={{
            color: "rgba(0, 0, 0, 0.85)",
            fontFamily: Fonts.roboto,
            fontSize: "15px",
            fontWeight: "500",
            ...headerStyle,
          }}
          {...tableCellProps}
        >
          {sortable ? (
            <Tooltip title="Sort" placement={numeric ? "bottom-end" : "bottom-start"} enterDelay={300}>
              <TableSortLabel
                active={orderBy === key}
                direction={order}
                onClick={Some(onRequestSort) ? () => onRequestSort(key) : undefined}
              >
                {label}
              </TableSortLabel>
            </Tooltip>
          ) : (
            label
          )}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);
