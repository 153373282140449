import * as React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, withMobileDialog } from "@material-ui/core";

interface Props {
  readonly open: boolean;
  readonly closeConfirmation: () => void;
  readonly goToPreviousPage: () => void;
  readonly fullScreen?: boolean;
  readonly message?: string | React.ReactNode;
  readonly testId?: string;
  readonly contentWidth?: number;
}

class ConfirmationBox extends React.PureComponent<Props> {
  close = () => {
    const { closeConfirmation } = this.props;
    closeConfirmation();
  };

  handleClose = () => {
    const { goToPreviousPage } = this.props;
    goToPreviousPage();
  };

  render() {
    const { fullScreen, open, closeConfirmation, message, testId, contentWidth = 350 } = this.props;

    return (
      <div>
        <Dialog
          data-testid={testId}
          fullScreen={fullScreen}
          open={open}
          onClose={closeConfirmation}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent style={{ width: contentWidth }}>
            {typeof message === "string" ? <DialogContentText>{message}</DialogContentText> : <div>{message}</div>}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.close} color="primary" autoFocus data-testid={testId && `${testId}NoButton`}>
              No
            </Button>
            <Button onClick={this.handleClose} color="primary" data-testid={testId && `${testId}YesButton`}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog<Props>()(ConfirmationBox);
