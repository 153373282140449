/* eslint-disable no-unused-vars */
import { LoginIntermediateScreen, OrgInfo } from "../types/auth";
import { createJSONStorage, persist } from "zustand/middleware";
import type { PersistOptions } from "zustand/middleware";
import type { StateCreator } from "zustand";
/* eslint-enable no-unused-vars */
import { create } from "zustand";

export type AppState = {
  readonly landingPagePath: string | undefined;
  readonly intermediateScreen: LoginIntermediateScreen | undefined;
  readonly selectedOrg: OrgInfo | undefined;
  readonly adminEntityId: string | undefined;
  readonly setLandingPagePath: (path: string | undefined) => void;
  readonly setIntermediateScreen: (screen: LoginIntermediateScreen | undefined) => void;
  readonly setSelectedOrg: (selectedOrg: OrgInfo) => void;
  readonly setAdminEntityId: (entityId: string | undefined) => void;
  readonly clearAppState: () => void;
};

type TypedPersist = (config: StateCreator<AppState>, options: PersistOptions<AppState>) => StateCreator<AppState>;

const useStore = create<AppState>(
  (persist as unknown as TypedPersist)(
    (set) => ({
      adminEntityId: undefined,
      clearAppState: () => {
        set({ intermediateScreen: undefined, landingPagePath: undefined, selectedOrg: undefined });
      },
      intermediateScreen: undefined,
      landingPagePath: undefined,
      selectedOrg: undefined,
      setAdminEntityId: (entityId: string | undefined) => {
        set({ adminEntityId: entityId });
      },
      setIntermediateScreen: (intermediateScreen: LoginIntermediateScreen | undefined) => {
        set({ intermediateScreen });
      },
      setLandingPagePath: (landingPagePath: string | undefined) => {
        set({ landingPagePath });
      },
      setSelectedOrg: (selectedOrg: OrgInfo) => {
        set({ selectedOrg });
      },
    }),
    {
      name: "appstate-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);

export { useStore };
