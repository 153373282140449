import { Button, Modal } from "antd";
import { StyleSheet, css } from "aphrodite";
import { Colors } from "../../theme/Colors";
import { Fonts } from "../../theme/Fonts";
import React from "react";

const styles = StyleSheet.create({
  buttonBody: {
    marginLeft: "165px",
    marginTop: "50px",
  },
  cancelButton: {
    background: Colors.white,
    border: `1px solid ${Colors.blue}`,
    borderRadius: "4px",
    boxSizing: "border-box",
    color: Colors.blue,
    fontFamily: Fonts.lato,
    fontSize: "16px",
    fontWeight: "bold",
    height: "44px",
    letterSpacing: "0.01em",
    lineHeight: "19px",
    position: "absolute",
    textAlign: "center",
    width: "131px",
  },
  modalBody: {
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    height: "140px",
    letterSpacing: "0.5px",
    lineHeight: "24px",
    paddingRight: "20px",
  },
  paragraphStyle: {
    color: Colors.gulfBlue,
    fontFamily: Fonts.roboto,
    fontSize: "16px",
    letterSpacing: "0.5px",
    lineHeight: "24px",
  },
  submitButton: {
    background: Colors.blue,
    borderRadius: "4px",
    color: Colors.white,
    fontFamily: Fonts.lato,
    fontSize: "16px",
    fontWeight: "bold",
    height: "44px",
    letterSpacing: "0.05em",
    lineHeight: "19px",
    marginLeft: "148px",
    position: "absolute",
    textAlign: "center",
    width: "136px",
  },
});
interface Props {
  readonly visible: boolean;
  readonly onCancel: () => void;
  readonly onActivate: () => void;
  readonly isActive: boolean;
}

export const KioskConfirmation: React.SFC<Props> = ({ visible, onCancel, onActivate, isActive }) => {
  const buttonName = isActive ? "Deactivate" : "Activate";
  const modalTitle = isActive ? (
    <span data-testid="deactivateModalTitle-KioskConfirmation">Deactivate this kiosk</span>
  ) : (
    <span data-testid="activateModalTitle-KioskConfirmation">Activate this kiosk</span>
  );
  const modalData = isActive
    ? "Your participants will no longer be able to check-in when this virtual kiosk is inactive"
    : "Activating this kiosk will allow people to check-in this kiosk.";

  return (
    <Modal title={modalTitle} visible={visible} onCancel={onCancel} onOk={onActivate} footer={false}>
      <div className={css(styles.modalBody)}>
        <div className={css(styles.paragraphStyle)}>
          <p>{modalData}</p>
        </div>
        <div className={css(styles.buttonBody)}>
          <Button className={css(styles.cancelButton)} key="back" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            key="submit"
            className={css(styles.submitButton)}
            data-testid={`${buttonName}Button-KioskConfirmation`}
            type="primary"
            onClick={onActivate}
          >
            {buttonName}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
